import { DoubleLeftOutlined, DoubleRightOutlined, SettingOutlined,  HomeOutlined, ShoppingCartOutlined, TagsOutlined, GoldOutlined, LineChartOutlined, FundProjectionScreenOutlined, GlobalOutlined, TagOutlined, SyncOutlined, CloudDownloadOutlined, ShoppingOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Layout as AntdLayout, Menu } from 'antd';

import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EcommerceMenuRoutes } from '../../../routing/sideMenu/EcommerceMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import  LogDrawer  from '../../components/model/loglsDrawer/LogDrawer';

import './sideMenu.sass';

const { Sider, Content } = AntdLayout;

export const EcommerceLayout = () => {

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();	
	const [ openLogs , setOpenLogs ] = useState(false);	

	const items = [
		{ label: <Link to={'/ecommerce/inicio'}><b>Panel</b></Link>, key: '/ecommerce/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 				
		{
			label: 'Wordpress',
			key: 'precios',
			type: 'group',		  
			children: [			  
				{ label: <Link to={'/ecommerce/sync'}><b>Productos</b></Link>, key: '/ecommerce/sync', icon: <CloudDownloadOutlined className='menu2-icon' /> },
				{ label: <Link to={'/ecommerce/pedidos'}><b>Pedidos</b></Link>, key: '/ecommerce/pedidos', icon: <ShoppingOutlined className='menu2-icon' /> },
				{ label: <Link to={'/ecommerce/clientes'}><b>Clientes</b></Link>, key: '/ecommerce/clientes', icon: <UserOutlined className='menu2-icon' /> },  						
			  ],
		  },
		{
			label: 'Configuración',
			key: 'config',
			type: 'group',		  
			children: [			  			  
			  { label: <Link to={'/ecommerce/precios'}><b>Precios</b></Link>, key: '/ecommerce/precios', icon: <span style={{ fontSize: '25px' }} className="material-symbols-rounded">tune</span> },
			  { label: <Link to={'/ecommerce/webs'}><b>Web</b></Link>, key: '/ecommerce/webs', icon: <GlobalOutlined className='menu2-icon' />},					
			  ],
		  },
	  ];
	
	const onClose = () => {
		setOpenLogs(false);
	};

	
	const [selectedKey, setSelectedKey] = useState('/ecommerce/inicio');

	useEffect(() => {
	  setSelectedKey(pathName);
	}, [pathName]);
	

	return (
		<AntdLayout className='container-menu'>
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>
			<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/ecommerce/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			<div>			
				{openLogs && (
					<LogDrawer visible={openLogs} onClose={onClose} nameLog='E-COMMERCE' />
				)}
				</div>
				
			</Sider>			
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<EcommerceMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};