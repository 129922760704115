import { BarsOutlined, CarOutlined, CheckOutlined, ClockCircleOutlined, DatabaseOutlined, DoubleLeftOutlined, DoubleRightOutlined,FundProjectionScreenOutlined, GoldOutlined, ShopOutlined, ShoppingCartOutlined, TagsOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ShoppingMenuRoutes } from '../../../routing/sideMenu/ShoppingMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';


const { Sider, Content } = AntdLayout;

export const ShoppingLayout = () => {

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		

	const items = [
		{ label: <Link to={'/compras/inicio'}><b>Panel</b></Link>, key: '/compras/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 		
		{
		  label: 'Compras externas',
		  key: 'peticiones',
		  type: 'group',		  
		  children: [
			{ label: <Link to={'/compras/pendientes'}><b>Peticiones</b></Link>, key: '/compras/pendientes', icon: <ShoppingCartOutlined className='menu2-icon' />},	
			{ label: <Link to={'/compras/ordenes'}><b>Órdenes</b></Link>, key: '/compras/ordenes', icon: <BarsOutlined className='menu2-icon' />}
			],
		},
		{
			label: 'Proveedores',
			key: 'proveedores',
			type: 'group',		  
			children: [
			  { label: <Link to={'/compras/proveedores'}><b>Proveedores</b></Link>, key: '/compras/proveedores', icon: <ShopOutlined className='menu2-icon' />},			
			  
			  ],
		  }
	  ];

	  const [selectedKey, setSelectedKey] = useState('/compras/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  

	return (
		<AntdLayout className='container-menu'>
			<Sider className='side-menu'  collapsed={collapsed} collapsible={true} trigger={null}>
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/compras/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
				
			</Sider>			
			<div className='menu-drop'>
				{collapsed?
				<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
				:
				<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
				}
			</div>
			<Content className='content'>
				<div className='content-div'>
					<ShoppingMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};	