import { Card, Tabs, TabsProps, Tag } from "antd";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG";
import { useContext } from "react";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { InventoryTab } from "./InventoryTab";
import { TransactionTab } from "./TransactionTab";
import { formatPrice } from "../../../../../common/utils/formatPrice";
import moment from "moment";
import { CaretDownOutlined, CaretUpOutlined, EuroCircleOutlined, GoldOutlined } from "@ant-design/icons";
import { INVESTMENT_STATUS } from "../../../../../common/constants/investmentStatus";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";

export const InvestmentDrawer = () => {

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Inventario',
            children: <InventoryTab />,
        },
        {
            key: '2',
            label: 'Transacciones',
            children: <TransactionTab />,
        },

    ];

    const { investmentDrawer, setInvestmentDrawer } = useContext(DrawerContext)

    const { data: investmentData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/investments/${investmentDrawer}`,
        {},
        [],
    );


    return (
        <DrawerSG
            title={<EntityLabel prefix='Inversión' id={investmentData.id} color={false}/>}
            onClose={() => setInvestmentDrawer(null)}
            placement='right'
            visible={!!investmentDrawer}
            width={'40%'}
        >

          
           {INVESTMENT_STATUS[investmentData.status] && (
                        <Tag style={{fontSize:'14px'}} color={INVESTMENT_STATUS[investmentData.status].color}>
                            {INVESTMENT_STATUS[investmentData.status].name}
                        </Tag>
            )}

            <div style={{ display: 'flex', gap:'10px', justifyContent:'center', margin:'20px 0px' }}>
                <Card style={{ borderRadius: '25px', width:'35%', textAlign:'center' }} size='small'>

                    <h3>Valor inicial</h3>
                    <div>
                        <div>{formatPrice(investmentData.revenue)} €</div>
                        <small>{moment(investmentData.start).format('ll')}</small>
                    </div>
                </Card>

                <Card style={{ borderRadius: '25px', width:'35%', textAlign:'center'  }} size='small'>

                    <h3>Cuenta <EuroCircleOutlined /></h3>
                    <>
                        {investmentData.cashAccount > 0 ? <div><b>{formatPrice(investmentData.cashAccount)} €</b></div> : " - "}
                        {
                            Math.round(investmentData.revenue / (100 * investmentData.revenue - (investmentData.cashAccount + investmentData.unitsCostAccount)) / 100) !== 0 ?
                                <small>
                                    {investmentData.revenue - (investmentData.cashAccount + investmentData.unitsCostAccount) > 0 ?
                                        <span style={{ color: 'green' }}>
                                            <CaretUpOutlined />
                                            <span>{Math.round(investmentData.revenue / (100 * investmentData.revenue - (investmentData.cashAccount + investmentData.unitsCostAccount)) / 100)} %</span>
                                        </span>
                                        :
                                        <span style={{ color: 'red' }}>
                                            <CaretDownOutlined />
                                            <span>{Math.round(investmentData.revenue / (100 * investmentData.revenue - (investmentData.cashAccount + investmentData.unitsCostAccount)) / 100)} %</span>
                                        </span>
                                    }
                                </small>
                                :
                                <small style={{ color: 'lightgrey' }}>.</small>
                        }
                    </>

                </Card>

                <Card style={{ borderRadius: '25px', width:'35%', textAlign:'center' }} size='small'>

                    <h3>Cuenta <GoldOutlined /></h3>
                    <>
                        {investmentData.unitsCostAccount > 0 && <div><b>{formatPrice(investmentData.unitsCostAccount)} €</b></div>}
                        {investmentData.units > 0 && <small>coste {investmentData.price} uds.</small>}
                    </>

                </Card>

            </div>

            <Tabs defaultActiveKey="1" items={items} />

        </DrawerSG>
    )
}