

export function ColorLabel({idProp}:any) {
   
    
    const color = stringToColor(idProp);

  return (
      <div style={{ backgroundColor: color,  height: '1px', width:'75px'}}>
      </div>
  );
}

export function stringToColor(str:string) {
  let hash = 0;
  for (let i = 0; i < str?.length; i++) {
    hash = str?.charCodeAt(i) + ((hash << 5) - hash);
    

  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value?.toString(16)).slice(-2);
  }

  return color;
}


