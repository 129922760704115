import { RightCircleOutlined } from "@ant-design/icons"
import { Card, Collapse, Table } from "antd"

export const CollapsedTable = ({columns, data}: any) => {

    const { Panel } = Collapse;

    return (
        <Collapse 
        style={{marginTop:'20px'}}
        accordion
        defaultActiveKey={1}
        expandIcon={({ isActive }) => <RightCircleOutlined style={{color:"orange", fontSize:'15px'}} rotate={isActive ? 90 : 0} />}
        >
            {data && data.map((el: any) => {
               return <Panel  header={ 
                <Card size='small' style={{width:'fit-content', borderRadius:'10px'}}>{el.metal} {el.brand}</Card>} key={el.key}>
                    <Table size='middle' pagination = {false} columns={columns} dataSource={el.children} loading={false}/>
                </Panel>
            })}
        </Collapse>
    )
}