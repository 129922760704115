import { useContext, useState } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { Alert, Card, Col, Spin, Form, Input, Row, Select, Tabs, TabsProps } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import TableSG from "../../../components/design/Table/TableSG";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { CheckOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { customRequest } from "../../../../common/request/customRequest";
import { InputEditable } from "../../../components/design/InputEditable/InputEditable";
import { ROLE_ADMIN, ROLE_CLIENTS } from "../../../../common/constants/roles";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";


const ClientDrawer = () => {

  const { clientDrawer, setClientDrawer } = useContext(DrawerContext)

  const [updateData, setUpdateData] = useState(false);
  const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext)

  const [error, setError] = useState<any>(null);
  
  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/client/${clientDrawer}`,
    {},
    [updateData, updateUserData],
  );

  const [form] = Form.useForm();

  const { data: webs } = useFetch(`${process.env.REACT_APP_API}/combos/webs`, {}, []);


  const TYPE_PERSON = "PERSON"

  const isPerson: boolean = data?.client?.type === TYPE_PERSON;

  const onChangeTab = (key: string) => {

  }

  const { setContractDrawer, setWordPressClientDrawer } = useContext(DrawerContext)
  



  const handleSubmit = async (values: any) => {

    if (!values.webClientId || !values.webClientId[0]?.webName || !values.webClientId[0]?.ecommerceId) {
      setError("Por favor, completa ambos campos.");
      return;
    }

    const data = {
      clientId: clientDrawer,
      webClientId: values.webClientId[0]
    }

    await customRequest(`${process.env.REACT_APP_API}/client/web`, { method: 'POST', data: data }).then(() => {
      setError(null)
      setUpdateData(!updateData)
      removeField(data)
    });

  }

  const deleteWeb = async (web: any) => {

    const data = {
      clientId: clientDrawer,
      webClientId: web
    }

    await customRequest(`${process.env.REACT_APP_API}/client/web`, { method: 'DELETE', data: data }).then(() => {
      setUpdateData(!updateData)
    });

  }

  const columns = [
    {
      title: 'Identificador',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <a onClick={() => setContractDrawer(id)}><EntityLabel id={id} prefix="Contrato" /></a>

      }
    },
    {
      title: 'Tipo',
      key: 'typeDescription',
      dataIndex: 'typeDescription',
    },
    {
      title: 'Cantidad',
      dataIndex: 'revenue',
      key: 'revenue',
      render: (revenue: number) => {
        return <PriceTag price={revenue} />

      },
    },

  ]

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Contratos',
      children: data?.contracts?.length > 0 ?
        <TableSG dataSource={data?.contracts} columns={columns} loading={isLoading} /> : "Sin contratos"
    },

  ];

  const removeField = (index: any) => {
    const { getFieldValue, setFieldsValue } = form;
    const webClientId = getFieldValue('webClientId');
    webClientId.splice(index, 1);
    setFieldsValue({ webClientId });
  };

  const onFormNIF = async (values: any) => {
    const body = {
      mask: "MASK_NIF",
      fiscalId: values.fiscalId,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }

  const onFormPhone = async (values: any) => {
    const body = {
      mask: "MASK_PHONE",
      phone: values.phone,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateUserData(!updateUserData))
  }
  const onFormAddress = async (values: any) => {
    const body = {
      mask: "MASK_ADDRESS",
      address: values.address,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }
  const onFormAccount = async (values: any) => {
    const body = {
      mask: "MASK_ACCOUNT",
      accountNumber: values.accountNumber,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }

  const onFormInvoice = async (values: any) => {
    const body = {
      mask: "MASK_INVOICE",
      invoiceAddress: values.invoiceAddress,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }
  
  const onFormContactPhone = async (values: any) => {
    const body = {
      mask: "MASK_CONTACT_PHONE",
      contactPhone: values.contactPhone,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }
  
  const onFormContactEmail = async (values: any) => {
    const body = {
      mask: "MASK_CONTACT_EMAIL",
      contactEmail: values.contactEmail,
    }

    await customRequest(`${process.env.REACT_APP_API}/client/${clientDrawer}/update`, { method: 'PATCH', data: body }, 'Cambios guardados')
      .then(() => setUpdateData(!updateData))
  }
  

  return (
    <>

      {clientDrawer && (
        <DrawerSG
          title={data?.client?.name}
          onClose={() => setClientDrawer(null)}
          placement='right'
          width={600}
          visible={!!clientDrawer}
        >
          {isLoading ? <div style={{ minHeight: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin /></div> :
            <div style={{ padding: '10px 30px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
              <Card style={{ borderRadius: '25px' }}
                title={isPerson ? 'Datos de Persona Física' : 'Datos de Empresa'}
                size="small"
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div>
                      <span className="label-item-view">Email</span>
                      <div className="item-view-product">{data?.client?.email}</div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={data?.client?.fiscalId || 'No disponible'} label={"NIF"} nameInput={"fiscalId"} onFormFinish={onFormNIF} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={data?.client?.phone || 'No disponible'} label={"Teléfono"} nameInput={"phone"} onFormFinish={onFormPhone} />
                  </Col>
                  <Col span={12}>
                    <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={data?.client?.address || 'No disponible'} label={"Dirección"} nameInput={"address"} onFormFinish={onFormAddress} />
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                 
                    <Col span={12}>
                      <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} value={data?.client?.accountNumber || 'No disponible'} label={"Número de cuenta"} nameInput={"accountNumber"} onFormFinish={onFormAccount} />
                    </Col>
                 
                    <Col span={12}>
                      <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} value={data?.client?.invoiceAddress || 'No disponible'} label={"Dirección de Facturación"} nameInput={"invoiceAddress"} onFormFinish={onFormInvoice} />
                    </Col> 
                </Row>
              </Card>
              {!isPerson &&
                <Card style={{ borderRadius: '25px' }}
                  size="small"
                  title='Datos de Persona de Contacto'
                >    <>

                    <Row gutter={[16, 16]}>
                      <Col span={16}>
                        <div>
                        <span className="label-item-view">Nombre</span>
                          <div className="item-view-product">{data?.client?.contactName}</div>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={data?.client?.contactPhone || 'No disponible'} label={"Teléfono"} nameInput={"contactPhone"} onFormFinish={onFormContactPhone} />
                      </Col>
                      <Col span={12}>
                        <InputEditable roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={data?.client?.contactEmail || 'No disponible'} label={"Email"} nameInput={"contactEmail"} onFormFinish={onFormContactEmail} />
                      </Col>
                    </Row>
                  </>   
                  </Card>
              }
            
              <Card
                style={{ borderRadius: '25px' }}
                size="small"
                title='Id CRM'>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div>
                      <span className="label-item-view">Id CRM</span>                      
                      <div className="item-view-product">{data?.client?.crmId}</div>
                    </div>
                  </Col>
                  </Row>
                </Card>
                
              <Card
                style={{ borderRadius: '25px' }}
                size="small"
                title='Ecommerce'>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <div>
                    <span className="label-item-view">Web</span>
                      {data?.client?.webClientId?.map((web: any, index: any) => {
                        return <div key={index} className="item-view-product">{web.webName}
                        </div>
                      })}
                      {data?.client?.webClientId?.length == 0 && <div className="item-view-product">-</div>}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div>
                    <span className="label-item-view">Identificador</span>
                      {data?.client?.webClientId?.map((web: any, index: any) => {
                        return (
                          <div key={index} className="item-view-product">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <a onClick={() => { setWordPressClientDrawer({ id: web.ecommerceId, webName: web.webName }) }}>
                                {web.ecommerceId}
                              </a>
                              <a onClick={() => { deleteWeb(web) }}>
                                <DeleteOutlined />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                      {data?.client?.webClientId?.length === 0 && <div className="item-view-product">-</div>}
                    </div>
                  </Col>
                </Row>

                <Form form={form} onFinish={(values) => handleSubmit(values)}>
                  <Form.List name="webClientId">
                    {(fields, { add, remove }) => (
                      <>
                        <Row gutter={[16, 16]}>
                          {fields.map((field, index) => (
                            <div key={field.key} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                              <Col span={12}>
                                <Form.Item name={[index, 'webName']}>
                                  <Select style={{ minWidth: '200px' }}>
                                    {webs.map((element: any) => (
                                      <Select.Option key={element.name} value={element.name}>
                                        {element.name}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item name={[index, 'ecommerceId']}>
                                  <Input placeholder="EcommerceId" />
                                </Form.Item>
                              </Col>
                              <div style={{ display: 'flex', gap: '5px', marginBottom: '24px' }}>

                                <CloseOutlined onClick={() => remove(field.name)} />
                                <CheckOutlined onClick={() => { form.submit() }} />
                              </div>
                            </div>
                          ))}
                        </Row>

                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
                          {fields.length < webs?.length && webs?.length !== data?.client?.webClientId.length && (
                            <a onClick={() => add()}>+ Añadir</a>
                          )}
                        </div>
                      </>
                    )}
                  </Form.List>
                </Form>

                {error &&
                  <Alert
                    style={{ marginTop: '12px', borderRadius: '10px' }}
                    type='error'
                    message={error}
                  />
                }
              </Card>
            </div>
          }

          <Tabs defaultActiveKey="1" items={items} onChange={onChangeTab} />

        </DrawerSG>
      )}


    </>
  )
}

export default ClientDrawer;