import { Form, Input, Modal} from 'antd';
import { useState } from 'react';
import { useAuth } from '../../../../../store/AuthContext/AuthContext';
import '../header.sass'
import { customRequest } from '../../../../../common/request/customRequest';


export const EditPassword = (props : {visible : boolean,onClose : any, userId: string}) => {

    const [form] = Form.useForm();
    const [verifyPassword, setVerifyPassword] = useState(false);
    const [samePassword, setSamePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const { logIn } = useAuth();

   

    const setState = () => {
        setVerifyPassword(false);
        setSamePassword(false);
    }

    const onFormFinish = async (values: any) => {
        const data = {
            username: props.userId,
            password: values.currentPassword
        }
        return logIn(data).then((res: any) => {
			if (res.success) {
                if(values.password != values.confirmPassword) 
                    return setVerifyPassword(true);
                if(values.currentPassword == values.password)
                    return setSamePassword(true);
                updatePassword(values);
			}
			if (res.statusCode == 401) {
				setErrorMessage(true);
			}

		})
    }

    const updatePassword = async (values: any) => {
        const body = {
            password: values.password,
        }

        await customRequest(`${process.env.REACT_APP_API}/users/${props.userId}/profile?masks=PASSWORD`, { method: 'PATCH', data: body }, 'Cambios guardados').then(() => 
        setState(), props.onClose())
        
    }

    return (
        <Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onClose} afterClose={form.resetFields}>
			<h2>Cambiar contraseña</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
                <Form.Item label='Contraseña actual' name='currentPassword' rules={[{ required: true }]}>
					<Input.Password />
				</Form.Item>	
				<Form.Item label='Nueva contraseña' name='password' rules={[{ required: true }]}>
					<Input.Password />
				</Form.Item>	
                <Form.Item label='Confirmar contraseña' name='confirmPassword' rules={[{ required: true }]}>
					<Input.Password />
				</Form.Item>				
			</Form>
            {
                verifyPassword &&  <p className='message-error'>Las contraseñas no son iguales</p>
            }
            {
                samePassword && <p className='message-error'>La contraseña actual y la nueva contraseña deben ser distintas</p>
            }
            {
                errorMessage && <p className='message-error'>La contraseña actual no es correcta</p>
            }
		</Modal>
    )
}
