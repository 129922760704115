

function calculateContractDuration(days: number) {
  const years = Math.floor(days / 365);
  const remainingDays = days % 365;
  const months = Math.floor(remainingDays / 30);

  return { years, months };
}

function ContractDuration({ days }: any) {

  const { months, years } = calculateContractDuration(days);


  return (
    <div>
      {years > 0 && (years === 1 ? <p>{years} año</p> : <p>{years} años</p>)}
      {months > 0 && (months === 1 ? <p>{months} mes</p> : <p>{months} meses</p>)}
    </div>
  );
}

export default ContractDuration;