import { Modal, Form, Input, Select, Row, Col, InputNumber } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { WeightUnits } from '../../../../../common/constants/WeightUnits';

export const EditQuotation = (props : {visible : boolean, onFinish : any, quotationSelected: any}) => {

    const [form] = Form.useForm();

    
    const onFormFinish = async (values: any) => {
        
        const data = {
            ...values,
            overCostPrice:values.overCostPrice / 100,
        };
        await authFetch(`${process.env.REACT_APP_API}/quotations/${props.quotationSelected.id}?mask=OVERCOSTPRICE`, { method: 'PUT', data: data });
        props.onFinish();
    };

    
  return (
    <>
        <Modal 
           open={true}
           onOk={() => form.submit()}
           onCancel={props.onFinish}
           afterClose={form.resetFields}
           footer={[
               <div style={{ padding: '10px' }}>
                   <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
                       Cancelar
                   </ButtonSG>
                   <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                       Guardar
                   </ButtonSG>
               </div>
           ]}>
            <h2>Editar Cotización</h2>
            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Row gutter={12}>
					<Col >
						<Form.Item label='Peso' name='weight' initialValue={props.quotationSelected.weightInGrams}>
							<InputNumber min={0} step={0.1} stringMode disabled={true} className="disabled" />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label='Unidad' name='units' initialValue="Gramos">
                            <Input disabled={true} className="disabled" />
						</Form.Item>
					</Col>
					<Col><Form.Item label='Valor de penalización' name='overCostPrice' rules={[{ required: true }]} initialValue={(props.quotationSelected.overCostPrice * 100).toFixed(2)}>
						<InputNumber addonAfter='%'  />
					</Form.Item></Col>
				</Row>	
            </Form>
        </Modal>
    </>
  )
}