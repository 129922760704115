import { Footer } from 'antd/lib/layout/layout';
import './footerSG.sass'


interface FooterSGProps {
    children: React.ReactNode
  }


  const FooterSG = (props:FooterSGProps) => { 
  
  
      return (
          <Footer className= 'footerSG'>
            {props.children}
          </Footer>
      )
    }
  
  
  export default FooterSG