import { Alert, Card, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import ButtonSG from '../../components/design/Buttons/ButtonSG';
import './register.sass';
import { authRequest } from '../../../common/request/authRequest';


const Register = () => {



  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname.split('/');
  const { logIn } = useAuth();
  const email = pathName[pathName.length - 1];
  const verifyToken = new URLSearchParams(location.search).get('verificationToken');

  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    authRequest(`${process.env.REACT_APP_API}/users/${email}?verificationToken=${verifyToken}`, {})
      .then((response) => {

        setUserData(response)
      }).catch((error) => {
        setFormError(true);
        !!error.data.message ?
          setErrorMessage(error.data.message) : setErrorMessage('Error al intentar cargar el usuario');
      }
      );
  }, [])

  const onFinishRegister = async (values: any) => {
    const data = {
      ...values,
      email,
      status: 'REGISTERED',
      verificationToken: verifyToken
    };

    const loginData = {
      username: values.email,
      password: values.password
    };

    authRequest(`${process.env.REACT_APP_API}/users/register?verificationToken=${verifyToken}`, { method: 'PUT', data })
      .then(() => {
        logIn(loginData);
        message.success('Te has registrado con éxito', 3);
        history.push('/welcome');
        setFormError(false);
      }).catch((error) => {
        setFormError(true);
        !!error.data.message ?
          setErrorMessage(error.data.message) : setErrorMessage('Error al intentar registrar el usuario');
      }
      );
  };

  useEffect(() => {
    form.setFieldsValue({ email: (userData as any).email });
  }, [userData])

  return (

    <div className='register-general'>
      <div className='register-general-left'>
        <div className='text-position-register'>
          <h1>SilverGold</h1>
          <p>
            Bienvenido a SilverGold, ha sido invitado a la plataforma a través del email <span className='bold'> {email}</span> </p>
          <p>
            Rellene sus datos para completar el registro.
          </p>
        </div>
      </div>

      <Card className='register-general-right'>
        <div className='header-register-form'>
          <h2>Registro</h2>
        </div>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinishRegister}
          autoComplete="off"
          className='register-form'
        >
          <Form.Item name='email' label='Usuario'>
            <Input className='disabled' disabled={true} defaultValue={email}
            />
          </Form.Item>

          <Form.Item name='name' label='Nombre'
            rules={[{
              required: true,
              type: 'string',
              message: 'Escriba su nombre completo y apellidos (numeros no permitidos)',
              pattern: new RegExp(/^([^0-9]*)$/)
            }]}>
            <Input placeholder='Escriba su nombre'
            />
          </Form.Item>
          
          <Form.Item name='password' label='Contraseña'
            rules={[
							{
							  required: true,
							  message: 'La contraseña es un campo obligatorio',
							},
							{
							  min: 8,
							  message: 'Debe tener al menos 8 caracteres',
							},
							{
								pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
								message: 'Debe contener letras y números',
							},
							{
							  pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
							  message: 'Debe contener mayúsculas y minúsculas',
							},
						  ]}>
          
            <Input.Password autoComplete='new-password' placeholder='Introduce la contraseña' />
          </Form.Item>

          <Form.Item name='confirm-password' label='Confirmar contraseña'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Confirmar la contraseña es un campo requerido'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('La contraseña debe coincidir con el campo anterior'));
                },
              }),
            ]}>
            <Input.Password autoComplete='confirm-password' placeholder='Confirmar la contraseña' />
          </Form.Item>

          <ButtonSG color='black' htmlType='submit'>
            Registrar
          </ButtonSG>

        </Form>
        {
          formError && <Alert type='error' message={errorMessage} />
        }
      </Card>

    </div>
  )
}

export default Register;