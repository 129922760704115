import { NotificationList } from "../../../../interfaces/notificationList"

export function NotificationView (props:{notification: any})  {

  
    function NotificationItem(notification: NotificationList){

        return(
            <div className='notification-item' key={notification.id} style={{backgroundColor:!notification.read ?  'var(--light-accent)' : '#F9F9F9'}}>
            <div className='status'>
                <div className='icon-circle' style={{backgroundColor:!notification.read ?  '#F6B834' : '#DDDDDD'}}></div>
            </div>
            <div className='body'>
                <div className='title-notification'>
                    {notification.title}
                </div>
                <div className='description'>
                    {notification.content}
                </div>
            </div>
        </div>
        )
    }
        
    return (
        <div className='notifications-list'>
                    { props.notification ?  props.notification?.notificationList?.map((notification: NotificationList) => 
                    {
                        return(NotificationItem(notification))
                    }
                    ) : <div className='spinner'>No tiene nuevas notificaciones.</div>}
        </div>
    )
}
