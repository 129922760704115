import { Badge, Card, Col, Collapse, Row, Table } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { authFetch } from "../../../../common/request/authFetch";
import { RightCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useState } from "react";
import { defaultFormat } from "../../../../common/utils/formatDate";

export const Technology = () => {

    const [updateData, setUpdateData] = useState(false)
    const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/services`, {}, [updateData]);

    const { Panel } = Collapse;

    const lastYear = async () => {
        await authFetch(`${process.env.REACT_APP_API}/metalsapi/lastYear`, { method: 'POST' }).then((res) => {
        });
    }

    const latest = async () => {
        await authFetch(`${process.env.REACT_APP_API}/metalsapi/latest`, { method: 'POST' }).then((res) => {
        });
    }

    const timeseries = async () => {
        await authFetch(`${process.env.REACT_APP_API}/metalsapi/timeseries`, { method: 'POST' }).then((res) => {
        });
    }

    const competitors = async () => {
        await authFetch(`${process.env.REACT_APP_API}/competitors`, { method: 'POST' }).then((res) => {
        });
    }

    const wordPressProductsSync = async () => {
        await authFetch(`${process.env.REACT_APP_API}/wordPress/products`, { method: 'PUT' }).then((res) => {
        });
    }

    const wordPressOrdersSync = async () => {
        await authFetch(`${process.env.REACT_APP_API}/wordPress/orders`, { method: 'PUT' }).then((res) => {
        });
    }

    const wordPressClientsSync = async () => {
        await authFetch(`${process.env.REACT_APP_API}/wordPress/clients`, { method: 'PUT' }).then((res) => {
        });
    }
    const notificationTest = async () => {
        await authFetch(`${process.env.REACT_APP_API}/users/notifications`, { method: 'POST' }).then((res) => {
        });
    }

    const operationsTotalFreedom = async () => {
        await authFetch(`${process.env.REACT_APP_API}/operations/freedom`, { method: 'POST' }).then((res) => {
        });
    }

    const uploadAutomaticPrice = async () => {
        await authFetch(`${process.env.REACT_APP_API}/wordPressProducts/automaticPrice`, { method: 'POST' }).then((res) => {
        });
    }



    const columns = [
        {
            title: 'Ejecución',
            dataIndex: 'date',
            key: 'date',
            width: '20%',
            render: (_: any, record: any) => {
                return <div style={{ display: 'flex' }}>
                    <Badge status={record.success ? "success" : "error"} />
                    <p>{defaultFormat(record.date)}</p>
                </div>
            }
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            width: '20%',
            render: (_: any, record: any) => {
                const tipo = record.manual ? "Manual" : "Automático";
                return <div style={{ display: 'flex' }}>
                    <p>{tipo}</p>
                </div>
            }
        },
        {
            title: 'Mensaje',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
            width: '30%',
        },
    ];

    return (

        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Servicios Programados</span>

                <Row gutter={16}>
                    <Col>
                        <ButtonSG>
                            <SyncOutlined onClick={() => { setUpdateData(!updateData) }} />
                        </ButtonSG>
                    </Col>

                    <Col ><ButtonSG onClick={() => lastYear()}>
                        Metal - Último año por día
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => latest()}>
                        Metal - Última hora
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => timeseries()}>
                        Metal - Últimos 30 días por día
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => competitors()}>
                        Competidores por hora
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => wordPressProductsSync()}>
                        WP Products
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => wordPressOrdersSync()}>
                        WP Orders
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => wordPressClientsSync()}>
                        WP Clients
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => notificationTest()}>
                        Notification test
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => operationsTotalFreedom()}>
                        Scheduler Libertad
                    </ButtonSG></Col>
                    <Col ><ButtonSG onClick={() => uploadAutomaticPrice()}>
                        Precios Automáticos
                    </ButtonSG></Col>
                </Row>

            </div>
            <Collapse style={{ marginTop: '20px' }} accordion>
                {storesData &&
                    storesData.map((el: any, index: any) => {
                        const bageColor = el.executions[0].success ? 'green' : 'red';
                        return (
                            <Panel
                                header={
                                    <>
                                        <Badge color={bageColor} />
                                        {el.name} <small> :: {el.api}</small>
                                    </>
                                }
                                key={index}
                            >
                                <Table
                                    size='middle'
                                    pagination={{ position: ['bottomLeft'] }}
                                    columns={columns}
                                    dataSource={el.executions}
                                    loading={isLoading}
                                />
                            </Panel>
                        );
                    })}
            </Collapse>

        </Card>
    )
}