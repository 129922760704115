export const ADJUDICATION_STATUS: {[key:string] : any } = {
    ASSIGNED : {name: 'Asignado', color:'green'},
    INTERMEDIATION : {name: 'Intermediación', color:'gold'},
    COMPANY : {name: 'Stock Interno', color:'blue'},
    NON_STOCK : {name: 'Compra Externa', color:'red'}    
  }

  export const SALE_STATUS: {[key:string] : any } = {
    SOLD : {name: 'Vendido', color:'green'},
    PENDING : {name: 'Pendiente', color:'gold'},
  }