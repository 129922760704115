import { useState, useContext } from 'react'
import { Badge, Card, Input, Modal, Switch, Tag, Tooltip } from "antd";
import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import TableSG from '../../../components/design/Table/TableSG';
import { WordPressAddPricesModal } from './modal/WordPressAddPricesModal';
import { RulePriceInfo, RulePriceObject } from '../../../../interfaces/RulePriceInfo';
import { FilterDropdownProps } from '../../../../types/filterDropDown';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons';
import './WordPress.css'
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { ROLE_ADMIN, ROLE_ECOMMERCE } from '../../../../common/constants/roles';
import AuthRoles from '../../../components/design/AuthRoles/AuthRoles';
import { authFetch } from '../../../../common/request/authFetch';
import WordPressPricesDrawer from './drawer/WordPressPricesDrawer';
import { useIsAuthorized } from '../../../../common/request/AuthorizationUtils';


const MASKS_IS_AUTOMATIC = "masks=IS_AUTOMATIC"
export const WordPressPrices = () => {

  const IsAuthorized = useIsAuthorized();


  const [updateData, setUpdateData] = useState(false);
  const [rulePriceInfo, setRulePriceInfo] = useState<RulePriceInfo | null>(null);

  const [uploadedToWebsite, setUpLoadedToWebsite] = useState(true);

  const [openDrawer, setOpenDrawer] = useState(false);


  const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/prices/?upload=${uploadedToWebsite}`, {}, [updateData, uploadedToWebsite]);
  
  const { setProductDrawer } = useContext(DrawerContext)

  async function addPricesAutomatically(isAutomatic: boolean, value: RulePriceObject) {
    const data = {
      id: value.id,
      sku: value.sku,
      ecommerceId: value.ecommerceId,
      webId: value.webId,
      capitalGain: value.capitalGain,
      
      automaticSync: isAutomatic
    }
     await authFetch(`${process.env.REACT_APP_API}/prices/?${MASKS_IS_AUTOMATIC}`, { 
      method: 'PATCH',
      data: data
  })

  }

  const deleteProduct = async (rulePriceId: string) => {
    await authFetch(`${process.env.REACT_APP_API}/prices/${rulePriceId}`, { method: 'DELETE'});
    setUpdateData(!updateData);
  };

  const columns = [
    {
      title: '',
      width: '5%',
      render: () => {
        return (
          <>
            {<span style={{ fontSize: '25px' }} className="material-symbols-rounded">tune</span>}
          </>
        )
      }
    },
    {
      title: 'Producto Interno',
      dataIndex: ['productTypeEnriched', 'name'],
      width: '20%',
      render: (_: string, record: RulePriceInfo) => {
        return (
          <>
            {record.productTypeEnriched && (
              <>
                {record.productTypeEnriched.name && <div>{record.productTypeEnriched.name}</div>}
                {record.productTypeEnriched.sku && (
                  <a onClick={() => setProductDrawer(record.productTypeEnriched!.sku)}>
                    <small>{record.productTypeEnriched.sku}</small>
                  </a>
                )}
              </>
            )}
          </>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: string, record: RulePriceInfo) => {
        return (record.productTypeEnriched && record.productTypeEnriched.name && record.productTypeEnriched.name.toLowerCase().includes(value.toLowerCase()) 
        || (record.productTypeEnriched && record.productTypeEnriched.sku && record.productTypeEnriched.sku?.toLowerCase().includes(value.toLowerCase())));
      },
    },
    {
      title: 'Wordpress',
      dataIndex: 'webId',
      render: (_: string, record: RulePriceInfo) => (
        <>
          {
            <div>
              {record.wordPressId && <div>{record.webId}</div>}
              {record.wordPressId ? <small>{record.wordPressId}</small> : <div><small><i style={{ color: 'grey' }}>No subido en {record.webId}</i></small></div>}
            </div>
          }
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dominio"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.webId.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Parámetros',
      dataIndex: 'rulePrice',
      filters: [
        { text: 'Por defecto', value: true },
        { text: 'Manual', value: false },
      ],
      onFilter: (value:any, record:any) => record.rulePrice.defaultCapitalGain === value,
      render: (_: RulePriceObject, record: RulePriceInfo) => {
        return <>
          {
            !!record.rulePrice ?
              <Card size="small" style={{ borderRadius: '10px', width: 'fit-content' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', gap: '1rem' }}>
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div>
                      {record.rulePrice.capitalGain && (
                        <>
                        Margen:
                        {record.rulePrice.defaultCapitalGain ? (
                          <Tag color="grey" className='btn_rulePrice'>
                                {100 * Number(record.rulePrice.capitalGain.toFixed(2))}%
                                </Tag>
                        ) : (

                        <Tag color="var(--primary)" className='btn_rulePrice'>
                          {(100 * record.rulePrice.capitalGain).toFixed(0)}%
                        </Tag>

                        ) 
                      }

                        </>)}
                        <div>
                        {record.rulePrice.discount && (
                          <>
                            Rebaja:
                            <Tag color="var(--primary)" className='btn_rulePrice'>
                              {100 * record.rulePrice.discount}%
                            </Tag>
                          </>
                        )}
                        </div>
                      </div>
                    </div>
                    {(record.rulePrice.andorranoUrl || record.rulePrice.coinInvestUrl || record.rulePrice.serviOroUrl || record.rulePrice.inversOroUrl) &&
                      <div>
                        <span>Competencia:</span>
                        <div style={{ marginLeft: '15px' }}>
                          {record.rulePrice.andorranoUrl && (
                            <span >
                              <li><a href={record.rulePrice.andorranoUrl} target="_blank">Andorrano Joyería</a></li>
                            </span>
                          )}
                          {record.rulePrice.coinInvestUrl && (
                            <li>
                              <a href={record.rulePrice.coinInvestUrl} target="_blank">StoneX Bullion</a>
                            </li>
                          )}
                          {record.rulePrice.serviOroUrl && (
                            <li>
                              <a href={record.rulePrice.serviOroUrl} target="_blank">Servi Oro</a>
                            </li>
                          )}
                          {record.rulePrice.inversOroUrl && (
                            <li>
                              <a href={record.rulePrice.inversOroUrl} target="_blank">Invers Oro</a>
                            </li>
                          )}
                        </div>
                      </div>}
                  </div>
                  <AuthRoles roles={[ROLE_ADMIN, ROLE_ECOMMERCE.WRITE]}>
                  <div style={{ display: 'flex' }}>
                    <EditOutlined onClick={() => { setRulePriceInfo(record) }} style={{ marginTop: '5px' }} />
                  </div>
                  </AuthRoles>
                </div>
              </Card>
              :
              record.wordPressId &&
              <ButtonSG size='small' color='yellow' onClick={() => { setRulePriceInfo(record); }}>
                <small> Añadir</small>
              </ButtonSG>
          }
        </>
      }
    },
    {
      title: 'Precios automáticos',
      dataIndex: 'rulePrice',
      render: (_: RulePriceObject, record: RulePriceInfo) => (
        record?.rulePrice?.id && (
          <Switch
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE])}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={record.rulePrice.automaticSync} 
            onChange={(e) => addPricesAutomatically(e, record.rulePrice!)}
          />
        )
      ),
    },
    {
      title: 'Acciones',
      render: ( record: RulePriceInfo) => {
       return <AuthRoles roles={[ROLE_ADMIN, ROLE_ECOMMERCE.WRITE]} >
              <ButtonSG size='small' onClick={						
                () => {
                  Modal.confirm({
                  title: '¿Estás seguro de eliminar esta configuración de precio?',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk: () => deleteProduct(record.rulePrice!.id!)});
                }
              }>
              <DeleteOutlined/> 
            </ButtonSG>
          </AuthRoles>
          
      }
    },
    
  ]

  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
        <div className="table-header">
          <span className='title-summary'>Configuración de Precios</span>
        </div>
      </Card>
      <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end', alignItems:'center' }}>
      <SettingOutlined onClick={()=>{setOpenDrawer(true)}}/>
        <p className='pl-20 desc'>No subidos a web</p>
        <Switch loading={isLoading} onChange={(value) => { setUpLoadedToWebsite(!value) }} />
      </div>
      <TableSG size='small' columns={columns} dataSource={data} rowKey={'key'} loading={isLoading} />
      {
        rulePriceInfo != null && <WordPressAddPricesModal rulePriceInfo={rulePriceInfo} onFinish={() => { setRulePriceInfo(null); setUpdateData(!updateData); }} />
      }

      {
        openDrawer && <WordPressPricesDrawer  onClose={() => { setOpenDrawer(false); setUpdateData(!updateData); }}/>
      }
    </>
  )
}