import { DoubleLeftOutlined, DoubleRightOutlined, IdcardOutlined, LaptopOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass';
import { useEffect, useState } from 'react';
import { TechnologyMenuRoutes } from '../../../routing/sideMenu/TechnologyMenuRoutes';

const { Sider, Content } = AntdLayout;

export const TechnologyLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		
	
	const items = [
		{ label: <Link to={'/tecnologia/inicio'}><b>Servicios</b></Link>, key: '/tecnologia/inicio', icon: <LaptopOutlined className='menu2-icon' /> }, 		
		{ label: <Link to={'/tecnologia/actividad'}><b>Actividad</b></Link>, key: '/tecnologia/actividad', icon: <IdcardOutlined className='menu2-icon' /> }, 		
	
	  ];

	  const [selectedKey, setSelectedKey] = useState('/tecnologia/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  

	return (
		<AntdLayout className='container-menu'>			
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>	
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/tecnologia/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			</Sider>
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<TechnologyMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};