import { Modal, Form, Input, Select, Row, Col, InputNumber, Tag } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const EditMargin = (props : {visible : boolean, onFinish : any, marginSelected: any}) => {

    const [form] = Form.useForm();
    
    const onFormFinish = async (values: any) => {
        
        const data = {
            type: props.marginSelected.type,
            value: values.value / 100,
        };
        await authFetch(`${process.env.REACT_APP_API}/businessParams`, { method: 'POST', data: data });
        props.onFinish();
    };
    
  return (
    <>
        <Modal 
           open={true}
           onOk={() => form.submit()}
           onCancel={props.onFinish}
           afterClose={form.resetFields}
           footer={[
               <div style={{ padding: '10px' }}>
                   <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
                       Cancelar
                   </ButtonSG>
                   <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                       Guardar
                   </ButtonSG>
               </div>
           ]}>
            <h2>Editar Parámetro</h2>           
        
            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Row gutter={12}>
					<Col >
						<Form.Item label='Tipo' name='margin' initialValue={props.marginSelected.typeDescription}>
							<Input disabled={true} className="disabled" />
						</Form.Item>
                       
                        <Form.Item label='Valor' name='value' rules={[{ required: true }]} initialValue={props.marginSelected.value * 100}>
						    <InputNumber step="0.001" addonAfter='%' />
					    </Form.Item>
                          
					</Col>
				</Row>	
            </Form>

        </Modal>
    </>
  )
}