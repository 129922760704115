import {DollarCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined, FundProjectionScreenOutlined, SwapOutlined, TeamOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AccountingMenuRoutes } from '../../../routing/sideMenu/AccountingMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass';
import { useEffect, useState } from 'react';

const { Sider, Content } = AntdLayout;

export const AccountingLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		
	
	
	const items = [
		{ label: <Link to={'/contabilidad/inicio'}><b>Panel</b></Link>, key: '/contabilidad/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 
		{
			label: 'Almacén',
			key: 'Almacen',
			type: 'group',		  
			children: [
        	{ label: <Link to={'/contabilidad/transacciones'}><b>Facturación</b></Link>, key: '/contabilidad/transacciones', icon:  <SwapOutlined className='menu2-icon' />},
		]},		
		{
			label: 'Contratos',
			key: 'Contratos',
			type: 'group',		  
			children: [
        		{ label: <Link to={'/contabilidad/pagos'}><b>Pagos de Cliente</b></Link>, key: '/contabilidad/pagos', icon: <DollarCircleOutlined className='menu2-icon' /> }		
		]},
		{
			label: 'Comisiones',
			key: 'Comisiones',
			type: 'group',		  
			children: [
        		{ label: <Link to={'/contabilidad/comisiones'}><b>Consultores</b></Link>, key: '/contabilidad/comisiones', icon: <TeamOutlined className='menu2-icon' /> }		
		]}
	  ];

	  const [selectedKey, setSelectedKey] = useState('/contabilidad/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  
	return (
		<AntdLayout className='container-menu'>			
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>	
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/contabilidad/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			</Sider>
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<AccountingMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};