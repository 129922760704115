import { Affix, ConfigProvider, Layout as AntdLayout, message } from 'antd';
import es_ES from "antd/lib/locale/es_ES";
import 'moment/locale/es';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import Bot from '../../components/model/bot/Bot';
import FooterSG from '../../pages/common/footer/footerSG';
import CustomHeader from '../../pages/common/header/CustomHeader';
import MainContent from './MainContent';
import AllDrawers from '../../pages/common/Drawers/AllDrawers';

const MainLayout = () => {
  
  const { isAuth } = useAuth();  


  return (
    <AntdLayout style={{ minHeight: '100vh', background: 'white' }}>
   
    {isAuth && <CustomHeader isAuthenticated={isAuth} viewHeader={true}/>}
							
      <ConfigProvider locale={es_ES}>
        <MainContent/>
      </ConfigProvider>
      {
          isAuth && <Affix >

        <FooterSG>                
        {
          isAuth && <Affix >            
          <Bot />
        </Affix>
        }	
      </FooterSG>
      </Affix>

      }
      <AllDrawers/>
    </AntdLayout>
	)
};

export default MainLayout;