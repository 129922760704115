import React from 'react'
import { Modal, Form, Input } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import { WebInterface } from '../../../../../interfaces/WebInterface';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const EditCredentials = (props : {visible : boolean,onClose : any, credentialsSelected: any}) => {

    const [form] = Form.useForm();

    const onFormFinish = async (values: any) => {
        const data = {
            name: props.credentialsSelected.name,
            mask:"API_CREDENTIALS_MASK",
            productsSyncAutomated: props.credentialsSelected.productsSyncAutomated,
            pricingUploadAutomated: props.credentialsSelected.pricingUploadAutomated,
            description: props.credentialsSelected.description,
            url: props.credentialsSelected.url,
            apiUser: values.apiUser,
            apiKey: values.apiKey
        };
        await authFetch(`${process.env.REACT_APP_API}/webs`, { method: 'PATCH', data: data });
        props.onClose();
    };

  return (
    <>
        <Modal 
            open={props.visible} 
            onOk={() => form.submit()} 
            onCancel={props.onClose} 
            afterClose={form.resetFields}
            footer={[
                <div style={{padding:'10px'}}>
                  <ButtonSG key="back" color='dark-white' onClick={props.onClose}>
                    Cancelar 
                  </ButtonSG>
                  <ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
                    Guardar
                  </ButtonSG>
                </div>
              ]}>
            <h2>Credenciales API</h2>
            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Form.Item 
                    initialValue={props.credentialsSelected.apiUser}
                    label='Usuario de API' 
                    name='apiUser' 
                    rules={[{ required: true, message: 'El campo es requerido'}]}
                    >
                    <Input />
                </Form.Item>
                <Form.Item 
                    initialValue={props.credentialsSelected.apiKey}
                    label='Contraseña de API' 
                    name='apiKey' 
                    rules={[{ required:true, message: 'El campo es requerido'}]}
                    >
                    <Input.Password 
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>			
            </Form>
        </Modal>
    </>
  )
}
