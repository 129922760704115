import { Column } from "@ant-design/charts";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG";
import { Card } from "antd";

export const UserActivityDrawer = ({ openChart, setOpenChart }: any) => {



    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/userActivity/chart?email=${openChart}`, {}, []);

    const viewsChartConfig : any = {
        data: data,
        isStack: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        color: ['#80DEEA', '#1E88E5']        
    };


    return (
        <DrawerSG
            title={<div className="text-ellipsis" style={{ width: '500px' }}>{`Usuario: ${openChart}`}</div>}
            onClose={() => {
                setOpenChart(null);
            }}
            placement='right'
            visible={!!openChart}
            className="text-ellipsis"
            width={'40%'}
        >
            <Card bodyStyle={{ backgroundColor: 'white', borderRadius: '25px', height: '327px' }} style={{ border: 'none' }}>
                <div className="title-charts">
                    <h1>Interacciones</h1>
                </div>
                <div style={{ padding: '25px 0px 25px 25px' }}>
                    <Column {...viewsChartConfig} height={200} loading={false} />
                </div>
            </Card>        </DrawerSG>
    )
}