import { useContext, useState } from 'react';
import { Card, Input, Modal } from 'antd';
import { MailOutlined, PhoneOutlined, PaperClipOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import TableSG from '../../../components/design/Table/TableSG';
import { ModalStateDocument } from './modal/ModalStateDocument';
import { NewClientModal } from './modal/NewClientModal';
import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import AuthRoles from '../../../components/design/AuthRoles/AuthRoles';
import { ROLE_ADMIN, ROLE_CLIENTS } from '../../../../common/constants/roles';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { Client } from '../../../../interfaces/client';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { customRequest } from '../../../../common/request/customRequest';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { DocumentsModal } from '../../common/UploadDocuments/DocumentsModal';
import { useHistory } from 'react-router-dom'
import { GlobalVariableContext } from '../../../../store/GlobalVariableContext/GlobalVariableContext';
import { useIsAuthorized } from '../../../../common/request/AuthorizationUtils';

export const Prospects = () => {

  const IsAuthorized = useIsAuthorized();

  const [openModalStateDocument, setOpenModalStateDocument] = useState(false);
  const [openModalNewClient, setOpenModalNewClient] = useState(false);
  const [documentsModal, setDocumentsModal] = useState<any>(null);
  const onCLoseModalStateDocument = () => setOpenModalStateDocument(false)
  const [updateData, setUpdateData] = useState(false);

  const {updateUserData} = useContext(GlobalVariableContext)

  const { setClientDrawer } = useContext(DrawerContext);
  const { setUserDrawer } = useContext(DrawerContext);

  const history = useHistory();

  const { data: clientsData, isLoading } = useFetch(`${process.env.REACT_APP_API}/client/prospects`, {}, [updateData, updateUserData]);

  const setElegible = async (clientId: string) => {

    const uri = `${process.env.REACT_APP_API}/client/${clientId}/elegible`;

    await customRequest(uri, { method: 'PATCH' }, 'Cliente movido a elegible').then(() => {
      setUpdateData(!updateData);
      history.push('/client/elegible');
    }
    )
  }

  const columns = [
    {
      title: '',
      width: '3%',
      sorter: (a: any, b: any) => {
        return (a.contactEMail === null ? 1 : b.contactEMail === null ? -1 : a.contactEMail.localeCompare(b.contactEMail));
      },
      render: (record: any) => {

        return (
          <>
            {
              !record.contactEMail ? (
                <span style={{ fontSize: '20px' }} className="material-symbols-rounded">person</span>
              )
                :
                (
                  <span style={{ fontSize: '20px' }} className="material-symbols-outlined">corporate_fare</span>

                )
            }
          </>
        )
      }
    },
    {
      title: 'Cliente',
      render: (record: Client) => {
        return <>
          <a onClick={() => setClientDrawer(record.id)}>
            {record.name}
          </a>
          <p style={{ color: 'DimGray' }}><MailOutlined /> {record.email}</p>
          <p style={{ color: 'DimGray' }}><PhoneOutlined /> {record.phone}</p>
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,

      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Client) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'eCommerce',
      render: (record: any) => (
        <div>
          {record.websName.length !== 0 ? record.websName.map((web: string) => (
            <p key={web}>{web}</p>
          )): <small style={{color:'#bbbbbb'}}>Sin registro</small>}
        </div>
      )
  },
  {
    title: 'Id CRM',
    key: 'crmId',    
    render: (record: any) => (
      <div style={{color:'grey'}}>
        {record?.crmId}
      </div>
    ),    
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,

      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="CRM"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Client) => {
        return record.crmId?.toLowerCase().includes(value.toLowerCase());
      }
  },
    {
      title: 'Documentos',
      align: 'center',
      width:'15%',
      render: (record: any) => {
        return (
          <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
              <div  style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            {record.documents !== null ? record.documents.map((d: any) => (
              <div key={d.name}><small style={{display:'flex', alignItems:'center', gap:'3px'}}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
            )): <small style={{color:'#bbbbbb'}}>Sin documentos</small>}</div>
            <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
            <PlusOutlined />
            </ButtonSG>
          </div>
        );
      },
      sorter: (a:any, b:any) => {
        if (a.documents !== null && b.documents === null) {
          return -1;
        }
        if (a.documents === null && b.documents !== null) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Comercial',
      render: (record: Client) => {
        return <>
          {!!record.commercialName &&
            <>
              <a onClick={() => { setUserDrawer(record.commercialId) }}>
                <strong>{record.commercialName}</strong>
              </a>
              <div style={{ color: 'grey' }}>{record.commercialId}</div>
            </>
          }
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,

      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Comercial"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Client) => {
        return record.commercialName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Acciones',

      render: (record: Client) => {
        return <>
          <AuthRoles roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]}>
            <ButtonSG size='small' disabled={!IsAuthorized([ROLE_ADMIN, ROLE_CLIENTS.WRITE])}
              onClick={
                () => {
                  Modal.confirm({
                    title: '¿Confirma mover a clientes?',
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    onOk: () => { setElegible(record.id) }
                  });
                }
              }
            >
              <small>Mover a Cliente</small>
            </ButtonSG>
          </AuthRoles>
        </>
      }
    }
  ];

  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
        <div className="table-header">
          <span className='title-summary'>Prospectos</span>
          <ButtonSG
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_CLIENTS.WRITE])}
            onClick={() => setOpenModalNewClient(true)}>
            Nuevo Prospecto
          </ButtonSG>

        </div>        

        <TableSG dataSource={clientsData} columns={columns} loading={isLoading} rowKey='id' />
      </Card>
      {
        openModalStateDocument && <ModalStateDocument visible={openModalStateDocument} onFinish={onCLoseModalStateDocument} title='Estado de Documentos' />
      }
      {
        openModalNewClient && <NewClientModal visible={openModalNewClient} onFinish={() => { setOpenModalNewClient(false); setUpdateData(!updateData); }} />
      }
      {
        documentsModal && <DocumentsModal entity='CLIENTS' title='Documentos de Contratos' tabs={[{ key: '1', label: 'LOPD', type: 'pdf', tabName: 'LOPD' }, { key: '2', label: 'Contratos', type: 'pdf', tabName:'CONTRACTS'}]} onFinish={() => { setDocumentsModal(null); setUpdateData(!updateData); }} entityId={documentsModal} />
      }
    </>
  )
}
