import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { AuthRoleProps } from "../../../../interfaces/authRolesInterface";
const AuthRoles = ({ roles, children }: AuthRoleProps) => {
 
  const IsAuthorized = useIsAuthorized();

    return (
        <>
          {!roles || IsAuthorized(roles) ? children : null}
        </>
    )
}

export default AuthRoles