import { Card, Col, Radio, Row } from 'antd';
import './home.sass';
import { useEffect, useState } from 'react';
import { Column } from '@ant-design/charts';
import { authRequest } from '../../../common/request/authRequest';
import TableSG from '../../components/design/Table/TableSG';
import { PriceTag } from '../../components/design/PriceTag/PriceTag';
import { useFetch } from '../../../hooks/useFetch/useFetch';
import { ClientTotalActivesInfo } from '../../../types/clientTotalActivesInfo';
import { AccountingMonthlyTransaction } from '../../../types/accountingMonthly';
import { LongChartPoint } from '../../../types/totalRegisteredByDepartment';
import { SpinSG } from '../../components/model/Spin/SpinSG';



const LAST_30_DAYS = 30;
const LAST_90_DAYS = 90;

export const Home = () => {

	const [dateFilter, setDateFilter] = useState(LAST_30_DAYS);
	const [users, setUsers] = useState<LongChartPoint[]>();
	const [accounting, setAccounting] = useState();
	const [monthlyAccounting, setMonthlyAccounting] = useState<AccountingMonthlyTransaction[]>();
	const [contracts, setContracts] = useState<ClientTotalActivesInfo[]>();

	const { data: generalData, isLoading } = useFetch(`${process.env.REACT_APP_API}/summary/general?days=${dateFilter}`, {}, []);

	const orders = generalData.orders ? [generalData.orders] : [];

	useEffect(()=> {
		setAccounting(generalData?.accounting?.accountingTotals);
		setMonthlyAccounting(generalData?.accounting?.accountingMonthly);
		setUsers(generalData?.usersAllTime?.totalRegisteredByDepartment?.totalUsersByDepartment);
		setContracts(generalData?.clients?.clientTotalActivesInfoList);
	},[generalData])

	const columns = [
		{
			title: '',
			key: 'ownerName',
			dataIndex: 'ownerName',
			width: '30%',
			render: (ownerName: string) => (
				<span style={{ fontWeight: 500 }}> {ownerName}</span>
			)
		},
		{
			title: "Ingresos",
			key: 'inflowOfMoney',
			dataIndex: 'inflowOfMoney',
			align: 'center',
			width: 150,
			render: (_: number, record: any) => {
				return <PriceTag price={record.inflowOfMoney} />
			}
		},
		{
			title: "Gastos",
			key: 'outflowOfMoney',
			dataIndex: 'outflowOfMoney',
			align: 'center',
			width: 150,
			render: (_: number, record: any) => {
				return <PriceTag price={record.outflowOfMoney} />
			}
		},
		{
			title: "Beneficio",
			key: 'revenue',
			dataIndex: 'revenue',
			align: 'center',
			width: 150,
			render: (revenue: number) => {
				return <PriceTag price={revenue} />
			}
		}
	]

	const columnsClient = [
		{
			title: "Comercial",
			key: 'commercialName',
			dataIndex: 'commercialName',
		},
		{
			title: "Activos",
			key: 'totalActiveContracts',
			dataIndex: 'totalActiveContracts',
		},
		{
			title: "Beneficio",
			key: 'totalContractInvestment',
			dataIndex: 'totalContractInvestment',
			render: (_: number, record: any) => {
				return <PriceTag price={record.totalContractInvestment} />
			}
		}
	]

	const userColumns = [
		{
			title: 'Departamento',
			dataIndex: 'x',
			key: 'x',
		},
		{
			title: 'Cantidad',
			dataIndex: 'value',
			key: 'value',
		},
	];

	const ordersColumns = [
		{
			title: 'Órdenes',
			dataIndex: 'totalOrders',
			key: 'totalOrders',
			render: (_: number, record: any) => {
				return <p>{record.totalOrders} uds</p>
			}
		},
		{
			title: 'Cantidad',
			dataIndex: 'totalCost',
			key: 'totalCost',
			render: (_: number, record: any) => {
				return <PriceTag price={record.totalCost} />
			}
		},
	];


	const config = {

		isGroup: true,
		xField: 'x',
		yField: 'value',
		seriesField: 'category',

	};

	return (
		<div style={{ width: '100%', height: '100%', padding: '30px 50px' }}>
			<Card style={{ border: 'none' }} >
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div className="table-header" style={{ paddingLeft: '0' }}>
						<span className='title-summary'>Panel General</span>
					</div>
					<div>
						<Radio.Group defaultValue={true}  >
							<Radio.Button value={true} onClick={() => { setDateFilter(LAST_30_DAYS) }}>30 días</Radio.Button>
							<Radio.Button value={false} onClick={() => { setDateFilter(LAST_90_DAYS) }}>90 días</Radio.Button>
						</Radio.Group>
					</div>
				</div>
			</Card>

			{isLoading ? <SpinSG/> : 
			<><Card style={{ border: 'none' }}>
					<span className='title-chart'>Contabilidad</span>
				</Card><Row>
						<Col span={12}>
							<Card style={{ border: 'none' }}>
								<span className='title-chart'>Totales - {dateFilter} días</span>
								<TableSG pagination={false} columns={columns} dataSource={accounting} loading={isLoading} />
							</Card>
						</Col>

						<Col span={12}>
							<Card style={{ border: 'none' }}>
								<span className='title-chart'>Histórico</span>
								{monthlyAccounting && monthlyAccounting.map((transaction: any) => {
									return <><h1>{transaction.ownerName}</h1><Column data={transaction.monthlyChartPointsTransaction} height={150} {...config} /></>;
								}
								)}
							</Card>
						</Col>
					</Row><Card style={{ border: 'none' }}>
						<span className='title-chart'>Actividad  - {dateFilter} días</span>
					</Card><Row>
						<Col span={12}>

							<div style={{ marginLeft: '30px', display: 'flex', gap: '30px' }}>
								{contracts && contracts.length > 0 &&
									<Card style={{ width: 'fit-content', borderRadius: '10px' }}>
										<span className='title-chart' style={{ fontSize: '15px' }}>Contratos</span>
										<TableSG size='small' pagination={false} dataSource={generalData?.clients?.clientTotalActivesInfoList} columns={columnsClient} loading={isLoading} />

									</Card>}
								{users && users.length > 0 && <Card style={{ width: 'fit-content', borderRadius: '10px' }}>
									<><span className='title-chart' style={{ fontSize: '15px' }}>Usuarios</span><TableSG
										size='small'
										pagination={false}
										dataSource={users}
										columns={userColumns}
										loading={isLoading} /></>
								</Card>}
								{orders &&
									<Card style={{ width: 'fit-content', borderRadius: '10px' }}>
										<span className='title-chart' style={{ fontSize: '15px' }}>Productos</span>
										<TableSG size='small' pagination={false} dataSource={orders} columns={ordersColumns} loading={isLoading} />
									</Card>}
							</div>
						</Col>
					</Row><Row>

					</Row></>}
		</div>
	)
};



