import { Card, Timeline } from "antd"
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { useContext } from "react";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { PriceTag } from "../../../../components/design/PriceTag/PriceTag";
import { defaultFormat } from "../../../../../common/utils/formatDate";

const ArticleLogs = ({updateCost, articleDrawer}:any) => {

    const { setContractDrawer, setWordPressOrderDrawer } = useContext(DrawerContext)

    const { data: storesData } = useFetch(
        `${process.env.REACT_APP_API}/logs/${articleDrawer}/articleLogs`,
        {},
        [updateCost],
    );
   
    
    const listItems = storesData.map((d: any) =>
        <Timeline.Item key={d.created}>
            <Card size="small" title={defaultFormat(d.created)} style={{ borderRadius: "10px" }}>
                <p style={{ fontStyle: "italic", padding: "5px 0" }}>{d.msg}</p>
                <div style={{ marginLeft: "5px" }}>
                    <p><strong style={{paddingRight:'10px'}}>Coste:</strong><PriceTag price={d.cost}/></p>                    
                    <p><strong style={{paddingRight:'10px'}}>Comprador:</strong>                    
                    <span>
                        {
                        d.contractId ? (
                            <>
                                <a onClick={() => setContractDrawer(d.contractId)}>
                                <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={d.contractId} />
                                </a>
                                <small>{d.clientName}</small>
                            </>
                        ) :
                            d.clientId ?
                            <>
                                <a onClick={() => setWordPressOrderDrawer({ id: d.wordPressOrderId, webName: d.wordPressOrderWebName })}>
                                <EntityLabel style={{ display: 'inline' }} prefix='WordPress' id={d.wordPressOrderId} />
                                </a>
                                <small>{d.clientName}</small>
                            </>
                            :
                            d.owner
                        }
                    </span>
                    </p>
                    {/*<p><strong style={{paddingRight:'10px'}}>Almacen:</strong>{d.store}</p>*/}
                    
                </div>
            </Card>
        </Timeline.Item>
    );

    return (
        <Timeline
            style={{ marginTop: '20px' }}
        >
            {listItems}
        </Timeline>
    )
}

export default ArticleLogs;