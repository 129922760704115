import { Alert, Form, Input, Modal } from "antd";
import { useState } from "react";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import { STATUS_CODE } from "../../../../../common/constants/statusCode";
import { customRequest } from "../../../../../common/request/customRequest";

export const UploadContractModal = (props: { visible: boolean, onFinish: any }) => {

	const [form] = Form.useForm();

	const [file, setFile] = useState<any>(null);
	const [errorMessage, setErrorMessage] = useState<any>(null);
	
	
	const onFormFinish = async (values: any) => {

		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}

		let form_data = new FormData();
		form_data.append('file', file);

		
		const response = await customRequest(`${process.env.REACT_APP_API}/clients/contracts/upload`, {...config,  method: 'POST', accept: 'text/csv', data: form_data }, 'Contratos subido con éxito')
		if(response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else { 
			props.onFinish();
		}
	}

	const onChange = (e: any) => {
		setFile(e.target.files[0])
	}

	return (

		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>	
			<h2>Subir Contratos</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish} >
				<Form.Item name='reportFile' rules={[{ required: true }]}>
					<Input type={'file'} accept={'.csv'} onChange={(event) => { onChange(event) }} onClick={(event) => { onChange(null) }}>
					</Input>
				</Form.Item>
			</Form>

			<a href="https://silvergold-s3-plantillas.s3.eu-west-1.amazonaws.com/Plantilla_Importacion_ARTICULOS.csv">Plantilla Contratos</a>


			{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
				}
		</Modal>
	)
}