import { useContext, useState, useEffect } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Card, Col, Row } from "antd";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ADMIN, ROLE_USERS } from "../../../../common/constants/roles";
import { EditOutlined } from "@ant-design/icons";
import { UserEditRoles } from "../../users/users/ModalUsers/UserEditRoles";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import { InputEditable } from "../../../components/design/InputEditable/InputEditable";
import { customRequest } from "../../../../common/request/customRequest";
import { SelectEditable } from "../../../components/design/SelectEditable/SelectEditable";
import '../../users/users/products.sass'
import { User } from "../../../../interfaces/user";

const UserDrawer = () => {

    const [editRoles, setEditRoles] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deparments, setDepartments] = useState<any[]>([]);

    const { userDrawer, setUserDrawer } = useContext(DrawerContext)

    const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext)

    const { data: userData } = useFetch(
        `${process.env.REACT_APP_API}/users/${userDrawer}/public`,
        {},
        [updateUserData],
    );
        
    const { data: rolesData } = useFetch(`${process.env.REACT_APP_API}/users/roles`, {}, []);
    const { data: departmentsData } = useFetch(`${process.env.REACT_APP_API}/users/departments`, {}, []);
    const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/users/`, {}, []);


    useEffect(() => {
        var deparments = [];
        for (const [key, value] of Object.entries(departmentsData)) {
            var dep = { name: key, description: value }
            deparments.push(dep);
        }
        setDepartments(deparments)
    }, [departmentsData]);


    const onFormFinishPosition = async (values: any) => {
        const body = {
            position: values.position,
        }

        await customRequest(`${process.env.REACT_APP_API}/users/${userData.email}?masks=POSITION`, { method: 'PATCH', data: body }, 'Cambios guardados')
            .then(() => setUpdateUserData(!updateUserData))
    }
    const onFormFinishDeparment = async (values: any) => {
        const body = {
            ...values
        }
        await customRequest(`${process.env.REACT_APP_API}/users/${userData.email}?masks=DEPARTMENT`, { method: 'PATCH', data: body }, 'Cambios guardados')
            .then(() => setUpdateUserData(!updateUserData))
    }

    const onFormFinishManager = async (values: any) => {
        const body = {
            ...values,
        }
       
        await customRequest(`${process.env.REACT_APP_API}/users/${userData.email}?masks=MANAGER`, { method: 'PATCH', data: body }, 'Cambios guardados')
            .then(() => setUpdateUserData(!updateUserData))
    }
    const filteredUsers = usersData.filter((user: User) => user.enabled === true);

    return (
        <>
            {userData && (
                <DrawerSG
                title={<div className="text-ellipsis" style={{ width: '500px' }}>{`Usuario: ${userData.email}`}</div>}
                onClose={() => {
                        setUserDrawer(null);
                    }}
                    placement='right'
                    visible={!!userDrawer}
                    className = "text-ellipsis"
                >
                    <div style={{ padding: '10px 30px' }}>
                        <Card  style={{borderRadius:'25px'}} size='small'>
                        <Col span={12}>
                            <div>
                                <span className="label-item-view">Nombre</span>
                                <div className="item-view-product">{userData.name || 'No disponible'}</div>
                            </div>
                        </Col>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <div>
                                    <span className="label-item-view">Email</span>
                                    <div className="item-view-product text-ellipsis">{userData.email || 'No disponible'}</div>
                                </div>
                            </Col>
                            <Col span={12}>
                                <SelectEditable
                                    value={userData.manager || 'No disponible'}
                                    label={"Responsable"}
                                    nameInput={"manager"}
                                    onFormFinish={onFormFinishManager}
                                    comboData={filteredUsers}
                                    roles={[ROLE_ADMIN, ROLE_USERS.WRITE]}
                                />
                                {/* <Form>
                                <Form.Item label='Responsable' name='manager'>
                                    <Select defaultValue="">
                                        {users.filter((obj: User) => obj.enabled).map((obj: User) =>
                                            <Select.Option key={obj.email} value={obj.email}>{obj.name}</Select.Option>
                                        )}
                                    </Select>
                                </Form.Item></Form> */}
                            </Col>

                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <InputEditable roles={[ROLE_ADMIN, ROLE_USERS.WRITE]} rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={userData.position || 'No disponible'} label={"Cargo"} nameInput={"position"} onFormFinish={onFormFinishPosition} />
                            </Col>
                            <Col span={12}>

                                {deparments && <SelectEditable roles={[ROLE_ADMIN, ROLE_USERS.WRITE]} value={userData.departmentDescription || 'No disponible'} label={"Departamento"} nameInput={"department"}
                                    onFormFinish={onFormFinishDeparment} comboData={deparments} />}

                            </Col>

                        </Row>
                        <Row gutter={[16, 16]}>

                            <Col span={12}>
                                <span className="label-item-view">Roles</span>
                                <AuthRoles roles={[ROLE_ADMIN, ROLE_USERS.WRITE]}>
                                    <span onClick={() => {
                                        setEditRoles(true)
                                        setSelectedUser(userData)
                                    }} className='modify-icon'><EditOutlined />
                                    </span></AuthRoles>
                                {userData.roles && userData.roles.map((role: any) => <div className="item-view-product">{role.description}</div>)}

                            </Col>
                        </Row>
                        </Card>
                    </div>
                </DrawerSG>
            )}
            {
                editRoles && (
                    <UserEditRoles
                        visible={editRoles}
                        rolesData={rolesData}
                        onClose={() => { setEditRoles(false) }}
                        roleSelected={selectedUser}
                        setUpdateUserData={setUpdateUserData}
                    />
                )
            }

        </>
    )
}

export default UserDrawer;