import { Form, Input, Modal, Select } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';

export const StoresAddModal = (props : {
	visible : boolean,
	onFinish : any
	} ) => {

	const [form] = Form.useForm();

	const { data: contractType } = useFetch(`${process.env.REACT_APP_API}/combos/contracttypes`, {});

	
	const onFormFinish = async (values: any) => {
		
		const data = {
			...values,			
		};

		await authFetch(`${process.env.REACT_APP_API}/stores`, { method: 'POST', data: data });
		props.onFinish();
	};

  return (
		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
		footer={[
			<div style={{padding:'10px'}}>
				<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
				Cancelar 
				</ButtonSG>
				<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
				Guardar
				</ButtonSG>
			</div>
		  ]}>
			<h2>Nuevo Almacén</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label='Nombre' name='name' rules={[{ required: true}]}>
					<Input />
				</Form.Item>
				<Form.Item label='Descripción' name='description' rules={[{ required: true}]}>
					<Input />
				</Form.Item>
				<Form.Item label='Dirección' name='address'>
					<Input />
				</Form.Item>	
				<Form.Item label='Tipo de Contrato' name='contractType'>
					<Select mode="multiple">
						{contractType.map((element: any) =>
							<Select.Option value={element.description}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}