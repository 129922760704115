import { Alert, Card, Col, Form, Input, InputNumber, Modal,Select } from 'antd';
import { useContext, useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { GlobalVariableContext } from '../../../../../store/GlobalVariableContext/GlobalVariableContext';

export const ArticleAssignContractModal = ({
	product,
	onFinish,
}: any) => {
	
		
	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	
	const {updateAdjucateData, setUpdateAdjudicateData} = useContext(GlobalVariableContext);


	const onFormFinish = async (values: any) => {


		const data = {
			articleId: product.id,
			buyerOwner: "CONTRACT",
			buyerContractId: values.contractId,
			buyerInvestmentId: values.investmentId,
			price: values.price,
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/transactions`, { method: 'POST', data })

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
			setUpdateAdjudicateData(!updateAdjucateData)
		}
	};

	return (

		<Modal
			open={product}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Vender
					</ButtonSG>
				</div>
			]}>

			<h2>Venta del Artículo</h2>

			<Card size='small' style={{borderRadius:'25px', marginBottom:'20px'}} title={'Producto'}>
				<h3>{product?.name}</h3>
				{product?.barCode && <span>Código de barras - {product?.barCode}</span>}
			</Card>

			<Form layout='vertical' form={form} onFinish={onFormFinish} >
			

				<Form.Item label='Identificador de Contrato' name='contractId'>
					<Input/>
				</Form.Item>

				<Form.Item label='Identificador de Inversión' name='investmentId'>
					<Input/>
				</Form.Item>
				
				<Form.Item label='Precio' name='price' >
					<InputNumber addonAfter="€"  step={0.01}  precision={2}/>
				</Form.Item>

				<Col>
					{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
					}
				</Col>
			</Form>
		</Modal>
	)
}
