import { Alert, Card, Col, Form, Input, Modal, Switch, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import TextArea from 'antd/lib/input/TextArea';
import { DrawerContext } from '../../../../../store/DrawerContext/DrawerContext';
import { DeleteOutlined } from '@ant-design/icons';
import { useIsAuthorized } from '../../../../../common/request/AuthorizationUtils';

export const AddNoteModal = ({
	article,
	onFinish,
	updateData,
	setUpdateData
}: any) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);

	const { setUserDrawer } = useContext(DrawerContext)

	const IsAuthorized = useIsAuthorized();


	const onFormFinish = async (values: any) => {

		const articleUpdateRequest = {
			mask: "NOTE",
			...values
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/products/articles/${article.id}/lock`, { method: 'PATCH', data: articleUpdateRequest }).then(()=>{
			setUpdateData(!updateData)
		})

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
		}
	};

	const handleDeleteClick = async () => {
		const articleUpdateRequest = {
			mask: "NOTE",
			noteTitle: "",
			noteDescription: "",
			locked:false
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/products/articles/${article.id}/lock`, { method: 'PATCH', data: articleUpdateRequest }).then(()=>{
			setUpdateData(!updateData)
		})

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
		}
	};

	return (

		<Modal
			open={article}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Añadir Nota</h2>

			<Card size='small' style={{ borderRadius: '25px', marginBottom: '10px' }} title={'Artículo'}>
				<h3>{article?.name}</h3>
				{article?.barCode && <span>Código de barras - {article?.barCode}</span>}
			</Card>

			<Card size='small' style={{ borderRadius: '25px', marginBottom: '10px', backgroundColor:'rgba(255, 249, 237, 1)' }}
				title={
					article?.noteTitle && 
					<div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
					{article?.lockedByUser && (
						<div>
							Creada por{' '}
							<a onClick={() => setUserDrawer(article?.lockedByUser)}>
								{article.lockedByUser}
							</a>
						</div>
					)}
				<Tooltip title="Eliminar Nota">
						<a style={{display:'flex', justifyContent:'flex-end'}} onClick={handleDeleteClick}>
							<DeleteOutlined />
						</a>
					</Tooltip>
				</div>
					
				}>


				<Form layout='vertical' form={form} onFinish={onFormFinish} >


					<Form.Item label='Título' name='noteTitle' initialValue={article?.noteTitle} required>
						<Input maxLength={25} />
					</Form.Item>


					<Form.Item label='Descripción' name='noteDescription' initialValue={article?.noteDescription}>
						<TextArea rows={4} />
					</Form.Item>

					<Form.Item label='Reservar' name='locked' >
						<Switch
						defaultChecked={article?.locked}
						// disabled={!IsAuthorized([ROLE_ADMIN, ROLE_LOGISTIC.WRITE, ROLE_LOGISTIC.WRITE_LOCK]) || article?.owner == Owners.CLIENT || article?.owner == Owners.CONTRACT}
						/>
					</Form.Item>

					<Col>
						{errorMessage && <Alert
							type='error'
							message={errorMessage}
							banner
						/>
						}
					</Col>
				</Form>

			</Card>
		</Modal>
	)
}
