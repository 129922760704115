import { useContext } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Card, Col, Row, Tabs, TabsProps } from "antd";
import '../../users/users/products.sass'
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { defaultFormat } from "../../../../common/utils/formatDate";
import TableSG from "../../../components/design/Table/TableSG";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";

const WordPressClientDrawer = () => {


    const { wordPressClientDrawer, setWordPressClientDrawer, setProductDrawer } = useContext(DrawerContext)

    const { data: clientData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/wordPressClients/${wordPressClientDrawer?.id}/${wordPressClientDrawer.webName}`,
        {},
        [],
    );

    const columns = [
      {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
        
      },
      {
        title: 'Sku',
        dataIndex: 'sku',
        key: 'sku',
        render: (_: any, record: any) => {
            return <a onClick={() => setProductDrawer(record.sku)}>{record.sku}</a>
    
          }
      },
      {
        title: 'Cantidad',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Subtotal',
        dataIndex: 'subtotal',
        key: 'subtotal',
        render: (_: any, record: any) => {
          return <PriceTag price={record.subtotal}/>    
        }
      },    
    ]
  const items: TabsProps['items'] = [
      {
        key: '1',
        label: 'Pedidos',
        children: clientData?.order ?
          <TableSG dataSource={clientData?.order} columns={columns} loading={isLoading} /> : "Sin Pedidos"
      },   
      
    ];
    return (
        <>

           <DrawerSG
                title={<div className="text-ellipsis" style={{ width: '500px' }}><EntityLabel prefix='Cliente WordPress' id={wordPressClientDrawer.id}/></div>}
                onClose={() => {
                    setWordPressClientDrawer({id:null});
                }}
                placement='right'
                visible={!!wordPressClientDrawer.id}
                className="text-ellipsis"
            >
          {clientData && <><Card style={{ borderRadius: '25px' }} size='small'>
            <div style={{ padding: '10px 30px' }}>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <b>Cliente</b>
                    <div className="item-view-product">{clientData?.cliente?.firstName} {clientData?.client?.lastName}</div>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <b>Email</b>
                    <div className="item-view-product">{clientData?.client?.email || "-"}</div>
                  </div>
                </Col>

              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <b>Dirección</b>
                    <div className="item-view-product">{clientData?.client?.address || "-"}</div>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <b>Teléfono</b>
                    <div className="item-view-product">{clientData?.client?.phone || "-"}</div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <div>
                    <b>Web</b>
                    <div className="item-view-product">{clientData?.client?.webName}</div>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <b>Fecha</b>
                    <div className="item-view-product">{defaultFormat(clientData?.client?.dateSync)}</div>
                  </div>
                </Col>
              </Row>
              {clientData.clientSG?.length > 0 &&
              <Row gutter={[16, 16]}>
                <Col span={12}>
                   <div>
                    <b>Cliente SilverGold</b>
                    {clientData?.clientSG?.map((name:any)=>{
                    return <p className="item-view-product">{name}</p>
                  })}
                  </div>
                </Col>
               
              </Row>}
            </div>
          </Card><Tabs defaultActiveKey="1" items={items} /></>
              }

            </DrawerSG>

        </>
    )
}

export default WordPressClientDrawer;