import { DoubleLeftOutlined, DoubleRightOutlined, TagsOutlined, MessageOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass';
import { useEffect, useState } from 'react';
import { AppMenuRoutes } from '../../../routing/sideMenu/AppMenuRoutes';

const { Sider, Content } = AntdLayout;

export const AppLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		
	
	const items = [
		{ label: <Link to={'/app/inicio'}><b>Productos</b></Link>, key: '/app/inicio', icon: <TagsOutlined className='menu2-icon' /> }, 		
		{ label: <Link to={'/app/solicitudes'}><b>Solicitudes</b></Link>, key: '/app/solicitudes', icon: <MessageOutlined className='menu2-icon' /> }, 		
	
	  ];

	  const [selectedKey, setSelectedKey] = useState('/app/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  

	return (
		<AntdLayout className='container-menu'>			
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>	
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/app/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			</Sider>
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<AppMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};