import {useContext } from "react";
import ClientDrawer from "./ClientDrawer";
import ProductDrawer from "./ProductDrawer";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import ContractDrawer from "./ContractDrawer";
import OrderDrawer from "./OrderDrawer";
import UserDrawer from "./UserDrawer";
import ArticleDrawer from "./ArticleDrawer/ArticleDrawer";
import { InvestmentDrawer } from "./InvestmentDrawer/InvestmentDrawer";
import WordPressOrderDrawer from "./WordPressOrderDrawer";
import WordPressClientDrawer from "./WordPressClientDrawer";

const AllDrawers = () => {

  const {productDrawer, clientDrawer, contractDrawer, orderDrawer, userDrawer, articleDrawer, investmentDrawer, wordPressOrderDrawer, wordPressClientDrawer } = useContext(DrawerContext)
  

  return (
    <>
      {productDrawer && <ProductDrawer />}
      {clientDrawer && <ClientDrawer />}
      {contractDrawer && <ContractDrawer />}
      {orderDrawer && <OrderDrawer />}
      {userDrawer && <UserDrawer/>}
      {articleDrawer && <ArticleDrawer/>}
      {investmentDrawer && <InvestmentDrawer/>}
      {wordPressOrderDrawer.id && <WordPressOrderDrawer/>}
      {wordPressClientDrawer.id && <WordPressClientDrawer/>}

    </>
  )
}

export default AllDrawers;