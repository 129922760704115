import { Form, Input, InputNumber, Modal } from "antd"
import { WordPressStock } from "../../../../../types/WordPressStock"
import ButtonSG from "../../../../components/design/Buttons/ButtonSG"
import { useState } from "react"
import { customRequest } from "../../../../../common/request/customRequest"
import { STATUS_CODE } from "../../../../../common/constants/statusCode"
import { SpinSG } from "../../../../components/model/Spin/SpinSG"

export const ChangeWordPressStockModal = (props: {
	wordPressStock: WordPressStock,
    updateData: boolean,
    setUpdateData: any
	onFinish: any
}) => {

    const [errorMessage, setErrorMessage] = useState("")
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm();
    const onFormFinish = async (value: any) => {

        setLoading(true);

        const updateWordPressStockQuantityRequest = {
            sku: value.sku,
            webId: value.webId,
            wordPressId: value.wordPressId,
            stockQuantity: value.stock
        };

        const response = await customRequest(`${process.env.REACT_APP_API}/wordPressProducts/stock`, { method: 'PATCH', data: updateWordPressStockQuantityRequest }, 'Stock subido con éxito')
        
        if(response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		}else{
            const stock = updateWordPressStockQuantityRequest.stockQuantity;
            form.setFieldsValue({ stock })
            props.setUpdateData(!props.updateData)
			props.onFinish();
		}
		setLoading(false)

    }


   return (

   <Modal
        open={true}
        onOk={() => form.submit()}
        afterClose={form.resetFields}
        onCancel={props.onFinish}
        footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Subir
					</ButtonSG>
				</div>
				
			]}>

        <h2>Subir Stock</h2>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {loading && <SpinSG/>}
            <Form layout="vertical" form={form} onFinish={onFormFinish} initialValues={{
                webId:  props.wordPressStock.webId,
                sku: props.wordPressStock.sku,
                wordPressId: props.wordPressStock.wordPressId
            }}>

            <Form.Item label='Web' name='webId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Producto (sku)' name='sku' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Id en WordPress' name='wordPressId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>
                <Form.Item label = 'Stock' name= 'stock' rules={[{ required: true}]}>
                    <InputNumber addonAfter="uds">

                    </InputNumber>
                </Form.Item>

            </Form>
        </div>
   </Modal>
   
)}