import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { Line } from '@ant-design/charts';
import moment from "moment";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG";
import { Col, Radio, Row } from "antd";
import { useState } from "react";


export const PriceChart = ({selectedRecord}: any) => {

	const [range, setRange] = useState<String>('DAYS');
	const { data: price, isLoading } = useFetch(`${process.env.REACT_APP_API}/competitors/lastYearPrices?sku=${selectedRecord.sku}&range=${range}`, {}, [selectedRecord, range]);

	
	  const roundedData = price.map((item: any) => {
		const date = moment(item.x, 'DD/MM/YYYY HH:mm');
		const minutes = date.minute();
		if (minutes >= 30) {
		  date.add(1, 'hour');
		}
		date.minute(0);
		return {
		  date: date.format('DD/MM HH:mm'),
		  value: item.value,
		  category: item.category
		};
	 });  

	const config : any = {    
		data : roundedData,
		min: 'auto',
		autoFit: 'true',
		xField: 'date',
		yField: 'value',
		seriesField: 'category',
		xAxis: {
		},
		yAxis: {
			label: {
				
			},
		},
		legend: {
			position: 'top-right',
			itemName: {
			  style: {
				fill: '#000',
			  },
			},
		  },
				
    	color: ['#0029c3', '#00C39A', '#001977','#EA9E0C'],
		outerHeight: '30%',
		point: {
		  size: 2,
		  shape: 'circle',
		  style: {
			fill: 'red',
			stroke: 'red',
			lineWidth: 1			
		  },
		},
		lineStyle: {			
		lineWidth: 2,			
			cursor: 'pointer'
		},
		  slider: {
			start: 0,
			end: 1,
			trendCfg: {
			  isArea: true,
			},
		  },
		smooth: false,    
	  };
	return (


		<>
		<h2>Gráfica de precios</h2>
		<h3>{selectedRecord.name}</h3>
		<Row justify="start">
			<Col>
				<Radio.Group defaultValue="DAYS" onChange={(e) => setRange(e.target.value)}>
					<Radio.Button value={"DAYS"}>
						Últimos 3 días
					</Radio.Button>
					{/* <Radio.Button value={"MONTHS"}>
						Últimos 3 meses
					</Radio.Button> */}
				</Radio.Group>
			</Col>
		</Row>
		<Line loading={isLoading} {...config} style={{ height: '60vh', padding: '20px'}}></Line>
		</>

	)
}
