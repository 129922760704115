import { Modal, Col, Form, Input, Row, Radio, Card, Alert } from 'antd';
import { useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const NewClientModal = (props: { visible: boolean, onFinish: any }) => {

    const [form] = Form.useForm();
    const [isHuman, setIsHuman] = useState(true);

    const [errorMessage, setErrorMessage] = useState(null);

    const onFormFinish = async (values: any) => {

        const response = await customRequest(`${process.env.REACT_APP_API}/client`, { method: 'POST', data: values })
        if (response == undefined || response.statusCode === STATUS_CODE.OK || response.statusCode > STATUS_CODE.BAD_REQUEST) {
            props.onFinish();
        }

        if (response.message) {
            setErrorMessage(response.message)
        }
    };

    const onChangeClientType = (event: string) => {
        setIsHuman(event === 'PERSON');
    }

    return (
        <>
            <Modal
                open={props.visible}
                onOk={() => form.submit()}
                onCancel={props.onFinish}
                width='60%'
                footer={[
                    <div style={{ padding: '10px' }}>
                        <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
                            Cancelar
                        </ButtonSG>
                        <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                            Guardar
                        </ButtonSG>
                    </div>
                ]}
            >
                <h2>Nuevo Prospecto</h2>

                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFormFinish}
                    autoComplete="off"
                    initialValues={{ type: 'PERSON' }}>

                    <Row gutter={16} className="content-item-view">
                        <Col span={24}>
                            <Form.Item
                                name="type">
                                <Radio.Group onChange={(obj: any) => { onChangeClientType(obj.target.value) }} value={'PERSON'}>
                                    <Radio value={'PERSON'}>Persona Física</Radio>
                                    <Radio value={'COMPANY'}>Persona Jurídica</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="content-item-view">
                        <Col span={16}>
                            <Form.Item
                                name="name"
                                label={isHuman ? "Nombre" : "Nombre (Razón Social)"}
                                rules={[{ required: true, message: "Por favor ingrese el nombre" },]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="email"
                                label="Email"
                                validateTrigger='onFinish'
                                rules={[{ required: true, message: 'Por favor ingrese el E-mail' }, { type: 'email', message: "Introduce un correo válido" }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} className="content-item-view">
                        <Col span={8}>
                            <Form.Item
                                name="fiscalId"
                                label={"NIF"}
                                rules={[{ required: true, message: "Por favor ingrese el NIF" },]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="phone"
                                label="Teléfono"
                                validateTrigger='onFinish'
                                rules={[{ required: true, message: 'Por favor ingrese el teléfono' }, { pattern: /^[0-9]{9,15}$/, message: 'Introduce un teléfono válido' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="address"
                                label="Dirección"
                                rules={[{ required: true, message: "Por favor ingrese la dirección" },]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>


                    <h3 style={{color:'grey'}}>DATOS DE FACTURACIÓN</h3>


                    <Row gutter={24} className="content-item-view">

                        <Col span={8}>
                            <Form.Item
                                name="accountNumber"
                                label="Número de cuenta">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="invoiceAddress"
                                label="Dirección"
                                >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <h3 style={{color:'grey'}}>Datos del CRM</h3>

                    <Row gutter={24} className="content-item-view">

                        <Col span={8}>
                            <Form.Item
                                name="crmId"
                                label="Id del CRM">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <h3 style={{color:'grey'}}>Datos del Comercial</h3>

                        <Row gutter={24} className="content-item-view">

                            <Col span={8}>
                            <Form.Item
                                name="commercialEmail"
                                label="Email"
                                validateTrigger='onFinish'
                                rules={[{ message: 'Por favor ingrese el E-mail del comercial' }, { type: 'email', message: "Introduce un email válido" }]}>
                                <Input />
                            </Form.Item>
                            </Col>
                        </Row>
                    {
                        !isHuman && (
                            <Card title='Persona de contacto' size='small' style={{ borderRadius: '10px', background: 'transparent', borderColor: 'lightgrey' }}>
                                <Row gutter={24} className="content-item-view">
                                    <Col span={8}>
                                        <Form.Item
                                            name="contactName"
                                            label="Nombre"
                                            rules={[{ required: true, message: "Por favor ingrese el nombre de contacto" },]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="contactEmail"
                                            label="Email"
                                            validateTrigger='onFinish'
                                            rules={[{ required: true, message: 'Por favor ingrese el E-mail de contacto' }, { type: 'email', message: "Introduce un email válido" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="contactPhone"
                                            label="Teléfono"
                                            validateTrigger='onFinish'
                                            rules={[{ required: true, message: 'Por favor ingrese el teléfono de contacto' }, { pattern: /^[0-9]{9,15}$/, message: "Introduce un teléfono válido" }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        )
                    }
                    {errorMessage && <Alert
							type='error'
							message={errorMessage}
							banner
						/>
						}
                </Form>
            </Modal>
        </>
    )
}
