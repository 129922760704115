import { Image } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import logo from '../../../../assets/silvergold-header.png';
import './companyLogo.sass';
import { useIsAuthorized } from '../../../../common/request/AuthorizationUtils';
import { ROLE_DIRECTIVE } from '../../../../common/constants/roles';

export const CompanyLogo = () => { 
  const history = useHistory();
 const location = useLocation(); 

 const IsAuthorized = useIsAuthorized();
 const isRoleDirective = IsAuthorized([ROLE_DIRECTIVE]);


  function toHome() {
    if(isRoleDirective){
      history.push('/Home', { from: location });
    }
  }

  return (    
    <div className='companyLogo' onClick={() => toHome()} >
      <Image preview={false}  src={logo} />
    </div>
  )
}