import { useState } from 'react';
import { Card } from "antd";
import  '../../summary/UsersSummary'; 
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { useLocation } from 'react-router';
import { Organigrama } from '../../../../../interfaces/organigrama';

import donutYellow from "../../../../../assets/background/medio-donut-yellow.svg"
import circleGreen from "../../../../../assets/background/ellipse-green.svg"
import circleYellow from "../../../../../assets/background/circle-yellow.svg"
import donutGreen from "../../../../../assets/background/circle.green.svg"

export const OrganigramaDepartmet = () => {
  const [updateData] = useState(false);
  const location = useLocation();
  const departmentName = new URLSearchParams(location.search).get('departmentName');
  const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/users/organigram/${departmentName}`, {}, [updateData]);

 
  function UserTree({ users }: { users:Organigrama[] }){
    function renderUserList(users:Organigrama[]){
      return (
        <ul>
          { users.map((user) => (
            user.userName !== null && <li key={user.userName}>
              <Card style={{width:'fit-content', borderColor:'var(--primary)', borderRadius:'10px', margin:'10px', textAlign:'center'}}>{user.userName}</Card>
              {user.childs !==null && user.childs && renderUserList(user.childs)}
            </li>
          ))}
        </ul>
      );
    }
  
    return <div>{renderUserList(users)}</div>;
  }

  function getNameDepartment() {

    switch (departmentName) {

      case 'DEPARTMENT_SHOPPING':
        return 'Compras';
        
      case 'DEPARTMENT_LOGISTIC':
        return 'Logística';
        
        case 'DEPARTMENT_ECOMMERCE':
        return 'E-commerce';

      case 'DEPARTMENT_COMERCIAL':
        return 'Comercial';

      case 'DEPARTMENT_OPERATION':
        return 'Operaciones';

      case 'DEPARTMENT_ACCOUNTING':
        return 'Contabilidad';

      default: 
        return '';
    }

  }

  return (
    <>
     <Card style={{border: 'none', background:'white'}} bodyStyle={{padding: "0px 10px 10px 10px"}}>       
        <div className="table-header">
            <span className='title-summary'>Organigrama {getNameDepartment()}</span>
        </div>
      </Card>  
       {usersData.length > 0 ? <UserTree users={usersData} /> :
        <div className="no-row-select" style={{position:'relative'}}>
        <h1 >No hay usuarios activos de este departamento</h1>
        <img src={circleYellow} style={{ position: 'absolute', width: '90px', top: '10%', right: '20%' }} />
        <img src={circleGreen} style={{ position: 'absolute', width: '50px', top: '30%', right: '15%' }} />
        <img src={donutGreen} style={{ position: 'absolute', width: '200px', top: 0, left: '-2%', transform: 'rotate(100deg)' }} />
        <img src={donutYellow} style={{ position: 'absolute', width: '250px', bottom: 0, right: 0 }} />
    </div>
       }
    </>
  )
}



