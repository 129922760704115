import { ColorLabel } from "../ColorLabel/ColorLabel";

export const EntityLabel = ({ prefix = '', suffix = '', id = '', color = true }: any) => {




  return (
    <>
      <p>{prefix} #{formattedId(id)} {suffix}</p>
      {color && <ColorLabel idProp={id} />}
    </>
  );
}

export function formattedId(id: any) {
  let formattedId;

  if (id?.length > 5) {
    formattedId = id?.slice(-5, id.length)
  } else {
    formattedId = id;
  }
  return formattedId?.toString();
}