import { Card, Input, Switch, Tag } from "antd";
import { Key, useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ROLE_ADMIN, ROLE_PURCHASES } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { PetitionsAddModal } from "./modal/PetitionsAddModal";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";

const allStatusFilters = 'status=PENDING'

export const Petitions = () => {

  const [updateData, setUpdateData] = useState(false);
  const [statusFilter, setStatusFilter] = useState(allStatusFilters);
  const [purchaseOrderVisible, setPurchaseOrderVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { setProductDrawer, setOrderDrawer } = useContext(DrawerContext)

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/petitions?${statusFilter}`, {}, [updateData]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: record.orderId !== null,
      name: record.orderId,
    })
  };


  const petitionsByDepartment = storesData.filter((obj: { id: Key; }) => selectedRowKeys.includes(obj.id))

  const columns = [
    {
      title: 'Producto',
      key: 'productTypeId',
      dataIndex: 'productTypeId',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.productName.toLowerCase().includes(value.toLowerCase()) || record.productTypeId.toLowerCase().includes(value.toLowerCase());
      },
      render: (productTypeId: string, record: any) => (
        <>
          <div>{record.productName}</div>
          {
            <a onClick={() => {
              setProductDrawer(productTypeId)
            }}>
              <small>{record.productTypeId}</small>
            </a>
          }
        </>
      ),
    },
    {
      title: 'Solicitante',
      key: 'ownerDescription',
      dataIndex: 'ownerDescription',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Propietario o email"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.ownerDescription.toLowerCase().includes(value.toLowerCase()) || record.userId.toLowerCase().includes(value.toLowerCase());
      },

      render: (_: any, record: any) => {
        return <>
          <p>{record.ownerDescription}</p>
          <small>{record.userId}</small>
        </>
      }
    },
    {
      title: 'Orden de Compra',
      key: 'orderId',
      dataIndex: 'orderId',
      render: (_: any, record: any) => {
        return record.orderId &&
          <div >
            <p><Tag>{record.orderStatus}</Tag></p>
            <a onClick={() => setOrderDrawer(record.orderId)}>
            <EntityLabel prefix='Orden' id={record.orderId}/>
            </a>
          </div>
      }
    },
    {
      title: 'Cantidad',
      key: 'quantity',
      dataIndex: 'quantity',
      render: (quantity: string) => (
        <>
          {quantity} uds
        </>
      ),
      sorter: (a: any, b: any) => b.quantity - a.quantity
    },
    {
      title: 'Coste/uds',
      key: 'unitCost',
      dataIndex: 'unitCost',
      render: (_:any, render: any) => (
        render.unitCost > 0 && <PriceTag price={render.unitCost}/>

      ),
      sorter: (a: any, b: any) => b.unitCost - a.unitCost
    },
    {
      title: 'Coste total',
      key: 'unitCost',
      dataIndex: 'unitCost',
      render: (_:any, render: any) => {
        const totalCost = render.unitCost * render.quantity
        return totalCost > 0 && <PriceTag price={totalCost}/>
      },
      sorter: (a: any, b: any) => (b.unitCost * b.quantity) - (a.unitCost * a.quantity)
    },
  ]

  function dataStatusDone(isHistoricSelected: boolean) {
    if (isHistoricSelected) {
      setStatusFilter('status=COMPLETED')
    } else {
      setStatusFilter(allStatusFilters)
    }
  }

  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Peticiones Internas</span>
        <AuthRoles roles={[ROLE_ADMIN, ROLE_PURCHASES.WRITE]}>
          <ButtonSG onClick={() => setPurchaseOrderVisible(true)} disabled={!(selectedRowKeys.length > 0)}>
            Orden de Compra
          </ButtonSG>
        </AuthRoles>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p className='pl-20 desc'>Silvergold, Patrimonio Metales, Invermoneda,...</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <p className='pl-20 desc'>Ver histórico</p>
          <Switch loading={isLoading} onChange={(e) => { dataStatusDone(e) }} />
        </div>
      </div>

      <TableSG rowSelection={rowSelection} columns={columns} dataSource={storesData} loading={isLoading} rowKey={'id'} />

      {
        purchaseOrderVisible &&
        <PetitionsAddModal
          visible={purchaseOrderVisible}
          petitionsByDepartment={petitionsByDepartment}
          onFinish={() => {
            setPurchaseOrderVisible(false);
            setUpdateData(!updateData);
            setSelectedRowKeys([])
          }
          }
        />
      }
    </Card>
  )
};