
import { ApartmentOutlined, DoubleLeftOutlined, DoubleRightOutlined, FundProjectionScreenOutlined, GoldOutlined, HomeOutlined, TagsOutlined, TeamOutlined, UserAddOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu, MenuProps } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AdminMenuRoutes } from '../../../routing/sideMenu/AdminMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass'
import { useEffect, useState } from 'react';

const { Sider, Content } = AntdLayout;

export const AdminLayout = () => {

	const { collapsed, setCollapsed } = useAuth();
	const location = useLocation();
	const pathName = location.pathname;

	const items = [
		{ label: <Link to={'/admin/inicio'}><b>Panel</b></Link>, key: '/admin/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 
		{
			label: 'CRM-ERP',
			key: 'Usuarios',
			type: 'group',		  
			children: [
				{ label: <Link to={'/admin/usuarios'}><b>Usuarios</b></Link>, key: '/admin/usuarios', icon: <UserOutlined className='menu2-icon' />},
			
				{
					label: 'Equipos',
					key: 'departamentos',
					icon: <ApartmentOutlined className='menu2-icon' />,
					children: [
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_COMERCIAL'}><b>Comercial</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_COMERCIAL'},
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_OPERATION'}><b>Operaciones</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_OPERATION'},
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_ECOMMERCE'}><b>E-commerce</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_ECOMMERCE'},
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_LOGISTIC'}><b>Logística</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_LOGISTIC'},
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_SHOPPING'}><b>Compras</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_SHOPPING'},
					  { label: <Link to={'/admin/department/?departmentName=DEPARTMENT_ACCOUNTING'}><b>Contabilidad</b></Link>, key: '/admin/department/?departmentName=DEPARTMENT_ACCOUNTING'}
					  ],
				  },
			]},	
			{
				label: 'APP Clientes',
				key: 'AppClientes',
				type: 'group',		  
				children: [
					{ label: <Link to={'/admin/clientes'}><b>Usuarios</b></Link>, key: '/admin/clientes', icon: <UserOutlined className='menu2-icon' />},
	]}		
		
	  ];

	  const [selectedKey, setSelectedKey] = useState('/admin/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  
	  
	return (
		<AntdLayout className='container-menu'>
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/admin/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			</Sider>
			<div className='menu-drop'>
				{collapsed ?
					<DoubleRightOutlined onClick={() => setCollapsed(false)} />
					:
					<DoubleLeftOutlined onClick={() => setCollapsed(true)} />
				}
			</div>
			<Content className='content'>
				<div className='content-div'>
					<AdminMenuRoutes />
				</div>
			</Content>

		</AntdLayout>
	)
};
