import { CloudDownloadOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Card, Input, Radio, Spin, Switch, Tag } from "antd";
import { useContext, useState } from "react";
import { customRequest } from "../../../../common/request/customRequest";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { FilterDropdownProps } from "../../../../types/filterDropDown";
import { ROLE_ADMIN, ROLE_ECOMMERCE } from "../../../../common/constants/roles";
import { WordPressOrderSellModal } from "./modal/WordPressOrderSellModal";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { formatDaysAgo } from "../../../../common/utils/formatDate";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { EntityLabel, formattedId } from "../../../components/design/EntityLabel/EntityLabel";
import { STATUS } from "../../../../common/constants/ordersWordPress";

import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";

import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";


export const WordPressOrders = () => {

  const IsAuthorized = useIsAuthorized();

  const { setProductDrawer, setWordPressOrderDrawer, setArticleDrawer, setUserDrawer } = useContext(DrawerContext)
  const { updateConsultantData } = useContext(GlobalVariableContext)

  const [updateData, setUpdateData] = useState(false);
  const [orderToSell, setOrderToSell] = useState(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [webFilter, setWebFilter] = useState<String>("")

  const DEADLINE_ASSIGNED = new Date ("2021-01-01T00:00:00");
  const DEADLINE_ASSIGNED_INVERMONEDA = new Date ("2023-05-01T00:00:00");


  const [statusFilter, setStatusFilter] = useState<String>(`?status=PENDING_STATUSES`);


  const { data: ordersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/wordPressOrders/orders${statusFilter}${webFilter}`, {}, [updateData, updateConsultantData]);
  const { data: webs } = useFetch(`${process.env.REACT_APP_API}/combos/webs`, {}, [updateData]);


  const syncOrders = async () => {
    await customRequest(`${process.env.REACT_APP_API}/wordPress`, { method: 'PUT' })
      .then(() => {
        setUpdateData(!updateData);
        setIsSynchronizing(false);
      })
      .catch(() => {
        setIsSynchronizing(false);
      });
  }

  function selectWeb(web: any) {

    if (web.target.value === "all") {
      setWebFilter("");
    } else {
      setWebFilter(`&web=${web.target.value}`);
    }
    setUpdateData(!updateData);
  }

  const columns = [
    {
      title: 'Producto',
      render: (_: any, record: any) => (
        <>
          <div>{record.productName}</div>
          <a key={record.sku} onClick={() => setProductDrawer(record.sku)}>
            <small>{record.sku}</small>
          </a>

        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.productName?.toLowerCase().includes(value.toLowerCase()) || record?.sku?.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Precio',
      alingn: 'center',
      width: '8%',
      render: (_: any, record: any) => {
        return <>
          <PriceTag price={record.price} />
        </>

      },
    },
    {
      title: "Pedido",
      key: 'id',
      datIndex: 'id',
      render: (_: any, record: any) => (
        <a onClick={() => { setWordPressOrderDrawer({ id: record.id, webName: record.webName }) }}>
          <EntityLabel id={record.id} prefix='WordPress' />
        </a>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Id"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const searchText = value.toLowerCase();
        const lastFiveDigits = formattedId(record?.id).toLowerCase();
        return (
          lastFiveDigits.includes(searchText) ||
          (`WordPress #${lastFiveDigits}`.toLowerCase().includes(searchText))
        );
      },
    },
    {
      title: 'Estado',
      key: 'statusDescription',
      dataIndex: 'statusDescription',
      render: (statusDescription: string) => {
        return <Tag>{statusDescription}</Tag>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Estado"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.statusDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Cliente',
      key: 'clientName',
      dataIndex: 'clientName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Cliente"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.clientName.toLowerCase().includes(value.toLowerCase()) || record.clientSurname.toLowerCase().includes(value.toLowerCase());
      },
      render: (_: any, record: any) => {
        return <>
          <p>{record.clientName}</p>
          <small>{record.email}</small>
        </>

      },
    },
    {
      title: 'Web',
      key: 'webName',
      dataIndex: 'webName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dominio"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.webName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Fecha',
      key: 'dateCreated',
      dataIndex: 'dateCreated',
      render: (_: any, record: any) => {
        return <div style={{ display: 'flex' }}>
          <p>{defaultFormat(record.dateCreated)}</p>
        </div>
      },
      sorter: (a: any, b: any) => Date.parse(a.dateCreated) - Date.parse(b.dateCreated)

    },
    {
      title: 'Consultor',
      key: 'consultant',
      dataIndex: 'consultant',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o DNI"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.consultantName.toLowerCase().includes(value.toLowerCase()) || record?.consultantId.toLowerCase().includes(value.toLowerCase());
      },
      render: (_: any, record: any) => {
        return <div style={{display:'flex', flexDirection:'column'}}>
          <b> {record?.consultantName}</b>
          <a onClick={()=>setUserDrawer(record?.consultantId)}>{record?.consultantId}</a>
        </div>
      },
    },
    {
      title: <b>Logística</b>,
      width: '10%',
      align: 'center',
      render: (_: any, record: any) => {

        if (record.assignedArticleId) {
          return <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <a onClick={() => setArticleDrawer(record.assignedArticleId)}>
              <EntityLabel prefix="Artículo" id={record.assignedArticleId} color={false} />
            </a>
          </div>

        } else {
          if ((record.status === STATUS.PROCESSING || record.status === STATUS.COMPLETED || record.status === STATUS.ON_HOLD) 
          && new Date(record.dateCreated) > new Date((record.webName === 'invermoneda.com' ? DEADLINE_ASSIGNED_INVERMONEDA : DEADLINE_ASSIGNED))) {
            return <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>

              {<ButtonSG size='small'
                disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE])}
                onClick={() => { setOrderToSell(record) }}
              >Asignar</ButtonSG>}
            </div>
          }
        }
      },

      filters: [
        { text: 'Asignar', value: 'asignar' },
        { text: 'Por Asignar', value: 'porasignar' },
        { text: 'Otros', value: 'otros'}
      ],
      onFilter: (value: string, record:any) => {
        if (value === 'asignar' && record.assignedId) {
          return true;
        } else if (
          value === 'porasignar' &&
          !record.assignedId &&
          (record.status === STATUS.PROCESSING || record.status === STATUS.COMPLETED || record.status === STATUS.ON_HOLD) &&
          new Date(record.dateCreated) > new Date((record.webName === 'invermoneda.com' ? DEADLINE_ASSIGNED_INVERMONEDA : DEADLINE_ASSIGNED))
        ) {
          return true;
        } else if (value === 'otros') {
          return !record.assignedId &&
          !(record.status === STATUS.PROCESSING || record.status === STATUS.COMPLETED || record.status === STATUS.ON_HOLD) &&
          new Date(record.dateCreated) > new Date((record.webName === 'invermoneda.com' ? DEADLINE_ASSIGNED_INVERMONEDA : DEADLINE_ASSIGNED));
        }
        return false;
      },
    },
  ]

  function dataStatusDone(isHistoricSelected: boolean) {
    if (isHistoricSelected) {
      setStatusFilter(`?status=COMPLETED_STATUSES`)
    } else {
      setStatusFilter(`?status=PENDING_STATUSES`)
    }
  }


  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>

        <div className="table-header" style={{ paddingRight: 0 }}>
          <span className='title-summary'>Pedidos de WordPress</span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px', alignItems:'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ButtonSG 
              color="grey"
              size='small' disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE, ROLE_ECOMMERCE.WRITE_SYNC])}
              onClick={() => {
                setIsSynchronizing(true);
                syncOrders();
              }}>
              {!isSynchronizing ? (
                <>
                  <CloudDownloadOutlined />
                  <span style={{ fontSize: '11px' }}> 24h</span>
                </>
              ) : <Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />}
            </ButtonSG>
            {ordersData && ordersData.minutesSinceLastSync != null &&
              <small
                style={{ color: 'grey', position: 'absolute', left: '10px', top: '100px' }}
              >Sincronizado hace {formatDaysAgo(ordersData.minutesSinceLastSync)}</small>
            }
          </div>

          <Radio.Group defaultValue="all" buttonStyle="outline" onChange={(e) => selectWeb(e)}>
            <Radio.Button value={"all"}>{"Todas"}</Radio.Button>
            {
              webs && webs.map((element: any) =>
                <Radio.Button value={element.name}>{element.name}</Radio.Button>
              )}
          </Radio.Group>

          <Switch checkedChildren="Completados" unCheckedChildren="Pendientes" loading={isLoading} onClick={(e) => dataStatusDone(e)} />

        </div>

        <TableSG columns={columns} dataSource={ordersData.ordersList} rowKey={'index'} loading={isLoading} />
      </Card>
      {
        orderToSell && (
          <WordPressOrderSellModal
            order={orderToSell}
            onFinish={() => {
              setOrderToSell(null);
              setUpdateData(!updateData);
            }} />
        )
      }
    </>
  )
};