import { Pie, measureTextWidth } from '@ant-design/plots';

export const DonutGraph = ({data}:any) => {

  function renderStatistic(containerWidth: number, text: string, style: { fontSize: number; }) {
      const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
      const R = containerWidth / 2;

      let scale = 1;

      if (containerWidth < textWidth) {
        scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
      }

      const textStyleStr = `width:${containerWidth}px;`;
      return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }


    const config : any= {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'x',
      radius: 0.5,
      innerRadius: 0.64,
      meta: {
        value: {
          formatter: (v:any) => `${v}`,
        },
      },
      label: {
        type: 'inner',
        offset: '-50%',
        style: {
          textAlign: 'center',

        },
        autoRotate: false,
        content: '{value}',
      },
      statistic: {
        title: {
          offsetY: -4,
          customHtml: (container: { getBoundingClientRect: () => { width: any; height: any; }; }, view: any, datum: { type: any; }) => {
            const { width, height } = container.getBoundingClientRect();
            const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
            const text = datum ? datum.type : '';
            return renderStatistic(d, text, {
              fontSize: 28,
            });
          },
        },
        content: {
          offsetX: 2,
          offsetY: -10,
          style: {
            fontSize: '20px',
          },
          customHtml: (container: { getBoundingClientRect: () => { width: any; }; }, view: any, datum: { value: any; }, data: any[]) => {
            const { width } = container.getBoundingClientRect();
            const text = datum ? `Total: ${datum.value}` : `Total: ${data.reduce((r, d) => r + d.value, 0)}`;
            return renderStatistic(width, text, {
              fontSize: 20,
            });
          },
        },
      },
    };
    return <Pie {...config} />;
  };

