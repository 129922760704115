import { Card, Pagination, Timeline, Badge, Tooltip } from "antd";
import { DoubleLeftOutlined, GoldOutlined, EuroOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { formatPrice } from "../../../../common/utils/formatPrice";
import { useContext, useEffect, useState } from "react";
import { BalanceChart } from "./modal/BalanceChart";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { AdjustmentModal } from "./modal/AdjustmentModal";


export const FreedomContractDetail = () => {
    
    const location = useLocation();
    const pathName = location.pathname.split('/');
    const id = pathName[pathName.length - 1];


    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;


    const { data: investments, isLoading } = useFetch(`${process.env.REACT_APP_API}/investments/contracts/${id}`, {}, [])

    const { data: kpi } = useFetch(`${process.env.REACT_APP_API}/operations/freedom/kpis/${id}`, {}, [])


    const typeColors: any = {
        Compra: 'var(--primary)',
        Venta: '#007aff',
        Pago: 'var(--grey)',
        Liquidación: 'var(--accent-variant)'
    };


    const { setContractDrawer } = useContext(DrawerContext)


    return (
        <>
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div style={{ padding: "0px 20px 40px 20px", display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '50px' }}>
                <Link to={'/contratos/libertad'}><DoubleLeftOutlined /><span style={{ fontSize: '20px' }}> Atrás</span> </Link>
                <div>
                    <span className='title-summary' style={{ fontSize: '20px' }}> <a onClick={() => setContractDrawer(id)}><EntityLabel prefix='Contrato' id={id} /></a></span>
                    <p>Libertad Total</p>
                </div>
            </div>

            <div style={{ display: 'flex', gap: '50px' }} >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '50px', width: '60vw' }} >

                    <div style={{ display: 'flex', gap: '50px' }}>
                        <Card style={{ borderRadius: '25px', display: 'flex', width: '50%' }}>

                            <div >
                                <h2 style={{ margin: 0 }}>Saldo</h2>

                            </div>

                            <div style={{ display: 'flex', gap: '50px', margin: '10px' }}>

                                <div style={{ borderRadius: '25px', display: 'flex', flexDirection: 'column' }}>
                                    <p><EuroOutlined style={{ marginRight: '5px' }} />{kpi?.balanceKpis?.cashAccount}€</p>
                                    <Tooltip placement="leftBottom" title={`${kpi?.balanceKpis?.units} lingotes`}><p><GoldOutlined style={{ marginRight: '5px' }} />{kpi?.balanceKpis?.unitsCostAccount}€</p></Tooltip>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {kpi?.balanceKpis?.benefit > 0 && <div style={{ color: 'green' }}><b>Beneficio</b> <span>{`+ ${(kpi?.balanceKpis?.cashAccount * 100).toFixed(2)}%`}</span></div>}
                                </div>

                            </div>
                        </Card>

                        <Card style={{ borderRadius: '25px', display: 'flex', width: '50%' }}>

                            <div >
                                <h2 style={{ margin: 0 }}>Siguiente Operación</h2>
                                <small style={{ marginLeft: '10px' }}><b>{kpi?.nextOperationKpi?.nextOperation}</b> (Faltan {kpi?.nextOperationKpi?.daysUntilNextOperation} días)</small>

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px' }}>
                                {kpi?.currentQuote &&
                                    Object.entries(kpi.currentQuote).map(([key, value]: any) => (
                                        value && <p key={key}>{`${key} ${value?.toFixed(2)}€/onza`}</p>
                                    ))}
                                {kpi?.increment &&
                                    Object.entries(kpi.increment).map(([key, value]: any) => (
                                        value && <p key={key}>{`${key} +${value}% esta semana`}</p>
                                    ))}
                            </div>
                        </Card>
                    </div>

                    <BalanceChart id={id} height={250} title={' '} />

                </div>

                <div style={{ width: '40vw' }}>

                    <h2>Histórico de Operaciones</h2>

                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        total={investments?.length}
                        pageSize={itemsPerPage}
                        onChange={(page) => setCurrentPage(page)}
                        style={{ marginBottom: '10px' }}
                    />

                    {investments
                        ?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                        .map((d: any, index:any) => {
                            return <Timeline.Item
                                color={typeColors[d.type]}
                                key={index}
                            >
                                <Badge.Ribbon text={d.type} color={typeColors[d.type]}>
                                    <Card size="small" title={defaultFormat(d.created)} style={{ borderRadius: "10px" }}>
                                        <p><strong>{d.buy ? "Compra" : "Venta"}: </strong>{d.units} lingotes por {formatPrice(d.price)}€</p>
                                        <p><strong>Margen SilverGold: </strong>{formatPrice(d.marginSG)}€</p>
                                        <div>
                                            <strong>Saldo:</strong>
                                            <ul>
                                                <li><EuroOutlined style={{ marginRight: '5px' }} />{formatPrice(d.cashAccount)}€</li>
                                                <li><Tooltip placement="leftBottom" title={`${d.units} lingotes`}><p><GoldOutlined style={{ marginRight: '5px' }} />{formatPrice(d.unitsCostAccount)}€</p></Tooltip></li>
                                            </ul>
                                        </div>

                                    </Card>
                                </Badge.Ribbon>
                            </Timeline.Item>
                        })}

                </div>

            </div>
        </Card>        
        </>   
    )
}