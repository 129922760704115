import { SearchOutlined } from "@ant-design/icons";
import { Card, Col, Input, Radio, Row, Spin } from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { FilterDropdownProps } from "../../../../types/filterDropDown";
import TableSG from "../../../components/design/Table/TableSG";
import { formatDaysAgo } from "../../../../common/utils/formatDate";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";

export const WordPressClients = () => {

  const [updateData, setUpdateData] = useState(false);
  const [webFilter, setWebFilter] = useState<String>("")

  const { data: webs } = useFetch(`${process.env.REACT_APP_API}/combos/webs`, {}, [updateData]);
  const { data: clientsData, isLoading } = useFetch(`${process.env.REACT_APP_API}/wordPressClients/${webFilter}`, {}, [updateData]);

  function selectWeb(web: any) {

    if(web.target.value === "all"){
      setWebFilter("");
    } else {
      setWebFilter(`?web=${web.target.value}`);
    }
		setUpdateData(!updateData);
	}

  const { setWordPressClientDrawer } = useContext(DrawerContext)


  const columns = [
    {
      title: 'Nombre',
      key: 'clientName',
      dataIndex: 'clientName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const searchText = value.toLowerCase();
        const fullName = `${record.firstName} ${record.lastName}`.toLowerCase();
        
        return (
          record.firstName.toLowerCase().includes(searchText) ||
          record.lastName.toLowerCase().includes(searchText) ||
          fullName.includes(searchText)
        );
      },
      render: (_: any, record: any) => (
        <>
          <div style={{display:'flex', gap:'5px'}}>
            <p>{record.firstName}</p>
            <p>{record.lastName}</p>
          </div>
          <a onClick={() => setWordPressClientDrawer({id:record?.wordPressKey?.wordPressId, webName:record?.wordPressKey?.webName})}>#{record?.wordPressKey?.wordPressId}</a>
        </>
      ),
    }, 
    {
      title: 'Web',
      key: 'webName',
      dataIndex: 'webName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dominio"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.webName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Teléfono',
      key: 'phone',
      dataIndex: 'phone',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Teléfono"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.phone.toLowerCase().includes(value.toLowerCase());
      },
    
    },
    {
      title: 'Dirección',
      key: 'address',
      dataIndex: 'address',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dirección"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.address.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Correo',
      key: 'email',
      dataIndex: 'email',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Correo"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.email.toLowerCase().includes(value.toLowerCase());
      },
    },
  ]

  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
        <div className="table-header" style={{paddingRight:0}}>
          <span className='title-summary'>Clientes de WordPress</span>
         
          <div style={{display:'flex', flexDirection:'column' , justifyContent:'center', alignItems:'center'}}>
            {clientsData && clientsData.minutesSinceLastSync != null &&
              <small style={{color:'grey', position:'absolute', left:'10px', top:'80px'}}>Sincronizado hace {formatDaysAgo(clientsData.minutesSinceLastSync)}</small>
            }
          </div>
        </div>
        <Row justify="space-around">
				<Col>
					<Radio.Group defaultValue="all" buttonStyle="outline" onChange={(e) => selectWeb(e)}>

            <Radio.Button value="all">{"Todos"}</Radio.Button>		
					{
            webs && webs.map((element: any) =>
						<Radio.Button value={element.name}>{element.name}</Radio.Button>		
					)}
					</Radio.Group>
				</Col>
			</Row>	
        <TableSG columns={columns} dataSource={clientsData.clientList} rowKey={'email'} loading={isLoading} />
      </Card>
    </>
  )
};