import { Col, Form, Input, Row } from "antd"
import ButtonSG from "../Buttons/ButtonSG"
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"
import { useState } from "react"
import AuthRoles from "../AuthRoles/AuthRoles"

type TInputEditableProps = {
    value: string,
    label: string,
    nameInput: string,
    symbol?:string,
    roles?: string[],
    rules?: {required: boolean, message:string}[],
    onFormFinish: (value: { nameInput: string }) => Promise<void>
}
export const InputEditable = ({ symbol, value, label, nameInput, onFormFinish, rules, roles }: TInputEditableProps) => {

    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);

    const handleFormFinish = (values: { nameInput: string }) => {
        onFormFinish(values).then(() => setIsEdit(false));
    };

    return (
        <>
            {
                isEdit ?
                    (
                        <Form
                            layout="vertical"
                            hideRequiredMark
                            form={form}
                            onFinish={handleFormFinish}
                            autoComplete="off"
                        >
                            <Form.Item
                                initialValue={value}
                                name={nameInput}
                                label={label}
                                rules={rules}
                            >
                                <div className="flex align-items-center">
                                    <Input defaultValue={value} />
                                    <Form.Item style={{ marginBottom: '0px' }}>
                                        <ButtonSG htmlType="submit" color='no-color'>
                                            <CheckOutlined className="pl-7 pr-7" />
                                        </ButtonSG>
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: '0px' }}>
                                        <CloseOutlined className="pl-7 pr-7 pt-7"
                                            onClick={() => setIsEdit(false)}
                                        />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            <span className="label-item-view">{label}</span>
                            {!isEdit && <AuthRoles roles={roles}>
                                <span onClick={() => setIsEdit(true)
                                } className='modify-icon'><EditOutlined />
                                </span></AuthRoles>}
                            <div className="item-view-product">{value}{symbol && symbol}</div>
                        </div>
                    )
            }
        </>

    )
}