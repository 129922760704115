import { BellOutlined, CustomerServiceOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Layout, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import { CompanyLogo } from '../../../components/model/logos/CompanyLogo';
import MainMenu from '../../../layouts/main/MainMenu';
import { NotificationView } from '../notification-view/NotificationView';
import './header.sass';
import { EditInfoProfile } from './modal/EditInfoProfile';
import { authFetch } from '../../../../common/request/authFetch';
import { Link } from 'react-router-dom';
import { Client } from '@stomp/stompjs';


const { Header } = Layout;
interface CustomHeaderProps {
    isAuthenticated: Boolean,
    viewHeader?: Boolean | undefined
}
const CustomHeader = (props: CustomHeaderProps) => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuVisibleNot, setIsMenuVisibleNot] = useState(false);
    const [, setIsPublic] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [updateHeader, setUpdateHeader] = useState(false);
    const [openEditDrawer, setOpenEditDrawer] = useState(false);
    const { logOut, profile, setProfile, } = useAuth();


    useFetch(`${process.env.REACT_APP_API}/users/principal/profile`, {}, [updateHeader], (data) => {
        setProfile(data);
        if ("ACTIVE" === data.status) {
            setIsPublic(true);
        }
    });


    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/users/notifications/last`, {}, [updateData]);


    useEffect(() => {

        const client = new Client({
            brokerURL: `${process.env.REACT_APP_NOTIFICATIONS}/ws`,
            onConnect: () => {
                client.subscribe('/topic/notifications', (message) => {
                    setUpdateData(!updateData);
                });
            },
        });

        client.activate();

    }, []);


    const accountOverlay = () => {
        return (
            <div>
                <div className='accountOverlay-UserData flex-center'>
                    <h5 className='title-overlay width-100 fw-bold'>Mi Cuenta</h5>
                    <div className='flex flex-column pl-7 width-100'>
                        <p className='width-100 fw-bold'>{profile.name}</p>
                        <p className='width-100'>{profile.email}</p>
                    </div>
                    <div onClick={() => { setOpenEditDrawer(true); setIsMenuVisible(false) }} className='width-100 border-top-dark-white border-bottom-dark-white mt-5 mb-5 cursor'>
                        <a className='width-100 fw-bold pl-7'>Gestionar cuenta</a>
                    </div>
                    <ButtonSG color="yellow" onClick={() => { logOut() }}>
                        <PoweroffOutlined />
                    </ButtonSG>
                </div>
            </div>
        );
    }

    const notificationOverlay = () => {


        return (
            <div>
                <div className='accountOverlay-notifications flex-center'>
                    <h5 className='title-overlay width-100 fw-bold title-not'>Notificaciones</h5>
                    <div className='flex flex-column pl-7 width-100 overflow'>
                        <div className='notifications-list'>
                            {storesData ?
                                <><NotificationView notification={storesData}></NotificationView></> : <Spin className='spinner' />
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link onClick={() => { setIsMenuVisibleNot(false) }} to='/notificaciones'>Ver más</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const updateNotifications = async () => {

        await authFetch(`${process.env.REACT_APP_API}/users/notifications`, { method: 'PATCH' }).then(() => {
            setUpdateData(!updateData);
        })
    }


    const ShowMenu = (props.isAuthenticated && props.viewHeader === undefined) || (props.viewHeader && props.isAuthenticated);

    return (
        <>
            {props.viewHeader === true ? (
                <Header className='headerComponent' >

                    <div className='flex align-items-center '>
                        <div style={{ display: "flex" }}>
                            <CompanyLogo />
                            {(ShowMenu) && (
                                <MainMenu />
                            )}

                        </div>
                        {(ShowMenu) && (
                            <div>
                                <div className='overlays-container'>

                                    <a style={{ color: 'black', marginBottom:'-5px' }} target='blank' href='https://deep-coding.atlassian.net/servicedesk/customer/portal/2'>
                                        <CustomerServiceOutlined  style={{ fontSize: '18px' }} />
                                    </a>


                                    {/* {storesData?.notReaded > 0 ? <div className='circle-notification'></div> : null}*/}

                                    <Badge color='orange' showZero={false} count={storesData?.notReaded} size="small">
                                        <Dropdown open={isMenuVisibleNot} onOpenChange={setIsMenuVisibleNot} overlay={notificationOverlay} trigger={['click']}>
                                            <BellOutlined style={{ fontSize: '18px' }} onClick={() => { updateNotifications() }} />
                                        </Dropdown>
                                    </Badge>


                                    <div >
                                        <Dropdown open={isMenuVisible} onOpenChange={setIsMenuVisible} overlay={accountOverlay} trigger={['click']}>

                                            <div>
                                                <div>
                                                    <p className='profileDropdown fw-bold'>{profile.name}</p>
                                                </div>
                                                <div className='container-logo flex-center'>
                                                    <div className='container-circle-logo flex-center'>
                                                        <span className='circle fw-bold'>
                                                            {profile.name && profile.name.toUpperCase().split('')[0]}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown>

                                    </div>


                                </div>


                                {
                                    !!profile.email &&
                                    openEditDrawer && <EditInfoProfile visible={openEditDrawer} onClose={() => { setOpenEditDrawer(false); setUpdateHeader(!updateHeader) }} profile={profile} />
                                }
                            </div>
                        )}

                    </div>
                </Header>
            ) : null}
        </>

    )
};

export default CustomHeader;