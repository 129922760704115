import { Alert, Card, Input, Progress, Radio, RadioChangeEvent, Tag } from "antd";
import { AuditOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";

import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import TableSG from "../../../components/design/Table/TableSG";
import { Client } from "../../../../interfaces/client";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { ContractsAddModal } from "../clients/modal/ContractsAddModal";
import { Contract } from "./contract";
import moment from "moment";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { UploadContractModal } from "./modal/UploadContractsModal";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ADMIN } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";


export const Contracts = () => {
  const [updateData, setUpdateData] = useState(false);
  const [contractModalClient, setContractModalClient] = useState<Client | null>();
  const [seeActiveContracts, setContractState] = useState(true);
  const [uploadModal, setUploadModal] = useState(false);

  const { setClientDrawer } = useContext(DrawerContext);
  const { setContractDrawer } = useContext(DrawerContext)


  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/contracts?active=${seeActiveContracts}`, {}, [updateData]);

  const columns = [
    {
      title: '',
      width: '3%',
      render: (record: any) => {

        return (
          <>
            {<AuditOutlined style={{ fontSize: '20px' }} />}
          </>
        )
      }
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      //width:'15%',
      render: (id: string, record: any) => {
        return <a onClick={() => setContractDrawer(record.id)}>
          <EntityLabel prefix='Contrato' id={record.id} />
        </a>
      }
    },
    {
      title: 'Tipo',
      render: (record: Contract) => {
        return <> {
          <Tag color={'geekblue'}>{record.typeDescription}</Tag>
        } </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Tipo"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Contract) => {
        return record.typeDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Inicio',
      sorter: (a: any, b: any) => Date.parse(a.start) - Date.parse(b.start),
      render: (record: Contract) => {
        return <> {
          <div style={{ fontSize: '13px' }}>{moment(record.start).format('ll')}</div>
        } </>
      }
    },
    {
      title: 'Fin',
      sorter: (a: any, b: any) => Date.parse(a.end) - Date.parse(b.end),
      render: (record: Contract) => {
        return <> {record.end ?
          <div style={{ fontSize: '13px' }}>{moment(record.end).format('ll')}</div>
          : <p>Indefinido</p>
        } </>
      }
    },
    {
      title: 'Duración',
      render: (record: Contract) => {
        return (
          <>
            {record.end ? (
              record.daysLeft ? (
                <Progress percent={record.progress} size='small' />
              ) : (
                <span><Tag>Completado</Tag></span>
              )
            ) : (
              <p>-</p>
            )}
          </>
        );
      },
      sorter: (a: any, b: any) => a.progress - b.progress
    },
    {
      title: 'Inversión',
      render: (record: Contract) => {
        return <PriceTag price={record.revenue} />

      },
      sorter: (a: any, b: any) => b.revenue - a.revenue
    },
    {
      title: 'Comercial',
      render: (record: Contract) => {
        return <>
          {!!record.commercialName &&
            <>{record.commercialName}</>
          }
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Comercial"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Contract) => {
        return record.commercialName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Cliente',
      render: (record: Contract) => {
        return <a onClick={() => setClientDrawer(record.clientId)}>
          {record.clientName}
        </a>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Contract) => {
        return record.clientName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Cartera',
      render: (record: Contract) => {
        return <>
          {!!record.walletDescription &&
            <>{record.walletDescription}</>
          }
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Cartera"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Contract) => {
        return record.walletDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Alertas',
      key: 'alerts',
      dataIndex: 'alerts',
      //width: '20%',
      render: (_: any, record: any) => (
        <>
          {
            record.alert &&
            <Alert type={record.alert.severity} message={<small>{record.alert.description}</small>} showIcon style={{ margin: '4px 10px', padding: '2px', borderRadius: '5px' }} />
          }
        </>
      )
    },

  ]

  return (

    <Card style={{ border: 'none', background: 'white' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Contratos</span>
      </div>
      <Radio.Group style={{ margin: '20px 0px' }} defaultValue={true} buttonStyle="solid" onChange={(e: RadioChangeEvent) => setContractState(e.target.value)}>
        <Radio.Button value={true}>Activos</Radio.Button>
        <Radio.Button value={false}>Liquidados</Radio.Button>
      </Radio.Group>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <AuthRoles roles={[ROLE_ADMIN]}>
          <ButtonSG color="grey" size="small" icon={<UploadOutlined />} onClick={() => setUploadModal(true)} />
        </AuthRoles>
      </div>

      <TableSG columns={columns} dataSource={storesData} rowKey={'id'} loading={isLoading} />
      {/* 
                editModal &&  <ClientEditModal onFinish={() => setUpdateData(!updateData)} elegible={clientSelected} visible={editModal} onClose={onClose} ></ClientEditModal>
            */}
      {
        !!contractModalClient && <ContractsAddModal client={contractModalClient} onFinish={() => { setContractModalClient(null); setUpdateData(!updateData); }} />
      }

      {uploadModal && (
        <UploadContractModal
          visible={uploadModal}
          onFinish={() => {
            setUploadModal(false);
            setUpdateData(!updateData);
          }}
        />
      )}

    </Card>
  )
};