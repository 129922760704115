import { Drawer, Tag, Timeline } from 'antd';
import { useState } from 'react';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import DrawerSG from '../../design/Drawer/DrawerSG';
import './logsDrawer.sass'


export default function LogDrawer(props: {
    visible: boolean,
    onClose: any,
    nameLog: string,
}) {
    const [updateData, setUpdateData] = useState(false);
    const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/logs/type?type=${props.nameLog}`, {}, [updateData]);


    function formatDate(props: string) {
        var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(props).toLocaleDateString([], options);
    }

    const listItems = storesData.map((d: any) =>
       <Timeline.Item key={d.creationDate}>
            <Tag> {d.subType}</Tag>
            <h3>{formatDate(d.creationDate)}</h3>
            <p>{d.user}</p>
            <p>{d.message}</p>
        </Timeline.Item>
    );

    return (
        <>
            <DrawerSG title={'Logs ' + props.nameLog} visible={props.visible} onClose={props.onClose} placement='right' width={500}>
                <Timeline >
                    {listItems}
                </Timeline>
            </DrawerSG>
        </>
    );
}