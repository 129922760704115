import { Form, Modal, Select, Tag } from "antd"
import { useState } from "react"
import ButtonSG from "../../../components/design/Buttons/ButtonSG"
import { SpinSG } from "../../../components/model/Spin/SpinSG"
import { useFetch } from "../../../../hooks/useFetch/useFetch"
import { STATUS_CODE } from "../../../../common/constants/statusCode"
import { customRequest } from "../../../../common/request/customRequest"

export const OnSaleModal = (props: {
    selectedItems: any,
    onFinish: any
}) => {

    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

	const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/internalOwners`, {});	


    const onFormFinish = async (value: any) => {
        const data = {
            products: props.selectedItems,
            owner: value.owner
        }

		const response = await customRequest(`${process.env.REACT_APP_API}/products/articles/onSale`, { method: 'POST', data: data });
		
		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}
    }


    return (

        <Modal
            open={true}
            onOk={() => form.submit()}
            afterClose={form.resetFields}
            onCancel={props.onFinish}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Vender
                    </ButtonSG>
                </div>

            ]}>

            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <h2>Vender</h2>
                <Tag style={{ color: 'grey' }}>{`Seleccionados ${props.selectedItems.length} productos`}</Tag></div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                {loading && <SpinSG />}
                <Form layout="vertical" form={form} onFinish={onFormFinish} >

                <Form.Item label='Propietario' name='owner' rules={[{ required: true }]}  >
							<Select allowClear defaultValue="" 
                            // onChange={(value) => {
							// 	setSelectedType(value)
							// }}
                            >
								{
									owners.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>

                </Form>
            </div>
        </Modal>

    )
}