import { Card } from "antd";
import { useFetch } from "../../../hooks/useFetch/useFetch";

export const Notifications = () => {

    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/users/notifications`, {}, []);

    return (
        <Card style={{ border: 'none', background: 'white' }} bodyStyle={{ padding: "30px 50px" }}>
            <div className="table-header">
                <span className='title-summary'>Notificaciones</span>
            </div>

            <div style={{padding:'10px 20px'}}>
                <ul>
                    {storesData?.notificationList?.map((n: any) => {
                        return <li><div><h1>{n.title}</h1>{n.content}</div></li>
                    })}
                </ul>
            </div>
        </Card>
    )
}