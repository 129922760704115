import { DatePicker, Form, Input, InputNumber, Modal, Select, Row, Col, Radio, Card, Alert } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import moment from 'moment';
import '../../../../../styles/modalSG.sass'
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { useState } from 'react';
import { WeightUnits } from '../../../../../common/constants/WeightUnits';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const EditProductTypesModal = ({
	product,
	onFinish,
	setUpdateData,
	updateData
}: any) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState<any>(null);


	const onFormFinish = async (values: any) => {

		if (!values.year) {
			values.year = null;
		} else {
			let a = moment(values.year);
			values.year = a.year();
		}

		const data = {
			...values,
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/producttypes`, { method: 'PUT', data: data });

		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
			setUpdateData(!updateData)
		}
	};

	const [selectedType, setSelectedType] = useState<string | null>(product?.type);

	const isTypeOtros = selectedType === 'OTROS'

	const { data: combos } = useFetch(`${process.env.REACT_APP_API}/combos`, {});

	function disabledDate(current: any) {
		return current && current > moment().endOf('day');
	}

	let weight;

	if (product.unit === "KILOS" && product.weightInKilos !== 0) {
		weight = product.weightInKilos;
	} else if (product.unit === "OUNZES" && product.weightInOunces !== 0) {
		weight = product.weightInOunces;
	} else if (product.weightInGrams !== 0) {
		weight = product.weightInGrams;
	}

	return (
		<Modal open={product} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Editar Producto</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{
				name: product.name,
				sku: product.sku,
				type: product.type,
				country: product.country,
				metal: product.metal,
				brand: product.brand,
				weight: weight,
				unit: product.unit,
				year: product.year !== 0 && moment(product.year, "YYYY")

			}} className='modalSG'>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Nombre' name='name' >
							<Input />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='SKU' name='sku' >
							<Input disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Tipo' name='type' rules={[{ required: true }]}  >
							<Select allowClear defaultValue="" onChange={(value) => {
								setSelectedType(value)
							}}>
								{
									combos.metalTypes && combos.metalTypes.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='País' name='country'>
							<Select defaultValue="" showSearch optionFilterProp="children" allowClear>
								{

									combos.countries && combos.countries.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Metal' name='metal' rules={[{ required: !isTypeOtros }]} >
							<Select defaultValue="" allowClear >
								{
									combos.metals && combos.metals.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Marca' name='brand' >
							<Input />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item label='Peso' name='weight' rules={[{ required: !isTypeOtros }]} >
							<InputNumber min={0.001} step={0.1} stringMode />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label='Unidad' name='unit' rules={[{ required: !isTypeOtros }]} >
							<Select>
								{
									combos.units && combos.units.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Año' name='year' >
							<DatePicker allowClear disabledDate={disabledDate} style={{ width: '100%' }} picker="year" format={"YYYY"} />
						</Form.Item>
					</Col>
				</Row>
				{errorMessage && <Alert
					type='error'
					message={errorMessage}
					banner
				/>
				}
			</Form>
		</Modal>
	)
}