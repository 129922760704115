
export const STATUS: {[key:string] : string } = {
    PENDING : 'pending',
    FAILED: 'failed',
    PROCESSING : 'processing',
    ON_HOLD: 'on-hold',
    COMPLETED : 'completed',
    CANCELLED : 'cancelled',
    REFUNDED : 'refunded',
   
  }

