import { Alert, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { LINGOTE } from '../../../../../common/constants/productType';
import { Departments} from '../../../../../common/constants/departments';
import { Owners } from '../../../../../common/constants/Owners';

export const ArticlesToLabelModal = ({
	product,
	onFinish,
}: any) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);


	const { data: productData } = useFetch(
		`${process.env.REACT_APP_API}/producttypes/${product.productTypeId}`,
		{},
		[],
	);

	const statusToLabel = "COMPLETED"

	const { data: storesDataAlmacen } = useFetch(`${process.env.REACT_APP_API}/stores/`, {}, []);

	const onFormFinish = async (values: any) => {

		const barCodes = values.barCodes?.split(",").map((str: string) => str.trim())

		const uniqueBarCodes:any = new Set(barCodes);
		const uniqueBarCodesArray = [...uniqueBarCodes];

	
		if (barCodes.length !== uniqueBarCodesArray.length) {
			setErrorMessage('No pueden haber códigos de barras duplicados');
			return
          }else{
			setErrorMessage(null)
		  }

		const data = {
			...values,
			barCodes: barCodes || []
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/petitions/${product.id}/articles`, { method: 'POST', data })

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			const { quantity } = values;
			if (barCodes.length !== quantity) {
				setErrorMessage('El número de código de barras no coincide con la cantidad de artículos');
			} else {
				setErrorMessage(null);
				onFinish();
			}
		}
	};

	return (

		<Modal
			open={product}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Introducir Artículo</h2>
			<h3>{productData.name}</h3>

			<Form layout='vertical' form={form} onFinish={onFormFinish} >
				<Form.Item label='Producto' name='productTypeId' initialValue={product.productTypeId} >
					<Input disabled />
				</Form.Item>
				<Row gutter={12}>
					<Col span={5}>
						<Form.Item label='Coste/ud' name='unitCost' initialValue={product.unitCost}>
							<InputNumber disabled />
						</Form.Item>
					</Col>
					<Col span={5}>
						<Form.Item label='Unidad' name='quantity' initialValue={product.quantity}>
							<InputNumber disabled />
						</Form.Item>
					</Col>
				</Row>
				<Form.Item label='Almacén' name='store' rules={[{ required: true }]}>
					<Select >
						{storesDataAlmacen && storesDataAlmacen.map((element: any) => {
							return <Select.Option key={element.name} value={element.name}>
								{element.name}
							</Select.Option>
						}
						)}
					</Select>
				</Form.Item>
				<Form.Item label='Propietario' name='owner' hidden initialValue={product.owner}>
					<Input />
				</Form.Item>
				{productData.type === LINGOTE &&
					<Form.Item label='Códigos de barras (separados por comas)' name='barCodes' rules={[{ required: true }]}>
						<Input />
					</Form.Item>}

				<Col>
					{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
					}
				</Col>
			</Form>
		</Modal>
	)
}
