import { Alert, Card, Form, Input, InputNumber, Modal, Select, Tag } from 'antd';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import '../../../../../styles/modalSG.sass'
import { useState } from 'react';
import { InternalStock } from '../../../../../interfaces/InternalStock';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';

export const BuyInternalStockModal = (props : {
	internalStock : InternalStock|null,
	updateData: boolean,
	setUpdateData: any, 
	onFinish : any
}) => {

	const [form] = Form.useForm();	
	const [errorMessage, setErrorMessage] = useState(null);
	
	const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/internalOwners`, {});	
	
	const onFormFinish = async (values: any) => {
		
		const data = {
			productType: values.sku,
			quantity: values.quantity,
			owner: values.owner			
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/petitions`, { method: 'POST', data: data });
		
		if(response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.setUpdateData(!props.updateData)
			props.onFinish();
		}
	};

  return (
		<Modal 
			open={true} 
			onOk={() => form.submit()} 
			onCancel={props.onFinish} 
			afterClose={form.resetFields}		
			footer={[
				<div style={{padding:'10px'}}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar 
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
						Pedir 
					</ButtonSG>
				</div>
			]}>

			<h2>Petición de compra de unidades</h2>			

			<br/><br/>
			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{ 								
				name: props.internalStock?.name || props.internalStock?.productName,
				sku: props.internalStock?.sku}}>
				
				<Form.Item label='Producto (sku)' name='sku' hidden>
					<Input className='disabled'/>
				</Form.Item>
				<Form.Item label='Producto' name='name'>
					<Input className='disabled' disabled={true}/>
				</Form.Item>				
				<Form.Item label='Propietario' name='owner' rules={[{ required: false }]}>
					<Select>
						{owners && owners.map((element: any) =>
							<Select.Option value={element.key}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>														
				<Form.Item label='Cantidad' name='quantity' rules={[{ required: true}]}>
					<InputNumber min={0} className='disabled'/>
				</Form.Item>		
			</Form>
			{errorMessage &&
				<Alert
					style={{ marginTop: '12px', borderRadius: '10px' }}
					type='error'
					message={errorMessage}
				/>
			}
		</Modal>
	)
}