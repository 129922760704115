import { useContext, useState } from 'react';
import { MailOutlined, SearchOutlined} from '@ant-design/icons';
import { Button, Card, Input, message, Switch, TableProps, Tooltip } from 'antd';
import { FilterDropdownProps, FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../../common/request/authFetch';
import TableSG from '../../../components/design/Table/TableSG';
import { User } from '../../../../interfaces/user';
import AuthRoles from '../../../components/design/AuthRoles/AuthRoles';
import { ROLE_ADMIN, ROLE_USERS } from '../../../../common/constants/roles';
import { GlobalVariableContext } from '../../../../store/GlobalVariableContext/GlobalVariableContext';
import { DrawerContext } from '../../../../store/DrawerContext/DrawerContext';
import { CREATED, REGISTERED, TO_EMAIL } from '../../../../common/constants/userStatus';

const ClientApp = () => {

  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<User>>({});
  
  const { setClientDrawer } = useContext(DrawerContext)
  const {updateUserData,setUpdateUserData} = useContext(GlobalVariableContext)
  const { data: usersData, isLoading } = useFetch(`${process.env.REACT_APP_API}/users?isAppClient=${true}`, {}, [updateUserData]);

  const sendEmail = (email: string) => {
    authFetch(`${process.env.REACT_APP_API}/users/` + email + '/invitation', { method: 'POST' });
  }

  const setEnabled = (email: string, enabled: boolean) => {
    const uri = enabled
      ?
      `${process.env.REACT_APP_API}/users/` + email + '/actions/unlock'
      :
      `${process.env.REACT_APP_API}/users/` + email + '/actions/lock'
    authFetch(uri, { method: 'PATCH' }).then((isOk) => {
      if (isOk) {
        message.success('Usuario actualizado')
      } else {
        message.error('Problema al intentar actualizar el usuario')
      }
    });
  }

  const handleChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<any>);
  };

  const columns = [
    {
      title: '',
      width: '5%',      
      render: () => {
          return (
            <>
              { <span style={{ fontSize: '25px' }} className="material-symbols-rounded">badge</span> }
            </>
          )
      }
  },
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      render: (_: any, record: any) => {
        return <div style={{display:'flex', flexDirection:'column'}}>
          <a onClick={()=>{setClientDrawer(record.clientId)}}>{record.name}</a>
        </div>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
            <Input
              autoFocus
              placeholder="Nombre"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
        title: 'Usuario',
        key: 'name',
        dataIndex: 'name',
        render: (_: any, record: any) => {
          return <div style={{display:'flex', flexDirection:'column'}}>
            <b>{record.email}</b>
          </div>
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
              <Input
                autoFocus
                placeholder="Email"
                value={selectedKeys[0]}
                onChange={(e: any) => {
                  setSelectedKeys(e.target.value ? [e.target.value] : []);
                  confirm({ closeDropdown: false });
                }}
                onPressEnter={() => {
                  confirm();
                }}
                onBlur={() => {
                  confirm();
                }}
              ></Input>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value: any, record: any) => {
          return record?.email.toLowerCase().includes(value.toLowerCase());
        },
      },
    {
      title: 'Acciones',
      key: 'status',
      dataIndex: 'staus',
      width: "10%",
      filters: [
        { text: 'Pendiente de registro', value: CREATED },
        { text: 'Enviar invitación', value: TO_EMAIL },
        { text: 'Activar o desactivar usuario', value: REGISTERED },
      ],
      onFilter: (value: string, record: any) => record.status.includes(value),
      ellipsis: true,
      render: (value: boolean, record: any) => {
        return <AuthRoles roles={[ROLE_ADMIN, ROLE_USERS.WRITE]}>
          <Tooltip placement="topLeft" title={getTooltipText(record.status, record.enabled)}>{
          record.status ===  CREATED || record.status === TO_EMAIL
            ?
            <Button disabled={record.status === CREATED} size='small' shape='round' type='primary' onClick={() => { sendEmail(record.email); setUpdateUserData(!updateUserData) }}>
              <MailOutlined style={{ fontSize: '15px' }} />
            </Button>
            :
            <Switch checkedChildren='Activado' unCheckedChildren='Desactivado' defaultChecked={record.enabled} onChange={() => { setEnabled(record.email, !record.enabled); setUpdateUserData(!updateUserData) }} />
        }
        </Tooltip>
        </AuthRoles>
      }
    }
  ]

  function getTooltipText(status: string, value: boolean) {
    switch (status) {
      case TO_EMAIL:
        return 'Enviar invitación';
      case CREATED:
        return 'Pendiente de registro';
      case REGISTERED:
        return value ? 'Desactivar usuario' : 'Activar usuario';
      default:
        break;
    }
  }

  return (
    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Usuarios</span>
         
      </div>
      <TableSG size='small' columns={columns} dataSource={usersData} rowKey={'email'} loading={isLoading} onChange={handleChange} />
     
    </Card>
  )
}

export default ClientApp;