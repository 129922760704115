import {useState} from 'react'
import { Form, Input, InputNumber, Modal } from "antd"
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import { customRequest } from "../../../../../common/request/customRequest";
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const SellAssignedAddModal = ({
    product,
    onFinish,
}: any) => {

    const [form] = Form.useForm();
    const [errorMessage, setErrorMessage] = useState(null);

    const onFormFinish = async (value: any) => {

        const body = {
            onsale: true,
            minPrice:value.minPrice
        }
        
        const response = await customRequest(`${process.env.REACT_APP_API}/products/articles/${product.articleId}/actions?onSale=true`, { 
            method: 'PATCH',
            data: body
        })
        
        if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
		}
    };

    return (

        <Modal
            open={product}
            onOk={() => form.submit()}
            onCancel={onFinish}
            afterClose={form.resetFields}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={onFinish}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Guardar
                    </ButtonSG>
                </div>
            ]}>
            <h2>Poner artículo a la venta</h2>
            <Form
                layout='vertical'
                form={form}
                onFinish={onFormFinish}
                autoComplete="off">
                <Form.Item
                    initialValue={product.productTypeName}
                    label='Artículo'
                    name='productTypeName'
                >
                    <Input disabled={true} className="disabled" />
                </Form.Item>
                <Form.Item label='Precio' name='minPrice' rules={[{ required: true }]} initialValue={product.marketValue}>
                    <InputNumber addonAfter="€"  step={0.01}  precision={2}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}