import { Card, Radio } from "antd";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import TableSG from "../../../components/design/Table/TableSG";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import Tooltip from "antd/es/tooltip";
import { Waterfall } from "@ant-design/charts";
import { useState } from "react";

export const Opportunities = () => {

  const [updateData, setUpdateData] = useState(false);
  const [metal, setMetal] = useState('AU')

  const { data: metals } = useFetch(`${process.env.REACT_APP_API}/combos/metals`, {});

  const { data, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/opportunities`,
    {},
    []
  );

  const { data: stockData, isLoading: isLoadingStock } = useFetch(`${process.env.REACT_APP_API}/opportunities/stockValues?metal=${metal}`, {}, [updateData]);


  function selectMetal(metal: any) {
    setMetal(metal.target.value);
    setUpdateData(!updateData);
  }

  function styleAverage(value: number){

    const isGray = value >= -0.01 && value <= 0.01;
    const isRed = value < -0.01;
    const isGreen = value > 0.01;

    let fontWeight = 400;

    if (isRed && value < -0.03) {
      fontWeight = 700;
    } else if (isGreen && value > 0.03) {
      fontWeight = 700;
    }

    const color = isGray ? 'darkgrey' : isRed ? 'red' : 'green';

    return {color, fontWeight}
  }

  const columns = [
    {
      title: '',
      key: 'title',
      dataIndex: 'title',
      width: '20%',
      render: (text: string, record: any) => (
        <>
          <span style={{ fontWeight: 500 }}> {record.title}</span>
        </>
      )
    },
    {
      title: 'Oro',
      key: 'gold',
      dataIndex: ['gold', 'increment'],
      render: (text: string, record: any) => {
        if (record.gold !==null) {
          const {color, fontWeight} = styleAverage(record.gold.increment);
          return (
            <Tooltip placement="topLeft" title={`${record.gold.beforeAvg.toFixed(2)} VS ${record.gold.afterAvg.toFixed(2)}`}>
              <div style={{ fontWeight: fontWeight, color: color }}>
                {record.gold.increment > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
                {record.gold.increment > 0 ? <span> {parseFloat((record.gold.increment * 100).toFixed(2))} %</span> : <span> {parseFloat((record.gold.increment * -100).toFixed(2))} %</span>}
              </div>
            </Tooltip>
          );
        } else {
          return <>No hay valores</>;
        }
      }
    },
    {
      title: 'Plata',
      key: 'silver',
      dataIndex: ['silver', 'increment'],
      render: (text: string, record: any) => {
        if (record.silver !== null) {
            const {color, fontWeight}= styleAverage(record.silver.increment)
          return <Tooltip placement="topLeft" title={`${record.silver.beforeAvg.toFixed(2)} VS ${record.silver.afterAvg.toFixed(2)}`}><div style={{ fontWeight: fontWeight, color: color }}>
            {record.silver.increment > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
            {record.silver.increment > 0 ? <span> {parseFloat((record.silver.increment * 100).toFixed(2))} %</span> : <span> {parseFloat((record.silver.increment * -100).toFixed(2))} %</span>}
          </div></Tooltip>
        } else {
          return <>No hay valores</>;
        }
      },
    },
    {
      title: 'Platino',
      key: 'platino',
      dataIndex: ['platino', 'increment'],
      render: (text: string, record: any) => {
        if (record.platino !== null) {
          const {color, fontWeight}= styleAverage(record.platino.increment)
        return <Tooltip placement="topLeft" title={`${record.platino.beforeAvg.toFixed(2)} VS ${record.platino.afterAvg.toFixed(2)}`}><div  style={{ fontWeight: fontWeight, color: color }}>
            {record.platino.increment > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
            {record.platino.increment > 0 ? <span> {parseFloat((record.platino.increment * 100).toFixed(2))} %</span> : <span> {parseFloat((record.platino.increment * -100).toFixed(2))} %</span>}
          </div></Tooltip>
        } else {
          return <>No hay valores</>;
        }
      },
    },
    {
      title: 'Paladio',
      key: 'paladio',
      dataIndex: ['paladio', 'increment'],
      render: (text: string, record: any) => {
        if (record.paladio !== null) {
          const {color, fontWeight}= styleAverage(record.paladio.increment)
          return <Tooltip placement="topLeft" title={`${record.paladio.beforeAvg.toFixed(2)} VS ${record.paladio.afterAvg.toFixed(2)}`}><div style={{ fontWeight: fontWeight, color: color }}>
            {record.paladio.increment > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
            {record.paladio.increment > 0 ? <span> {parseFloat((record.paladio.increment * 100).toFixed(2))} %</span> : <span> {parseFloat((record.paladio.increment * -100).toFixed(2))} %</span>}
          </div></Tooltip>
        } else {
          return <>No hay valores</>;
        }
      },
    },
    {
      title: 'Rodio',
      key: 'rodio',
      dataIndex: ['rodio', 'increment'],
      render: (text: string, record: any) => {
        if (record.rodio !== null) {
          const {color, fontWeight}= styleAverage(record.rodio.increment)
          return <Tooltip placement="topLeft" title={`${record.rodio.beforeAvg.toFixed(2)} VS ${record.rodio.afterAvg.toFixed(2)}`}><div style={{ fontWeight: fontWeight, color: color }}>
            {record.rodio.increment > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
            {record.rodio.increment > 0 ? <span> {parseFloat((record.rodio.increment * 100).toFixed(2))} %</span> : <span> {parseFloat((record.rodio.increment * -100).toFixed(2))} %</span>}
          </div></Tooltip>
        } else {
          return <>No hay valores</>;
        }
      },

    }
  ]

  const config : any = {
    data: stockData,
    xField: 'x',
    yField: 'value',
    appendPadding: [20, 0, 10, 0],

    formatter: (value: any, index: string | number) => {
      if (data[index].Total) {
        return '';
      }
    },
    meta: {
      value: {
        formatter: (v: number) => `${v.toFixed(2)} %`
      }
    },
    background: {
      style: {
        fill: '#fff6f6',
        stroke: '#e6e6e6',
        strokeOpacity: 0.65,
        radius: 2,
      },
    }, color: (v: any) => {
      if(v.x === "Total"){
        return '#9B9B9B';
      }else{
        if (v.value <= 0) {
          return '#f4664a';
        } else {
          return '#30bf78';
        }

      }
    },
    legend: {
      custom: true,
      items: [
        {
          name: 'Incremento',
          value: 'increase',
          marker: {
            symbol: 'square',
            style: {
              r: 5,
              fill: '#30bf78',
            },
          },
        },
        {
          name: 'Descenso',
          value: 'decrease',
          marker: {
            symbol: 'square',
            style: {
              r: 5,
              fill: '#f4664a',
            },
          },
        },
        {
          name: 'Total',
          value: 'total',
          marker: {
            symbol: 'square',
            style: {
              r: 5,
              fill: '#96a6a6',
            },
          },
        },
      ],
    },

  }

  return (
    <div>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
        <div className="table-header">
          <span className='title-summary'>Oportunidades</span>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1>Incremento semanal - 3 meses</h1>
            <div style={{paddingTop:'20px'}}>
              <Radio.Group defaultValue="AU" buttonStyle="outline" onChange={(e) => selectMetal(e)}>
                {
                  metals && metals.map((element: any) =>
                    <Radio.Button value={element.key}>{element.description}</Radio.Button>
                  )}
              </Radio.Group>
              <Waterfall loading={isLoadingStock}  {...config}
                style={{ width: '75vh' }}

              /></div>
          </div>
          <div style={{ marginLeft: '25px'}}>
            <h1>Comparativa estacional</h1>
            <TableSG columns={columns} dataSource={data} loading={isLoading} pagination={false} />
          </div>
        </div>



      </Card>
    </div>
  )
}

