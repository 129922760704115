import { Tag } from "antd"
import { formatPrice } from "../../../../common/utils/formatPrice"

type TPriceTagProps = {
    price: number | string,
    size?:string
}
export const PriceTag = ({ price, size }: TPriceTagProps) => {
   
    const formatedPrice = formatPrice(Number(price), 2)
  

    return (
      <Tag
        color="white"
        style={{
          color: "black",
          borderColor: "grey",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: size
        }}
      >
      {formatedPrice + "€"}
      </Tag>
    );
}