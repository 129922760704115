import { EditOutlined, SearchOutlined, SolutionOutlined } from "@ant-design/icons";
import { Card, Input } from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ROLE_ADMIN, ROLE_OPERATIONS } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { TiposAddModal } from "./modal/TiposAddModal";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { EditSpotModal } from "./modal/EditSpotModal";

export const Tipos = () => {
  const IsAuthorized = useIsAuthorized();

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [editSpotModal, setEditSpotModal] = useState(null);

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/contracttypes/`, {}, [updateData]);

  const columns = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      render: (text: string, record: any) => (
        <>
          <SolutionOutlined style={{ fontSize: '20px' }} />
          <span> {record.shortName}</span>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Id"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.shortName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      render: (value: any, record: any) => {
        return <b>
          {record.name}
        </b>

      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Duración',
      key: 'minDurationInMonths',
      dataIndex: 'minDurationInMonths',
      sorter: (a: any, b: any) => a.minDurationInMonths - b.minDurationInMonths,
      render: (value: any, record: any) => {
        return <small>
          {record.maxDurationInMonths ?
            <><div>Mínimo: {record.minDurationInMonths} meses</div><div>Máximo: {record.maxDurationInMonths} meses</div></>
            : <span>Ilimitado</span>
          }
        </small>
      }
    },
    {
      title: 'Pago del Cliente',
      key: 'paymentPlanDescription',
      dataIndex: 'paymentPlanDescription',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Plan de pago"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.paymentPlanDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Plan de inversión',
      key: 'investmentPlanDescription',
      dataIndex: 'investmentPlanDescription',
      render: (value: any, record: any) => {
        return <>
          {record.investmentPlanDescription}
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Plan de inversión"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.inversionPlanDescription?.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Spot',
      //key: 'buySpot',
      //dataIndex: 'buySpot',
      render: (value: any, record: any) => {
        return (
          <small>
            <div>Oro: {(100 * record.spotGold).toFixed(0)}%</div>
            <div>Otros: {(100 * record.spotOthers).toFixed(0)}%</div>
          </small>
        )
      }
    },
    {
      render: (value: any, record: any) => {
        return (
          <a onClick={()=>{setEditSpotModal(record)}}><EditOutlined/></a>
        )
      }
    }
    /*
    {
      title: 'Liquidación al cliente',
      key: 'liquidatePlan',
      dataIndex: 'liquidatePlan'
    },
    {
      title: 'Acciones',
      key: 'liquidatePlan',
      dataIndex: 'liquidatePlan',
      render: (value: any, record: any) => {
        return <Space>
          <DeleteTwoTone className="icon-action" twoToneColor="#eb2f96" />
        </Space>
      }
    }*/
  ]

  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Tipos de Contratos</span>
        <ButtonSG
          disabled={!IsAuthorized([ROLE_ADMIN, ROLE_OPERATIONS.WRITE])}
          onClick={() => setAddUserVisible(true)}>
          Nuevo Tipo
        </ButtonSG>
      </div>
      <TableSG columns={columns} dataSource={storesData} rowKey={'id'} loading={isLoading} />
      {
        addUserVisible &&
        <TiposAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }
          }
        />}

        {
          editSpotModal &&
          <EditSpotModal
          spot={editSpotModal}
          onFinish={() => {
            setEditSpotModal(null);
            setUpdateData(!updateData);
          }
          }
        />
        }
    </Card>
  )
};