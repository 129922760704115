import { Card, Input, Modal, Switch, Tag, Tooltip } from "antd"
import { CheckOutlined, SearchOutlined } from "@ant-design/icons";

import { customRequest } from "../../../../common/request/customRequest";
import { ROLE_ACCOUNTING, ROLE_ADMIN } from "../../../../common/constants/roles";
import { useContext, useState } from 'react'
import moment from "moment";
import TableSG from "../../../components/design/Table/TableSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { IsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { PAYMENT_STATUS } from "../../../../common/constants/paymentStatus";
import { EntityLabel, formattedId } from "../../../components/design/EntityLabel/EntityLabel";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";

export const ConsultantsComission = () => {

    const [updateData, setUpdateData] = useState(false);

    const [paymentState, setPaymentState] = useState(true);

    const { setWordPressOrderDrawer } = useContext(DrawerContext);
    const { updateConsultantData } = useContext(GlobalVariableContext)

    const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/consultant/consultantOrder?status=${paymentState}`, {}, [updateData, updateConsultantData]);

    const paymentStatus = async (record: any) => {
        await customRequest(`${process.env.REACT_APP_API}/consultant/consultantOrder/?status=${false}`, {
            method: 'PATCH',
            data: record.consultantOrderKey
        }).then(() => setUpdateData(!updateData))
    }


    const columns = [

        {
            title: 'Consultor',
            key: 'consultant',
            dataIndex: 'consultant',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Cliente"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.consultant.name.toLowerCase().includes(value.toLowerCase()) || record.consultant.email.toLowerCase().includes(value.toLowerCase());
            },
            render: (_: any, record: any) => {
                return <div>
                    {record.consultant.name}
                </div>

            }
        },
        {
            title: "Pedido",
            key: 'wordPressOrderId',
            datIndex: 'wordPressOrderId',
            render: (_: any, record: any) => (
                <a onClick={() => { setWordPressOrderDrawer({ id: record.wordPressOrderId, webName: record.webName }) }}>
                    <EntityLabel id={record.wordPressOrderId} prefix='WordPress' />
                </a>
            ),
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Id"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                const searchText = value.toLowerCase();
                const lastFiveDigits = formattedId(record?.wordPressOrderId).toLowerCase();
                return (
                    lastFiveDigits.includes(searchText) ||
                    (`WordPress #${lastFiveDigits}`.toLowerCase().includes(searchText))
                );
            },
        },
        {
            title: 'Fecha',
            render: (record: any) => {
                return <>
                    <div style={{ fontSize: '13px' }}>{moment(record.created).format('ll')}</div>
                </>
            },
        },
        {
            title: "Comisión",
            dataIndex: "commission",
            key: "commission",
            render: (_: any, record: any) => {
              return (
                  <div style={{display:'flex', gap:'10px'}}>
                    <div>{(record.commissionPercentage * 100).toFixed(0)}%</div>
                  <div><PriceTag price={record?.commission} /></div>
                  
                  </div>
              );
            },
            sorter: (a: any, b: any) => b.consultant.commission - a.consultant.commission
          }
          ,
        {
            title: 'Confirmar Pago',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (_: any, record: any) => {
                return (
                    <ButtonSG
                        disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ACCOUNTING.WRITE])}
                        onClick={
                            () => {
                                Modal.confirm({
                                    title: '¿Confirmar el pago de esta comisión?',
                                    okText: 'Confirmar',
                                    cancelText: 'Cancelar',
                                    onOk() { paymentStatus(record) }
                                });
                            }
                        }
                        size='small'><CheckOutlined /></ButtonSG>

                )
            }
        },

    ];

    const [filteredColumns, setFilteredColumns] = useState<any>(columns);

    const handleChange = (value: boolean) => {
        if (value) {
            setPaymentState(false);
            const filteredcolumns = columns.slice(0, columns.length - 1);
            setFilteredColumns(filteredcolumns)
        } else {
            setPaymentState(true);
            setFilteredColumns(columns)
        }
    };


    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Pagos Comisión</span>
            </div>

            <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                <p className='pl-20 desc'>Pagados</p>
                <Switch loading={isLoading}
                    onChange={handleChange}
                />
            </div>
            <TableSG columns={filteredColumns} dataSource={storesData} loading={isLoading} rowKey="id" />

        </Card>


    )
}