import React, { createContext, useContext } from 'react';
import { useAuthProvider } from '../../hooks/useAuthProvider/useAuthProvider';

interface userProfile{
	email: string,
	name: string,
	postion: string,
	roles: userRole[],
	projects: any[]
	bussines: string,
	satus: string,
}
interface userRole{
	key: string,
	description: string,
}
type contextProps = {
	token: string
	project: any
	setProject: any
	profile: userProfile
	setProfile: any
	isAuth: boolean		
	logIn: (data: any)  => any;
	logOut: () => void;
	getHeader:()  => void;
	groupAccount: null | any
	setGroupAccount: null | any
	googleLogIn:(data: any, project: string) => any;
	googleLogOut: (project: string) => any;
	collapsed: boolean
	setCollapsed: null | any
}

const authContext = createContext({
	token: null as any,
	project: '' as string,
	setProject: null as any,
	profile: null as any,
	setProfile: null as any,
	isAuth: false,
	logIn: (data: any) => Promise.resolve(),
	logOut: () => {},
	getHeader: () => {},
	groupAccount: null as any,
	setGroupAccount: null as any,
	googleLogIn: (data: any, project: string) => Promise.resolve(false),
	googleLogOut: (project: string) => Promise.resolve(false),
	collapsed: true,
	setCollapsed: null as any
} as contextProps);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {

	const auth: any = useAuthProvider();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = () => {
	return useContext(authContext);
}

export {
	AuthProvider,
	useAuth
};
