import { message } from 'antd';
import { errorUtils } from '../constants/errorUtils';
import { STATUS_CODE } from '../constants/statusCode';
import { authRequest } from './authRequest';

export const customRequest = (url:string, config: any, successMessage = "Operación realizada con éxito", infoMessage ="Error informativo" ): any => {

       return authRequest(url, config)
        .then(() => {
            message.success(successMessage);
            return {statusCode: STATUS_CODE.OK}
            
        }).catch((error) => {
            if (error) {
                if (error.status === STATUS_CODE.BAD_REQUEST) {
                    message.warning(infoMessage)
                    return {statusCode: error.status, message: error.data.errorMessage}

                } else if(error.status > STATUS_CODE.BAD_REQUEST){
                    message.error(errorUtils[error.status])
                }
            }
        }) 
}

