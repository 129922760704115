import { message, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { STATUS_CODE } from '../../common/constants/statusCode';
import { useAuth } from '../../store/AuthContext/AuthContext';
import { authRequest } from '../../common/request/authRequest';


const allowedPaths: any = {
  '/client': true,
  '/contratos': true,
  '/ecommerce': true,
  '/logistica': true,
  '/compras': true,
  '/contabilidad': true,
  '/admin': true,
};

export const useFetch = (url: string, config: any, deps: Array<any> = [], callback?: (data: any) => void) => {

  const [data, setData]: any = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { logOut } = useAuth();

  useEffect(() => {
    let isMounted = true;
    const pathName = location.pathname
    let basePath = pathName.substring(0, pathName.lastIndexOf('/'))

    if (!allowedPaths[basePath]) {
      basePath = '';
    }



    setIsLoading(true);

    authRequest(url, config)
      .then((data: any) => {

        setData(data)
        !!callback ? callback(data) : setData(data);
        setIsLoading(false);
      })
      .catch((error: any) => {

        setIsLoading(false);

        if (error && error.status === STATUS_CODE.UNAUTHORIZED) {
          logOut();
        } else {
          if (error) {
            if (error.status === STATUS_CODE.FORBIDDEN) {
              history.push(`${basePath}/error403`, { from: location });
            } else if (error.status === STATUS_CODE.PAGE_NOT_FOUND) {
              history.push(`${basePath}/error404`, { from: location });
            } else {
              notification.error({
                message: 'Error inesperado',
                description: 'Se ha producido algún error, inténtalo más tarde y contacta con tu operador si el error persiste'
              });
            }
          } else {

            message.warning('Comprueba tu conexión a internet');
          }
        }
      }
      );

      return () => {
        isMounted = false;
      };
  }, [url, ...deps]);

  return {
    data,
    isLoading,
  };
}