
import { Button } from 'antd'
import { ButtonSGProps } from '../../../../interfaces/buttonSGInterface'
import './buttonSG.sass'

const ButtonSG = ({ color, style, onClick, htmlType, type, icon, disabled, href, target, shape, children, size }: ButtonSGProps) => {

  if(disabled && !color){
    color = 'grey'
}
  return (    
        <Button className={(color) ? color : 'yellow'}
          shape={(shape) ? shape : 'round'}
          onClick={onClick}
          htmlType={htmlType}
          type={type}
          icon={icon}
          disabled={disabled}
          href={href}
          target={target}
          size={size}
          style={style}
        >
          {children}
        </Button>
  )
}

export default ButtonSG