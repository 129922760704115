import { useContext, useState, } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Card, Col, Modal, Row, Tabs, TabsProps, Tag } from "antd";
import '../../users/users/products.sass'
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import TableSG from "../../../components/design/Table/TableSG";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { AssignConsultantModal } from "../../ecommerce/orders/modal/AssignConsultantModal";
import { DeleteOutlined } from "@ant-design/icons";
import { customRequest } from "../../../../common/request/customRequest";
import {GlobalVariableContext} from "../../../../store/GlobalVariableContext/GlobalVariableContext";

const WordPressOrderDrawer = () => {

  const { wordPressOrderDrawer, setWordPressOrderDrawer, setProductDrawer } = useContext(DrawerContext)
  const { updateConsultantData, setUpdateConsultantData } = useContext(GlobalVariableContext)

  const [assignConsultant, setAssignConsultant] = useState(null);

  const { data: orderData, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/wordPressOrders/${wordPressOrderDrawer?.id}?webName=${wordPressOrderDrawer.webName}`,
    {},
    [updateConsultantData],
  );

  const deleteConsultor = async () => {

    await customRequest(`${process.env.REACT_APP_API}/wordPressOrders/consultant?wordPressId=${wordPressOrderDrawer?.id}&webName=${wordPressOrderDrawer?.webName}`, { method: 'DELETE' }).then(() => {
      setUpdateConsultantData(!updateConsultantData)
    });

  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',

    },
    {
      title: 'Sku',
      dataIndex: 'sku',
      key: 'sku',
      render: (_: any, record: any) => {
        return <a onClick={() => setProductDrawer(record.sku)}>{record.sku}</a>

      }
    },
    {
      title: 'Cantidad',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
      render: (_: any, record: any) => {
        return <PriceTag price={record.subtotal} />
      }
    },
  ]
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Productos',
      children: orderData?.wordPressProducts?.length > 0 ?
        <TableSG dataSource={orderData?.wordPressProducts} columns={columns} loading={isLoading} /> : "Sin productos"
    },
  ];

  return (
    <>
      <DrawerSG
        title={<div className="text-ellipsis" style={{ width: '500px' }}><EntityLabel prefix='Pedido Wordpress' id={wordPressOrderDrawer.id} /></div>}
        onClose={() => {
          setWordPressOrderDrawer({ id: null });
        }}
        placement='right'
        visible={!!wordPressOrderDrawer.id}
        className="text-ellipsis"
      >
        <Card style={{ borderRadius: '25px' }} size='small'>
          <div style={{ padding: '10px 30px' }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <b>Cliente</b>
                  <div className="item-view-product">{orderData?.billingInfo?.first_name} {orderData?.billingInfo?.last_name}</div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <b>Web</b>
                  <div className="item-view-product">{orderData.webName}</div>
                </div>
              </Col>

            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <b>Fecha</b>
                  <div className="item-view-product">{defaultFormat(orderData.datePaid)}</div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <b>Estado</b>
                  <div className="item-view-product"><Tag>{orderData.status}</Tag></div>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <b>Precio</b>
                  <div className="item-view-product"> <PriceTag price={orderData.total} /></div>
                </div>
              </Col>
              <Col span={12}>
                <div style={{display:'flex', flexDirection:'column'}}>
                  <b>Consultor</b>
                  {orderData?.consultant ?
                    <div className="item-view-product">{orderData?.consultant?.name} <span onClick={
                      () => {
                        Modal.confirm({
                          title: '¿Confirma la eliminación de este consultor?',
                          content: `${orderData?.consultant?.name} se eliminará como consultor de este pedido.`,
                          okText: 'Confirmar',
                          cancelText: 'Cancelar',
                          onOk: ()=> deleteConsultor()
                        });
                      }
                    } style={{ cursor: 'pointer' }}><DeleteOutlined /></span></div> :

                    <a style={{paddingLeft:'17px', marginBottom:'0.3em'}} onClick={() => { setAssignConsultant(orderData) }}>Asignar</a>}
                </div>
              </Col>
            </Row>
          </div>
        </Card>

        <Tabs defaultActiveKey="1" items={items} />


        {
          assignConsultant &&
          <AssignConsultantModal
            order={orderData}
            onFinish={() => {
              setAssignConsultant(null);
              setUpdateConsultantData(!updateConsultantData);
            }}
          />
        }
      </DrawerSG>

    </>
  )
}

export default WordPressOrderDrawer;