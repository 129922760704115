import { Alert, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { InputPrice } from '../../../../components/design/InputPrice/InputPrice';

export const ArticlesAddModal = ({
	reference,
	onFinish,
}: any) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	
	const { data: stores } = useFetch(`${process.env.REACT_APP_API}/combos/stores`, {});
	const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/internalOwners`, {});

	const onFormFinish = async (values: any) => {

		const barCodes = values.barCodes?.split(",").map((str: string) => str.trim())

		const data = {
			...values,
			roles: values.roles,
			barCodes: barCodes,
			status: 'CREATED',
			created: values.created.format("YYYY-MM-DDTHH:mm")
		};
		
		const response = await customRequest(`${process.env.REACT_APP_API}/products/articles`, { method: 'POST', data: data })

		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
		}

	};


	const isSlug = reference.type === 'LINGOTE'


	return (

		<Modal
			open={reference}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Nuevo Artículo</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish} style={{ marginTop: '15px' }}>
				<Form.Item label='Producto' name='productTypeId' initialValue={reference.sku} >
					<Input disabled />
				</Form.Item>
				<Form.Item label='Almacén' name='store' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							stores && stores.map((element: any) =>
								<Select.Option value={element.key}>{element.name}</Select.Option>
							)}
					</Select>
				</Form.Item>
				<Form.Item label='Propietario' name='owner' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							owners && owners.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>
							)}
					</Select>
				</Form.Item>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Código de barras' name='barCodes' rules={[{ required: isSlug }]}>
							<Input disabled={!isSlug} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Cantidad' name='quantity' rules={[{ required: !isSlug }]} initialValue={1}>
							<InputNumber
								min={0}
								disabled={isSlug}
								style={{ backgroundColor: isSlug ? "#f5f5f5" : "", width: '100%', padding: '0 10px' }}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<InputPrice label={'Coste (con IVA)'} nameInput={'unitCost'} disabled={false} />
					</Col>
					<Col span={12}>
						<Form.Item label='Fecha de compra' name='created' rules={[{ required: true, message: 'La fecha obligatoria' }]}>
							<DatePicker showTime={{ format: 'YYYY-MM-DD HH:mm' }} format={"YYYY-MM-DD HH:mm"}/>
						</Form.Item>
					</Col>
				</Row>

				<Col>
					{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
					}
				</Col>
			</Form>
		</Modal>
	)
}
