import { Alert, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Switch, Tag, Tooltip } from 'antd';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { useState } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const TiposAddModal = (props: {
	visible: boolean,
	onFinish: any
}) => {

	const [form] = Form.useForm();
	const [showCard, setShowCard] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);


	const onFormFinish = async (values: any) => {

		const data = {
			...values,
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/contracttypes`, { method: 'POST', data: data })

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}

	};

	const { data: paymentPlans } = useFetch(`${process.env.REACT_APP_API}/combos/paymentPlans`, {});
	const { data: investmentPlans } = useFetch(`${process.env.REACT_APP_API}/combos/investmentPlans`, {});

	const limitContract = (e: any) => {
		setShowCard(e)
		if (e) {
			form.setFieldsValue({
				minDuration: null,
				maxDuration: null
			})
		}
	}
	return (
		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Nuevo Tipo de Contrato</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>

				<Form.Item label='Nombre Corto' name='shortName' rules={[{ required: true }]}>
					<Input />
				</Form.Item>

				<Form.Item label='Nombre' name='name' rules={[{ required: true }]}>
					<Input />
				</Form.Item>

				<Form.Item label='Plan de Pago' name='paymentPlan' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							paymentPlans && paymentPlans.map((element: any, index: any) =>
								<Select.Option key={index} value={element.key}>{element.description}</Select.Option>
							)}
					</Select>
				</Form.Item>

				<Form.Item label='Plan de inversión' name='investmentPlan' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							investmentPlans && investmentPlans.map((element: any, index: any) =>
								<Select.Option key={index} value={element.key}>{element.description}</Select.Option>
							)}
					</Select>
				</Form.Item>

				<Card title={
					<Row align="middle" justify={"space-between"}>
						<Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
								<Switch size='small' checked={showCard} onChange={(e) => { limitContract(e) }} />
								<p>Limitar duración del contrato</p>
							</div>
						</Col>
					</Row>
				} size='small' style={{ marginBottom: '10px', borderRadius: '10px', background: 'transparent', borderColor: 'lightgrey' }}>

					{!showCard && <Row><Alert style={{fontSize:'12px'}}  banner icon={<WarningOutlined/>} type='warning' message = 'Solo con plan de pago único' /></Row>}
					{(
						<Row gutter={16} style={{ marginTop: '5px' }}>
							<Col span={12}>
								<Form.Item label='Mínima' name='minDuration' rules={[{ required: showCard }]}>
									<InputNumber placeholder='Sin duración' style={{ width: '100%' }} min={0} disabled={!showCard} />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label='Máxima' name='maxDuration' rules={[{ required: showCard }]}>
									<InputNumber placeholder='Sin duración' style={{ width: '100%' }} min={0} disabled={!showCard} />
								</Form.Item>
							</Col>
						</Row>
					)
					}
				</Card>
				<Card title={<Tag color='gold'>Spot</Tag>} size='small' style={{ marginBottom: '10px', borderRadius: '10px', background: 'transparent', borderColor: 'lightgrey' }}>
					<Row gutter={16} style={{ marginTop: '5px' }}>
						<Col span={12}>
							<Form.Item label='Oro' name='spotGold' rules={[{ required: true}]}>
								<InputNumber style={{width: '100%'}} min={1} max={100} addonAfter="%"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Otros' name='spotOthers' rules={[{ required: true}]}>
								<InputNumber style={{width: '100%'}} min={1} max={100} addonAfter="%"/>
							</Form.Item>							
						</Col>
					</Row>				
				</Card>
				
				{errorMessage && <Alert
					type='error'
					message={errorMessage}
					banner
				/>
				}
			</Form>
		</Modal>
	)
}