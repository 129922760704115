export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_DIRECTIVE = 'ROLE_DIRECTIVE';

export const ROLE_TECHNOLOGY = {
    READ:"ROLE_TECHNOLOGY_READ",
    WRITE:"ROLE_TECHNOLOGY_WRITE"
};

export const ROLE_USERS = {
    READ:"ROLE_USERS_READ",
    WRITE:"ROLE_USERS_WRITE"
};

export const ROLE_LOGISTIC = {
    READ:"ROLE_LOGISTIC_READ",
    WRITE:"ROLE_LOGISTIC_WRITE",
    WRITE_LOCK:"ROLE_LOGISTIC_WRITE_LOCK"
};

export const ROLE_ECOMMERCE = {
    READ:"ROLE_ECOMMERCE_READ",
    WRITE:"ROLE_ECOMMERCE_WRITE",
    WRITE_SYNC: "ROLE_ECOMMERCE_WRITE_SYNC"
};

export const ROLE_CLIENTS = {
    READ:"ROLE_CLIENTS_READ",
    WRITE:"ROLE_CLIENTS_WRITE"
};

export const ROLE_OPERATIONS = {
    READ:"ROLE_OPERATIONS_READ",
    WRITE:"ROLE_OPERATIONS_WRITE"
};

export const ROLE_PURCHASES = {
    READ:"ROLE_PURCHASES_READ",
    WRITE:"ROLE_PURCHASES_WRITE"
};

export const ROLE_ACCOUNTING = {
    READ:"ROLE_ACCOUNTING_READ",
    WRITE:"ROLE_ACCOUNTING_WRITE"
};

export const ROLE_APP_BACKOFFICE = {
    READ: 'ROLE_APP_BACKOFFICE_READ',
    WRITE: 'ROLE_APP_BACKOFFICE_WRITE',
};


// TODO ASR export const ROLE_EXTERNAL_CLIENT = "ROLE_EXTERNAL_CLIENT";