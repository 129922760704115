import { Button, Col, Modal, Progress, Row, Tabs, Tag, Tooltip } from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import TableSG from "../../../components/design/Table/TableSG";
import moment from "moment";
import TabPane from "antd/lib/tabs/TabPane";
import { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, EuroCircleOutlined, GoldOutlined, SyncOutlined } from "@ant-design/icons";
import { ColorLabel } from "../../../components/design/ColorLabel/ColorLabel";
import ContractDuration from "../../../../common/utils/ContractDuration";
import '../../../../styles/contractDrawer.css'
import { formatPrice } from "../../../../common/utils/formatPrice";
import { INVESTMENT_STATUS } from "../../../../common/constants/investmentStatus";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { DRAWERS_TABLE_ROWS } from "../../../../common/constants/tableRows";
import { INVESTMENT_TYPE } from "../../../../common/constants/investmentType";
import { INVESTMENT_PLAN } from "../../../../common/constants/investmentPlan";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ADMIN, ROLE_OPERATIONS } from "../../../../common/constants/roles";
import { authFetch } from "../../../../common/request/authFetch";
import { AdjustmentModal } from "../../operations/totalFreedom/modal/AdjustmentModal";



const ContractDrawer = () => {

  const { contractDrawer, setContractDrawer, setProductDrawer, setArticleDrawer, setInvestmentDrawer } = useContext(DrawerContext)

  const [updateData, setUpdateData] = useState(false);  
  const [adjustementModal, setAdjustementModal] = useState<any>(false);

  const { data: contractData } = useFetch(
    `${process.env.REACT_APP_API}/contracts/${contractDrawer}/investments`,
    {},
    [updateData],
  );

  const liquidateContract = async () => {
    await authFetch(`${process.env.REACT_APP_API}/contracts/${contractDrawer}/liquidate`, { method: 'POST' }).then(() => {
      setUpdateData(!updateData)
    })

  }

  const investmentsColumns = [
    {
      title: '',
      dataIndex: 'type',
      align: 'center',
      render: (_: any, record: any) => {
        return <div className="item-view-product">
          {moment(record.start).isBefore(moment.now()) &&
            INVESTMENT_TYPE[record.type] && (
              <>
                <Tag color={INVESTMENT_TYPE[record.type].color} style={{ borderRadius: '8px' }}>
                  {INVESTMENT_STATUS[record.status] && (
                    record.status === 'DONE' ?
                      <span style={{ marginRight: '5px' }}><CheckCircleOutlined /></span>
                      :
                      <span style={{ marginRight: '5px' }}><SyncOutlined spin /></span>)}
                  {INVESTMENT_TYPE[record.type].name} {formatPrice(record.revenue, 2)}€
                </Tag>
                <div><small>{moment(record.start).format('ll')}</small></div>
              </>
            )}
        </div>
      }
    },
    {
      title: 'Saldo Metálico',
      dataIndex: 'contractCashAccount',
      align: 'center',
      render: (_: any, record: any) => (
          <div>{formatPrice(record.contractCashAccount, 2)}€</div>
      ),
    },
    {
      title: 'Saldo en Metal',
      dataIndex: 'contractUnitsCostAccount',
      align: 'center',
      render: (_: any, record: any) => (
        <Tooltip title={`${record.units} lingotes`}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>{formatPrice(record.contractUnitsCostAccount, 2)}€
          </div>
        </Tooltip>
      ),
    },
    
  ];

  const inventoryColumns = [
    {
      title: 'Barcode',
      dataIndex: 'barCode',
      key: 'barCode',
      align: 'center',
      render: (_: any, record: any) => {
        return <>
          <a onClick={() => { setArticleDrawer(record.id) }}>#{record.barCode}</a>
        </>
      }
    },
    {
      title: 'Coste',
      key: 'coste',
      dataIndex: 'coste',
      width: '20%',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record.cost} €</>
      },
    },
    {
      title: 'Nombre',
      dataIndex: 'productName',
      key: 'productName',
      width: '30%',
      align: 'center',
      render: (_: any, record: any) => {
        return <>
          <div>{record.productType.name}</div>
          <a onClick={() => { setProductDrawer(record.productType.sku) }}><small>{record.productType.sku}</small></a>
        </>

      },
    },
    {
      title: 'Almacén',
      key: 'store',
      dataIndex: 'store',
      align: 'center',
      width: '20%',

      render: (_: any, record: any) => {
        return <>{record.store}</>
      },
    },
    {
      title: '',
      key: 'onSale',
      align: 'center',
      render: (_: any, record: any) => {
        return <>{record.onSale && <Tag color='lime'>En venta</Tag>}</>
      },
    }
  ]

  const investmentsTab = contractData.investments || [];
  const inventoryTab = contractData.inventory || [];

  const buttonDisabled = (inventoryTab.length > 0 || investmentsTab.some((investment: { status: string; }) => investment.status === 'PENDING'));

  return (
    <>
      {contractData && contractData.contract && contractData.contract.contractInfo &&

        <DrawerSG
          title={`Contrato ${contractData.contract.contractInfo.id.slice(-5, contractData.contract.contractInfo.id.lenght)}`}
          visible={!!contractDrawer}
          width={'50%'}
          onClose={() => setContractDrawer(null)}>

          {/*contractData.contract.contractInfo.blocked && <Alert message={<b>Pagos Pendientes</b>} showIcon description="Contrato Bloqueado" type="error" />*/}

          <div style={{ padding: '0px 30px' }}>

            <div className="container-kpi">
              <div className="card-kpi" style={{ width: '30%', height: '100px' }}>
                <div className='value-kpi' >
                  <b>{contractData.contract.contractInfo.revenue}€</b>
                  <small>{<ContractDuration days={contractData.contract.contractInfo.contractDuration} />}</small></div>
                <div className="container-colorLabel" >
                  <ColorLabel idProp={contractData.contract.contractInfo.id} /></div>
              </div>

              <div className="card-kpi" style={{ width: '65%', height: '100px' }}>
                <div className='value-kpi'>
                  {contractData.contract.contractInfo.typeDescription}
                </div>
                <div className='value-kpi' >{contractData.contract.contractInfo.walletDescription}</div>
                <div className="container-colorLabel" >
                  <ColorLabel idProp={contractData.contract.contractInfo.id} /></div>
              </div>
            </div>

            <div>
              <div className="container-info-client">
                <div style={{ marginBottom: '10px' }}>
                  <Row gutter={[16, 16]}>
                    <Col span={22}>
                      <div>
                        <b>Cliente: </b> {contractData.contract.contractInfo.clientName}
                      </div>
                    </Col>
                    <Col span={2}>
                      <Tooltip title={contractData.contract.contractInfo.id}>
                        <span style={{ fontSize: '20px' }} className="material-symbols-outlined">
                          info
                        </span>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      <div>
                        <b>Comercial: </b>{contractData.contract.contractInfo.commercialName}
                      </div>
                    </Col>
                    {contractData.contract.contractInfo.investmentPlan === INVESTMENT_PLAN.TOTAL_FREEDOM && <Col span={8}>
                      <b>Inicio: </b>  {moment(contractData.contract.contractInfo.start).format('ll')}
                    </Col>}
                  </Row>
                </div>
                {contractData.contract.contractInfo.investmentPlan !== INVESTMENT_PLAN.TOTAL_FREEDOM &&
                  <><Progress percent={contractData.contract.contractInfo.progress} /><div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <small>
                        <b>Inicio: </b>  {moment(contractData.contract.contractInfo.start).format('ll')}
                      </small>
                    </div>
                    <div>
                      <small>
                        <b>Fin: </b>  {moment(contractData.contract.contractInfo.end).format('ll')}
                      </small>
                    </div>
                  </div></>}
              </div>
            </div>
            <div className="container-kpi">
              <div className="card-kpi" style={{ width: '30%', height: '100px' }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <h3 style={{ fontSize: '16px' }}>Pagado</h3>
                  <p>{contractData.contract.paid} €</p></div>
              </div>

              <div className="card-kpi" style={{ width: '65%', height: '100px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                  <h3 style={{ fontSize: '16px' }}>Saldo</h3>
                  <p><EuroCircleOutlined /> {Math.round(100 * contractData.contract.accountRevenue) / 100} €
                    {Math.abs(contractData.contract.benefit) > 0.0001 &&
                      (contractData.contract.benefit > 0 ?
                        <span style={{ color: 'green' }}>
                          <CaretUpOutlined />{Math.round(100 * contractData.contract.benefit) / 100} %</span>
                        :
                        <span style={{ color: 'RED' }}>
                          <CaretDownOutlined />{-1 * Math.round(100 * contractData.contract.benefit) / 100} %</span>)
                    }
                  </p>
                  <Tooltip placement="leftBottom" title={`${contractData.contract.slugs} lingotes`}><p><GoldOutlined /><span style={{ color: 'grey' }}> {Math.round(100 * contractData.contract.articlesCost) / 100} €</span></p></Tooltip>
                </div>
              </div>
            </div>

            {!(investmentsTab.some((investment: { type: string; }) => investment.type === 'LIQUIDATION')) && (
              <div>
                <AuthRoles roles={[ROLE_ADMIN, ROLE_OPERATIONS.WRITE]}>
                  <Button
                    disabled={buttonDisabled}
                    onClick={
                      () => {
                        Modal.confirm({
                          title: '¿Confirma la Liquidación de este Contrato?',
                          okText: 'Confirmar',
                          cancelText: 'Cancelar',
                          onOk: liquidateContract
                        });
                      }
                    }
                    style={{ float: 'right', borderColor: buttonDisabled ? 'grey' : 'var(--accent-variant)', color: buttonDisabled ? 'grey' : 'var(--accent-variant)' }} type='ghost' shape="round" color="white" size="small">Liquidar</Button>                    
                  <Button
                  disabled={buttonDisabled}
                  onClick={
                    () => {
                      setAdjustementModal(true);
                    }
                  }                      
                  style={{ margin:'0px 10px', float: 'right', borderColor: buttonDisabled ? 'grey' : 'var(--accent-variant)', color: buttonDisabled ? 'grey' : 'var(--accent-variant)' }} type='ghost' shape="round" color="white" size="small">Ajuste de Saldo</Button>                    
                </AuthRoles>
              </div>)}
            <Tabs>
              <TabPane tab="Operaciones" key="investments" style={{ minHeight: '1000px' }}>
                <TableSG dataSource={investmentsTab} columns={investmentsColumns} rowKey={'id'} loading={false}
                  pagination={{
                    defaultPageSize: DRAWERS_TABLE_ROWS,
                  }} />
              </TabPane>
              <TabPane tab="Inventario" key="Inventario" style={{ minHeight: '1000px' }}>
                <TableSG dataSource={inventoryTab} columns={inventoryColumns} rowKey={'id'} loading={false}
                  pagination={{
                    defaultPageSize: DRAWERS_TABLE_ROWS,
                  }} />
              </TabPane>
            </Tabs>
          </div>
        </DrawerSG >
      }{
            adjustementModal && <AdjustmentModal 
            onCancel={()=>{setAdjustementModal(null)}}
            contract={contractData.contract} onFinish={() => { setAdjustementModal(false); setUpdateData(!updateData) }} />
        }         
    </>
  )
}

export default ContractDrawer;
