import { Spin } from "antd"

export const SpinSG = () => {

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Spin/>
        </div>
    )

}