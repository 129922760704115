import React from 'react';
import { Route, Switch } from 'react-router';
import { clientMenuRoutes } from '../constants';

export const ClientMenuRoutes = () => {

  return (
		<Switch>
			{
				clientMenuRoutes.map((route, index) => {
					return (
						<Route
							key={index}
							path={route.path}
							exact={route.exact}
							render={() => <route.component />} />						
					)
				})
			}
		</Switch>
	);
}