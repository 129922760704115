import { PlusOutlined, PaperClipOutlined, SearchOutlined, ShoppingCartOutlined, UploadOutlined, DoubleRightOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  Badge,
  Card,
  Input,
  Modal,
  Radio,
  Switch,
  TableColumnsType,
  Tag,
  Tooltip,
} from "antd";
import { useContext, useState } from "react";
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ProductByArticle } from "../../../../interfaces/productByArticle";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import ArticleLogDrawer from "../../../components/model/loglsDrawer/ArticleLogDrawer";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../common/constants/roles";
import { UploadArticlesModal } from "../productType/modal/UploadArticlesModal";
import { customRequest } from "../../../../common/request/customRequest";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { Owners } from "../../../../common/constants/Owners";
import { DocumentsModal } from "../../common/UploadDocuments/DocumentsModal";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { AddNoteModal } from "./modal/AddNoteModal";
import { authFetch } from "../../../../common/request/authFetch";

export const Articles = () => {

  const [updateData, setUpdateData] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [openModal, setOpenModal] = useState(null);
  
  const IsAuthorized = useIsAuthorized();

  const { setUserDrawer, articleDrawer, setArticleDrawer, setProductDrawer, setWordPressOrderDrawer } = useContext(DrawerContext)


  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<ProductByArticle | null>(null);

  const [owner, setOwner] = useState<string>("");
  const [storageStatus, setStorageStatus] = useState<string>("?storeStatus=INTERNAL");

  const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/owners`, {});


  async function setLocked(articleId: String, enabled: boolean) {
    const articleUpdateRequest = {
      mask: "LOCKED",
      locked: enabled
    }
    await customRequest(`${process.env.REACT_APP_API}/products/articles/${articleId}/lock`, { method: 'PATCH', data: articleUpdateRequest }).then(() => {
      setUpdateData(!updateData);
    })
  }

  function selectOwner(owner: any) {
    if (owner.target.value === 'ALL') {
      setOwner("")
    } else {
      setOwner(`&owner=${owner.target.value}`);
    }
    setUpdateData(!updateData);
  }

  function dataStatusDone(isHistoricSelected: boolean) {
    if (isHistoricSelected) {
      setStorageStatus(`?storeStatus=INTERNAL`)
    } else {
      setStorageStatus(`?storeStatus=EXTERNO`)
    }
    setUpdateData(!updateData);
  }

  const { setContractDrawer } = useContext(DrawerContext)

  const [documentsModal, setDocumentsModal] = useState<any>(null);

  const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext)


  const { data: storesData, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/products/articles/${storageStatus}${owner}`,
    {},
    [updateData, updateUserData, articleDrawer]
  );

  const deleteArticle = async (articleId:string) => {
    await authFetch(`${process.env.REACT_APP_API}/products/articles/${articleId}`, { method: 'DELETE' });
        setUpdateData(!updateData);
  }

  const columns: TableColumnsType<ProductByArticle> = [
    {
      title: '',
      align: 'center',
      dataIndex: "id",
      key: "id",
      render: (_: any, record: any) => (
        <>
          {
            <div style={{ display: 'flex' }}>
              {record.onSale && <Tooltip title='En Venta'><Badge status="processing" color='#FFBF00' /></Tooltip>}
              <a onClick={() => setArticleDrawer(record.id)}>
                <EntityLabel prefix="Artículo" id={record.id} color={false} />
              </a>
            </div>
          }
        </>
      ),
      filters: [
        { text: 'En venta', value: true },
        { text: 'No en venta', value: false },
      ],
      onFilter: (value, record) => record.onSale === value,

    },
    {
      title: "Código de Barras",
      align: 'center',
      dataIndex: "barCode",
      key: "barCode",
      render: (_: any, record: any) => (
        <>
          {
            <div>
              {record.barCode}
            </div>
          }
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Código de barras"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.barCode?.includes(value);
      },
    },
    {
      title: "Almacén", dataIndex: "store", key: "store", filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Almacén"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return (record.store && record.store.toLowerCase().includes(value.toLowerCase()));
      },
    },
    {
      title: 'Coste',
      key: 'cost',
      dataIndex: 'cost',
      align: 'center',
      render: (_: any, record: any) => (
        <>
          {
            <div>
              <PriceTag price={record.cost} />
            </div>
          }
        </>
      ),
      sorter: (a: any, b: any) => a.cost - b.cost
    },
    {
      title: "Producto",
      dataIndex: "productTypeId",
      key: "productTypeId",
      render: (_: any, record: any) => (
        <>
          <div>{record.name}</div>
          <a onClick={() => {
            setProductDrawer(record.productTypeId);
          }}>
            <small>{record.productTypeId}</small>
          </a>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {

        return (record.name && record.name.toLowerCase().includes(value.toLowerCase())) ||
          (record.productTypeId && record.productTypeId.toLowerCase().includes(value.toLowerCase())) ? true : false;
      },
    },
    {
      title: 'Metal',
      key: 'metalDescription',
      dataIndex: 'metalDescription',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Metal"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return (record?.metalDescription.toLowerCase().includes(value.toLowerCase()));

      },
    },
    {
      title: 'Propietario',
      key: 'ownerDescription',
      render: (_: any, record: any) => (
        <>
          <span>
            {
              record.contractId ? (
                <>
                  <a onClick={() => setContractDrawer(record.contractId)}>
                    <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.contractId} />
                  </a>
                  <small>{record.clientName}</small>
                </>
              ) :
                record.clientId ?
                  <>
                    <a onClick={() => setWordPressOrderDrawer({ id: record.wordPressOrderId, webName: record.wordPressOrderWebName })}>
                      <EntityLabel style={{ display: 'inline' }} prefix='WordPress' id={record.wordPressOrderId} />
                    </a>
                    <small>{record.clientName}</small>
                  </>
                  :
                  record.ownerDescription
            }
          </span>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Propietario"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const lowerCaseValue = value.toLowerCase();
      
        if (record.contractId) {
          return (
            record.clientName.toLowerCase().includes(lowerCaseValue) ||
            record.contractId.toLowerCase().includes(lowerCaseValue) ||
            (`Contrato #${record.contractId}`.toLowerCase().includes(lowerCaseValue))
          );
        } else if (record.clientId) {
          return (
            record.clientName.toLowerCase().includes(lowerCaseValue) ||
            record.wordPressOrderId.toString().toLowerCase().includes(lowerCaseValue) ||
            (`WordPress #${record.wordPressOrderId}`.toLowerCase().includes(lowerCaseValue))
            );
        } else {
          return record.ownerDescription.toLowerCase().includes(lowerCaseValue);
        }
      },
  
    },
    {
      title: 'Reservar',
      align: 'center',
      key: 'booked',
      dataIndex: 'booked',
      render: (_: any, record: any) => {
        return (
          (
            <div>
              <Switch size="small" disabled={!IsAuthorized([ROLE_ADMIN, ROLE_LOGISTIC.WRITE, ROLE_LOGISTIC.WRITE_LOCK]) || record.owner == Owners.CLIENT || record.owner == Owners.CONTRACT}
                checked={record.locked}
                onChange={(checked) => { setLocked(record.id, checked) }}
              />
              <div style={{ justifyContent: "center" }}>
                {
                  record.noteTitle ? (
                    <Tag color='gold' onClick={() => setOpenModal(record)}>
                      <small>{record.noteTitle}</small>
                    </Tag>
                  ) : record.lockedByUser && record.locked ? (
                    <a onClick={() => setUserDrawer(record.lockedByUser)}>
                      <small>{record.lockedByUser}</small>
                    </a>
                  ) : (
                    <a style={{ color: "var(--grey)" }} onClick={() => setOpenModal(record)}>
                      <small>Notas</small>
                    </a>
                  )
                }
              </div>
            </div>
          ))
      },
      filters: [
        { text: 'Reservados', value: true },
        { text: 'Disponibles', value: false },
      ],
      onFilter: (value: any, record: any) => record.locked === value,
    },
    {
      title: 'Fotos',
      align: 'center',
      width: '15%',
      render: (record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              {record.documents !== null ? record.documents.map((d: any) => (
                <div key={d.name}><small style={{ display: 'flex', alignItems: 'center', gap: '3px' }}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
              )) : <small style={{ color: 'grey' }}>Sin fotos</small>}</div>
            <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
              <PlusOutlined />
            </ButtonSG>
          </div>
        );
      },
      sorter: (a: any, b: any) => {
        if (a.documents !== null && b.documents === null) {
          return -1;
        }
        if (a.documents === null && b.documents !== null) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: '',
      align: 'center',
      render: (record: any) => {
        if(record.wordPressOrderId == null && record.clientId == null && record.contractId == null ){
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '10px' }}>
            <ButtonSG disabled={!IsAuthorized([ROLE_ADMIN])} size="small"
            
            onClick={
              () => {
                  Modal.confirm({
                      title: '¿Confirma la eliminación de este artículo?',
                      okText: 'Confirmar',
                      cancelText: 'Cancelar',
                      onOk() {  deleteArticle(record.id) }
                  });
              }
          }
           >
              <DeleteOutlined />
            </ButtonSG>
          </div>
        );
        }
      },
     
    }
  ];

  return (
    <Card
      style={{ border: "none" }}
      bodyStyle={{ padding: "0px 10px 10px 10px" }}
    >
      <div className="table-header">
        <span className='title-summary'>Artículos</span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>
          <ButtonSG color="grey" size="small" icon={<UploadOutlined />} onClick={() => setUploadModal(true)} />
        </AuthRoles>
      </div>

      <div style={{ textAlign: 'center' }}>
        <Radio.Group defaultValue="ALL" buttonStyle="outline" onChange={(e) => selectOwner(e)}>
          <Radio.Button value='ALL'>Todos</Radio.Button>
          {
            owners && owners.map((element: any) =>
              <Radio.Button value={element.key}>{element.description}</Radio.Button>
            )}
        </Radio.Group>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <p className='pl-20 desc'></p>
        <Switch defaultChecked={true} checkedChildren='En Almacén' unCheckedChildren='Custodia Cliente' loading={isLoading} onChange={(e) => { dataStatusDone(e) }} />
      </div>

      <TableSG rowKey="id"
        columns={columns}
        dataSource={storesData}
        size="middle"
        loading={isLoading}
      />
      {
        selectedRecord && (
          <ArticleLogDrawer
            onClose={() => {
              setOpen(false)
              setSelectedRecord(null)
            }
            }
            visible={open}
            nameLog={selectedRecord.name}
            id={selectedRecord.id}

          />
        )
      }

      {uploadModal && (
        <UploadArticlesModal
          visible={uploadModal}
          onFinish={() => {
            setUploadModal(false);
            setUpdateData(!updateData);
          }}
        />
      )}

      {

        documentsModal && <DocumentsModal action='open' img={true} entity='ARTICLES' title='Fotos de Artículos' tabs={[{ key: '1', label: 'Fotos', type: 'img', tabName: 'IMAGES' }]} onFinish={() => { setDocumentsModal(null); setUpdateData(!updateData); }} entityId={documentsModal} />

      }

      {
        openModal && <AddNoteModal article={openModal} onFinish={() => { setOpenModal(null) }} updateData={updateData} setUpdateData={setUpdateData} />
      }

    </Card>
  );
};
