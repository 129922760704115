import { AutoComplete, Form, Input, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../../interfaces/user';

import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';

const UsersAddModal = ({
	visible,
	onFinish,
	users,
}: any) => {

	const [form] = Form.useForm();

	const { data: rolesData } = useFetch(`${process.env.REACT_APP_API}/users/roles`, {}, []);
	const { data: departmentsData } = useFetch(`${process.env.REACT_APP_API}/users/departments`, {}, []);

	const [roles, setRoles] = useState<any[]>([]);
	const [departments, setDepartments] = useState<any[]>([]);
	const cargos= [
		{ value: 'Manager' },
		{ value: 'Supervisor' },
		{ value: 'Consultor' },]

	
	useEffect(() => {
		var roles = [];
		var departments = [];
		for (const [key, value] of Object.entries(rolesData)) {
            var rol = {name: key, description: value}
			roles.push(rol);
		}
		setRoles(roles)
		for (const [key, value] of Object.entries(departmentsData)) {
            var dep = {name: key, description: value}
			departments.push(dep);
		}
		setDepartments(departments)
	}, [rolesData, departmentsData]);

	const onFormFinish = async (values: any) => {

		const data = {
			...values,
			roles: values.roles,
			status: 'CREATED'
		};

		await authFetch(`${process.env.REACT_APP_API}/users`, { method: 'POST', data: data });
		onFinish();
	};

	return (
		<Modal open={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}
			footer={[
			<div style={{padding:'10px'}}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
			<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>
			<h2>Nuevo usuario</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label='Email' name='email' rules={[{ required: true, type: 'email' }]}>
					<Input />
				</Form.Item>
				<Form.Item label='Roles' name='roles' rules={[{ required: true }]}>
					<Select mode="multiple">
						{roles.map((element: any) =>
							<Select.Option value={element.name}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>
				<Form.Item label='Departamento' name='department' rules={[{ required: false }]}>
					<Select>
						{departments.map((element: any) =>
							<Select.Option value={element.name}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>
				<Form.Item label='Cargo' name='position' rules={[{ required: true }]}>
					<AutoComplete
						style={{ width: '100%' }}
						//options={cargos}
						//placeholder="Cargo"
						/*filterOption={(inputValue, option) =>
							option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
						}*/
					/>
				</Form.Item>
				<Form.Item label='Responsable' name='manager'>
					<Select defaultValue="">
						{users.filter((obj: User) => obj.enabled).map((obj: User) =>
							<Select.Option key={obj.email} value={obj.email}>{obj.name}</Select.Option>
						)}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}

export default UsersAddModal;