import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.less';
import { AuthProvider } from './store/AuthContext/AuthContext';
import DrawerProvider from './store/DrawerContext/DrawerContext';
import Layout from './view/layouts/main/MainLayout';
import { NonResponsive } from './view/pages/common/responsive/NonResponsive';
import GlobalVariableProvider from './store/GlobalVariableContext/GlobalVariableContext';




export default function App() {

  React.useEffect(() => {
    document.title = "Silver Gold";
  }, []);

 

  return (
    <BrowserRouter>
      <GlobalVariableProvider>
        <DrawerProvider>
          < AuthProvider >
            <div id='largeDevice'>
              <Layout />
            </div>
            <div id='smallDevice'>
              <NonResponsive />
            </div>
          </AuthProvider>
        </DrawerProvider>
      </GlobalVariableProvider>
    </BrowserRouter >
  );
}