import { SearchOutlined, DeleteOutlined} from "@ant-design/icons";
import {Card, Modal, Space,Input} from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ROLE_ADMIN,ROLE_OPERATIONS } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { WalletAddModal } from "./modal/WalletAddModal";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { FilterDropdownProps } from "antd/lib/table/interface";

import { authFetch } from "../../../../common/request/authFetch";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
 

export const Wallet = () => {

  const IsAuthorized = useIsAuthorized();

  const [addWalletVisible, setAddWalletVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  const deleteWallet = async (walletId: string) => {
    
    await authFetch(`${process.env.REACT_APP_API}/wallets?walletId=${encodeURIComponent(walletId)}`, { method: 'DELETE',data: { webNameId: walletId } });
    setUpdateData(!updateData);
  };

  const showDeleteConfirm = (record: string) => {
    deleteWallet(record)
  };
  
  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/wallets/`, {}, [updateData]);

  const columns = [    
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            },
    },
    {/*
      title: 'Producto',
      key: 'shortName',
      dataIndex: 'shortName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Id"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.shortName.toLowerCase().includes(value.toLowerCase());
      },
    */},
    {
      title: 'Distribución',
      key: 'metalsPrettyFormat',
      dataIndex: 'metalsPrettyFormat',
      ellipsis: true,
      filters: [
        { text: 'Oro', value: "ORO" },
        { text: 'Plata', value: "PLATA" },
        { text: 'Platino', value: "PLATINO" },
        { text: 'Paladio', value: "PALADIO" },
      ],
      onFilter: (value: string, record: any) => {
        const selectedValues = value.split(',');
        return selectedValues.every((selectedValues) => record.metalsPrettyFormat.some((val: string | string[]) => val.includes(value)))
      },
      
      render: (value: Array<any>) => (
        
            value && value.map(val => {
              return (
              <div style={{width:'55%',  backgroundColor:'white', marginBottom:'5px',  height:'10%', borderRadius:'5px'}}>
              <p style={{marginLeft: '10px', fontSize: '12px'}}>{val}</p>
              </div>
              );
            })
      )
    },
    {
      title: 'Acciones',
      key: 'actions',
      dataIndex: 'actions',
      render: (_: any, record:any) => {
         return (

          <Space size="middle">
              <AuthRoles roles={[ROLE_ADMIN, ROLE_OPERATIONS.WRITE]} >
              <ButtonSG size='small' onClick={						
                () => {
                  
                  Modal.confirm({
                    title: '¿Estás seguro de eliminar esta cartera?',
                    okText: 'Confirmar',
                    cancelText: 'Cancelar',
                    onOk: () => showDeleteConfirm(record.shortName)});                  
                }
              }>
              <DeleteOutlined/> 
            </ButtonSG>
          </AuthRoles>
          </Space>

         )}
    }    
  ]

  const onFinishModal = () => {
      setAddWalletVisible(false);
      setUpdateData(!updateData);
  };

  return (
    
      <Card style={{border: 'none'}} bodyStyle={{padding: "0px 10px 10px 10px"}}>
            
        <div className="table-header">
          <span className='title-summary'>Tipos de cartera</span>
            <ButtonSG 
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_OPERATIONS.WRITE])}
            onClick={() => setAddWalletVisible(true)}>
              Nueva Cartera
            </ButtonSG>
        </div>
        <p className='pl-20 desc'>Definición de combinaciones de metales para los contratos</p>
        <TableSG columns={columns} dataSource={storesData} rowKey={'shortName'} loading={isLoading} />         
        {addWalletVisible &&  <WalletAddModal onFinish={onFinishModal} visible={addWalletVisible}></WalletAddModal>}
      </Card>   
	)
};