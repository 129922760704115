import { useEffect, useState } from 'react';
import { Alert, Card, Col, message, Row, Tag } from "antd";
import { Column } from '@ant-design/plots';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../common/request/authRequest';
import TableSG from '../../../components/design/Table/TableSG';
import { PriceTag } from '../../../components/design/PriceTag/PriceTag';
import { TSummaryAlerts } from '../../../../types/summaryAlerts';
import { TArticleByOwner, TLogisticOwnerSummary } from '../../../../types/logisticOwnerSummary';
import { SpinSG } from '../../../components/model/Spin/SpinSG';

export const LogisticsSummary = () => {

    const [usersByMonthData, setUsersByMonthData] = useState([]);
    const [usersLastWeek, setUsersLastWeek] = useState([]);
    const [alerts, setAlerts] = useState<TSummaryAlerts[]>();
    const [ownerArticles, setOwnerArticles] = useState<TLogisticOwnerSummary[]>();
    const [loading, setLoading] = useState(true);


    const { logOut } = useAuth();

    useEffect(() => {
        authRequest(`${process.env.REACT_APP_API}/summary/logistic`, {})
            .then((response) => {
                setLoading(false);

                setUsersByMonthData(response.monthly.adminMonthlyChartPointsQuantity);
                setOwnerArticles(response.logisticOwnerSummaries);
                setUsersLastWeek(response.weekly.notes);
                setAlerts(response.logisticAlerts)
            })
            .catch((error) => {
                setLoading(false);

                if (error && error.status === 401) {
                    message.info('La sesión ha caducado');
                    logOut()
                } else {
                    throw error.response;
                }
            });
    }, [])


    const columns = [
        {
            title: '',
            key: 'ownerName',
            dataIndex: 'ownerName',
            width: '30%',
            render: (ownerName: string) => (
                <span style={{ fontWeight: 500 }}> {ownerName}</span>
            )
        },
        {
            title: 'Unidades',
            key: 'units',
            dataIndex: 'units',
            align: 'center',
            render: (_: number, record: TArticleByOwner) => {
                return <div style={{ display: 'flex', flexDirection: 'column' }}><span> {record.units} uds</span><small> {(record.unitPercentage * 100).toFixed(2)} % </small></div>
            },
        },
        {
            title: 'Coste',
            key: 'cost',
            dataIndex: 'cost',
            align: 'center',
            render: (_: number, record: TArticleByOwner) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}><div><PriceTag price={record.cost} /></div><small> {(record.costPercentage * 100).toFixed(2)} %</small></div>
            ),
        },

    ]

    const config : any = {
        xField: 'x',
        yField: 'value',
        height: 250,
        color: "#58C09C",
        maxColumnWidth: 30,
        isStack: true,
        label: {
          position: 'middle',
          style: {
            fill: '#fffbe6',
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          value: {
            alias: 'Unidades',
          }          
        }
      };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
                <div className="table-header">
                    <span className='title-summary'>Panel de Logística</span>
                </div>
            </Card>
            {loading ? <SpinSG /> :
                <><Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>TOTALES</div>
                            {ownerArticles && ownerArticles.map((el: any, index: any) => {
                                return <div>                                    
                                    <Tag color='geekblue'>Almacén <b>{el.title}</b></Tag>
                                    <div style={{paddingLeft:'50px', paddingBottom:'10px'}}>
                                        <TableSG showHeader={false} pagination={false} size='small' columns={columns} dataSource={el.articlesByOwner} loading={false} />
                                    </div>
                                </div>;
                            })}
                        </Card>
                    </Col>
                    <Col span={8}>
                    <Card style={{ border: 'none' }}>
                        <div className='title-chart'>ALERTAS</div>
                        <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                            { alerts && alerts.length > 0 ? 
                                alerts.map((alert: any) => {
                                    return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                })
                                :
                                <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                            }
                        </div>
                    </Card>
                    </Col>

                </Row><Col span={8}>
                        <div style={{ paddingLeft: '24px' }}>
                            <span className='title-chart'>HISTÓRICO</span>
                        </div>
                    </Col><Row gutter={[8, 8]}>
                        <Col span={16}>
                            <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                                <div className='title-chart-secondary'>Entradas en almacen </div>
                                {usersByMonthData && <Column data={usersByMonthData} {...config} />}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card style={{ border: 'none' }} bodyStyle={{ margin: "10px 5px" }}>
                                <div className='title-chart'>ÚLTIMA SEMANA</div>
                                {usersLastWeek && usersLastWeek.map(comment => {
                                    return (<div>{comment}</div>);
                                })}
                            </Card>

                        </Col>
                    </Row></>
            }
        </div>
    )
}