import { Modal, Spin } from 'antd';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface stateDocuments{
    name: string;
    state: string;
    action: string;
    icon: boolean,
    uploadFile?: string,
    spinner: boolean
}

export const ModalStateDocument = (props : {visible : boolean,onFinish : any, title: string}) => {

    const elementsTable = [
        {
            name: 'LOPD',
            state: 'Confirmado',
            action: 'Eliminar',
            icon: true,
            spinner: false
        },
        {
            name: 'Documento Ley',
            state: 'Confirmado',
            action: 'Eliminar',
            icon: true,
            spinner: false
        },
        {
            name: 'Documento Autorización',
            state: 'Pendiente',
            action: 'Descargar',
            uploadFIle: 'Subir archivo',
            icon: false,
            spinner: false
        }
    ]
    
    const [stateTable, setStateTable] = useState(elementsTable);

    const action = (element: stateDocuments) => {
        let timeout = 3000;
        if(element.action == 'Eliminar'){
            element.state = 'Pendiente';
            setStateTable(c => [...c]);
        }else{
           element.spinner = true;
           setStateTable(c => [...c]);
           setTimeout(() => {
            element.spinner = false;
            setStateTable(c => [...c]);
           }, timeout);
        }
    }

    const uploadFIle = (element: stateDocuments) => {
        element.state = 'Confirmado';
        setStateTable(c => [...c]);
    }

    return (
        <Modal 
            open={props.visible} 
            onOk={props.onFinish} 
            onCancel={props.onFinish}
        >
            <h2>{props.title}</h2>
            <table>
                <thead>
                    <tr>
                        <th>NOMBRE</th>
                        <th>ESTADO</th>
                        <th>ACCIONES</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        stateTable.map((elemet, index) => {
                            return(
                                <tr key={index}>
                                    <td data-column="LOPD">{elemet.state == 'Confirmado' &&  <CheckOutlined />} {elemet.name} </td>
                                    <td data-column="confirmado">{elemet.state}</td>
                                    <td data-column="eliminar">
                                        <strong onClick={() => action(elemet) } className='text-doc pr-3'>
                                            {elemet.action}
                                        </strong>
                                        {
                                            elemet.spinner &&  <Spin indicator={antIcon} />
                                        }
                                        {
                                            elemet.uploadFIle && (
                                                <>
                                                    <br />
                                                    <strong onClick={()=> uploadFIle(elemet)} className='text-doc pr-3'>{elemet.uploadFIle}</strong>
                                                </>
                                            )
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}
