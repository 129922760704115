import { AppstoreAddOutlined, DoubleLeftOutlined, DoubleRightOutlined, EnterOutlined, FundProjectionScreenOutlined, GoldOutlined, LockOutlined, RetweetOutlined, ShoppingOutlined, TagsOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LogisticaMenuRoutes } from '../../../routing/sideMenu/LogisticaMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { ROLE_ADMIN, ROLE_ECOMMERCE } from '../../../common/constants/roles';
import { useIsAuthorized } from '../../../common/request/AuthorizationUtils';


const { Sider, Content } = AntdLayout;

export const LogisticaLayout = () => {

	const IsAuthorized = useIsAuthorized();

	const location = useLocation();
	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		

	const itemsContracts = [
		{ label: <Link to={'/logistica/inicio'}><b>Panel</b></Link>, key: '/logistica/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 		
		{
		  label: 'Inventario',
		  key: 'productos',
		  type: 'group',		  
		  children: [
			{ label: <Link to={'/logistica/productos'}><b>Productos</b></Link>, key: '/logistica/productos', icon: <TagsOutlined className='menu2-icon' />},			
			{ label: <Link to={'/logistica/inventario'}><b>Artículos</b></Link>, key: '/logistica/inventario', icon: <GoldOutlined className='menu2-icon' />}			
			],
		},
		{
			label: 'Contratos',
			key: 'contratos',
			type: 'group',		  
			children: [
			  { label: <Link to={'/logistica/adjudicar'}><b>Marketplace</b></Link>, key: '/logistica/adjudicar', icon: <RetweetOutlined className='menu2-icon' />},			
			  { label: <Link to={'/logistica/venta'}><b>Provisión</b></Link>, key: '/logistica/venta', icon: <LockOutlined className='menu2-icon' />},			
			  ],
		  },
		  {
			  label: 'Compras',
			  key: 'compras',
			  type: 'group',		  
			  children: [
				{ label: <Link to={'/logistica/etiquetado'}><b>Etiquetar</b></Link>, key: '/logistica/etiquetado', icon: 
				<span className="material-symbols-outlined menu2-icon" >
				barcode_reader
			  	</span>},
				],
			},
			{
				label: 'Config',
				key: 'config',
				type: 'group',		  
				children: [
				  { label: <Link to={'/logistica/almacenes'}><b>Almacenes</b></Link>, key: '/logistica/almacenes', icon: <span style={{ fontSize: '25px' }} className="material-symbols-rounded">forklift</span> }	,
				  { label: <Link to={'/logistica/cotizaciones'}><b>Cotizaciones</b></Link>, key: '/logistica/cotizaciones', icon: <span style={{ fontSize: '25px' }} className="material-symbols-rounded">request_quote</span> }			  
				  ],
			}
	  ];

	  const itemsEcommerce = [
		{ label: <Link to={'/logistica/inicio'}><b>Panel</b></Link>, key: '/logistica/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 		
		{
		  label: 'Inventario',
		  key: 'productos',
		  type: 'group',		  
		  children: [
			{ label: <Link to={'/logistica/productos'}><b>Productos</b></Link>, key: '/logistica/productos', icon: <TagsOutlined className='menu2-icon' />},			
			{ label: <Link to={'/logistica/inventario'}><b>Artículos</b></Link>, key: '/logistica/inventario', icon: <GoldOutlined className='menu2-icon' />}			
			],
		},
		  {
			  label: 'Compras',
			  key: 'compras',
			  type: 'group',		  
			  children: [
				{ label: <Link to={'/logistica/etiquetado'}><b>Etiquetar</b></Link>, key: '/logistica/etiquetado', icon: 
				<span className="material-symbols-outlined menu2-icon" >
				barcode_reader
			  	</span>},
				],
			},
			{
				label: 'Config',
				key: 'config',
				type: 'group',		  
				children: [
				  { label: <Link to={'/logistica/almacenes'}><b>Almacenes</b></Link>, key: '/logistica/almacenes', icon: <span style={{ fontSize: '25px' }} className="material-symbols-rounded">forklift</span> }	,
				  { label: <Link to={'/logistica/cotizaciones'}><b>Cotizaciones</b></Link>, key: '/logistica/cotizaciones', icon: <span style={{ fontSize: '25px' }} className="material-symbols-rounded">request_quote</span> }			  
				  ],
			}
	  ];


	  const [selectedKey, setSelectedKey] = useState('/logistica/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  

	return (
		<AntdLayout className='container-menu'>
			<Sider className='side-menu'  collapsed={collapsed} collapsible={true} trigger={null}>
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/logistica/inicio']}					
					mode="inline"					
					items={IsAuthorized([ROLE_ECOMMERCE.READ, ROLE_ECOMMERCE.WRITE]) && !IsAuthorized([ROLE_ADMIN])? itemsEcommerce : itemsContracts}
					selectedKeys={[selectedKey]}

				/>
				{/*<Menu selectedKeys={[pathName]} className='menu'>
					{
						[																										,
							<Menu.Item key={'/logistica/almacenes'} className='menu-item'>
								<SettingOutlined className='menu-icon'/>
								<Link to={'/logistica/almacenes'}>
									{
										!collapsed && 'Almacenes'
									}
								</Link>
							</Menu.Item>
							,
							<Menu.Item key={'/logistica/adjudicacion'} className='menu-item'>
								<SwapOutlined className='menu-icon'/>
								<Link to={'/logistica/adjudicacion'}>
									{
										!collapsed && 'Adjudicación'
									}
								</Link>
							</Menu.Item>
							,
							<Menu.Item key={'/logistica/compras'} className='menu-item'>
								<ShoppingCartOutlined className='menu-icon'/>
								<Link to={'/logistica/compras'}>
									{
										!collapsed && 'Compras'
									}
								</Link>
							</Menu.Item>
							,
							<Menu.Item key={'/logistica/proveedores'} className='menu-item'>
								<DatabaseOutlined className='menu-icon'/>
								<Link to={'/logistica/proveedores'}>
									{
										!collapsed && 'Proveedores'
									}
								</Link>
							</Menu.Item>
						]	
					}
				</Menu>*/}
			</Sider>			
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<LogisticaMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};	