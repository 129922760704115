import { Col, Radio, Row } from "antd";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG";
import { SpinSG } from "../../../../components/model/Spin/SpinSG";
import { useState } from "react";
import TableSG from "../../../../components/design/Table/TableSG";

const WordPressPricesDrawer = ({ onClose }: any) => {


    const [metal, setMetal] = useState('AU')
	const [updateData, setUpdateData] = useState(false);
	const [updateDataDay, setUpdateDataDay] = useState(false);
	
	const { data: metals, isLoading:comboLoading } = useFetch(`${process.env.REACT_APP_API}/combos/metals`, {});
    
    const { data: defaultMargin, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/defaultPercentages?metal=${metal}`,
        {},
        [],
    );

    function selectMetal(metal: any) {
		setMetal(metal.target.value);
		setUpdateData(!updateData);
		setUpdateDataDay(!updateDataDay);
	}
	
    const columns = [
        {
            title: 'Peso',
            key: 'weight',
            dataIndex: 'weight',
            align: 'center',
            render: (weight: number) => (
                <div > {weight} gr</div>
            )
        },
        {
            title: 'Lingote',
            key: 'slug',
            dataIndex: 'slug',
            align: 'center',
            render: (slug: number) => (
                <div > {slug*100} %</div>
            )
        },
        {
            title: 'Moneda',
            key: 'coin',
            dataIndex: 'coin',
            align: 'center',
            render: (coin: number) => (
                <div > {coin *100} %</div>
            )
        }
    
    ]

    return (
        <>
            {
                (defaultMargin &&
                    <DrawerSG
                        title={'Margen por defecto'}
                        onClose={onClose}
                        placement='right'
                        visible={!!defaultMargin}
                    >

                     {comboLoading ? <SpinSG /> :  <Row justify="space-around" style={{marginTop:'30px'}}>

                            <Col  >
                                <Radio.Group defaultValue="AU" buttonStyle="outline" onChange={(e) => selectMetal(e)}>
                                    {
                                        metals && metals.map((element: any) =>
                                            <Radio.Button value={element.key}>{element.description}</Radio.Button>
                                        )}
                                </Radio.Group>
                            </Col>
                        </Row>}

                       {isLoading ?  <SpinSG /> : <div style={{padding:'20px 30px'}}>
                        <TableSG dataSource={defaultMargin} columns={columns} loading={isLoading} pagination={{pageSize:20}} />
                        </div>}
                       
                    </DrawerSG>
                )
            }
        </>
    )
}

export default WordPressPricesDrawer;