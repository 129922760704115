import { Column, DualAxes } from "@ant-design/charts";
import { Card, Pagination, Timeline, Row, Col, Modal } from "antd";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { useContext, useEffect, useState } from 'react'
import { authRequest } from "../../../../../common/request/authRequest";

export const BalanceChart = ({ id, name, title, height = 185 }: any) => {

    const [margin, setMargin] = useState([]);
    const [balance, setBalance] = useState<any>([]);
    const [revenue, setRevenue] = useState<any>([]);

    // const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/investments/${id}/balance`, {}, []);

    const { setContractDrawer } = useContext(DrawerContext)

    // useEffect(() => {
    //     if (data || data.length > 0) {
    //         setMargin(data?.marginSG)
    //         setBalance(data?.balance)
    //         setRevenue(data?.revenue)
    //     }
    // }, [data])

    useEffect(() => {
        if (!id) {
            authRequest(`${process.env.REACT_APP_API}/investments/balance`, {}).then((res:any)=>{
                setMargin(res?.marginSG)
                setBalance(res?.balance)
                setRevenue(res?.revenue)
            })
        }
        else {
            authRequest(`${process.env.REACT_APP_API}/investments/${id}/balance`, {}).then((res:any)=>{
                setMargin(res?.marginSG)
                setBalance(res?.balance)
                setRevenue(res?.revenue)
            })
        }
        
    }, []);

    let maxY;

    if (balance && revenue) {
        const valuesData = balance?.length > 0 && balance.map((d: any) => d.value);
        const valuesTransformData =revenue?.length > 0 && revenue?.map((d: any) => d.value);

        if (valuesData && valuesTransformData) {
            maxY = Math.max(...valuesData, ...valuesTransformData) + 1000;
        }
    }

    const config = {
        data: [balance, revenue],
        xField: 'x',
        yField: ['value', 'value'],
        geometryOptions: [
            {
                geometry: 'column',
                isStack: true,
                seriesField: 'category',
                color: ["#998e85", "#EA9E0C"],

            },
            {
                geometry: 'line',
                color: 'black'
            },
        ],
        yAxis: {
            count: {
                min: 0,
                max: maxY,
            },
            value: {
                min: 0,
                max: maxY,
            },
        },
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            }
        }
    }

    /*const configColumn = {
        data: margin,
        xField: 'x',
        yField: 'value',
        color: '#30bf78',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            }
        }
    };*/

    return (
        <>
            <h1 style={{ display: 'flex', gap: '5px' }}>Saldo Histórico</h1>
            {title ? title :
                <div>
                    <b><EntityLabel prefix='Contrato' id={id} /></b>
                    <small>{name}</small>
                </div>
            }            
            <br/>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                <Card style={{ borderRadius: '25px' }}>
                    <h2>Gráfica de Saldos</h2>                    
                    <div>
                        {balance && <DualAxes {...config} height={height} />}
                    </div>
                </Card>
                {/*<Card style={{ borderRadius: '25px' }}>
                    <h2>Margen</h2>
                    <div>
                        {margin && <Column {...configColumn} height={height} />}
                    </div>
                </Card>*/}
            </div>
        </>
    )
}