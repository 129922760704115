import { Alert, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';
import { useState } from 'react';
import { RulePriceInfo } from '../../../../../interfaces/RulePriceInfo';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const WordPressAddPricesModal = (props: {
	rulePriceInfo: RulePriceInfo,
	onFinish: any
}) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);


	const onFormFinish = async (values: any) => {

		const data = {
			...values,
			sku: props.rulePriceInfo.productTypeEnriched!.sku,
			webId: props.rulePriceInfo.webId,
			wordPressId: props.rulePriceInfo.wordPressId
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/prices`, { method: 'POST', data: data });

		
		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}
	};

	const initialValue = props.rulePriceInfo.rulePrice ?
		{
			webId: props.rulePriceInfo.webId,
			sku: props.rulePriceInfo.productTypeEnriched!.sku,
			wordPressId: props.rulePriceInfo.wordPressId,
			default: props.rulePriceInfo.rulePrice.defaultCapitalGain,
			capitalGain: (props.rulePriceInfo.rulePrice.capitalGain! * 100).toFixed(0),
			discount: (props.rulePriceInfo.rulePrice.discount === 0 || !props.rulePriceInfo.rulePrice.discount ? null : props.rulePriceInfo.rulePrice.discount! * 100),
			andorranoUrl: props.rulePriceInfo.rulePrice.andorranoUrl,
			coinInvestUrl: props.rulePriceInfo.rulePrice.coinInvestUrl,
			serviOroUrl: props.rulePriceInfo.rulePrice.serviOroUrl,
			inversOroUrl: props.rulePriceInfo.rulePrice.inversOroUrl
		}
		: {
			webId: props.rulePriceInfo.webId,
			sku: props.rulePriceInfo.productTypeEnriched!.sku,
			wordPressId: props.rulePriceInfo.wordPressId
		}

		const [isDiscountEnabled, setIsDiscountEnabled] = useState(initialValue.discount !== null)
		const [isMarginEnabled, setIsMarginEnabled] = useState(!initialValue.default)


	return (

		<Modal
			open={true}
			onOk={() => form.submit()}
			onCancel={props.onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Configuración de Precios</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={initialValue}>

				<Form.Item label='Producto' name='sku' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Web' name='webId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Id en WordPress' name='wordPressId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>
				<Row style={{ alignItems: 'center' }}>
				<Form.Item label='Margen de beneficio' name='capitalGain' rules={[{ required: isMarginEnabled }]}>
					<InputNumber min={0}
					 addonAfter="%"
					 disabled={!isMarginEnabled} />
				</Form.Item>
				<Switch
				size='small'
				style={{ marginLeft: '20px' }}
				defaultChecked={isMarginEnabled}
				onChange={(checked) => {
				setIsMarginEnabled(checked);
				if (!checked) {
					form.setFieldsValue({ capitalGain: null });
				} else {
					form.validateFields(['capitalGain']);
				}
				}}
			/>
					
				</Row>

				<Row style={{ alignItems: 'center' }}>
			<Form.Item
				label='Rebaja'
				name='discount'
				rules={[{ required: isDiscountEnabled }]}
			>
				<InputNumber
				min={1}
				max={100}
				addonAfter="%"
				disabled={!isDiscountEnabled}
				onChange={(value) => {
					form.setFieldsValue({ discount: value });
				}}
				/>
			</Form.Item>
			<Switch
				size='small'
				style={{ marginLeft: '20px' }}
				defaultChecked={isDiscountEnabled}
				onChange={(checked) => {
				setIsDiscountEnabled(checked);
				if (!checked) {
					form.setFieldsValue({ discount: null });
				} else {
					form.validateFields(['discount']);
				}
				}}
			/>
			</Row>

				<Form.Item label='Andorrano Joyeria URL' name='andorranoUrl'
					rules={[{

						pattern: /^https?:\/\/(www\.)?andorrano-joyeria\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
						message: 'Ingrese una URL válida',
					},
					]}
					normalize={(value) => (value ? value : null)}
				>
					<Input allowClear />
				</Form.Item>

				<Form.Item label='StoneX Bullion URL' name='coinInvestUrl'
					rules={[{
						pattern: /^https?:\/\/(www\.)?stonexbullion\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
						message: 'Ingrese una URL válida',
					},
					]}
					normalize={(value) => (value ? value : null)}
				>
					<Input allowClear />
				</Form.Item>

				<Form.Item label='Servioro URL' name='serviOroUrl' rules={[{
					pattern: /^https?:\/\/(www\.)?servioro\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
					message: 'Ingrese una URL válida',
				},
				]}
					normalize={(value) => (value ? value : null)}>
					<Input allowClear />
				</Form.Item>

				<Form.Item label='Inversoro URL' name='inversOroUrl' rules={[{
					pattern: /^https?:\/\/(www\.)?inversoro\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
					message: 'Ingrese una URL válida',
				},
				]}
					normalize={(value) => (value ? value : null)}>
					<Input allowClear />
				</Form.Item>
			</Form>

			{errorMessage &&
				<Alert
					style={{ marginTop: '12px', borderRadius: '10px' }}
					type='error'
					message={errorMessage}
				/>
			}
		</Modal >
	)
}