import { Alert, Form, Input, Modal, Tabs, Tag } from 'antd';

import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { useState } from 'react';
import { customRequest } from '../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../common/constants/statusCode';
import DownloadLink from '../../../components/design/DownloadFileActions';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';


type Ttabs = {
	key: string;
	label: string;
	type: string
	tabName: string;
}

export const DocumentsModal = (props: {
	onFinish: any,
	entity: string,
	entityId: string
	title?: string,
	tabs: Ttabs[],
	action?: string,
	htmlElement?: React.ReactNode,
	img?:boolean
}) => {



	const [file, setFile] = useState<any>(null);
	const [updateData, setUpdateData] = useState(false);
	const [errorMessage, setErrorMessage] = useState<any>(null);

	const validate = (name: string) => {
		const patron = /^[a-zA-Z0-9_.()\-\s]+$/;
		return patron.test(name);
	};

	const submitDocument = async () => {

		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			}
		}

		let form_data = new FormData();
		form_data.append('file', file);
		form_data.append('entity', props.entity);
		form_data.append('tab', selectedTab.tabName);
		form_data.append('entityId', props.entityId);

		 if (validate(file.name)) {
			const response = await customRequest(`${process.env.REACT_APP_API}/documents`, { ...config, method: 'POST', accept: 'image/*, application/pdf', data: form_data }, 'documentos subido con éxito')
			if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
				setErrorMessage(response.message)
			}
			else {
				setUpdateData(!updateData)
				setErrorMessage('')
				setFile(null)
				form.resetFields();

			}
		} else {
			setErrorMessage("El nombre del archivo no puede contener carácteres especiales.")
		}


	}

	const deleteFile = async (name: any) => {

		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			}
		}


		const response = await customRequest(`${process.env.REACT_APP_API}/documents/${props.entity}/${selectedTab.tabName}/${props.entityId}/${name}`, { ...config, method: 'DELETE' }, 'documento eliminado con éxito')
		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		}
		else {
			setUpdateData(!updateData)
			setErrorMessage('')

		}
	}

	const [selectedTab, setSelectedTab] = useState<any>(props.tabs[0])
	const [form] = Form.useForm();


	const { data } = useFetch(`${process.env.REACT_APP_API}/documents/${props.entity}/${selectedTab.tabName}/${props.entityId}`, {}, [updateData]);

	const onChange = (e: any) => {
		setFile(e.target.files[0])

	}


	const onChangeTab = (key: string) => {
		const tab = props.tabs.find((tab: { key: string; }) => tab.key === key);
		setSelectedTab(tab);
	}

	return (
		<Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
			footer={null}
		>
			<div>
				<h2 style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px' }}>{props.title}</h2>
				{props.htmlElement}
			</div>

			<Tabs defaultActiveKey="1" items={props.tabs} onChange={onChangeTab} />


			<Form layout='vertical' form={form} onFinish={submitDocument}>
				<p style={{ marginBottom: '8px' }}>Subir nuevo archivo</p>
				<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
					<Form.Item name='reportFile'>
						<Input type='file'
							accept={selectedTab?.type === 'pdf' ? 'application/pdf' : 'image/*'}
							onChange={(event) => { onChange(event) }} >
						</Input>
					</Form.Item>
					<ButtonSG disabled={!file} style={{ marginBottom: '24px' }} size='small' onClick={() => form.submit()}><CheckOutlined /></ButtonSG>

				</div>
			</Form>

			{data.length > 0 && <p style={{ marginBottom: '8px' }}>Archivos subidos</p>}
			{data.map((file: string) => {

				return <div style={{ paddingBottom: '20px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<DownloadLink action={'open'} filename={file} entity={props.entity} tabName={selectedTab.tabName} entityId={props.entityId} img={props.img} />
						<a role='button' onClick={
							() => {
								Modal.confirm({
									title: 'Eliminar documento?',
									cancelText: 'Cancelar',
									onOk: () => deleteFile(file)
								});
							}
						}>
							<DeleteOutlined />
						</a>
					</div>
				</div>

			})}
			{errorMessage &&
				<Alert
					style={{ marginTop: '12px', borderRadius: '10px' }}
					type='error'
					message={errorMessage}
				/>
			}
		</Modal>
	)
}
