import { Alert, Layout, Result } from 'antd';
import { Content } from 'antd/lib/layout/layout';


export const NonResponsive = () => {
    return (
        <Layout style={{ minHeight: '100vh', background: 'white' }}>
        <Content style={{
				width: '100vw',
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',				
                alignItems:'center'				
			}}>
        <Result status="warning" title="Esta aplicación está diseñada para uso en escritorio." extra={ <Alert message="El ancho mínimo de la página es de 500px" /> } />
            </Content>
        </Layout>
    )
}
