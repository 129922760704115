import { DatePicker, Collapse, Form, Input, Modal, Switch, Col } from "antd"
import ButtonSG from "../../../components/design/Buttons/ButtonSG"
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons"
import { customRequest } from "../../../../common/request/customRequest"
import { useState } from "react"
import { useFetch } from "../../../../hooks/useFetch/useFetch"

export const CreateOfferModal = (props: {
    onFinish: any,
    productId: any
}) => {

    const [form] = Form.useForm();

    const [updateData, setUpdateData] = useState(true);

    const { data } = useFetch(`${process.env.REACT_APP_API}/offers/${props.productId}`, {}, [updateData]);

    const submitProduct = async (values: any) => {

        const [fechaInicio, fechaFinal] = values.date;

        const data = {
            name: values.name,
            description: values.description,
            start: fechaInicio,
            end: fechaFinal,
            productId: props.productId
        }

        await customRequest(`${process.env.REACT_APP_API}/offers`, { method: 'POST', data }).then(() => {
            props.onFinish();
        })

    }

    async function deleteOffer(id: string) {
        await customRequest(`${process.env.REACT_APP_API}/offers/${id}`, { method: 'DELETE' }, 'Oferta eliminada').then(() => {
            setUpdateData(!updateData)
        })

    }
    const { Panel } = Collapse;

    const { RangePicker } = DatePicker;

    return (
        <Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
            footer={null}
        >

            <div>
                <h2 style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px' }}>Nueva Oferta</h2>

            </div>

            <Collapse defaultActiveKey={[]} ghost >
                <Panel key="1" header={<span style={{ color: 'var(--accent-variant)' }}>+ Añadir Oferta</span>} showArrow={false}>

                    <Form layout='vertical' form={form} onFinish={submitProduct}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Form.Item name='name' label='Nombre'>
                                <Input />
                            </Form.Item>
                            <Form.Item name='description' label='Descripción'>
                                <Input />
                            </Form.Item>
                        
                        </div>
                            <Form.Item name='date'>
                            <RangePicker />
                            </Form.Item>
                           
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonSG size='small' onClick={() => form.submit()}><CheckOutlined /></ButtonSG>
                        </div>
                    </Form>
                </Panel>
            </Collapse>

            <div style={{ marginTop: '15px' }}>
                {data.length > 0 && <p style={{ marginBottom: '10px' }}>Ofertas Publicadas</p>}
                {data.map((file: any) => {

                    return <div style={{ paddingBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Col span={10}>
                            <small>
                                <p>
                                    {file.name}
                                </p>
                                <p>
                                    {file.description}
                                </p>
                            </small>
                            </Col>
                            <Col span={10}>
                                <small>{file.start} - {file.end}</small>
                            </Col>

                            <div style={{ display: 'flex', gap: '20px' }}>
                                <DeleteOutlined onClick={() => { deleteOffer(file.id) }} />
                            </div>

                        </div>
                    </div>

                })}
            </div>
        </Modal>
    )
}