import { DatePicker, Form, Input, InputNumber, Modal, Select, AutoComplete, Row, Col, Radio } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import moment from 'moment';
import '../../../../../styles/modalSG.sass'
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const LogisticRequestAddModal = (props : {visible : boolean, onFinish : any} ) => {

	const [form] = Form.useForm();
	
	const onFormFinish = async (values: any) => {

		let a = moment(values.year)
		values.year = a.year();
		const data = {
			...values,			
		};
		console.log('data', data)
		await authFetch(`${process.env.REACT_APP_API}/producttypes`, { method: 'POST', data: data });
		props.onFinish();
	};

	//const [updateData, setUpdateData] = useState(false);
	const { data: combos } = useFetch(`${process.env.REACT_APP_API}/combos`, {});

	return (
		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
		footer={[
			<div style={{padding:'10px'}}>
				<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
				Cancelar 
				</ButtonSG>
				<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
				Guardar
				</ButtonSG>
			</div>
		  ]}
		>
			
			<h2>Nuevo Pedido</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{ unit:'KG' }} className='modalSG'>
				<Form.Item label='Nombre' name='name' rules={[{ required: true}]}>
					<Input />
				</Form.Item>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Tipo' name='type' rules={[{ required: true}]}>
							<Select defaultValue="">											
							{
								combos.metalTypes && combos.metalTypes.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>																
							)}	
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='País' name='country' rules={[{ required: true}]}>
							<Select defaultValue="" showSearch  optionFilterProp="children">											
							{
								combos.countries && combos.countries.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>																
							)}	
							</Select>
						</Form.Item>
					</Col>
				</Row>		
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Metal' name='metal' rules={[{ required: true}]}>							
							<Select defaultValue="">											
							{
								combos.metals && combos.metals.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>																
							)}	
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Año' name='year' rules={[{ required: true}]}>
							<DatePicker style={{width:'100%'}} picker="year" format={"YYYY"} />				
						</Form.Item>	
					</Col>
				</Row>						
				<Row>
					<Col span={6}>
						<Form.Item label='Peso' name='weight' rules={[{ required: true}]}>
							<InputNumber min={0} step={0.1} stringMode/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label='Unidad' name='unit' rules={[{ required: true}]}>
							<Select>											
								{
									combos.units && combos.units.map((element: any) =>
									<Select.Option value={element.key}>{element.description}</Select.Option>																
								)}	
							</Select>
						</Form.Item>
					</Col>
				</Row>														
			</Form>
		</Modal>
	)
}