import { Card, Input, Switch } from "antd";
import { Key, useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ROLE_ACCOUNTING, ROLE_ADMIN, ROLE_LOGISTIC, ROLE_PURCHASES } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { ArticlesToLabelModal } from "./modal/ArticlesToLabelModal";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";

const allStatusFilters = 'status=PENDING'
const statusOrder = 'orderStatus=RECEIVED'

export const Labeled = () => {

  const [updateData, setUpdateData] = useState(false);
  const [statusFilter, setStatusFilter] = useState(allStatusFilters);
  const [articlesToLabel, setArticlesToLabel] = useState(null);

  const { setProductDrawer, setOrderDrawer } = useContext(DrawerContext)

  const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/petitions?${statusFilter}&${statusOrder}`, {}, [updateData]);


  const columns = [
    {
        title: 'Producto',
        key: 'productTypeId',
        dataIndex: 'productTypeId',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <Input
              autoFocus
              placeholder="Nombre o sku"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value: any, record: any) => {
          return record.productName.toLowerCase().includes(value.toLowerCase()) || record.productTypeId.toLowerCase().includes(value.toLowerCase());
        },
        render: (productTypeId: string, record: any) => (
          <>
            <div>{record.productName}</div>
            {
              <a onClick={() => {
                setProductDrawer(productTypeId)
              }}>
               <small>{record.productTypeId}</small> 
              </a>
            }
          </>
        ),
      },
    {
        title: 'Solicitante',
        key: 'ownerDescription',
        dataIndex: 'ownerDescription',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
        }: FilterDropdownProps) => {
          return (
            <Input
              autoFocus
              placeholder="Propietario o email"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value: any, record: any) => {
          return record?.ownerDescription.toLowerCase().includes(value.toLowerCase()) || record?.userId.toLowerCase().includes(value.toLowerCase());
        },
  
        render: (_: any, record: any) => {
          return <>
            <p>{record.ownerDescription}</p>
            <small>{record.userId}</small>
          </>
        }
      },
      {
        title: 'Orden',
        key: 'orderId',
        dataIndex: 'orderId',
        render: (_: any, record: any) => {
          return record.orderId &&
            <div>
              <p>{record.orderStatus}</p>
              <a onClick={() => setOrderDrawer(record.orderId)}>
               <EntityLabel prefix='Orden' id={record.orderId}/>             
              </a>
            </div>
        }
      },
      {
        title: 'Cantidad',
        key: 'quantity',
        dataIndex: 'quantity',
        render: (quantity: string) => (
          <>
            {quantity} uds
          </>
        ),
        sorter: (a: any, b: any) => b.quantity - a.quantity
      },
    {
        title: 'Coste/uds',
        key: 'unitCost',
        dataIndex: 'unitCost',
        render: (_:any, render: any) => (
          render.unitCost > 0 && <PriceTag price={render.unitCost}/>
        ),
        sorter: (a: any, b: any) => b.unitCost - a.unitCost
      },
      {
        title: 'Coste total',
        key: 'unitCost',
        dataIndex: 'unitCost',
        render: (_:any, render: any) => {
          const totalCost = render.unitCost * render.quantity
          return totalCost > 0 && <PriceTag price={totalCost} />
        },
        sorter: (a: any, b: any) => (b.unitCost * b.quantity) - (a.unitCost * a.quantity)
      },
      {
        title: '',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: any) => {
            return <div style={{display:'flex'}}>
                <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]} >
                <ButtonSG size="small" onClick={()=>setArticlesToLabel(record)}><PlusOutlined/></ButtonSG>
                </AuthRoles>
            </div>
        }
    },
  ]


  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Etiquetado de Compra</span>
      </div>
    
      <TableSG columns={columns} dataSource={data} loading={isLoading} rowKey='id' />
      {
        articlesToLabel && (
          <ArticlesToLabelModal
          product={articlesToLabel}
          onFinish={() => {
            setArticlesToLabel(null);
            setUpdateData(!updateData);
          }}/>
        )
      }
    </Card>
  )
};