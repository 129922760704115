import { Card, Form, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { PriceTag } from '../../../../components/design/PriceTag/PriceTag';
import { EntityLabel } from '../../../../components/design/EntityLabel/EntityLabel';

export const WordPressOrderSellModal = ({
	order,
	onFinish,
}: any) => {

	const [form] = Form.useForm();

	const [barCode, setBarCode] = useState('');

	const { data: orderData } = useFetch(`${process.env.REACT_APP_API}/products/${order.sku}/articles${barCode}`, {}, []);

	const onFormFinish = async (values: any) => {

		const articleUpdateRequest = {
			articleId: values.articleId,
			buyerOwner: "CLIENT",
			buyerOrderId: order.id,
			buyerOrderWebName: order.webName,
			price: order.price,
			buyerClientId: order.clientId,
			buyerClientName: order.clientName + ' ' + order.clientSurname,
		}

		const updateArticle = await customRequest(`${process.env.REACT_APP_API}/transactions`, { method: 'POST', data: articleUpdateRequest })
		if (updateArticle.statusCode === STATUS_CODE.OK) {
			onFinish();
		}
	};

	const getArticles = (event: any) => {
		if (event.target.value) {
			setBarCode(`?barcode=${event.target.value}`)
		} else {
			setBarCode('')
		}
	}

	return (

		<Modal
			open={order}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Asignar
					</ButtonSG>
				</div>
			]}>

			<h2>Asignación a Cliente de WordPress</h2>
			<h3 style={{ marginTop: '30px' }}>Producto Adquirido:</h3>
			<Card style={{ borderRadius: '10px' }}>

				<div>{order.productName} - {order.sku}</div>
				<p>Precio: <PriceTag price={order.price}></PriceTag></p>
			</Card>
			<br />

			<Form layout='vertical' >
				<Form.Item label='Código de barras'>
					<Input onBlur={getArticles} />
				</Form.Item>
			</Form>

			<Form layout='vertical' form={form} onFinish={onFormFinish} >
				<Form.Item label='Selecciona un Artículo' name='articleId' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							orderData && orderData.map((element: any) =>
								<Select.Option value={element.id}>
									<EntityLabel prefix={<> Artículo</>}
										suffix={element.barCode && <span><b>(Barcode {element.barCode})</b></span>}
										id={element.id} color={false} />
								</Select.Option>
							)}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}
