import { Form, Input, Modal, Select } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';

export const EditContractTypeModal = ({
	store,
	onFinish,
}: any) => {

	const [form] = Form.useForm();
	const { data: contractType } = useFetch(`${process.env.REACT_APP_API}/combos/contracttypes`, {});


	const onFormFinish = async (values: any) => {

		 await authFetch(`${process.env.REACT_APP_API}/stores/contractType/${store.name}?contractType=${values.contractType}`, { method: 'POST' });
		 onFinish();
	};

	return (
		<Modal open={store} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>
			<h2>Editar Tipo de Contrato</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label='Tipo de Contrato' name='contractType' required initialValue={store?.contractType}>
					<Select mode="multiple">
						{contractType.map((element: any) =>
							<Select.Option value={element.description}>
								{element.description}
							</Select.Option>
						)}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	)
}