import { useContext } from "react";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { Tag } from "antd";
import TableSG from "../../../../components/design/Table/TableSG";

export const InventoryTab = () => {


    const { investmentDrawer, setArticleDrawer, setProductDrawer } = useContext(DrawerContext)

    const { data: inventoryData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/investments/${investmentDrawer}/inventory`,
        {},
        [],
    );

    const inventoryColumns = [
        {
          title: 'Barcode',
          dataIndex: 'barCode',
          key: 'barCode',
          align: 'center',
          render: (_: any, record: any) => {
            return <>
              <a onClick={() => { setArticleDrawer(record.id) }}>#{record.barCode}</a>
            </>
          }
        },
        {
          title: 'Coste',
          key: 'coste',
          dataIndex: 'coste',
          width: '20%',
          align: 'center',
          render: (_: any, record: any) => {
            return <>{record.cost} €</>
          },
        },
        {
          title: 'Nombre',
          dataIndex: 'productName',
          key: 'productName',
          width: '30%',
          align: 'center',
          render: (_: any, record: any) => {
            return <>
              <div>{record.productType.name}</div>
              <a onClick={() => { setProductDrawer(record.productType.sku) }}><small>{record.productType.sku}</small></a>
            </>
    
          },
        },
        {
          title: 'Propietario',
          key: 'ownerDescription',
          dataIndex: 'ownerDescription',
          align: 'center',
          width: '20%',
    
          render: (_: any, record: any) => {
            return <>{record.store}</>
          },
        },
        {
          title: '',
          key: 'onSale',
          align: 'center',
          render: (_: any, record: any) => {
            return <>{record.onSale && <Tag color='lime'>En venta</Tag>}</>
          },
        }
      ]
    

    return (

        <TableSG dataSource={inventoryData} columns={inventoryColumns} rowKey={'id'} loading={isLoading} />

    )
}