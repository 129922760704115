import { Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const QuotationAddModal = (props: {
	visible: boolean,
	onFinish: any
}) => {

	const [form] = Form.useForm();

	const onFormFinish = async (values: any) => {
		const data = {
			...values,
		};


		await authFetch(`${process.env.REACT_APP_API}/quotations`, { method: 'POST', data: data })
		props.onFinish();
	};

	const { data: combos } = useFetch(`${process.env.REACT_APP_API}/combos`, {});


	return (
		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>
			<h2>Nueva Cotización<nav></nav></h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label='Metal' name='metal' rules={[{ required: true }]}>
					<Select defaultValue="" >
						{
							combos.metals && combos.metals.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>
							)}
					</Select>
				</Form.Item>
				<Form.Item label='Marca' name='brand'>
					<Input />
				</Form.Item>
				<Row gutter={12}>
					<Col span={6} >
						<Form.Item label='Peso' name='weight' rules={[{ required: true }]}>
							<InputNumber min={0} step={0.1} stringMode />
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label='Unidad' name='units' rules={[{ required: true }]}>
							<Select>
								{
									combos.units && combos.units.map((element: any) =>
										<Select.Option value={element.key}>{element.description}</Select.Option>
									)}
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Col><Form.Item label='Valor de penalización' name='overCostPrice' rules={[{ required: true }]}>
					<InputNumber addonAfter='%' />
				</Form.Item>
				</Col>
			</Form>
		</Modal>
	)
}