//import { Table } from 'antd';
import { Children } from 'react';
import { TableSGProps } from '../../../../interfaces/tableSGInterface';
import './tableSG.sass';
import { Table } from "ant-table-extensions";



const TableSG = ({onRow, columns, title, rowClassName, exportable = false, rowSelection, size, style= {paddingTop:'20px'}, showHeader, dataSource, rowKey, loading, locale, onChange, expandable, pagination = {position:['bottomLeft']}}: TableSGProps) => {
  
  return (
    <>
    
      <Table className='tableSG'
        onRow={onRow}
        rowSelection={rowSelection}
        rowClassName = {rowClassName}
        columns={columns}
        dataSource={dataSource}
        showHeader={showHeader}
        rowKey={rowKey}
        loading={loading}
        pagination={pagination}
        locale={locale}
        onChange={onChange}
        expandable={expandable}
        style={style}
        size={size}
        title={title}
        //exportableProps={{ showColumnPicker: true }}
        exportable = {exportable}
      >
          {Children}
        </Table>
    </>
  )
}


export default TableSG