import { Alert, Card, Col, message, Row, Input, Tag } from "antd";
import { useState, useEffect } from 'react'
import { TSummaryAlerts } from "../../../../types/summaryAlerts";
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authRequest } from "../../../../common/request/authRequest";
import { FilterDropdownProps } from "../../../../types/filterDropDown";
import { SearchOutlined } from '@ant-design/icons';
import TableSG from "../../../components/design/Table/TableSG";
import { Column } from '@ant-design/plots';
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { SpinSG } from "../../../components/model/Spin/SpinSG";

export const AccountingSummary = () => {

    const [transactionByMonthData, setTransactionByMonthData] = useState([]);
    const [transactionsLastWeek, setTransactionsLastWeek] = useState([]);
    const [alerts, setAlerts] = useState<TSummaryAlerts[]>();
    const [total, setTotal] = useState<any[]>();
    const [loading, setLoading] = useState(true);


    const { logOut } = useAuth();

    useEffect(() => {

        let isMounted = true;

        authRequest(`${process.env.REACT_APP_API}/summary/accounting`, {})
            .then((response) => {
                setLoading(false);

                setTransactionByMonthData(response.monthly)
                setTransactionsLastWeek(response.weekly);
                setTotal(response.accountingTransactionsSummaries);
                setAlerts(response.accountingAlerts)
            })
            .catch((error) => {
                setLoading(false);

                if (error && error.status === 401) {
                    message.info('La sesión ha caducado');
                    logOut()
                } else {
                    throw error.response;
                }
            });

            return () => {
                isMounted = false;
              };
    }, [])

    const columns = [
        {
            title: '',
            key: 'ownerName',
            dataIndex: 'ownerName',
            render: (ownerName: string) => (
                <span style={{ fontWeight: 500 }}> {ownerName}</span>
            )
        },
        {
            title: 'Entradas',
            key: '',
            dataIndex: '',
            children: [
                {
                    title: "",
                    key: 'inflowOfUnits',
                    dataIndex: 'inflowOfUnits',
                    align: 'center',
                    width: 150,
                    render: (_: number, record: any) => {
                        return <span>{record.inflowOfUnits} uds</span>
                    }
                }, {
                    title: "",
                    key: 'inflowOfMoney',
                    dataIndex: 'inflowOfMoney',
                    align: 'center',
                    width: 150,
                    render: (_: number, record: any) => {
                        return <PriceTag price={record.inflowOfMoney} />
                    }
                }
            ]

        },
        {
            title: 'Salidas',
            key: '',
            dataIndex: '',
            children: [
                {
                    title: "",
                    key: 'outflowOfUnits',
                    dataIndex: 'outflowOfUnits',
                    align: 'center',
                    width: 150,
                    render: (_: number, record: any) => {
                        return <span>{record.outflowOfUnits} uds</span>
                    }
                }, {
                    title: "",
                    key: 'outflowOfMoney',
                    dataIndex: 'outflowOfMoney',
                    align: 'center',
                    width: 150,
                    render: (_: number, record: any) => {
                        return <PriceTag price={record.outflowOfMoney} />
                    }
                }
            ]

        },
        {
            title: 'Beneficio',
            key: 'revenue',
            dataIndex: 'revenue',
            render: (revenue: number) => (
                <PriceTag price = { revenue } />
            )
        }

    ]
    const config = {

        isGroup: true,
        xField: 'x',
        yField: 'value',
        seriesField: 'category',
        height: 250,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
            }
        }
    };

return (
    <div>
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
            <div className="table-header">
                <span className='title-summary'>Panel de Contabilidad</span>
            </div>
        </Card>
        {loading ? <SpinSG /> : 

        <><Row gutter={[8, 8]}>
                <Col span={16}>
                    <Card style={{ border: 'none' }}>
                        <div className='title-chart'>TOTALES</div>
                        <div className='title-chart-secondary'>Totales últimos 30 días</div>
                        <TableSG pagination={false} size='small' columns={columns} dataSource={total} loading={false} />
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ border: 'none' }}>
                        <div className='title-chart'>ALERTAS</div>
                        <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                            { alerts && alerts.length > 0 ? 
                                alerts.map((alert: any) => {
                                    return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                })
                                :
                                <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                            }
                        </div>
                    </Card>
                </Col>

            </Row>
            <Row gutter={[8, 8]}>
                <Col span={16}>
                    <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                        <div className='title-chart'>HISTÓRICO</div>
                        <div className='title-chart-secondary'>Costes por entradas y salidas por mes </div>
                        {transactionByMonthData && transactionByMonthData.map((transaction: any) => {
                            return <><Tag color="geekblue">{transaction.ownerName}</Tag><Column data={transaction.monthlyChartPointsTransaction} {...config} /></>;
                        }
                        )}
                    </Card>
                </Col>
                <Col span={8}>
                    <Card style={{ border: 'none' }} bodyStyle={{ margin: "10px 5px" }}>
                        <div className='title-chart'>ÚLTIMA SEMANA</div>
                        {transactionsLastWeek && transactionsLastWeek.map(comment => {
                            return (<div>{comment}</div>);
                        })}
                    </Card>

                </Col>
            </Row></>
    }
    </div>
)
}