import { Form, InputNumber, Modal, Select } from 'antd';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';


export const AssignConsultantModal = ({
    order,
    onFinish,
}: any) => {

    const [form] = Form.useForm();

    const { data } = useFetch(`${process.env.REACT_APP_API}/users/commercial`, {}, []);

    const onFormFinish = async (values: any) => {

        const articleUpdateRequest = {
            orderId: order.id,
            userId: values.userId,
            webName: order.webName,
            commission:(values.commission / 100) || 0.02
        }

        const updateArticle = await customRequest(`${process.env.REACT_APP_API}/wordPressOrders/consultant`, { method: 'POST', data: articleUpdateRequest })
        if (updateArticle.statusCode === STATUS_CODE.OK) {
            onFinish();
        }
    };


    return (

        <Modal
            open={order}
            onOk={() => form.submit()}
            onCancel={onFinish}
            afterClose={form.resetFields}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={onFinish}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Asignar
                    </ButtonSG>
                </div>
            ]}>

            <h2>Asignar Consultor</h2>

            <Form layout='vertical' form={form} onFinish={onFormFinish} >
                <Form.Item label='Consultor' name='userId' rules={[{ required: true }]}>
                    <Select defaultValue="" showSearch optionFilterProp="children">
                        {
                           data?.map((element: any) =>
                                <Select.Option value={element.email
                                }>{element.name} - {element.email}</Select.Option>
                            )}
                    </Select>
                </Form.Item>
                <Form.Item label='Comisión' name='commission'>
                    <InputNumber addonAfter='%' defaultValue={2}/>
                </Form.Item>
            </Form>
        </Modal>
    )
}
