import { Drawer } from "antd";
import './drawerSG.sass';
import { DrawerSGProps } from "../../../../interfaces/drawerSGInterface";


const DrawerSG = ({title, visible, onClose, placement, width = 650, height, extra, open, closable, closeIcon, children}: DrawerSGProps) => {
    return (
        <Drawer className="drawerSG"
            title={title}
            open={visible}
            onClose={onClose}
            placement={placement}
            width={width}
            height={height}
            extra={extra}
            closable={closable}
            closeIcon={closeIcon}
        >
            {children}
        </Drawer>
    )
}

export default DrawerSG