// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";


import { Image, Spin } from 'antd';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./swiper.css";

export default function SwiperCarousel({ mediaItems }: any) {

  return (
    <>

      <div style={{ height: '350px', position: 'relative' }}>
        <Swiper
          navigation={true}
          modules={[Navigation]}
        >
          {
            mediaItems?.map((mediaItem: any, index: any) => {
              return (
                <SwiperSlide >
                  <Image
                    key={index}
                    src={mediaItem}
                    className="swiper-slide-img"
                  />
                </SwiperSlide>
              )
            })
          }
        </Swiper>

      </div>
    </>
  );
}
