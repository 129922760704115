import { useContext } from "react";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import TableSG from "../../../../components/design/Table/TableSG";
import { Tag } from "antd";
import { ForwardOutlined } from "@ant-design/icons";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { defaultFormat } from "../../../../../common/utils/formatDate";
import { PriceTag } from "../../../../components/design/PriceTag/PriceTag";

export const TransactionTab = () => {

    const { investmentDrawer, setProductDrawer, setContractDrawer } = useContext(DrawerContext)


    const { data: transactionData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/investments/${investmentDrawer}/transactions`,
        {},
        [],
    )



    const columns = [
        {
            title: 'Artículo',
            render: (_: any, record: any) => (
                <>
                    <div>
                        <p>{record.articleBarCode}</p>
                        <small><a><EntityLabel prefix='Artículo' id={record.articleId} color={false} /></a></small>
                    </div>

                </>
            ),
        },
        {
            title: 'Propietario',
            dataIndex: 'originOwner',
            key: 'originOwner',
            render: (_: any, record: any) => {
                return <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>

                    {record.sellerContractId ? (
                        <div>
                            {/* <p >{record.sellerOwnerDescription}</p> */}
                            <a onClick={() => setContractDrawer(record.sellerContractId)}>
                                <EntityLabel prefix='Contrato' id={record.sellerContractId} />
                            </a>
                        </div>
                    ) :
                        <p >{record.sellerOwnerDescription}</p>
                    }
                    <ForwardOutlined style={{ margin: '0 10px' }} />
                    {record.buyerContractId ? (
                        <div>
                            {/* <p >{record.buyerOwnerDescription}</p> */}
                            <a onClick={() => setContractDrawer(record.buyerContractId)}>
                                <EntityLabel prefix='Contrato' id={record.buyerContractId} />
                            </a>

                        </div>
                    ) :
                        <p >{record.buyerOwnerDescription}</p>
                    }
                </div>
            },
        },
        {
            title: 'Coste',
            dataIndex: 'cost',
            key: 'cost',
            render: (cost: number) => (
                <PriceTag price={cost} />

            ),

        },
        {
            title: '',
            key: 'isPurchase',
            align: 'center',
            render: (_: any, record: any) => {
                return <>{record.isPurchase ? <Tag color='green'>Compra</Tag> : <Tag color='volcano'>Venta</Tag>}
                    <small>{defaultFormat(record.date)}</small>
                </>

            },
        }

    ];


    return (
        <TableSG dataSource={transactionData} columns={columns} rowKey={'id'} loading={isLoading} />
    )
}