import { InfoCircleOutlined, LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Form, Input, message, Modal } from 'antd';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import { authFetch } from '../../../common/request/authFetch';
import ButtonSG from '../../components/design/Buttons/ButtonSG';
import './Login.sass'

interface IServerError {
	statusCode?: string,
	message?: string,
	success?: boolean
}

const Login = (): JSX.Element => {
	const [formAuthError, setFormAuthError] = useState<boolean>(false)
	const [serverError, setServerError] = useState<IServerError>({})
	const [user, setUser] = useState<string>('')
	const location = useLocation()
	const history = useHistory()
	const { logIn } = useAuth()


	const onSubmit = (data: any): Promise<void> => {
		const state: any = location.state
		return logIn(data).then((res: any) => {
			if (res.success) {
				setServerError({})
				setFormAuthError(false);


				// state?.from?.pathname ?? <-- esto da error la primera vez que se inicia sesion
				return history.push(state?.from?.pathname ?? '/admin/inicio');
			}
			if (res.statusCode !== 401) {
				return setServerError({
					statusCode: res.statusCode,
					message: res.message
				})
			}
			return setFormAuthError(true)
		})
	};

	const sendRecoverEmail = async (): Promise<any> => {
		return await authFetch(`${process.env.REACT_APP_API}/users/${user}/recover`, { method: 'POST' })
			.then((isOk) => {
				if (isOk) {
					return message.success(`Correo enviando a ${user}, revise su bandeja de entrada`);
				} else {
					return message.error(`Problema enviando correo a ${user}, compruebe que el correo es correcto`);
				}
			}
			);
	}

	return (
		<div className='login-general'>
			<div className='login-general-left'>
				<div className='text-position'>
					<h1>SilverGold</h1>
					<p>
						SilverGold Patrimonio
					</p>
				</div>
			</div>
			<Card bordered={true} className="login-general-right">
				<h2>Iniciar Sesión</h2>
				<Form className='login-form'
					layout='vertical'
					onFinish={onSubmit}
					autoComplete='off'
				>
					<Form.Item
						label='Usuario'
						name='username'
						validateTrigger='onFinish'
						rules={[{
							required: true,
							type: 'email',
							message: 'El campo usuario debe ser un correo válido'
						}]}
					>
						<Input
							placeholder='Introduce el correo electrónico'
							prefix={<UserOutlined />}
							onChange={(e) => setUser(e.target.value)}
						/>
					</Form.Item>
					<Form.Item
						label='Contraseña'
						name='password'						
						rules={[{
							required: true,
							message: 'La contraseña es un campo obligatorio'
						}]}
					>
						<Input.Password
							placeholder='Introduce la contraseña'
							prefix={<LockOutlined />}
						/>
					</Form.Item>
					<ButtonSG color='black' htmlType="submit">
						Iniciar Sesión
					</ButtonSG>
					
				</Form>
				{
					formAuthError && <Alert
						style={{ marginTop: '12px', borderRadius:'10px' }}
						type='error'
						message='Usuario o contraseña incorrectos'
						showIcon
					/>
				}
				{
					serverError.statusCode && <Alert
						style={{ marginTop: '12px' }}
						type='error'
						message={`Status code: ${serverError.statusCode}`}
					/>
				}
				{
					user ?
						<button
							className='missing-password'
							onClick={() => {
								Modal.confirm({
									title: <p>¿Enviar correo de recuperación a <b>{user}</b>?</p>,
									content: <p>En este correo que enviaremos tendrás un link desde el que podrás después restablecer tu nueva contraseña</p>,
									okText: 'Enviar',
									cancelText: 'Cancelar',
									icon: <MailOutlined />,
									onOk: () => sendRecoverEmail()
								})
							}}
						>
							¿Olvidaste la contraseña?
						</button>
						:
						<button
							className='missing-password'
							onClick={
								() => {
									Modal.info({
										title: 'Primero introduce un correo válido en el formulario',
										icon: <InfoCircleOutlined />
									})
								}
							}
						>
							¿Olvidaste la contraseña?
						</button>
				}
			</Card>
		</div>
	);
}

export default Login;