import { useState } from 'react'
import { Card, Table, Space, Modal, Switch } from "antd";
import { CloseOutlined, CheckOutlined, EditOutlined, DeleteOutlined}  from "@ant-design/icons";
import { WebAddModal } from './modal/WebAddModal';
import { ModalConfirmation } from '../../../components/shared/Modals/ModalConfirmation';
import { WebInterface } from '../../../../interfaces/WebInterface';
import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { authFetch } from '../../../../common/request/authFetch';
import { EditCredentials } from './modal/EditCredentials';
import './webShops.sass'
import AuthRoles from '../../../components/design/AuthRoles/AuthRoles';
import { ROLE_ADMIN, ROLE_ECOMMERCE} from '../../../../common/constants/roles';
import { useIsAuthorized } from '../../../../common/request/AuthorizationUtils';

export const WebShops = () => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [credentialsSelected, setCredentialsSelected] = useState(null);
  const [openEditModalCredentials, setOpenEditModalCredentials] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { data: websData, isLoading } = useFetch(`${process.env.REACT_APP_API}/webs/`, {}, [updateData]);

  const IsAuthorized = useIsAuthorized();

  const deleteProduct = async (webNameId: string) => {
    await authFetch(`${process.env.REACT_APP_API}/webs/`, { method: 'DELETE',data: { webNameId: webNameId } });
    setUpdateData(!updateData);
  };

  const productsSyncAutomated = async (e: boolean, record: WebInterface) => {
    const data = {
      name: record.name,
      mask:"PRODUCT_SYNC_AUTOMATED_MASK",
      productsSyncAutomated: e,
      pricingUploadAutomated: record.pricingUploadAutomated,
      description: record.description,
      apiUser: record.apiUser,
      apiKey: record.apiKey
    };
    await authFetch(`${process.env.REACT_APP_API}/webs/`, { method: 'PATCH', data });
  };

  const pricingUploadAutomated = async (e: boolean, record: WebInterface) => {
    const data = {
      name: record.name,
      mask:"PRICING_UPLOAD_AUTOMATED",
      productsSyncAutomated: record.productsSyncAutomated,
      pricingUploadAutomated: e,
      description: record.description,
      apiUser: record.apiUser,
      apiKey: record.apiKey
    };
    await authFetch(`${process.env.REACT_APP_API}/webs/`, { method: 'PATCH', data });
  }

  const showDeleteConfirm = (record: WebInterface) => {
    deleteProduct(record.name)
  };
  
  const { Column } = Table;
 
  return (
    <>
      <Card style={{border: 'none'}} bodyStyle={{padding: "0px 10px 10px 10px"}}>
        <div className="table-header">
        <span className='title-summary'>Webs de WordPress</span>
          
          <AuthRoles roles={[ROLE_ADMIN, ROLE_ECOMMERCE.WRITE]}>
            <ButtonSG onClick={() => setIsModalVisible(true)}>
              Nueva web
            </ButtonSG>
          </AuthRoles>
        </div>
      </Card>   
      
      <Table className='tableSG' rowKey='name' dataSource={websData} loading={isLoading} pagination = {{position:['bottomLeft']}} style={{paddingTop:'20px'}}>
        <Column title="Dominio" dataIndex="name" key="name" />
        <Column title="Descripción" dataIndex="description" key="description" />
        <Column 
          title="Sincronizar" 
          dataIndex="productsSyncAutomated" 
          key="productsSyncAutomated"
          render={(_, record: any) => (
            <Switch
              disabled = {!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE])}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={record.productsSyncAutomated} 
              onChange={(e) => productsSyncAutomated(e, record)}
            />
          )} 
          
        />
        
         <Column 
          title="Subir precios" 
          dataIndex="pricingUploadAutomated" 
          key="pricingUploadAutomated"
          render={(_, record: any) => (
            <Switch
              disabled = {!IsAuthorized([ROLE_ADMIN, ROLE_ECOMMERCE.WRITE])}
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={record.pricingUploadAutomated}
              onChange={(e) => pricingUploadAutomated(e, record)}
              />
              )} 
              />
        <Column 
          title="Credenciales API" 
          dataIndex="apiUser" 
          key="apiUser" 
          render={(_, record: any) => (
            
            <>
              <div style={{display: 'flex', width: '100%'}}>
                <div>
                  <p>{record.apiUser}</p>
                  <p className='hiddenText'>{record.apiKey}</p>
                </div>
                <div>
                <AuthRoles roles={[ROLE_ADMIN, ROLE_ECOMMERCE.WRITE]} >
                <span  onClick={() => {setOpenEditModalCredentials(true); setCredentialsSelected(record) }}  className='modify-icon' >
                 <EditOutlined/>
                </span>
                </AuthRoles>
                </div>
              </div>
            </>
          )}
        />
        <Column
          title="Acciones"
          key="action"
          render={(_, record: any) => (
            <Space size="middle">
              <AuthRoles roles={[ROLE_ADMIN, ROLE_ECOMMERCE.WRITE]} >
              <ButtonSG size='small' onClick={						
                () => {
                  Modal.confirm({
                  title: '¿Estás seguro de eliminar esta web?',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk: () => showDeleteConfirm(record)});
                }
              }>
              <DeleteOutlined/> 
            </ButtonSG>
          </AuthRoles>
          </Space>
            
          )}
        />
      </Table>

      {
        <WebAddModal 
          visible={isModalVisible}
          onFinish={() => {
            setIsModalVisible(false);
            setUpdateData(!updateData)

            }
          }
        />
      }
      {
        <ModalConfirmation 
          visible={isDelete}
          title="¿Desea eliminar esta Tienda?"
          onFinish={() => {
            setIsDelete(false)
            }
          }
        />
      }
      {
        openEditModalCredentials && (
          <EditCredentials 
            visible={openEditModalCredentials} 
            credentialsSelected={credentialsSelected}
            onClose={() => {setOpenEditModalCredentials(false); setUpdateData(!updateData)}}
          />
        )
      }
    </>
  )
}
