import { Alert, Badge, Card, Modal, Radio, RadioChangeEvent, Tooltip } from "antd";
import TableSG from "../../../components/design/Table/TableSG";
import { useContext, useState } from "react";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { BarChartOutlined, CaretDownOutlined, CaretUpOutlined, CheckOutlined, ClockCircleOutlined, EuroCircleOutlined, GoldOutlined, LoadingOutlined } from "@ant-design/icons";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { formatPrice } from "../../../../common/utils/formatPrice";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import moment from "moment";
import { formatPercentage } from "../../../../common/utils/FormatUtils";
import { INVESTMENT_TYPE } from "../../../../common/constants/investmentType";
import { BalanceChart } from "../totalFreedom/modal/BalanceChart";
import { ConfirmOperation } from "../totalFreedom/modal/ConfirmOperation";

export const PurchaseForPayment = () => {

    const [seeActiveContracts, setContractState] = useState(true);
    const [updateData, setUpdateData] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { setContractDrawer } = useContext(DrawerContext)
    const [selectedRecord, setSelectedRecord] = useState<any>(null);
    const [operationModalData, setOperationModalData] = useState<any>(null);

    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/contracts/type/INVESTMENT_PER_PAYMENT?active=${seeActiveContracts}`, {}, [updateData]);


    const columns = [

        {
            title: 'Contrato',
            dataIndex: 'automated',
            key: 'automated',
            width: '16%',
            //align: 'center',
            render: (_: any, record: any) => (
                <Badge.Ribbon text={record.automated ? "Automático" : "Manual"} color={record.automated ? "var(--primary)" : "var(--accent-variant)"}  >
                    <Card style={{ padding: '5px 0px 0px 0px', backgroundColor: 'white', borderRadius: '10px' }}>
                        <a onClick={() => setContractDrawer(record.id)}>
                            <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.id} />
                        </a>
                        <small>{record.clientName}</small>
                    </Card>
                </Badge.Ribbon>
            )

        },
        {
            title: 'Inversión',
            dataIndex: 'revenue',
            align: 'center',
            render: (_: any, record: any) => (

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <PriceTag price={record.revenue} />
                </div>
            ),
            sorter: (a: any, b: any) => a.revenue - b.revenue,
        },
        {
            title: 'Saldo',
            dataIndex: 'cashAccount',
            render: (_: any, record: any) => (
                <>
                    <div><EuroCircleOutlined /> {formatPrice(record.cashAccount)}€</div>
                    <div>
                        <Tooltip title={`${record.units} lingotes`}>
                            <GoldOutlined /> {formatPrice(record.unitsCostAccount)}€
                        </Tooltip>
                    </div>
                </>
            ),
        },
        {
            title: 'Saldo Total',
            dataIndex: 'benefit',
            align: 'center',
            render: (_: any, record: any) => (
                <b>
                    <div>{formatPrice(record.cashAccount + record.unitsCostAccount)}€</div>
                    {record.benefit >= 0 ?
                        <small style={{ color: 'green', marginRight: '8px' }}><CaretUpOutlined />{record.benefit}%</small>
                        :
                        <small style={{ color: 'red', marginRight: '8px' }}><CaretDownOutlined />{record.benefit}%</small>
                    }
                </b>
            ),

        },
        {
            title: 'Incremento Metal',
            align: 'center',
            width: '15%',
            render: (_: any, record: any) => {

                return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {record?.operation &&
                        Object.entries(record.operation).map(([key, value]: any) => (
                            <>
                                {"PENDING_OPERATION" !== value.status &&
                                    value && "NO_MARKET_DATA" !== value.status && (value >= 0 ?
                                        <small style={{ color: 'green', marginRight: '8px' }} key={key}>
                                            <CaretUpOutlined /> {`${value.metalName} ${value.increment.toFixed(2)}%`}
                                        </small>
                                        :
                                        <small style={{ color: 'red', marginRight: '8px' }} key={key}>
                                            {`${value.metalName} ${value.increment.toFixed(2)}%`}<CaretDownOutlined />
                                        </small>
                                    )}
                                {"NO_MARKET_DATA" === value.status && <Alert showIcon type="warning" message={<span >Sin info</span>} style={{ padding: '1px 15px', borderRadius: '8px', width: 'fit-content', margin: 'auto' }}></Alert>}
                            </>
                        ))}
                </div>
            },
        },
        {
            title: 'Sugerencia',
            align: 'center',
            render: (_: any, record: any) => {

                return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {record.operation &&
                        Object.entries(record.operation).map(([key, value]: any) => (
                            <>
                                {value.readyToOperate ?
                                    "NO_MARKET_DATA" !== value.status && (
                                        <>
                                            {value.status.includes("NO_OPERATION") ? (
                                                <Tooltip title={<span>{value?.alerts?.map((a:any)=>{return a.description})}</span>}>
                                                    <div>
                                                    <ButtonSG color="dark-white" size="small" disabled={true} >
                                                        <span>No accionar {value.metalName}</span>
                                                    </ButtonSG>
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    < div style={{ display: 'flex', gap: '5px' }} >
                                                        <ButtonSG size="small" disabled={record.automated || !value.readyToOperate}
                                                            onClick={() => setOperationModalData({ contract: record, metalInfo: value })}
                                                            style={{ marginTop: '4px' }}>
                                                            <ClockCircleOutlined />
                                                            {"BUY" === value.status && <>Comprar {formatPrice(value.buyRevenue)}€ de {value.metalName}</>}
                                                            {"SELL" === value.status && <>Vender {value.sellUnits} uds. de {value.metalName}</>}
                                                        </ButtonSG>
                                                    </div>

                                                    {value.lastOperation && <small style={{ color: 'grey' }}><i>{moment(value.lastOperation).format('ll')}</i></small>}</>
                                            )}
                                        </>
                                    )
                                    :
                                    <>
                                        <ButtonSG color="dark-white" size="small" disabled={true} style={{ marginTop: '14px' }}>
                                            {"PENDING_OPERATION" === value.status ?
                                                <p><LoadingOutlined spin /> {value?.metalName}: {INVESTMENT_TYPE[value.pendingOperation]?.name} pendiente</p>
                                                :
                                                <><CheckOutlined /> {value.metalName}</>
                                            }
                                        </ButtonSG>
                                        {value.lastOperation && <small style={{ color: 'grey' }}><i>{moment(value.lastOperation).format('ll')}</i></small>}
                                    </>
                                }
                            </>
                        ))}
                </div >
            },
        },
        {
            title: '',
            align: 'center',
            render: (_: any, record: any) => {

                return <a onClick={() => {
                    setSelectedRecord(record)
                }} style={{ color: 'var(--accent-variant)' }}><BarChartOutlined className='menu2-icon' /></a>
            },
        }
    ]

    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>CCU - CCP</span>                
            </div>
            <br/>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Radio.Group defaultValue={true} buttonStyle="outline" onChange={(e: RadioChangeEvent) => setContractState(e.target.value)}>
                    <Radio.Button value={true}>Activos</Radio.Button>
                    <Radio.Button value={false}>Liquidados</Radio.Button>
                </Radio.Group>
            </div>

            {data && <TableSG columns={columns} dataSource={data} loading={isLoading} rowKey={'id'} />}
            {
                selectedRecord &&

                <Modal
                    open={selectedRecord}
                    onCancel={() => { setSelectedRecord(null) }}
                    footer={null}
                    width='90%'
                    style={{ position: 'absolute', top: 10, left: 5, right: 5 }}
                >
                    <BalanceChart id={selectedRecord.id} name={selectedRecord.clientName} onFinish={() => { setSelectedRecord(null) }} />
                </Modal>
            }
            {
                operationModalData && <ConfirmOperation
                    onCancel={() => { setOperationModalData(null) }}
                    suggestion={operationModalData}
                    onFinish={() => { setOperationModalData(null); setUpdateData(!updateData) }} />
            }
        </Card>
    )
}