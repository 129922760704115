import { CarOutlined, SearchOutlined, ShoppingCartOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Input, Switch, Tag, Tooltip} from "antd";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ROLE_ADMIN, ROLE_PURCHASES } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import TableSG from "../../../components/design/Table/TableSG";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { STATUS } from "../../../../common/constants/purchasesStatus";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { customRequest } from "../../../../common/request/customRequest";
import { EntityLabel, formattedId } from "../../../components/design/EntityLabel/EntityLabel";
import { DocumentsModal } from "../../common/UploadDocuments/DocumentsModal";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { DOCUMENTS } from "../../../../common/constants/folderName";



const allStatusFilters = 'status=PENDING,IN_PROGRESS,RECEIVED'

export const Orders = () => {

  const [updateData, setUpdateData] = useState(false);
  const [statusFilter, setStatusFilter] = useState(allStatusFilters);

  const [documentsModal, setDocumentsModal] = useState(null);


  const { setOrderDrawer } = useContext(DrawerContext)

  const purchaseStatus = async (record: any, selectedState: string) => {
    await customRequest(`${process.env.REACT_APP_API}/orders/${record.id}?status=${selectedState}`, { method: 'PATCH' }, 'Estado actualizado').then(()=> setUpdateData(true))  
    
  }
  

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/orders?${statusFilter}`, {}, [updateData]);
  
  useEffect(() => {
    if (storesData.length > 0) {
      setUpdateData(false);
    }
  }, [storesData]);

const columns = [
    {
      title: 'Identificador',
      key: 'id',
      dataIndex: 'id',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Orden"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const searchText = value.toLowerCase();
        const lastFiveDigits = formattedId(record.id).toLowerCase();
        return (
          lastFiveDigits.includes(searchText) ||
          (`Orden #${lastFiveDigits}`.toLowerCase().includes(searchText))
        );
      },
      render: (id: string) => {
        return id &&
          <div>
            <a onClick={() => setOrderDrawer(id)}>
            <EntityLabel prefix='Orden' id={id}/>
            </a>            
          </div>
      },
    },
    {
      title: 'Coste',
      key: 'cost',
      align: 'center',
      dataIndex: 'cost',
      render: (cost: number) => (
        cost > 0 && <PriceTag price={cost}/> 
      ),
      sorter: (a: any, b: any) => b.cost - a.cost
    },
    {
      title: 'Proveedor',
      key: 'providerName',
      dataIndex: 'providerName',
      sorter: (a: any, b: any) => {
        return a.providerName.localeCompare(b.providerName)
      } 
    },
    {
      title: 'Documentos',
      align: 'center',
      width:'15%',
      render: (record: any) => {
        return (
          <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
              <div  style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            {record.documents !== null ? record.documents.map((d: any) => (
              <div key={d.name}><small style={{display:'flex', alignItems:'center', gap:'3px'}}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
            )): <small>Sin documentos</small>}</div>
            <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
            <PlusOutlined />
            </ButtonSG>
          </div>
        );
      },
      sorter: (a:any, b:any) => {
        if (a.documents !== null && b.documents === null) {
          return -1;
        }
        if (a.documents === null && b.documents !== null) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Estado',
      key: 'statusDescription',
      dataIndex: 'statusDescription',
      render: (statusDescription: string) => (
       <Tag>{statusDescription}</Tag>
      ),
      sorter: (a: any, b: any) => {
        return a.statusDescription.localeCompare(b.statusDescription)
      } 
    },
    {
      title: 'Fecha',
      key: 'creationDate',
      dataIndex: 'creationDate',
      render: (creationDate:string) => {
        console.log(creationDate)
        return (
          <p>{defaultFormat(creationDate)}</p>
        )
      },
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.creationDate).getTime();
        const dateB = new Date(b.creationDate).getTime();
        return dateB - dateA;
      }
    },
    {
      title: '',
      key: 'status',
      dataIndex: 'status',
      render: (text: string, record:any) => {
        
        return (
          <AuthRoles roles={[ROLE_ADMIN, ROLE_PURCHASES.WRITE]}>
          <Tooltip title="Pendiente de tramitar">
        <ShoppingCartOutlined style={{ fontSize: '20px',
        color: record.status === STATUS.PENDING  ? 'orange' : 'lightgrey' }} 
        onClick={()=>{purchaseStatus(record, STATUS.PENDING)}}
        /></Tooltip>
        </AuthRoles> 
        )
      }
    },
    {
      title: '',
      key: 'status',
      dataIndex: 'status',
      render: (text: string, record: any) => (
        <AuthRoles roles={[ROLE_ADMIN, ROLE_PURCHASES.WRITE]}>
        <Tooltip title="Pendiente de Proveedor">
        <CarOutlined style={{ fontSize: '20px', 
        color: record.status === STATUS.IN_PROGRESS  ? 'orange' : 'lightgrey' 
      }}  onClick={()=>{purchaseStatus(record, STATUS.IN_PROGRESS)}}/>
      </Tooltip></AuthRoles>
      )
    },
    {
      title: '',
      key: 'status',
      render: (text: string,  record: any) => {
        return <AuthRoles roles={[ROLE_ADMIN, ROLE_PURCHASES.WRITE]}>
        <Tooltip title="Recibida">
      <span className="material-symbols-outlined" style={{  color: record.status === STATUS.RECEIVED  ? 'orange' : 'lightgrey', paddingTop:'3px', cursor:'pointer' }} onClick={()=>{purchaseStatus(record,STATUS.RECEIVED)}}>
        barcode_reader
      </span></Tooltip></AuthRoles>
    }
    },
    

  ]

  const [filteredColumns, setFilteredColumns] = useState<any>(columns);

  function dataStatusDone(isHistoricSelected: boolean) {
    if(isHistoricSelected){
      setStatusFilter('status=DONE')
      const filteredcolumns = columns.slice(0, columns.length - 3);
      setFilteredColumns(filteredcolumns)
    }else{
      setStatusFilter(allStatusFilters)
      setFilteredColumns(columns)
    }
  }

   
  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Órdenes de Compra</span>
      </div>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <p className='pl-20 desc'>Gestión de las compras a los proveedores</p>
        <div  style={{display:'flex', gap:'10px'}}>
          <p className='pl-20 desc'>Ver histórico</p>
          <Switch  loading={isLoading} onChange={(e) => {dataStatusDone(e)}}/>
        </div>
      </div>
      <TableSG columns={filteredColumns} dataSource={storesData} rowKey={'id'} loading={isLoading} />
      {
        documentsModal && <DocumentsModal entity='ORDERS' title='Órdenes de Compra' tabs={[{key:'1', label:'Facturas', type:'pdf', tabName:'INVOICES'}, {key:'2', label:'Albaranes', type:'pdf', tabName:'DELIVERYNOTE'}]} onFinish={() => {setDocumentsModal(null); setUpdateData(!updateData);}} entityId={documentsModal}/>   
      }
    </Card>

  )
};