import { Button, Card, Input } from 'antd';
import { useState } from 'react';

import { BarChartOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { defaultFormat } from '../../../../common/utils/formatDate';
import TableSG from '../../../components/design/Table/TableSG';
import ButtonSG from '../../../components/design/Buttons/ButtonSG';
import { UserActivityDrawer } from './drawer/UserActivityDrawer';
import { useIsAuthorized } from '../../../../common/request/AuthorizationUtils';
import { ROLE_DIRECTIVE, ROLE_TECHNOLOGY } from '../../../../common/constants/roles';


export const UserActivity = () => {

    const IsAuthorized = useIsAuthorized();

    const [updateData, setUpdateData] = useState(false);
    const [openChart, setOpenChart] = useState(null);

    const {data, isLoading} = useFetch(`${process.env.REACT_APP_API}/userActivity`, {}, [updateData]);


    const columns = [
        {
            title: 'Identificador',
            key: 'email',
            dataIndex: 'email',
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="email"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: any) => {
                return record.email.toLowerCase().includes(value.toLowerCase());

            }
        },
        {
            title: 'Interacciones',
            key: 'count',
            dataIndex: 'count',
           
        },
        {
            title: '',
            render: (text: string, record: any) => {
                return <>
                <ButtonSG 
                disabled={!IsAuthorized([ROLE_DIRECTIVE, ROLE_TECHNOLOGY.WRITE])}
                size='small' onClick={()=>{setOpenChart(record.email)}}><BarChartOutlined /></ButtonSG></>
            },
            
        },

    ]

    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
        <div className="table-header">
          <span className='title-summary'>Actividad</span>
        </div>
        <TableSG size='small' columns={columns} dataSource={data} rowKey={'id'} loading={isLoading}/>
        
        {openChart && <UserActivityDrawer openChart={openChart} setOpenChart={setOpenChart}/>}
      </Card>
    )
}
