import { Alert, Col, DatePicker, Divider, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Tag } from 'antd';
import { useState } from 'react';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import { Client } from '../../../../../interfaces/client';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { InputPrice } from '../../../../components/design/InputPrice/InputPrice';
import { authRequest } from '../../../../../common/request/authRequest';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const ContractsAddModal = (props : {
	onFinish : any,
	client : Client
	} ) => {
	
	const [contractType, setContractType] = useState<any>(null);

	const [form] = Form.useForm();
	
	const { data: contractTypes } = useFetch(`${process.env.REACT_APP_API}/combos/contracttypes`, {});

	const { data: walletTypes } = useFetch(`${process.env.REACT_APP_API}/combos/wallets`, {});

	const [errorMessage, setErrorMessage] = useState<any>(null);

	const onFormFinish = async (values: any) => {
		
		var typeDescription = contractTypes.find(({key}: { key: string }) => key === values.type).description;

		var walletDescription = walletTypes.find(({key}: { key: string }) => key === values.wallet).description;

		const data = {
			...values,		
			clientId: props.client.id,
			clientName: props.client.name,
			commercialId: props.client.commercialId,
			commercialName: props.client.commercialName,
			typeDescription: typeDescription,
			contractTypeId: values.type,
			walletDescription: walletDescription,
			duration: values.duration
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/clients/${props.client.id}/contracts`, { method: 'POST', data: data });
		if(response.statusCode ==  STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else{
			props.onFinish();
		}
	};

	const onChangeContratType = (contracttypeId: any) => {     

		authRequest(`${process.env.REACT_APP_API}/contracttypes/${contracttypeId}`, {})
		.then((response : any) => {			
			setContractType(response);		
		  })
		.catch(() => {
		  message.error('Error al cargar el nuevo tipo de contrato');
		});		
    }

  return (
		<Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
		footer={[
			<div style={{padding:'10px'}}>
				<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
				Cancelar 
				</ButtonSG>
				<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
				Guardar
				</ButtonSG>
			</div>
		  ]}
		>
			<h2>Nuevo Contrato</h2>
			
			<Divider orientation='right'><span style={{color:'grey'}}>{props.client.name}</span></Divider>
			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Tipo' name='type' rules={[{ required: true}]}>
							<Select onChange={(obj: any) => { onChangeContratType(obj) }}>											
							{
								contractTypes && contractTypes.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>																
							)}	
							</Select>
						</Form.Item>	
					</Col>											
					<Col span={12}>
						{contractType && contractType.paymentPlan === 'PARTIAL_MONTHLY' && 						
						<Form.Item label='Meses de pago' name='paymentMonths'>
							<InputNumber style={{width:'100%'}} controls={false}/>
						</Form.Item>	
						}
					</Col>
					</Row>	
				<Row gutter={16}>
					<Col span={12}>						
						<Form.Item label='Cartera' name='wallet' rules={[{ required: true}]}>
							<Select defaultValue="">
							{
								walletTypes && walletTypes.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>																
							)}	
							</Select>
						</Form.Item>	
					</Col>
					<Col span={12}>
						<InputPrice label={'Inversión'} nameInput={'revenue'} disabled={false}/>		
					</Col>
				</Row>		
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label='Fecha de Inicio' name='start'rules={[{ required: true}]}>
							<DatePicker format={"DD-MM-YYYY"} style={{width:'100%'}}/>
						</Form.Item>		
					</Col>

					<Col span={12}>
					{contractType && contractType.maxDurationInMonths &&
						<div>
						<Form.Item  rules={[{ required: true}]} label={<div>Duración en meses {contractType && <span>[{contractType.minDurationInMonths}, {contractType.maxDurationInMonths}]</span>}</div>} name='duration'>
							<InputNumber style={{width:'100%'}} controls={false}/>
						</Form.Item>		
						</div>
					}
					</Col>
				</Row>	
			</Form>
			{
				errorMessage && <Alert
				type='error'
				message={errorMessage}
				banner
				/>
			}
		</Modal>
	)
}