import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Card, Space } from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { QuotationAddModal } from "./modal/QuotationAddModal";
import { EditQuotation } from "./modal/EditQuotation";
import confirm from "antd/lib/modal/confirm";
import { CollapsedTable } from "../../../components/design/CollapsedTable/CollapsedTable";
import WeightSG from "../../../components/design/Weight/Weight";
import { ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../common/constants/roles";
import { authFetch } from "../../../../common/request/authFetch";

export const Quotation = () => {

    const [addQuotation, setAddQuotation] = useState(false);
    const [editQuotation, setEditQuotation] = useState(false);
    const [quotation, setQuotation] = useState(null);

    const [updateData, setUpdateData] = useState(false);

    const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/quotations`, {}, [updateData]);

    const deleteProduct = async (quotationId: string) => {
        await authFetch(`${process.env.REACT_APP_API}/quotations/${quotationId}`, { method: 'DELETE' });
        setUpdateData(!updateData);
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '¿Estás seguro de que quieres eliminar esta cotización?',
            icon: <ExclamationCircleOutlined />,
            content: record.name,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteProduct(record.id);
            },
            onCancel() {

            },
        });
    };

    const columns = [
        {
            title: 'Peso',
            dataIndex: 'weight',
            align: 'center',
            key: 'weight',
            width: '30%',
            sorter: (a: any, b: any) => a.weightInGrams - b.weightInGrams,
            render: (_: any, record: any) => {
                return <WeightSG weightInGrams={record.weightInGrams} weightInOunces={record.weightInOunces} units={record.units} />
            }
        },
        {
            title: 'Valor de mercado',
            dataIndex: 'overCostPrice',
            align: 'center',
            key: 'overCostPrice',
            sorter: (a: any, b: any) => a.overCostPrice - b.overCostPrice, 
            width: '30%',
            render: (_: any, record: any) => {
                return <>+{((100 * record.overCostPrice).toFixed(2))}%</>
            }
        },
        {
            title: 'Acciones',
            align: 'center',
            key: 'id',
            dataIndex: 'id',
            render: (_: any, record: any) => {
                return <>
                    <Space>
                        <ButtonSG size='small'><EditOutlined className="icon-action" onClick={() => {
                            setEditQuotation(true)
                            setQuotation(record)
                        }} 
                        style={{ fontSize: '15px' }} /></ButtonSG>
                        <ButtonSG size='small'><DeleteOutlined className="icon-action" onClick={() => {
                            showDeleteConfirm(record)
                            setQuotation(record)
                            }} style={{ fontSize: '15px' }} /></ButtonSG>
                    </Space>
                </>
            }
        },
    ];

    return (

        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Rectificación de Cotizaciones</span>
                <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>
                    <ButtonSG onClick={() => setAddQuotation(true)}>
                        Nueva Cotización
                    </ButtonSG>
                </AuthRoles>
            </div>
            <p className='pl-20 desc'>Se parametriza el impacto del peso en el valor del producto</p>
            <CollapsedTable isLoading={isLoading} columns={columns} data={storesData} rowKey='id'/>

            {addQuotation &&
                <QuotationAddModal
                    visible={addQuotation}
                    onFinish={() => {
                        setAddQuotation(false);
                        setUpdateData(!updateData);
                    }}
                />}
            {
                editQuotation &&
                <EditQuotation
                    visible={editQuotation}
                    onFinish={() => {
                        setEditQuotation(false);
                        setUpdateData(!updateData);
                    }}
                    quotationSelected={quotation}
                />
            }
        </Card>

    )
};