import { Card, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { isDOMComponent } from 'react-dom/test-utils';
import { customRequest } from '../../../../../common/request/customRequest';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { InputPrice } from '../../../../components/design/InputPrice/InputPrice';

export const PetitionsAddModal = (props: {
    visible: boolean,
    onFinish: any,
    petitionsByDepartment: any
}) => {

    const [form] = Form.useForm();

    const onFormFinish = async (values: any) => {

        const data = {
            ...values,
        };
        await customRequest(`${process.env.REACT_APP_API}/orders`, { method: 'POST', data: data })
        props.onFinish();
    };

    const { data: providers } = useFetch(`${process.env.REACT_APP_API}/combos/providers`, {});

    const [costPerUnit, setCostPerUnit] = useState({});

    const handleCostChange = (index: number, sku: string, value: any) => {
        const quantity = form.getFieldValue(['petitionsByDepartment', index, 'quantity']);
        setCostPerUnit({ ...costPerUnit, [sku]: (value * quantity) });
    };


    useEffect(() => {
        const sum = Object.values(costPerUnit).reduce((acc: any, curr: any) => acc + curr, 0);
        form.setFieldsValue({ cost: sum })
    }, [costPerUnit]);

    const skuObject: { [key: string]: any } = {};

    props.petitionsByDepartment.forEach((el: any) => {
        if (!skuObject[el.productTypeId]) {
            skuObject[el.productTypeId] = {
                productName: el.productName,
                sku: el.productTypeId,
                accumulatedQuantity: el.quantity,
                departments: {
                    [el.department]: {
                        department: el.department,
                        quantity: el.quantity,
                        ids: [el.id]
                    }
                }
            };
        } else {
            if (!skuObject[el.productTypeId].departments[el.department]) {
                skuObject[el.productTypeId].departments[el.department] = {
                    department: el.department,
                    quantity: el.quantity,
                    ids: [el.id]
                };
            } else {
                skuObject[el.productTypeId].departments[el.department].quantity += el.quantity;
                skuObject[el.productTypeId].departments[el.department].ids.push(el.id);
            }
            skuObject[el.productTypeId].accumulatedQuantity += el.quantity;
        }

    });


    return (
        <Modal open={props.visible} width='50%' onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Guardar
                    </ButtonSG>
                </div>
            ]}
        >
            <h2>Nueva Orden de Compra</h2>
            <Form layout='vertical' form={form} onFinish={onFormFinish}>
                <Card title='Lista de Peticiones' size='small' style={{ borderRadius: '10px', background: 'transparent', borderColor: 'lightgrey', marginBottom: '10px' }}>

                    {Object.values(skuObject).map((el: any, index: number) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>

                                <div>
                                    <p style={{ width: '250px' }}>
                                        {el.productName}
                                    </p>
                                    {el.departments && Object.values(el.departments).map((el: any) => {
                                        return (
                                            <>
                                                <div style={{ display: "block" }}>
                                                    <small>{el.department}: {el.quantity} unidades</small>
                                                </div>
                                                <Form.Item
                                                    name={['petitionsByDepartment', index, 'ids']}
                                                    initialValue={el.ids}
                                                    hidden
                                                >
                                                    <InputNumber />
                                                </Form.Item>
                                            </>
                                        )
                                    })}
                                </div>
                                <Row gutter={12}>
                                    <Col span={8}>
                                <Form.Item
                                    label='Cantidad'
                                    name={['petitionsByDepartment', index, 'quantity']}
                                    initialValue={el.accumulatedQuantity}
                                >
                                    <InputNumber disabled />
                                </Form.Item></Col>
                                <Col span={12}>
                                <Form.Item
                                    label='Coste/uds'
                                    name={['petitionsByDepartment', index, 'unitCost']}
                                    rules={[{ required: true }]}
                                    
                                >
                                    <InputNumber
                                        onChange={(value) => {
                                            handleCostChange(index, el.sku, value)

                                        }}
                                        addonAfter="€"
                                    />
                                    
                                </Form.Item>
                                </Col>
                                </Row>
                            </div>
                        );
                    })}
                </Card>

                <Form.Item label='Proveedor' name='provider' rules={[{ required: true }]}>
                    <Select >
                        {providers && providers.map((element: any) => {
                            return <Select.Option key={element.key} value={element.key}>
                                {element.description}
                            </Select.Option>
                        }
                        )}
                    </Select>
                </Form.Item>
                <Row gutter={12}>
                    <Col span={6}>
                        <InputPrice label={'Precio Total'} nameInput={'cost'} disabled={true} />
                    </Col>
                </Row>


            </Form>
        </Modal>
    )
}