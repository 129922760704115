import { Col, Form, Row, Tabs } from "antd";
import { useState, useContext } from "react";
import { EditOutlined } from "@ant-design/icons";
import DrawerSG from "../../../../components/design/Drawer/DrawerSG"
import '../header.sass'
import { EditPassword } from "./EditPassword";
import { customRequest } from "../../../../../common/request/customRequest";
import { InputEditable } from "../../../../components/design/InputEditable/InputEditable";
import { GlobalVariableContext } from "../../../../../store/GlobalVariableContext/GlobalVariableContext";

export const EditInfoProfile = (props: {
    visible: boolean;
    onClose: any;
    profile: any;
}) => {


    const [editPassword, setEditPassword] = useState(false);
    const [valueName, setValueName] = useState(props.profile.name);

    const { updateUserData, setUpdateUserData } = useContext(GlobalVariableContext)

    const onFormFinish = async (values: any) => {
        const body = {
            name: values.name,
        }

        await customRequest(`${process.env.REACT_APP_API}/users/${props.profile.email}/profile?masks=NAME`, { method: 'PATCH', data: body }, 'Cambios guardados')
        .then(() => setValueName(values.name), setUpdateUserData(!updateUserData))

    }

    return (
        <>
            <DrawerSG
                title="Gestión de Cuenta"
                width={640}
                placement="right"
                onClose={() => {
                    props.onClose();
                    setUpdateUserData(!updateUserData)
                }}
                visible={props.visible}
            >

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Perfil" key="1">
                        <Row gutter={[16, 16]} className="content-item-view">
                            <Col span={12}>
                            <InputEditable rules={[{ required: true, message: 'Este campo es obligatorio' }]} value={valueName} label='Nombre' nameInput='name' onFormFinish={onFormFinish} />
                            </Col>
                        </Row>
                        
                            <Row gutter={[16, 16]} className="content-item-view">
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Email</span>
                                        <div className="item-view">{props.profile.email}</div>
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div>
                                        <span className="label-item-view">Responsable</span>
                                        <div className="item-view">
                                            {props.profile.manager ? <div className="item-view">{props.profile.manager}</div> : <div className="item-view">No disponible</div>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} className="content-item-view">
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Departamento</span>
                                        {props.profile.departmentDescription ? <div className="item-view">{props.profile.departmentDescription}</div> : <div className="item-view">No disponible</div>}
                                    </div>
                                </Col>
                                <Col span={8}>
                                    <div>
                                        <span className="label-item-view">Cargo</span>
                                        <div className="item-view">
                                            {props.profile?.position ? <p className="item-view">{props.profile?.position}</p> : <p>No disponible</p>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]} className="content-item-view">
                                <Col span={12}>
                                    <div>
                                        <span className="label-item-view">Roles asignados</span>
                                        <div className="box">
                                            {props.profile.roles.map((role: any) => <p key={role.description} className="item-view">{role.description}</p>)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Credenciales" key="2">
                        <Row gutter={16} className="content-item-view">
                            <Col span={12}>
                                <div>
                                    <span className="label-item-view">Contraseña</span>
                                    <div className="item-view">******</div>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16} className="content-item-view">
                            <Col span={12}>
                                <a onClick={() => setEditPassword(true)}>Cambiar contraseña</a>
                            </Col>
                        </Row>
                    </Tabs.TabPane>

                </Tabs>
            </DrawerSG>
            {
                <EditPassword
                    userId={props.profile.email}
                    visible={editPassword}
                    onClose={() => setEditPassword(false)}
                />
            }
        </>
    )
}