import { useEffect, useState } from 'react';
import { Alert, Card, Col, message, Row, Statistic } from "antd";
import './UsersSummary.scss';
import { Column, Pie } from '@ant-design/plots';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../common/request/authRequest';
import { SpinSG } from '../../../components/model/Spin/SpinSG';
import { TSummaryAlerts } from '../../../../types/summaryAlerts';

export const UsersSummary = () => {
        
    const [usersByMonthData, setUsersByMonthData] = useState([]);    
    const [usersLastWeek, setUsersLastWeek] = useState([]);    
    const [allTime, setAllTime] = useState<any>();
    const [alerts, setAlerts] = useState<TSummaryAlerts[]>();
    const [loading, setLoading] = useState(true);

    const { logOut } = useAuth();
    
    useEffect(() => {
        authRequest(`${process.env.REACT_APP_API}/users/summary`, {})
          .then((response) => {
                setLoading(false);

                setUsersByMonthData(response.monthly.adminMonthlyCharPoints);
                setAllTime(response.allTime);
                setUsersLastWeek(response.lastWeek.notes);    
                setAlerts(response.alerts);                   
            })
          .catch((error) => {
            setLoading(false);

            if (error && error.status === 401) {
                message.info('La sesión ha caducado');
                logOut()
              } else {
                throw error.response;
              }
           
          });
      }, [])
     
      const config = {
        //appendPadding: 10,
        angleField: 'value',
        colorField: 'x',
        radius: 1,
        color: ['#1c61e0', 'black', '#58C09C', '#F6D17C', '#da8921'],
        label: {
          type: 'spider',
          labelHeight: 28,
          content: '{name}\n{percentage}',
        },
        interactions: [
          {
            type: 'element-selected',
          },
          {
            type: 'element-active',
          },
        ]
      };

      const configUsersByMonth : any = {
        xField: 'x',
        yField: 'value',
        height: 250,
        color: "#58C09C",
        maxColumnWidth: 30,
        isStack: true,
        label: {
          position: 'middle',
          style: {
            fill: '#fffbe6',
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          value: {
            alias: 'Usuarios',
          }          
        }
      };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
                <div className="table-header">
                    <span className='title-summary'>Panel de Usuarios</span>
                </div>
            </Card> 
            {loading ? <SpinSG /> : 

            <><Row gutter={[8, 8]}>
                    <Col span={8}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>                            
                        <div className='title-chart'>TOTALES</div>
                        <div style={{paddingLeft:'150px'}}>                            
                                {allTime &&
                                    <>
                                        <Statistic title="Usuarios" className='kpi' value={allTime.totals} />
                                        <Statistic title="Activos" className='kpi' value={allTime.actives} />
                                        <Statistic title="Inactivos" className='kpi' value={allTime.inactives} />
                                    </>}                           
                        </div>
                        </Card>                        
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "20px" }}>                                                                                            
                            <div style={{paddingTop:'30px'}} className='title-chart-secondary'>Usuarios activos por departamento</div>
                            {allTime &&
                            <div style={{width:'100%'}}>
                                <Pie
                                height={200}
                                {...config}
                                data={allTime.totalRegisteredByDepartment.totalUsersByDepartment} />
                            </div>
                        }
                        </Card>                        
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>ALERTAS</div>
                            <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                                { alerts && alerts.length > 0 ? 
                                    alerts.map((alert: any) => {
                                        return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                    })
                                    :
                                    <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                            <div className='title-chart'>HISTÓRICO</div>
                            <div className='title-chart-secondary'>Nuevos usuarios por mes </div>
                            {usersByMonthData && <Column data={usersByMonthData} {...configUsersByMonth} />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                            <div className='title-chart'>ÚLTIMA SEMANA</div>                            
                            {usersLastWeek && usersLastWeek.length > 0 ?
                                usersLastWeek.map(comment => {
                                    return (<div style={{ paddingLeft: '30px' }}>{comment}</div>);
                                })
                                :
                                <>Sin novedad</>}                            
                        </Card>
                    </Col>
                </Row>
            </>}
        </div>
    )
}