import { useState } from 'react'
import { Badge, Card, Switch, Tooltip } from "antd"
import { useFetch } from "../../../hooks/useFetch/useFetch"
import { ROLE_ADMIN, ROLE_APP_BACKOFFICE } from "../../../common/constants/roles"
import TableSG from "../../components/design/Table/TableSG"
import { customRequest } from "../../../common/request/customRequest"
import AuthRoles from "../../components/design/AuthRoles/AuthRoles"
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, StopOutlined, SyncOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons"
import { STATUS_REQUEST } from "../../../common/constants/statusRequest"

export const AppRequest = () => {

    const [update, setUpdate] = useState(false);


    const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/appRequest`, {}, [update]);


    const requestStatus = async (record: any, status: any) => {
        await customRequest(`${process.env.REACT_APP_API}/appRequest/${record.id}?status=${status}`, { method: 'PATCH' }).then(()=>{
            setUpdate(!update)
        })
    }

    const columns = [
        {
            title: 'Cliente',
            dataIndex: 'client',
            key: 'client',
        },
        {
            title: 'Producto',
            dataIndex: 'productName',
            key: 'productName',
            render: (_:any, render: any) => {
                return <div>
                    {render.titleOffers.length > 0 && <Tooltip title={render.titleOffers.join(' , ')}><Badge color='var(--primary)'/></Tooltip>}
                    {render.productName}</div>
            }
        },
        {
            title: 'Comentario',
            dataIndex: 'comment',
            key: 'comment',
        },
        {
            title: 'Inversión',
            dataIndex: 'investment',
            key: 'investment',
            render: (investment: any) => {
                return <p>{investment} €</p>
            }
        },
        {
            title: '',
            key: 'status',
            dataIndex: 'status',
            align:'center',
            render: (text: string, record: any) => {

                return (
                    <AuthRoles roles={[ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE]}>
                        <Tooltip title="Pendiente">
                            <ClockCircleOutlined style={{
                                fontSize: '20px',
                                color: record.status === STATUS_REQUEST.PENDING ? 'orange' : 'lightgrey'
                            }}
                                onClick={() => { requestStatus(record, STATUS_REQUEST.PENDING) }}
                            /></Tooltip>
                    </AuthRoles>
                )
            }
        },
        {
            title: '',
            key: 'status',
            dataIndex: 'status',
            align:'center',
            render: (text: string, record: any) => (
                <AuthRoles roles={[ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE]}>
                    <Tooltip title="En curso">
                        <UserOutlined style={{
                            fontSize: '20px',
                            color: record.status === STATUS_REQUEST.IN_PROGRESS ? 'orange' : 'lightgrey'
                        }} onClick={() => { requestStatus(record, STATUS_REQUEST.IN_PROGRESS) }} />
                    </Tooltip></AuthRoles>
            )
        },
        {
            title: '',
            key: 'status',
            align:'center',
            render: (text: string, record: any) => {
                return <AuthRoles roles={[ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE]}>
                    <Tooltip title="Cancelada">
                    <StopOutlined style={{
                            fontSize: '20px',
                            color: record.status === STATUS_REQUEST.CANCELED ? 'orange' : 'lightgrey'
                        }} onClick={() => { requestStatus(record, STATUS_REQUEST.CANCELED) }} />
                        
                        </Tooltip>
                    </AuthRoles>
            }
        },
        {
            title: '',
            key: 'status',
            align:'center',
            render: (text: string, record: any) => {
                return <AuthRoles roles={[ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE]}>
                    <Tooltip title="Completada">
                    <CheckCircleOutlined style={{
                            fontSize: '20px',
                            color: record.status === STATUS_REQUEST.COMPLETED ? 'orange' : 'lightgrey'
                        }} onClick={() => { requestStatus(record, STATUS_REQUEST.COMPLETED) }} />
                    </Tooltip></AuthRoles>
            }
        },
      
    ];

    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Solicitudes - APP
                </span>

            </div>

            <TableSG
                size='middle'
                columns={columns}
                dataSource={data}
                loading={isLoading}
            />


        </Card>

    )
}