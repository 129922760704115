import { useAuth } from "../../store/AuthContext/AuthContext";

export const IsAuthorized = (roles: string[]) : boolean => {
    const { profile } = useAuth();
    let isAuthorized = false;

    roles.forEach(role => {
        if (profile.roles && profile.roles.map(r => r.key).includes(role)) { 
            isAuthorized = true;
        }
    })
    return isAuthorized
}

export const useIsAuthorized = () : (roles: string[]) => boolean => {
    
    const { profile } = useAuth();

    return (roles: string[]) => {
        let isAuthorized = false;

        roles.forEach(role => {
            if (profile.roles && profile.roles.map(r => r.key).includes(role)) { 
                isAuthorized = true;
            }
        })
        return isAuthorized;
    }
}