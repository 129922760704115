import { Alert, Card, Col, Form, Input, InputNumber, Modal, Row, Tag, Tooltip } from "antd"
import ButtonSG from "../../../../components/design/Buttons/ButtonSG"
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { useContext } from "react";
import { formatPercentage } from "../../../../../common/utils/FormatUtils";
import { CaretDownOutlined, CaretUpOutlined, EuroOutlined, GoldOutlined } from "@ant-design/icons";
import { customRequest } from "../../../../../common/request/customRequest";

export const ConfirmOperation = (props: {
    suggestion: any,
    onFinish: any,
    onCancel:any
}) => {

    const [form] = Form.useForm();
    
    const contract = props.suggestion.contract;
    const metalInfo = props.suggestion.metalInfo;
    
    const onFormFinish = async (values: any) => {

        const data = {            
            contractId: contract.id,
            status: metalInfo.status,
            metalCode: metalInfo.metalCode,
            buyRevenue: metalInfo.buyRevenue,
            buyPriceQuotation: metalInfo.buyPriceQuotation,
            buyPriceOverCost: metalInfo.buyPriceOverCost,
            sellPriceQuotation: metalInfo.sellPriceQuotation,
            sellPriceOverCost: metalInfo.sellPriceOverCost,
            sellPrice: metalInfo.sellPrice,
            sellCost: metalInfo.sellCost,
            sellUnits: metalInfo.sellUnits,
            previousInvestmentId: metalInfo.previousInvestmentId
        };

        await customRequest(`${process.env.REACT_APP_API}/contracts/${contract.id}/operate`, { method: 'PUT', data: data })
        .then(() => {props.onFinish()})        
    };

    return (
        <Modal
            open={true}
            onOk={() => form.submit()}
            afterClose={form.resetFields}
            onCancel={props.onCancel}
            width={'50%'}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={props.onCancel}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Confirmar
                    </ButtonSG>
                </div>
            ]}>

            <h2 style={{ margin: '0px'}}>Sugerencia</h2>            
            <br/>
            <Card style={{ borderRadius: '25px', background:'WhiteSmoke'}} >  
                <div style={{ borderRadius: '25px', display: 'flex', width:'100%' }} >  
                    <span style={{width:'50%'}}>
                        <EntityLabel prefix='Contrato' id={contract.id} />                
                        <small>{contract.clientName}</small>
                    </span>
                    <span style={{ width:'50%' }}>
                        <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><EuroOutlined />{contract.cashAccount}€</p>
                        <Tooltip placement="leftBottom" title={`${contract.units} lingotes`}><p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><GoldOutlined />{contract.unitsCostAccount}€</p></Tooltip>                
                    </span>                
                </div>
            </Card>
            {metalInfo.status == 'BUY' &&
                <Card style={{ borderRadius: '25px', marginTop:'10px', background:'white' }} >
                    <div style={{ borderRadius: '25px', display: 'flex', width:'100%' }} >  
                        <span style={{width:'50%'}}>
                            <h2 style={{ margin: '0'}}>Comprar {metalInfo.buyRevenue}€ de {metalInfo.metalName}</h2>                        
                            <Tag color="green">Precio: {metalInfo.buyPriceQuotation.toFixed(2)}€ / Onza + {(100 *  metalInfo.buyPriceOverCost).toFixed(0)}%</Tag>
                        </span>
                        {contract.investmentPlan === 'TOTAL_FREEDOM' && 
                            <span style={{width:'50%'}}>
                                <h3 style={{ margin: '0'}}>{formatPercentage(metalInfo.buyPercentage, 2)}% de la inversión</h3>
                                {metalInfo.increment >= 0 ? 
                                    <div  style={{ color: 'green'}}>
                                        {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretUpOutlined />
                                    </div>   
                                    :
                                    <small  style={{ color: 'red'}}>
                                        {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretDownOutlined />
                                    </small>
                                }     
                            </span>
                            }
                    </div>                
                </Card>
            }
            {metalInfo.status == 'SELL' &&
                <Card style={{ borderRadius: '25px', marginTop:'10px', background:'white' }} >
                    <div style={{ borderRadius: '25px', display: 'flex', width:'100%' }} >  
                        <span style={{width:'50%'}}>
                            <h2 style={{ margin: '0'}}>Vender {metalInfo.metalName}</h2>                        
                            <Tag color="green">Precio: {metalInfo.sellPriceQuotation.toFixed(2)}€ / Onza + {(100 *  metalInfo.sellPriceOverCost).toFixed(0)}%</Tag>
                        </span>

                        <span style={{width:'50%'}}>
                            <div style={{ margin: '0'}}>Coste {metalInfo.sellUnits} uds.: {metalInfo.sellCost.toFixed(2)}€</div>
                            <div style={{ margin: '0'}}>Precio {metalInfo.sellUnits} uds.: {metalInfo.sellPrice.toFixed(2)}€</div>
                            {metalInfo.increment >= 0 ? 
                                <div  style={{ color: 'green'}}>
                                    {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretUpOutlined />
                                </div>   
                                :
                                <small  style={{ color: 'red'}}>
                                    {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretDownOutlined />
                                </small>
                            }     
                        </span>

                    </div>                
                </Card>
            }
             {metalInfo.status.includes('NO_OPERATION_') &&
                <Card style={{ borderRadius: '25px', marginTop:'10px', background:'white' }} >
                    <div style={{ borderRadius: '25px', display: 'flex', width:'100%' }} >  
                        <span style={{width:'50%', marginRight:'5%'}}>
                            <h2 style={{ margin: '0'}}>Descartar operación</h2>                        
                            <small>
                                <span>
                                {   metalInfo?.alerts?.map((alert: any) => {
                                    return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                })}
                            </span>
                        </small>
                            
                        </span>
                        <span style={{width:'50%'}}>
                            <h3 style={{ margin: '0'}}>Incremento</h3>
                            {metalInfo.increment >= 0 ? 
                                <div  style={{ color: 'green'}}>
                                    {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretUpOutlined />
                                </div>   
                                :
                                <small  style={{ color: 'red'}}>
                                    {`${metalInfo.metalName} ${metalInfo.increment.toFixed(2)}%`}<CaretDownOutlined />
                                </small>
                            }     
                        </span>
                    </div>                
                </Card>
            }
            <Form form={form} onFinish={onFormFinish} initialValues={{ buyRevenue: metalInfo.buyRevenue }}>          
                <Form.Item name='buyRevenue' hidden>
                    <InputNumber />
                </Form.Item>
            </Form>                

                    {/*<Col span={6}>
                        <Form.Item label='Precio' name='price' initialValue={formatPrice(Number(props.suggestion.contract.precio), 2)}>
                            <InputNumber addonAfter="€" />
                        </Form.Item>
                    </Col>*/}                
        </Modal>
    )
}