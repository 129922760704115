import { Alert, Card, Input, Modal, Select, Switch, Tag, Tooltip } from "antd"
import { CheckOutlined, PaperClipOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";

import { customRequest } from "../../../../common/request/customRequest";
import { ROLE_ACCOUNTING, ROLE_ADMIN } from "../../../../common/constants/roles";
import { useContext, useState } from 'react'
import moment from "moment";
import TableSG from "../../../components/design/Table/TableSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { DocumentsModal } from "../../common/UploadDocuments/DocumentsModal";
import { PAYMENT_STATUS } from "../../../../common/constants/paymentStatus";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";

export const Payments = () => {

  const IsAuthorized = useIsAuthorized();

  const [updateData, setUpdateData] = useState(false);
  const [documentsModal, setDocumentsModal] = useState(null);
  
  const [paymentState, setPaymentState] = useState(PAYMENT_STATUS.PENDING);

  const { setClientDrawer } = useContext(DrawerContext);
  const {setContractDrawer} = useContext(DrawerContext)

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/payments?status=${paymentState.code}`, {}, [updateData]);

  const paymentStatus = async (record: any, value: string) => {
    await customRequest(`${process.env.REACT_APP_API}/payments/${record.id}/?masks=STATUS`, {
      method: 'PATCH',
      data: { status: value }
    }).then(() => setUpdateData(!updateData))
  }

  
  const columns = [

    {
      title: 'Cliente',
      key: 'clientName',
      dataIndex: 'clientName',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Cliente"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.clientName.toLowerCase().includes(value.toLowerCase());
      },
      render: (_: any, record: any) => {
        return <a onClick={() => setClientDrawer(record.clientId)}>{record.clientName}</a>

      }
    },
    {
      title: 'Contrato',
      render: (record: any) => {
          return <a onClick={()=>setContractDrawer(record.contractId)}>
                <EntityLabel prefix='Contrato' id={record.contractId}/>
              </a>
      },
    },
    {
      title: 'Fecha Límite',
      render: (record: any) => {
        return <>
          {
            !record.alert ? <div style={{ fontSize: '13px' }}>{moment(record.start).format('ll')}</div>
              : <Tooltip placement="topLeft" title={record.alert.description}>
                <Alert type={record.alert.severity} message={moment(record.start).format('ll')} showIcon style={{ padding: '1px 15px', borderRadius: '8px', width: 'fit-content' }} />
              </Tooltip>
          }
        </>
      },
    },
    {
      title: "Pago", dataIndex: "revenue", key: "revenue",
      render: (revenue: number) => {
        return <> {
          <PriceTag price={revenue} />
        } </>
      },
      sorter: (a: any, b: any) => b.revenue - a.revenue

    },
    {
      title: 'Documentos',
      align: 'center',
      width:'15%',
      render: (record: any) => {
        return (
          <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
              <div  style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            {record.documents !== null ? record.documents.map((d: any) => (
              <div key={d.name}><small style={{display:'flex', alignItems:'center', gap:'3px'}}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
            )): <small>Sin documentos</small>}</div>
            <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
            <PlusOutlined />
            </ButtonSG>
          </div>
        );
      },
      sorter: (a:any, b:any) => {
        if (a.documents !== null && b.documents === null) {
          return -1;
        }
        if (a.documents === null && b.documents !== null) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Confirmar Pago',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <ButtonSG
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ACCOUNTING.WRITE])}
            onClick={
              () => {
                Modal.confirm({
                  title: '¿Confirmar el pago de esta adjudicación?',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk() { paymentStatus(record, 'PAID') }
                });
              }
            }
            size='small'><CheckOutlined /></ButtonSG>

        )
      }
    },

  ];

  const [filteredColumns, setFilteredColumns] = useState<any>(columns);

  const handleChange = (value: boolean) => {
    if (value) {
      setPaymentState(PAYMENT_STATUS.DONE);
      const filteredcolumns = columns.slice(0, columns.length - 1);
      setFilteredColumns(filteredcolumns)
    } else {
      setPaymentState(PAYMENT_STATUS.PENDING);
      setFilteredColumns(columns)
    }
  };


  return (
    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Pagos de Cliente</span>
      </div>

      <div style={{display:'flex', gap:'10px', justifyContent:'flex-end'}}>
        <p className='pl-20 desc'>Pagados</p>
        <Switch loading={isLoading}
         onChange={handleChange} 
        />
      </div>
      <TableSG columns={filteredColumns} dataSource={storesData} loading={isLoading} rowKey="id" />

      {
        documentsModal && <DocumentsModal entity="CLIENTPAYMENTS" title='Pagos del Cliente' tabs={[{key:'1', label:'Facturas', type:'pdf', tabName:'INVOICES'}]} onFinish={() => {setDocumentsModal(null); setUpdateData(!updateData);}} entityId={documentsModal}/>   
      }

    </Card>


  )
}