import { Alert, Form, Input, InputNumber, message, Modal, Row, Select } from 'antd';
import Col from 'antd/es/grid/col';
import React, { useState } from 'react';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { customRequest } from '../../../../../common/request/customRequest';

export const WalletAddModal = ({
	visible,
	onFinish,
}: any) => {

	const [form] = Form.useForm();
    const gold = Form.useWatch<number>('gold', form);
    const silver = Form.useWatch<number>('silver', form);
    const platinum = Form.useWatch<number>('platinum', form);
    const palladium = Form.useWatch<number>('palladium', form);
    const rhodium = Form.useWatch<number>('rhodium', form);
    const osmium = Form.useWatch<number>('osmium', form);
    const [errorMessage, setErrorMessage] = useState(null);


	const onFormFinish = async (values: any) => {
		const data = {
			...values
		};
		const response  = await customRequest(`${process.env.REACT_APP_API}/wallets`, { method: 'POST', data: data });
        if(response.statusCode == STATUS_CODE.BAD_REQUEST) {

            setErrorMessage(response.message)

        }else{

            onFinish();
        }
	};

  return (
		<Modal width={'60%'} visible={visible} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}
        footer={[
            <div style={{padding:'10px'}}>
                <ButtonSG key="back" color='dark-white' onClick={onFinish}>
                Cancelar 
                </ButtonSG>
                <ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
                Guardar
                </ButtonSG>
            </div>
		  ]}>
			<h2>Nueva Cartera</h2>            

			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{ gold: 0, silver: 0, platinum: 0, palladium: 0, rhodium: 0, osmium: 0 }}>

                <Form.Item label='Identificador' name='id' rules={[{ required: true, message: 'Por favor introduce el identificador'}]}>
					<Input />
				</Form.Item>				
				<Form.Item label='Nombre de la Cartera' name='name' rules={[{ required: true, message: 'Por favor introduce el nombre de la cartera'}]}>
					<Input />
				</Form.Item>				
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item label='Oro' name='gold'>
                            <InputNumber min={0} max={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Plata' name='silver'>
                            <InputNumber min={0} max={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Platino' name='platinum'>
                            <InputNumber min={0} max={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Paladio' name='palladium'>
                            <InputNumber min={0} max={100}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='Rodio' name='rhodium'>
                            <InputNumber min={0} max={100}/>
                        </Form.Item>
                    </Col>
                </Row>
			</Form>
            <h3>Total: {gold + silver + platinum + palladium + rhodium}%</h3>
            <Col>
				{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
				}
				</Col>
		</Modal>
	)
}