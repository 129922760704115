import { Card, Col, Form, Input, InputNumber, Modal, Row, Tag } from 'antd'
import '../../../../../styles/modalSG.sass'
import ButtonSG from '../../../../components/design/Buttons/ButtonSG'
import { InternalArticle } from '../../../../../interfaces/InternalArticle'
import { customRequest } from '../../../../../common/request/customRequest'
import { useState } from 'react'
import { STATUS_CODE } from '../../../../../common/constants/statusCode'
import { InputPrice } from '../../../../components/design/InputPrice/InputPrice'


export const AssignArticleModal = (props : {
	investmentId: string,
	constractId: string,
	clientId: string,
	clientName: string,
	defaultPrice: any, 
	articleInfo: any,
	updateData: boolean,
	setUpdateData: any, 
	onFinish : any
	
}) => {

	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	
	const onFormFinish = async (values: any) => {

		const data = {
			articleId: props.articleInfo.id,
			buyerOwner: "CONTRACT",
			buyerInvestmentId: props.investmentId,
			buyerContractId: props.constractId,
			buyerClientId: props.clientId,
			buyerClientName: props.clientName,
			price: values.price,
		}

		const response = await customRequest(`${process.env.REACT_APP_API}/transactions`, { method: 'POST', data: data })

		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.onFinish();
		}

		props.setUpdateData(!props.updateData)
		props.onFinish();
	};

	return (
		
		<Modal 
			open={true} 
			onOk={() => form.submit()} 
			onCancel={props.onFinish} 
			afterClose={form.resetFields}		
			footer={[
				<div style={{padding:'10px'}}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar 
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
						Asignar 
					</ButtonSG>
				</div>
			]}>
				<h2 style={{marginTop: '10px'}}>Asignación del artículo</h2>

				<Card style={{borderRadius:'25px'}} >					
					<div>{props.articleInfo.productType.name}</div>
					{props.articleInfo.barCode && <div><b>Código de Barras: </b>{props.articleInfo.barCode}</div>}
				</Card>				
				<br/>	
				<Form layout='vertical' form={form} onFinish={onFormFinish} 
					initialValues={{price: props.defaultPrice }} >										
					<InputPrice label={'Precio (con IVA incluido)'} nameInput='price' disabled={false}/>
				</Form>
		</Modal>
	)	
}
