import { Result } from "antd";

const Error500 = () => {

  return (
    <Result
    status="500"
    title="500"
    subTitle="Lo sentimos, error inesperado en el servidor, vuelve a intentarlo en unos instantes y contacta con tu operador si el error persiste."
  />)
}

export default Error500;