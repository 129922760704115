import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Modal, Form, Input } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { type } from 'os';

export const WebAddModal = (props : {visible : boolean,onFinish : any} ) => {

  const [form] = Form.useForm();

  const onFormFinish = async(values: any) => {
    const data = {
			...values,			
		};
    await authFetch(`${process.env.REACT_APP_API}/webs`, { method: 'POST', data: data });
    props.onFinish()
  };

  return (
    <Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
    footer={[
      <div style={{padding:'10px'}}>
        <ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
          Cancelar 
        </ButtonSG>
        <ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
          Guardar
        </ButtonSG>
      </div>
		  ]}
      >
      <h2>Nueva web</h2>
      <Form 
        layout='vertical' 
        form={form} 
        onFinish={onFormFinish}
        autoComplete="off">
				<Form.Item 
          label='Dominio' 
          name='name'
          rules={[{ required: true, message: 'Ingrese un dominio válido'}]}>
					<Input />
				</Form.Item>
				<Form.Item 
          label='Descripción' 
          name='description' 
          rules={[{ required: true, message: 'El campo es requerido'}]}>
					<Input />
				</Form.Item>
        <Form.Item 
          label='Usuario de API' 
          name='apiUser' 
          rules={[{ required: true, message: 'El campo es requerido'}]}
          >
					<Input />
				</Form.Item>
        <Form.Item 
          label='Contraseña de API' 
          name='apiKey' 
          rules={[{ required:true, message: 'El campo es requerido'}]}
          >
					<Input.Password iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
				</Form.Item>			
			</Form>
    </Modal>
  )
}
