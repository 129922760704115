import { Alert, Card, Col, Modal, Progress, Row, Tag, Tooltip } from "antd"
import { useHistory } from "react-router-dom";
import moment from "moment";
import { formatPrice } from "../../../../../common/utils/formatPrice";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import { IsAuthorized } from "../../../../../common/request/AuthorizationUtils";
import { ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../../common/constants/roles";
import { CheckOutlined } from "@ant-design/icons";
import { customRequest } from "../../../../../common/request/customRequest";

const LIMIT_TO_COMPLETE = 90;

export const KpisContract = ({ kpis, investment, id, type }: any) => {

    const history = useHistory();

    const paymentStatus = async (id: any, value: string) => {

        await customRequest(`${process.env.REACT_APP_API}/investments/${id}/?masks=STATUS`, {
            method: 'PATCH',
            data: { status: value }
        })
        .then(()=> 
            history.push('/logistica/adjudicar?completed=true')
        )
    }

    return (
        <Row justify="space-around" gutter={[32, 32]} style={{ margin: '20px 0px' }}>

            <Col span={5}>
                <Card style={{ borderRadius: '25px', minHeight: '100px', boxShadow: '-10px -10px 15px rgba(0, 0, 0, 0.03)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ color: 'grey', margin: '5px' }}>
                            Precio
                        </div>
                        <div>
                            {investment && <Tag color="blue"><b>{investment.priceQuotation.toFixed(2)}€ / Onza + {(100 * investment.priceOverCost).toFixed(0)}%</b>
                            </Tag>}
                        </div>
                    </div>
                </Card>
            </Col>
            <Col span={5}>
                <Card style={{ borderRadius: '25px', minHeight: '100px', boxShadow: '-10px -10px 15px rgba(0, 0, 0, 0.03)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {type === "BUY" ?
                            <><div style={{ color: 'grey', margin: '5px' }}>
                                Asignado
                            </div><div>
                                    <Tag color="var(--white)" className='market_price'>{Math.round(100 * kpis.investedRevenue) / 100} €</Tag>
                                    <small>{kpis.units} uds.</small>
                                </div></> :

                            <>
                                <div style={{ color: 'grey', margin: '5px' }}>
                                    Vendido
                                </div>
                                <div>
                                    <Tag color="var(--white)" className='market_price'>{formatPrice(investment?.price, 2)} €</Tag>
                                    <small>{investment?.units} uds.</small>
                                </div>
                            </>}
                    </div>
                </Card>
            </Col>
            <Col span={5}>
                <Card style={{ borderRadius: '25px', minHeight: '100px', boxShadow: '-10px -10px 15px rgba(0, 0, 0, 0.03)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ color: 'grey', margin: '5px' }}>
                           {type == "BUY" ? <p>Inicio Asignación</p> : <p>Fecha de Venta</p>} 
                        </div>
                        <div>
                            {kpis.activeDays > 15 ?

                                <div><Tooltip placement="topLeft" title={`Retraso: Día ${kpis.activeDays}`}>
                                    <Alert showIcon type="warning" message={<span >{moment(kpis.start).format('LL')}</span>} style={{ padding: '1px 15px', borderRadius: '8px', width: 'fit-content', margin: 'auto' }}></Alert>
                                </Tooltip></div>
                                : <div >{moment(kpis.start).format('LL')}</div>
                            }
                        </div>
                    </div>
                </Card>
            </Col>
            <Col span={5}>
                <Card style={{ borderRadius: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100px', boxShadow: '-10px -10px 15px rgba(0, 0, 0, 0.03)' }}>
                    <div>
                        <Progress strokeColor='var(--primary)' type="circle" percent={Math.floor(10 * kpis?.investedRevenuePercent) / 10} width={60} />
                    </div>
                </Card>
            </Col>
            <Col span={4}>
                <Card style={{ borderRadius: '25px', minHeight: '100px', boxShadow: '-10px -10px 15px rgba(0, 0, 0, 0.03)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ color: 'grey', margin: '5px' }}>
                            Finalizar
                        </div>
                        <ButtonSG
                            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_LOGISTIC.WRITE]) || (Math.floor(10 * kpis?.investedRevenuePercent) / 10) < LIMIT_TO_COMPLETE}
                            onClick={
                                () => {
                                    Modal.confirm({
                                        title: '¿Dar por cerrada la operación?',
                                        okText: 'Confirmar',
                                        cancelText: 'Cancelar',
                                        onOk() { paymentStatus(id, 'DONE') }
                                    });
                                }
                            }
                            size='small'><CheckOutlined />
                        </ButtonSG>
                    </div>
                </Card>
            </Col>
        </Row >
    )
}

export default KpisContract;