import { Route, Switch } from 'react-router';
import { accountingMenuRoutes, shoppingMenuRoutes } from '../constants';

export const AccountingMenuRoutes = () => {

  return (
		<Switch>
			{
				[accountingMenuRoutes.map((route, index) => {
					return (
						<Route 
							key={index}
							path={route.path}
							exact={route.exact}
							render={() => <route.component />} />													
					)
					})					
				]
			}
		</Switch>
	);
}
