import { Alert, Col, Form, Input, InputNumber, Modal, Row, Tag, Tooltip } from 'antd';
import { WordPressPrices } from '../../../../../interfaces/WordPressPrices';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import '../../../../../styles/modalSG.sass'
import { InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { customRequest } from '../../../../../common/request/customRequest';
import '../SyncProducts.css'
import { useState } from 'react';
import { SpinSG } from '../../../../components/model/Spin/SpinSG';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { formatPrice } from '../../../../../common/utils/formatPrice';

export const ChangePriceModal = (props: {
	wordPressPrices: WordPressPrices,
	setUpdateData : any,
	updateData: boolean,
	onFinish: any
}) => {

	const [errorMessage, setErrorMessage] = useState("")
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false)

	const discountPrice = props.wordPressPrices.priceParams.discount && ((1 -props.wordPressPrices.priceParams.discount) * props.wordPressPrices.suggestedPrice!);
	const marketPrice = props.wordPressPrices.priceParams.marketPrice
	const maxPrice = props.wordPressPrices.priceParams.maxPrice
	const minPrice = props.wordPressPrices.priceParams.minPrice
	const suggestedPrice = props.wordPressPrices.suggestedPrice
	const competencyPrice = props.wordPressPrices.priceParams.competencyPrice
	const basePrice = props.wordPressPrices.priceParams.basePrice
	const averageCost = props.wordPressPrices.priceParams.avgCost
	const capitalGain  = props.wordPressPrices.priceParams.capitalGain

	const onFormFinish = async (values: any) => {

		setLoading(true);

		const data = {
			sku: values.sku,
			webId: values.webId,
			wordPressId: values.wordPressId,
			regularPrice: values.regularPrice,
			salePrice: values.salePrice,
			suggestedPrice: suggestedPrice,	
			suggestedSalePrice:discountPrice
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/wordPressProducts/price`, { method: 'PATCH', data: data }, 'Precio subido con éxito')
		if (response.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			props.setUpdateData(!props.updateData)
			props.onFinish();
		}
		setLoading(false)
	};

	function calculateSalePrice(value:number, sale:number){
		const salePrice = value * (1 - (sale / 100));
		form.setFieldsValue({ salePrice });
	}

	return (

		<Modal
			open={true}
			onOk={() => form.submit()}
			afterClose={form.resetFields}
			onCancel={props.onFinish}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Subir
					</ButtonSG>
				</div>
				
			]}>

			<h2>Subir Precio</h2>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>

				{
					maxPrice ? (

					<div><Tag  color={maxPrice < basePrice && maxPrice > minPrice ? 'var(--green)' : "white"} className='tag_priceParams' style={{width:'auto'}}> Máximo: {maxPrice?.toFixed(2)}€ </Tag><Tooltip placement="topLeft" title={`AVG competencia: ${competencyPrice?.toFixed(2)} + 5%`}>

						<InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
					</Tooltip></div> ) :(

					<div><Tag color={"lightgrey"} className='tag_priceParams' style={{width:'auto'}}>Máximo: - </Tag><Tooltip placement="topLeft" title={`No existe información de la competencia`}>
					<InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
					</Tooltip></div>
					)
				}

				{
					basePrice && marketPrice ? (

					<div><Tag color={basePrice  >=  minPrice && (basePrice < maxPrice || maxPrice===null) ? 'var(--green)' : "white"} className='tag_priceParams' style={{width:'auto'}}> Base: {basePrice?.toFixed(2)}€</Tag><Tooltip placement="topLeft" title={`Cotización: ${marketPrice?.toFixed(2)}  + Margen: ${(100 * props.wordPressPrices.priceParams.capitalGain)?.toFixed(2)}%`}>
						 <InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
					</Tooltip>
					</div>
					) : competencyPrice ? (
						<div><Tag color={basePrice >= minPrice && (basePrice < maxPrice || maxPrice===null)? 'var(--green)' : "white"} className='tag_priceParams' style={{width:'auto'}}>Base: {basePrice?.toFixed(2)}€</Tag><Tooltip placement="topLeft" title={`AVG competencia: ${competencyPrice?.toFixed(2)}  -  5%`}>
						 <InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
					</Tooltip>
					</div>
					) : (
						<div><Tag color={basePrice >= minPrice && (basePrice < maxPrice || maxPrice===null) ? 'var(--green)' : "white"} className='tag_priceParams' style={{width:'auto'}}>Base: {basePrice?.toFixed(2)}€</Tag><Tooltip placement="topLeft" title={`Coste Medio ${averageCost?.toFixed(2)}€ + Margen ${100 * capitalGain}%`}>
						 <InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
					</Tooltip>
					</div>
					)
				}

				{
					minPrice &&
					<div ><Tag color={minPrice > basePrice ? 'var(--green)' : "white"} className='tag_priceParams' style={{width:'auto'}}>Mínimo: {minPrice?.toFixed(2)}€</Tag>
						<Tooltip placement="topLeft" title={`Coste Medio ${props.wordPressPrices.priceParams.avgCost?.toFixed(2)}€ + margen ${100 * props.wordPressPrices.priceParams.capitalGain?.toFixed(2)}%`}>
							<InfoCircleOutlined style={{ color: 'var(--accent-variant)' }} />
						</Tooltip></div>
				}
			</div>
			<br /><br />
			{loading && <SpinSG/>}
			<Form layout='vertical' form={form} onFinish={onFormFinish} initialValues={{
				webId: props.wordPressPrices.webId,
				sku: props.wordPressPrices.sku,
				wordPressId: props.wordPressPrices.wordPressId,
				regularPrice: Number(suggestedPrice?.toFixed(2)),
				salePrice: discountPrice,
				sale: (props.wordPressPrices.priceParams.discount === 0 || !props.wordPressPrices.priceParams.discount? null : props.wordPressPrices.priceParams.discount*100),
			}}>

				<Form.Item label='Web' name='webId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Producto (sku)' name='sku' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label='Id en WordPress' name='wordPressId' rules={[{ required: true }]}>
					<Input className='disabled' disabled={true} />
				</Form.Item>

				<Form.Item label={<span>Precio Regular <Tooltip title='Decimales con puntos, las comas son ignoradas.'><WarningOutlined style={{color:'var(--accent-variant)'}}/></Tooltip></span>}  name='regularPrice' rules={[{ required: true }]}>
					<InputNumber min={0.001} controls={false} addonAfter="€" onChange={(value)=>{
						const sale = form.getFieldValue("sale")
						calculateSalePrice(value, sale)}}/>
				</Form.Item>
				{props.wordPressPrices.priceParams.discount !== null ? (
				<Col style={{ display: 'flex', gap: '5px' }}>
					<Form.Item label='Precio Rebaja' name='salePrice' rules={[{ required: false }]}>
						<InputNumber className='disabled' addonAfter="€" disabled={true} controls={false} />
					</Form.Item>
					<Row style={{display:'flex', flexDirection:'row'}}>
					<Form.Item  
					  label={
						<>
						  Rebaja 
						  <Tooltip title='Rebaja configurada'><Tag style={{marginLeft:'5px'}} className="tag_priceParams">{props.wordPressPrices.priceParams.discount * 100} %</Tag></Tooltip>
						</>
					  }
					 name='sale' rules={[{ required: false }]} >
						<InputNumber min={1} controls={false} disabled={props.wordPressPrices.priceParams.discount === null} addonAfter='%' onChange={(value)=> {
						const regularPrice = form.getFieldValue("regularPrice")
						calculateSalePrice(regularPrice, value)}}/>
					</Form.Item  >
					
					</Row>
				</Col>
				): "¡No hay rebaja para este producto!"}
				<Col>
				{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
				}
				</Col>
			</Form>
		</Modal>
	)
}