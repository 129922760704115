import { DatabaseTwoTone, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Input, Table } from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { FilterDropdownProps } from "../../../../types/filterDropDown";
import { ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { StoresAddModal } from "./modal/StoresAddModal";
import { EditContractTypeModal } from "./modal/EditContractTypeModal";

export const Stores = () => {

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [editContractType, setEditContractType] = useState(null);

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/stores/`, {}, [updateData]);

  const columns = [
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      render: (_: any, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
            <span style={{ fontSize: '20px' }} className="material-symbols-rounded">forklift</span>
          </div>
        )
      }
    },
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.name.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Descripción',
      key: 'description',
      dataIndex: 'description',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Descripción"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.description.toLowerCase().includes(value.toLowerCase());
      },

    },
    {
      title: 'Tipo de Contrato',
      key: 'contractType',
      dataIndex: 'contractType',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Tipo de Contrato"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.contractType.toLowerCase().includes(value.toLowerCase());
      },
      render: (value: any, record: any) => {
        return <>
            <div style={{ display: 'flex', gap: '5px', minWidth: '100px' }}>
              <a onClick={() => { setEditContractType(record) }}><EditOutlined /></a>    
              <div>        
              {record?.contractType?.map((type: string, index: number) => (
                  <div key={index}>
                      {type}
                  </div>
              ))}    
              </div>        
            </div>
        </>
      },
    },
    {
      title: 'Dirección',
      key: 'address',
      dataIndex: 'address',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dirección"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.address?.toLowerCase().includes(value.toLowerCase());
      },


    }
  ]


  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Almacenes</span>
        <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>
          <ButtonSG onClick={() => setAddUserVisible(true)}>
            Nuevo Almacén
          </ButtonSG>
        </AuthRoles>
      </div>
      <TableSG columns={columns} dataSource={storesData} rowKey='name' loading={isLoading} />
      {
        addUserVisible &&
        <StoresAddModal
          visible={addUserVisible}
          onFinish={() => {
            setAddUserVisible(false);
            setUpdateData(!updateData);
          }
          }
        />}
      {editContractType &&
        <EditContractTypeModal
          store={editContractType}
          onFinish={() => {
            setEditContractType(null);
            setUpdateData(!updateData);
          }
          }
        />
      }
    </Card>

  )
};