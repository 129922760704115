import { Card, Timeline } from 'antd';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import DrawerSG from '../../design/Drawer/DrawerSG';
import './logsDrawer.sass'
import { defaultFormat } from '../../../../common/utils/formatDate';


export default function PriceLogDrawer(props: {
    visible: boolean,
    onClose: any,
    nameLog?: string,
    wordPressId?: number | string,
    webId?:string,
}) {

    const { data: priceData } = useFetch(
        `${process.env.REACT_APP_API}/priceLogs?wordPressId=${props.wordPressId}&webName=${props.webId}`,
        {},
        [],
    );


    function formatDate(props: string) {
        var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(props).toLocaleDateString([], options);
    }


    const listItems = priceData.map((d: any) =>
        <Timeline.Item key={d.created}>
            <Card size="small" title={defaultFormat(d.created)} style={{ borderRadius: "10px" }}>
                <div style={{ marginLeft: "5px" }}>
                    <p><strong>Usuario: </strong>{d.userId}</p>

                    <p><strong>Precios subidos:</strong></p>
                    {d.salePrice !==0 ? (
                        <div>
                        <ul>
                        <li><p><del><strong>Regular: </strong>{d.regularPrice}</del>€</p></li>
                        <li><p><strong>Rebaja: </strong>{d.salePrice} €</p></li>
                        </ul>
                        </div>  
                    ) : (
                        <div>
                            <ul>
                            <li><p><strong>Regular: </strong>{d.regularPrice} €</p></li>
                            </ul>
                            </div>
                    )}

                    <p><strong>Precios sugeridos: </strong></p>
                    {d.suggestedSalePrice !==0 ? (

                        <div>
                            <ul>
                            <li><p><del><strong>Regular: </strong>{d.suggestedPrice}</del>€</p></li>
                            <li> <p><strong>Rebaja: </strong>{d.suggestedSalePrice} €</p></li>
                            </ul>

                        </div>
                        ): (
                            <div>
                            <ul>
                            <li><p><strong>Regular: </strong>{d.suggestedPrice} €</p></li>
                            </ul>
                            </div>
                        )}
                        

                </div>
            </Card>
        </Timeline.Item>
    );



    return (
        <>
            <DrawerSG title={'Logs ' + props.nameLog} visible={props.visible} onClose={props.onClose} placement='right' width={500}>
                <Timeline
                >
                    {listItems}
                </Timeline>

            </DrawerSG>
        </>
    );
}