import { ReactChild, ReactFragment, ReactPortal, useContext } from "react";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Card, Col, Divider, Row, Tag } from "antd";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { TPetition } from "../../../../types/orderObject";
import { ORDER_STATUS } from "../../../../common/constants/orderStatus";
import { PETITION_STATUS } from "../../../../common/constants/petitionStatus";
import moment from "moment";


const OrderDrawer = () => {

  const { orderDrawer, setOrderDrawer } = useContext(DrawerContext)

  const { data: orderData } = useFetch(
    `${process.env.REACT_APP_API}/orders/${orderDrawer}`,
    {},
    [],
  );
  return (
    <>
      {orderData && orderData.id && (
        <DrawerSG
          title={`Orden de Compra: OR${orderData.id.slice(-5, orderData.lenght)}`}
          onClose={() => setOrderDrawer(null)}
          placement='right'
          width={'45%'}
          visible={!!orderDrawer}
        >
          <div style={{ padding: '10px 30px' }}>
            <Card style={{borderRadius:'25px'}} size='small'>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <b>Proveedor</b>
                  <div className="item-view-product">{orderData.providerName}</div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <b>Fecha</b>
                  <div className="item-view-product">{moment(orderData.created).format('LLL')}</div>
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div>
                  <b>Estado</b>
                  <div className="item-view-product">{orderData.status}</div>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <b>Coste</b>
                  <div className="item-view-product">{orderData.cost} €</div>
                </div>
              </Col>
            </Row>
            </Card>

            <Divider />
            <Card style={{borderRadius:'25px'}} size='small' 
            
            title={<b>Lista de peticiones:</b>}>
            <Row gutter={[16,24]}>
                <Col span={10} >
                  <b>Producto</b>
                    <div >
                      {orderData.petitions && orderData.petitions.map((petition: TPetition) => {
                        return <div style={{marginBottom:'10px'}} className="item-view-product">{petition.productName}</div>
                      })}
                    </div>
               </Col>
               <Col span={5}>
                  <div>
                    <b>Coste x uds</b>
                    {orderData.petitions && orderData.petitions.map((petition: TPetition) => {
                      return <div  style={{marginBottom:'10px'}} className="item-view-product">{petition.unitCost}€ x {petition.quantity} uds</div>
                    })}
                  </div>
                </Col>
                <Col span={5}>
                  <div>
                    <b>Empresa</b>
                    {orderData.petitions && orderData.petitions.map((petition: TPetition) => {
                      return <div  style={{marginBottom:'10px'}} className="item-view-product">{petition.ownerDescription}</div>
                    })}
                  </div>
                </Col>
                <Col span={2}>
                  <div>
                  <b>Estado</b>
                    {orderData.petitions && orderData.petitions.map((petition: TPetition) => {
                                  return <div style={{marginBottom:'10px'}}  className="item-view-product">
                      {petition.petitionStatus === PETITION_STATUS.COMPLETED ?

                        <Tag color="green">{petition.petitionStatus}</Tag>
                        :
                        <Tag color="yellow">{petition.petitionStatus}</Tag>
                      }
                    </div>
                    })}
                  </div>
                </Col>
              </Row>
              </Card>
          </div>
        </DrawerSG>
      )}
    </>
  )
}
export default OrderDrawer;