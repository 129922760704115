import { Alert, Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Switch, Tag, Tooltip } from 'antd';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';

export const EditSpotModal = ({
	spot,
	onFinish
}:any) => {

	const [form] = Form.useForm();

	const onFormFinish = async (values: any) => {

		const data = {
			...values,
			shortName:spot.shortName
		};

		const response = await customRequest(`${process.env.REACT_APP_API}/contracttypes/spots`, { method: 'POST', data: data })

		if (response?.statusCode === STATUS_CODE.OK) {
            onFinish();
		}

	};

	
	return (
		<Modal open={spot} onOk={() => form.submit()} onCancel={onFinish} afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Guardar
					</ButtonSG>
				</div>
			]}>

			<h2>Editar Spots</h2>
			<Form layout='vertical' form={form} onFinish={onFormFinish} 
            initialValues={{
                spotGold: Number((spot.spotGold*100).toPrecision(2)),
                spotOthers: Number((spot.spotOthers *100).toPrecision(2)),
            }}>

				<Card title={<Tag color='gold'>Spot</Tag>} size='small' style={{ marginBottom: '10px', borderRadius: '10px', background: 'transparent', borderColor: 'lightgrey' }}>
					<Row gutter={16} style={{ marginTop: '5px' }}>
						<Col span={12}>
							<Form.Item label='Oro' name='spotGold' rules={[{ required: true}]}>
								<InputNumber style={{width: '100%'}} min={1} max={100} addonAfter="%"/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label='Otros' name='spotOthers' rules={[{ required: true}]}>
								<InputNumber style={{width: '100%'}} min={1} max={100} addonAfter="%"/>
							</Form.Item>
						</Col>
					</Row>
				</Card>												
			</Form>
		</Modal>
	)
}