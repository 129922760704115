import { DeleteOutlined, DollarOutlined, EditOutlined, ExclamationCircleOutlined, GoldOutlined, PlusOutlined, ShoppingCartOutlined, ShoppingOutlined, UploadOutlined } from "@ant-design/icons";
import { Card, Input, Space, Tag } from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from '../../../../types/filterDropDown';
import { authFetch } from "../../../../common/request/authFetch";
import { Modal } from 'antd';
import TableSG from "../../../components/design/Table/TableSG";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ACCOUNTING, ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { ProductTypesAddModal } from "./modal/ProductTypesAddModal";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ArticlesAddModal } from "../inventory/modal/ArticlesAddModal";
import WeightSG from "../../../components/design/Weight/Weight";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { UploadProductsModal } from "./modal/UploadProductsModal";
import { BuyInternalStockModal } from "../../ecommerce/products/modal/BuyInternalStockModal";
import { InternalStock } from "../../../../interfaces/InternalStock";
import { EditProductTypesModal } from "./modal/EditProductTypesModal";

const { confirm } = Modal;

export const ProductTypes = () => {

  const [updateData, setUpdateData] = useState(false);

  const [editProduct, setEditProduct] = useState(null);
  
  const [addButtonVisible, setAddButtonVisible] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [articleReference, setArticleReference] = useState(null);

  const [internalStock, setInternalStock] = useState<InternalStock | null>(null);

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/producttypes`, {}, [updateData]);

  const deleteProduct = async (sku: string) => {
    await authFetch(`${process.env.REACT_APP_API}/producttypes/${sku}`, { method: 'DELETE' });
    setUpdateData(!updateData);
  };

  const { setProductDrawer } = useContext(DrawerContext)

  const showDeleteConfirm = (record: any) => {
    confirm({
      title: '¿Estás seguro de eliminar este producto?',
      icon: <ExclamationCircleOutlined />,
      content: record.name,
      okText: 'Si',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteProduct(record.sku);
      },
      onCancel() {

      },
    });
  };


  const columns = [   
    {
      title: 'Producto',
      key: 'sku',
      dataIndex: 'sku',
      width: '20%',
      render: (text: string, record: any) => (
        <>
          <div>{record.name}</div>
          <a onClick={() => {
            setProductDrawer(record.sku);
          }}>
            <small>{record.sku}</small>
          </a>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.name.toLowerCase().includes(value.toLowerCase()) || record.sku.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Metal',
      key: 'metal',
      dataIndex: 'metal',
      render: (_: any, record: any) => (
        <>
          <div className="camel-case">{record.type.toLowerCase()}</div>
          <p>{(() => {
            switch (record.metal) {
              case "AU":
                return "Oro"
              case "AG":
                return "Plata"
              case "PT":
                return "Platino"
              case "PD":
                return "Paladio"
              case "RH":
                return "Rodio"
              default:
                return ""
            }
          })()}</p>
          {record.brand}
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Tipo"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const metalValue = (() => {
          switch (record.metal) {
            case "AU":
              return "Oro";
            case "AG":
              return "Plata";
            case "PT":
              return "Platino";
            case "PD":
              return "Paladio";
            case "RH":
              return "Rodio";
            default:
              return "";
          }
        })();
        return record.type.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Peso',
      key: 'metal',
      dataIndex: 'metal',
      sorter: (a: any, b: any) => a.weightInGrams - b.weightInGrams,
      render: (_: any, record: any) => (
        <>
          {record.weightInGrams > 0 && <WeightSG weightInGrams={record.weightInGrams} weightInOunces={record.weightInOunces} units={record.unit} />}

        </>
      )
    },
    {
      title: 'País',
      key: 'countryDescription',
      dataIndex: 'countryDescription',
      render: (_: any, record: any) => (
        <>
          <div>{record.countryDescription}</div>
          {record.year !== 0 && <div> {record.year}</div>}
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="País"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.countryDescription.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Cotización',
      key: 'marketPrice',
      dataIndex: 'marketPrice',
      align: 'center',
      sorter: (a: any, b: any) => a.marketPrice - b.marketPrice,
      render: (_: any, record: any) => (
        <>
          {
            <div>
              {record.marketPrice !== null &&
                <PriceTag price={record.marketPrice} />
              }
            </div>
          }
        </>
      )
    },
    {
      title: 'Artículos',
      key: 'internalArticles',
      align: 'center',
      dataIndex: 'internalArticles',
      sorter: (a: any, b: any) => a.internalArticles - b.internalArticles,
      render: (_: any, record: any) => (
        <div style={{display:'flex', justifyContent:'space-around', alignItems:'flex-end'}}>
          <div>
            {
              record.internalArticles > 0 && <div>{record.internalArticles} uds.</div>
            }
            <span>
              <ButtonSG size='small' color="grey"  onClick={() => setArticleReference(record)}>
                <PlusOutlined />
              </ButtonSG>

            </span>
          </div>
          <ButtonSG size="small" color="yellow" onClick={() => { setInternalStock(record); }}>
            <ShoppingCartOutlined />
          </ButtonSG>
        </div>
      )
    },
    {
      title: 'Acciones',
      key: 'action',
      align: 'center',
      dataIndex: 'action',
      render: (value: any, record: any) => {
        return <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>

          <span style={{ paddingLeft: '10px' , display:'flex', justifyContent:'center', gap:'10px'}}>
          <ButtonSG color="grey"  size='small' onClick={() => setEditProduct(record)}>
              <EditOutlined />
            </ButtonSG>
            <ButtonSG color="grey" size='small' disabled={record.internalArticles > 0} onClick={() => showDeleteConfirm(record)}>
              <DeleteOutlined />
            </ButtonSG>
          </span>
        </AuthRoles>
      }
    }
  ];

  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
        <div className="table-header">
          <span className='title-summary'>Productos</span>
          <div style={{ display: 'flex', gap: '15px' }}>
            <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>
              <ButtonSG color="grey" size='small' icon={<UploadOutlined />} onClick={() => setUploadModal(true)} />
              <ButtonSG color="grey" size='small' onClick={() => setAddButtonVisible(true)}>
                Nuevo Producto
              </ButtonSG>
            </AuthRoles>
          </div>
        </div>

        <TableSG columns={columns} dataSource={storesData} rowKey={'sku'} loading={isLoading} />
        {
          addButtonVisible &&
          <ProductTypesAddModal
            visible={addButtonVisible}
            onFinish={() => {
              setAddButtonVisible(false);
              setUpdateData(!updateData);
            }
            }
          />
        }
        {articleReference && (
          <ArticlesAddModal
            reference={articleReference}
            onFinish={() => {
              setArticleReference(null);
              setUpdateData(!updateData);
            }}
          />
        )}
        {uploadModal && (
          <UploadProductsModal
            visible={uploadModal}
            onFinish={() => {
              setUploadModal(false);
              setUpdateData(!updateData);
            }}
          />
        )}
        {internalStock &&
          <BuyInternalStockModal updateData={updateData} setUpdateData={setUpdateData} internalStock={internalStock} onFinish={() => { setInternalStock(null); }} />
        }

        {editProduct && 
          <EditProductTypesModal product={editProduct} onFinish={() => { setEditProduct(null); }} setUpdateData={setUpdateData} updateData={updateData}/>
        }
      </Card>
    </>
  )
};