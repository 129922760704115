import { DatePicker, Form } from "antd"
import ButtonSG from "../Buttons/ButtonSG"
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons"
import { useState } from "react"
import AuthRoles from "../AuthRoles/AuthRoles"

type TSelectEditableProps = {
    value: string,
    label: string,
    nameInput: string,
    roles?: string[],
    onFormFinish: (value: { nameInput: string }) => Promise<void>
}
export const DatePickerEditable = ({ value, label, nameInput, onFormFinish, roles }: TSelectEditableProps) => {

    const [form] = Form.useForm();
    const [isEdit, setIsEdit] = useState(false);

    const handleFormFinish = (values: any) => {
        onFormFinish(values).then(() => setIsEdit(false));
    };



    return (
        <>
            {
                isEdit ?
                    (
                        <Form
                            layout='vertical'
                            form={form}
                            onFinish={handleFormFinish}
                            autoComplete="off"
                        >
                            <Form.Item  >
                                <div style={{ display: 'flex', alignItems:'center' }}>
                                    <Form.Item style={{marginBottom:'0px', display:'flex', alignItems:'center'}} label={label} name={nameInput} rules={[{ required: true, message: 'La fecha obligatoria' }]}>
                                        <DatePicker showTime={{ format: 'DD-MM-YYY HH:mm' }} format={"DD-MM-YYYY HH:mm"}/>
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: '0px' }}>
                                        <ButtonSG htmlType="submit" color='no-color' style={{marginTop:'24px'}}>
                                            <CheckOutlined />
                                        </ButtonSG>
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: '0px' }} >
                                        <CloseOutlined 
                                            style={{marginTop:'27px'}}
                                            onClick={() => setIsEdit(false)}
                                        />
                                    </Form.Item>
                                </div>
                            </Form.Item>
                        </Form>
                    ) : (
                        <div>
                            <span className="label-item-view">{label}</span>
                            {!isEdit && <AuthRoles roles={roles}>
                                <span onClick={() => setIsEdit(true)
                                } className='modify-icon'><EditOutlined />
                                </span></AuthRoles>}
                            <div className="item-view-product">{value}</div>
                        </div>
                    )
            }
        </>

    )
}