import { DatabaseOutlined, DatabaseTwoTone, MailOutlined, PhoneOutlined, SearchOutlined, ShopOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Card, Input, Switch, Table } from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ROLE_ADMIN,ROLE_PURCHASES } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { ProviderAddModal } from "./modal/ProviderAddModal";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { FilterDropdownProps, FilterValue } from 'antd/lib/table/interface';

export const Providers = () => {

  const [addUserVisible, setAddUserVisible] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  
  const IsAuthorized = useIsAuthorized();

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/providers/`, {}, [updateData]);

  const columns = [
    {
			title: '',
			key: 'id',
			dataIndex: 'id',
			render: (text: string, record: any) => (
					<ShopOutlined style={{fontSize:'20px'}}/>
			)
		},
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
            <Input
              autoFocus
              placeholder="Nombre"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {

        return (record.name && record.name.toLowerCase().includes(value.toLowerCase()));
      },
      
    },
    {
      title: 'NIF',
      key: 'nif',
      dataIndex: 'nif',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
            <Input
              autoFocus
              placeholder="NIF"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {

        return record?.nif?.includes(value);
      },
    },
    {
      title: 'Dirección',
      key: 'address',
      dataIndex: 'address',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
            <Input
              autoFocus
              placeholder="Dirección"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {

        return (record.address && record.address.toLowerCase().includes(value.toLowerCase()));
      },
    },
    {
      title: 'Contacto',
      key: 'contacto',      
      render: (record: any) => (
        <>
          <p><b>{record.contactName}</b></p>          
          <p style={{color:'DimGray'}}><MailOutlined/> {record.contactEmail}</p>
          <p style={{color:'DimGray'}}><PhoneOutlined/> {record.contactPhone}</p>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
            <Input
              autoFocus
              placeholder="Contacto"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {

        return (record.contactName && record.contactName.toLowerCase().includes(value.toLowerCase())) || 
        (record.contactEmail && record.contactEmail.toLowerCase().includes(value.toLowerCase())) ? true : false;
      },    
    }    
  ]

  return (
    
      <Card style={{border: 'none'}} bodyStyle={{padding: "0px 10px 10px 10px"}}>
            
        <div className="table-header">
          <span className='title-summary'>Proveedores de Metales</span>
      
          
            <ButtonSG 
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_PURCHASES.WRITE])} 
            onClick={() => setAddUserVisible(true)}>
              Nuevo Proveedor  
            </ButtonSG>
            
          
        </div>
        <TableSG columns={columns} dataSource={storesData} rowKey={'id'} loading={isLoading} />
          {
          addUserVisible && 
            <ProviderAddModal
              visible={addUserVisible}
              onFinish={() => {
                setAddUserVisible(false);
                setUpdateData(!updateData);
              }
            }
        />}
      </Card>   
	)
};