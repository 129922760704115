import { Layout as AntdLayout } from 'antd';
import AppRoutes from '../../../routing/AppRoutes';


const { Content } = AntdLayout;

const MainContent = () => {

	return (
		<Content style={{
				overflow: 'initial'				
			}}>
			<AppRoutes />		
		</Content>
	)
};

export default MainContent;