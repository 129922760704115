import { Modal, Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { authFetch } from '../../../../../common/request/authFetch';

export const UserEditRoles = (props : {visible : boolean, onClose : any, roleSelected: any, setUpdateUserData: any, rolesData : any[]}) => {

    const [form] = Form.useForm();
    const [roles, setRoles] = useState<any[]>([]);
    const {rolesData} = props;

    
    const onFormFinish = async (values: any) => {
        const data = {
            email: props.roleSelected.email,
            roles:values.roles,
        };
        await authFetch(`${process.env.REACT_APP_API}/users/${data.email}/roles/${data.roles}`, { method: 'PUT', data: data }).then(()=>window.location.reload());
        props.setUpdateUserData( (c: any) => !c);
        props.onClose();
    };

    useEffect(() => {
        var roles = [];
        for (const [key, value] of Object.entries(rolesData)) {
            var rol = {name: key, description: value}
            roles.push(rol);
        }
        setRoles(roles)
      }, []);
  return (
    <>
        <Modal 
            open={props.visible} 
            onOk={() => form.submit()} 
            onCancel={props.onClose} 
            afterClose={form.resetFields}>
            <h2>Editar roles</h2>
            <Form 
                layout='vertical' 
                form={form} 
                onFinish={onFormFinish}
                autoComplete="off">
                <Form.Item 
                    initialValue={props.roleSelected.email}
                    label='Email' 
                    name='email'
                    >
                    <Input disabled={true} className="disabled" />
                </Form.Item>
                <Form.Item label='Roles' name='roles' rules={[{ required: true }]}>
					<Select mode="multiple" defaultValue={props.roleSelected.roles}>
                        {roles.map((element: any) =>{
                           return <Select.Option key={element.name} value={element.name}>
							{element.description}
						</Select.Option>
                        }
                        )}	
					</Select>
				</Form.Item>			
            </Form>
        </Modal>
    </>
  )
}