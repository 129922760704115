import { Card, Spin, Timeline } from "antd"
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import SwiperCarousel from "../../../../components/design/Carrousel/Swiper";
import { useEffect, useState } from "react";
import { authRequest } from "../../../../../common/request/authRequest";

const ArticleCarousel = ({articleDrawer}:any) => {

    const [url, setUrl] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true);

    const { data } = useFetch(`${process.env.REACT_APP_API}/documents/ARTICLES/IMAGES/${articleDrawer}`, {}, []);

    useEffect(() => {
      setIsLoading(true);
        if (data) {
          const fetchPromises = data.map((filename: string) => {
            return authRequest(`${process.env.REACT_APP_API}/documents/ARTICLES/IMAGES/${articleDrawer}/${filename}`, { method: 'GET', responseType: 'blob' });
          });
      
          Promise.all(fetchPromises)
            .then((fetchedData) => {
              const newUrls = fetchedData.map((blobData) => URL.createObjectURL(blobData));
              setIsLoading(false);
              setUrl(newUrls);
            })
            .catch((error) => {
              console.error('Error fetching URLs:', error);
              setIsLoading(false);
            });
        }
      }, [data]);

    return (
      <>
        {url?.length > 0 && <SwiperCarousel mediaItems={url}/>}
        {!isLoading && url?.length == 0 && <div style={{margin:'200px'}}>No hay imágenes</div>}
        {isLoading && <div style={{margin:'200px'}}><Spin size="large"/></div>}
      </>
    )
}

export default ArticleCarousel;