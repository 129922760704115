
import { AuditOutlined, CheckOutlined, ClockCircleOutlined, DoubleLeftOutlined, DoubleRightOutlined, FileDoneOutlined, FileExclamationOutlined, FileUnknownOutlined, FundProjectionScreenOutlined, HomeOutlined, PercentageOutlined, QuestionOutlined, TagsOutlined, UserAddOutlined, UserDeleteOutlined, UserOutlined, VerifiedOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ClientMenuRoutes } from '../../../routing/sideMenu/clientMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass';
import { useEffect, useState } from 'react';

const { Sider, Content } = AntdLayout;

export const ClientLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		
	
	const [selectedKey, setSelectedKey] = useState('/client/inicio');

	const items = [
		{ label: <Link to={'/client/inicio'}><b>Panel</b></Link>, key: '/client/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 		
		{
		  label: 'Funnel de Ventas',
		  key: 'clientes',
		  type: 'group',		  
		  children: [
			{ label: <Link to={'/client/prospectos'}><b>Prospectos</b></Link>, key: '/client/prospectos', icon: <UserAddOutlined className='menu2-icon' />},			
			{ label: <Link to={'/client/elegible'}><b>Clientes</b></Link>, key: '/client/elegible', icon: <UserOutlined className='menu2-icon' />}			
			],
		},
		{
			label: 'Mis Contratos',
			key: 'contratos',
			type: 'group',		  
			children: [
			  { label: <Link to={'/client/contratos'}><b>Contratos</b></Link>, key: '/client/contratos', icon: <AuditOutlined className='menu2-icon' />},						  
			  ],
		  }
	  ];


	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);

	return (
		<AntdLayout className='container-menu'>			
			<Sider className='side-menu' 
					collapsed={collapsed} 
					collapsible={true} 
					trigger={null}>		
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/client/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}
				/>
			</Sider>
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<ClientMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};