import { Alert, Card, Col, Form, Input, InputNumber, Modal,Select } from 'antd';
import { useContext, useState } from 'react';
import { customRequest } from '../../../../../common/request/customRequest';
import { STATUS_CODE } from '../../../../../common/constants/statusCode';
import { useFetch } from '../../../../../hooks/useFetch/useFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';
import { GlobalVariableContext } from '../../../../../store/GlobalVariableContext/GlobalVariableContext';

export const ArticlesToSellModal = ({
	product,
	onFinish,
}: any) => {
	
	
	const [form] = Form.useForm();
	const [errorMessage, setErrorMessage] = useState(null);
	
	const { data: owners } = useFetch(`${process.env.REACT_APP_API}/combos/internalOwners`, {});

	const filteredOwners = owners?.filter((owner: { description: string; }) => owner.description !== product?.ownerDescription);
	const {updateAdjucateData, setUpdateAdjudicateData} = useContext(GlobalVariableContext);

	const onFormFinish = async (values: any) => {

		const response = await customRequest(`${process.env.REACT_APP_API}/transactions`, { method: 'POST', data: values })

		if (response?.statusCode === STATUS_CODE.BAD_REQUEST) {
			setErrorMessage(response.message)
		} else {
			onFinish();
			setUpdateAdjudicateData(!updateAdjucateData)
		}
	};

	return (

		<Modal
			open={product}
			onOk={() => form.submit()}
			onCancel={onFinish}
			afterClose={form.resetFields}
			footer={[
				<div style={{ padding: '10px' }}>
					<ButtonSG key="back" color='dark-white' onClick={onFinish}>
						Cancelar
					</ButtonSG>
					<ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
						Vender
					</ButtonSG>
				</div>
			]}>

			<h2>Venta del Artículo</h2>

			<Card size='small' style={{borderRadius:'25px', marginBottom:'20px'}} title={'Producto'}>
				<h3>{product.name}</h3>
				{product.barCode && <span>Código de barras - {product.barCode}</span>}
			</Card>

			<Form layout='vertical' form={form} onFinish={onFormFinish} >
				<Form.Item label='Artículo' name='articleId' initialValue={product.id} hidden>
					<Input disabled />
				</Form.Item>

				<Form.Item label='Propietario actual' name='originOwnerDescription' initialValue={product.ownerDescription} >
					<Input disabled = {true} defaultValue={product.ownerDescription}></Input>
				</Form.Item>
				<Form.Item label='Propietario' name='sellerOwner' initialValue={product.owner} hidden={true}>
					<Input disabled = {true} defaultValue={product.owner}></Input>
				</Form.Item>					

				<Form.Item label='Propietario nuevo' name='buyerOwner' rules={[{ required: true }]}>
					<Select defaultValue="">
						{
							filteredOwners && filteredOwners.map((element: any) =>
								<Select.Option value={element.key}>{element.description}</Select.Option>
							)}
					</Select>
				</Form.Item>
				
				<Form.Item name='previousCost' initialValue={product.cost} hidden>
					<InputNumber />
				</Form.Item>			

				<Form.Item label='Precio' name='price' initialValue={product.cost} >
					<InputNumber addonAfter="€"  step={0.01}  precision={2}/>
				</Form.Item>				
				<Col>
					{errorMessage && <Alert
						type='error'
						message={errorMessage}
						banner
					/>
					}
				</Col>
			</Form>
		</Modal>
	)
}
