import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { Card, Input, Space} from "antd";
import { useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { EditMargin } from "../../logistics/quotation/modal/EditMargin";
import { authFetch } from "../../../../common/request/authFetch";
import confirm from "antd/lib/modal/confirm";

export const BusinessParams = () => {

  const [updateData, setUpdateData] = useState(false);
  const [margin, setMargin] = useState(null);
  const [editMargin, setEditMargin] = useState(false);

  const deleteProduct = async (marginType: string) => {
    await authFetch(`${process.env.REACT_APP_API}/businessParams/?type=${marginType}`, { method: 'DELETE' });
    setUpdateData(!updateData);
};

  const showDeleteConfirm = (type: string) => {

    confirm({
      title: '¿Estás seguro de que quieres eliminar este parámetro de negocio?',
            icon: <ExclamationCircleOutlined/>,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteProduct(type);
            },
            onCancel() {

            },
    });
};

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/businessParams`, {}, [updateData]);

  const columns = [
    {
      title: '',
      width: '5%',
      render: () => {
        return (
          <>
            {<span style={{ fontSize: '25px' }} className="material-symbols-rounded">tune</span>}
          </>
        )
      }
    },
    {
      title: 'Parámetro',
      dataIndex: 'type',
      align: 'right',
      key: 'type',
      width: '30%',
      sorter: (a: any, b: any) => a.weightInGrams - b.weightInGrams,
      render: (_: any, record: any) => { 
        return <span> {record.typeDescription}</span>
      }
  },
  {
      title: 'Valor',
      dataIndex: 'value',
      align: 'center',
      key: 'value',
      sorter: (a: any, b: any) => a.overCostPrice - b.overCostPrice, 
      width: '30%',
      render: (_: any, record: any) => {
          return (
            record.value ? 
            <span>  {record.value*100} %</span> :
            <span>-</span>  
          )
          
      }
  },
  {
      title: 'Acciones',
      align: 'center',
      key: 'id',
      dataIndex: 'id',
      render: (_: any, record: any) => {
          return <>
              <Space>
                  <ButtonSG size='small'><EditOutlined className="icon-action" onClick={() => {
                    setEditMargin(true)
                    setMargin(record)
                     
                  }} style={{ fontSize: '15px' }} /></ButtonSG>
                  <ButtonSG disabled = {!record.value} size='small'><DeleteOutlined className="icon-action" onClick={() => {
                    showDeleteConfirm(record.type)
                    setMargin(record)
                  }} style={{ fontSize: '15px' }} /></ButtonSG>
              </Space>
          </>
      }
  },
  ]

  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Parámetros de Negocio</span>
      </div>
      <TableSG columns={columns} dataSource={storesData} rowKey={'type'} loading={isLoading} />

  {
  editMargin &&
  <EditMargin
      visible={editMargin}
      onFinish={() => {
          setEditMargin(false);
          setUpdateData(!updateData);
      }}
      marginSelected={margin}
  />
}

    </Card>
  )
};