import React from 'react';
import { Modal } from 'antd';

export const ModalConfirmation = (props : {visible : boolean,onFinish : any, title: string}) => {
  return (
    <Modal 
        open={props.visible} 
        onOk={props.onFinish} 
        onCancel={props.onFinish}
    >
      <h5>{props.title}</h5>
    </Modal>
  )
}
