import { Alert, Card, Col, Form, Input, InputNumber, Modal, Row, Tag, Tooltip } from "antd"
import ButtonSG from "../../../../components/design/Buttons/ButtonSG"
import { customRequest } from "../../../../../common/request/customRequest";
import { useState } from "react";
import { CaretDownOutlined, CaretUpOutlined, EuroCircleOutlined, GoldOutlined } from "@ant-design/icons";

export const AdjustmentModal = (props: {    
    contract: any,
    onFinish: any,
    onCancel:any
}) => {

    const [form] = Form.useForm();         

    const onFormFinish = async (values: any) => {

        const data = {            
            ...values,
            contractId: values.newRevenue,            
        };

        await customRequest(`${process.env.REACT_APP_API}/contracts/${props.contract.id}/adjustment`, { method: 'PUT', data: data })
        .then(() => {props.onFinish()})        
    };

    return (
        
        <Modal
            open={true}
            onOk={() => form.submit()}
            afterClose={form.resetFields}
            onCancel={props.onCancel}
            width={'50%'}
            footer={[
                <div style={{ padding: '10px' }}>
                    <ButtonSG key="back" color='dark-white' onClick={props.onCancel}>
                        Cancelar
                    </ButtonSG>
                    <ButtonSG key="submit" type="primary" color="yellow" onClick={() => form.submit()}>
                        Confirmar
                    </ButtonSG>
                </div>
            ]}>

            <h2 style={{ margin: '0px'}}>Ajuste de Saldo</h2>            
            <Alert banner showIcon type='warning' message='Este ajuste solo se debería usar al migrar contratos y situaciones excepcionales, no forma parte del flujo de contrato' />
            <div className="container-kpi">
              <div className="card-kpi" style={{ width: '30%', height: '100px' }}>
                <div style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <h3 style={{ fontSize: '16px' }}>Pagado</h3>
                  <p>{props.contract.paid} €</p></div>
              </div>

              <div className="card-kpi" style={{ width: '65%', height: '100px' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }} >
                  <h3 style={{ fontSize: '16px' }}>Saldo</h3>
                  <p><EuroCircleOutlined /> {Math.round(100 * props.contract.accountRevenue) / 100} €
                    {Math.abs(props.contract.benefit) > 0.0001 &&
                      (props.contract.benefit > 0 ?
                        <span style={{ color: 'green' }}>
                          <CaretUpOutlined />{Math.round(100 * props.contract.benefit) / 100} %</span>
                        :
                        <span style={{ color: 'RED' }}>
                          <CaretDownOutlined />{-1 * Math.round(100 * props.contract.benefit) / 100} %</span>)
                    }
                  </p>
                  <Tooltip placement="leftBottom" title={`${props.contract.slugs} lingotes`}><p><GoldOutlined /><span style={{ color: 'grey' }}> {Math.round(100 * props.contract.articlesCost) / 100} €</span></p></Tooltip>
                </div>
              </div>
            </div>
            <br/>            
            <Form form={form} onFinish={onFormFinish} initialValues={{  }}>          
                <Form.Item label='Nuevo saldo' name='newRevenue'>
                    <InputNumber addonAfter="€" />
                </Form.Item>
            </Form>                
        </Modal>
    )
}