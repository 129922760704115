import { Badge, Card, Input, Switch, Tooltip} from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ROLE_ADMIN, ROLE_OPERATIONS} from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import TableSG from "../../../components/design/Table/TableSG";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { CaretDownOutlined, CaretUpOutlined, SearchOutlined } from "@ant-design/icons";
import { SellAssignedAddModal } from "./modal/SellAssignedAddModal";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { defaultFormatWithOutHours } from "../../../../common/utils/formatDate";
import { EntityLabel, formattedId } from "../../../components/design/EntityLabel/EntityLabel";
import moment from "moment";

export const SellAssigned = () => {

  const [updateData, setUpdateData] = useState(false);
  const [sellArticle, setSellArticle] = useState(null);
  const [onSale, setOnSale] = useState<string>("onSale=false");
  const { setProductDrawer , setContractDrawer, setArticleDrawer} = useContext(DrawerContext)

  const { data, isLoading } = useFetch(`${process.env.REACT_APP_API}/products/articles?${onSale}`, {}, [updateData]);

  const columns = [
    {
      title: 'Barcode',
      key: 'barCode',
      align: 'center',
      render: (record: any) => (
        <>
          {record?.profitAboveMinimum && <Tooltip title='Sugerencia de Venta'><Badge status="processing" color='var(--primary)'/></Tooltip>}            
          <a onClick={() => { setArticleDrawer(record.articleId) }}>#{record.barCode}</a>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Código de barras"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return (record?.barCode.toLowerCase().includes(value.toLowerCase())) ||
        (`#${record?.barCode}`.toLowerCase().includes(value.toLowerCase()))
      },
    },
    {
      title: 'Metal',
      key: 'metalDescription',
      render: (record: any) => (
         <p>{record.metalDescription} </p> 
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Metal"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return (record?.metalDescription.toLowerCase().includes(value.toLowerCase()));
      },
    },
    {
        title: 'Producto',
        key: 'id',
        dataIndex: 'id',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <Input
              autoFocus
              placeholder="Nombre o sku"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value: any, record: any) => {
          return record.productTypeName.toLowerCase().includes(value.toLowerCase()) || record.id.toLowerCase().includes(value.toLowerCase());
        },
        render: (id: string, record: any) => (
          <>
            <div>{record.productTypeName}</div>
            {
              <a onClick={() => {
                setProductDrawer(id)
              }}>
                <small>{record.id}</small>
              </a>
            }
          </>
        ),
      },
      {
        title: 'Contrato',
        key: 'contractId',
        dataIndex: 'contractId',
        render: (contractId: string, record: any) => {
            return <a onClick={()=>setContractDrawer(contractId)}>
            <EntityLabel prefix='Contrato' id={record.contractId}/>
            </a>
        },
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: FilterDropdownProps) => {
          return (
            <Input
              autoFocus
              placeholder="Id"
              value={selectedKeys[0]}
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
            ></Input>
          );
        },
        filterIcon: () => {
          return <SearchOutlined />;
        },
        onFilter: (value: any, record: any) => {
          const searchText = value.toLowerCase();
          const lastFiveDigits = formattedId(record.contractId).toLowerCase();
          return (
            lastFiveDigits.includes(searchText) ||
            (`contrato #${lastFiveDigits}`.toLowerCase().includes(searchText))
          );
        },
      },
      {
        title: 'Fecha Liquidación',
        key: 'liquidationDate',
        render: (record:any ) => {
          console.log(record)
          return <>
            {/*<p>Quedan {record.daysToLiquidate} días</p>*/}
            <p>{moment(record.liquidationDate).format('ll')}</p>
          </>
        },
        sorter: (a: any, b: any) => b.dayToLiquidate - a.dayToLiquidate
      },
      {
        title: 'Coste',
        key: 'cost',
        dataIndex: 'cost',
        render: (cost: number) => (
          cost > 0 && <PriceTag price={cost} />

        ),
        sorter: (a: any, b: any) => b.cost - a.cost
      },
      {
        title: 'Valor Actual',
        key: 'marketValue',
        dataIndex: 'marketValue',
        render: (marketValue: number) => (
          marketValue > 0 && <PriceTag price={marketValue} />
        ),
        sorter: (a: any, b: any) => b.marketValue - a.marketValue
      },
      {
        title: 'Beneficio',
        key: 'profit',
        dataIndex: 'profit',
        sorter: (a: any, b: any) => b.profit - a.profit,
        render: (profit: number) => (
          <p>{profit > 0 ?
            <div style={{color:"green"}}><CaretUpOutlined/> {(100 * profit).toFixed(2)} %</div>
            :
            <div style={{color:"red"}}><CaretDownOutlined color="red"/> {(-100 * profit).toFixed(2)} %</div>
            }
          </p> 
        )        
      },
      {
        title: 'Acción',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: any) => {
            return <div style={{display:'flex'}}>
                <AuthRoles roles={[ROLE_ADMIN, ROLE_OPERATIONS.WRITE]} >
                  <ButtonSG  size="small" onClick = {()=> setSellArticle(record)}>
                    Poner a la venta
                  </ButtonSG>
                </AuthRoles>
            </div>
        }
    },
  ]

  const [filteredColumns, setFilteredColumns] = useState<any>(columns);
  function refreshStatus(isHistoricSelected: boolean) {
    if (isHistoricSelected) {
      setOnSale(`onSale=true`)
        const filteredColumnsCopy = [...columns];
        filteredColumnsCopy.pop();

        setFilteredColumns(filteredColumnsCopy)
    } else {
      setOnSale(`onSale=false`)      
      setFilteredColumns(columns)
    }
    setUpdateData(!updateData);
  }

  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Contratos Principales</span>
        <Switch checkedChildren="En venta" unCheckedChildren="Por vender" defaultChecked={false} loading={isLoading} onChange={(e) => { refreshStatus(e) }} />
      </div>
    
      <TableSG columns={filteredColumns} dataSource={data} loading={isLoading} rowKey={'id'} />
      {
        sellArticle && 
        <SellAssignedAddModal
        product={sellArticle}
          onFinish={() => {
            setSellArticle(null);
            setUpdateData(!updateData);
          }}/>
      }
    </Card>
  )
};