import { Card, Tag, Typography } from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Modal } from 'antd';
import TableSG from "../../../components/design/Table/TableSG";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { ROLE_ADMIN, ROLE_LOGISTIC, ROLE_OPERATIONS } from "../../../../common/constants/roles";
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { LogisticRequestAddModal } from "./modal/LogisticRequestAddModal";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";


export const LogisticRequests = () => {

  const [updateData, setUpdateData] = useState(false);
  const [addButtonVisible, setAddButtonVisible] = useState(false);
  
  const IsAuthorized = useIsAuthorized();

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/petitions`, {}, [updateData]);
  const { setProductDrawer } = useContext(DrawerContext)

  const columns = [    
    {
      title: 'SKU',
      key: 'productType',
      dataIndex: 'productType',
      render: (text: string, record: any) => (
        <a onClick={()=>setProductDrawer(record.productType)}>{record.productType}</a>
    ),
    },
    {
      title: 'Estado',
      key: 'status',
      dataIndex: 'status',
      render: (status: string) => {
        return <Tag>{status}</Tag>
      }
    },
    {
      title: 'Departamento',
      key: 'department',
      dataIndex: 'department'
    },
    {
      title: 'Cantidad',
      key: 'quantity',
      dataIndex: 'quantity'
    }
  ];

  return (
    <>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
        <div className="table-header">
            <span className='title-summary'>Pedidos a compras</span>
            <ButtonSG 
            disabled={!IsAuthorized([ROLE_ADMIN, ROLE_LOGISTIC.WRITE])}
            onClick={() => setAddButtonVisible(true)}>
              Nuevo Pedido
            </ButtonSG>
        </div>

        <TableSG columns={columns} dataSource={storesData} rowKey='id' loading={isLoading} />
        {
          addButtonVisible &&
          <LogisticRequestAddModal
            visible={addButtonVisible}
            onFinish={() => {
                setAddButtonVisible(false);
                setUpdateData(!updateData);
              }
            }
          />
          }

      </Card>    
    </>
  )
};