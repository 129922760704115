
import { AlertOutlined, ControlOutlined, DoubleLeftOutlined, DoubleRightOutlined, ExclamationCircleOutlined, FileDoneOutlined, FileTextOutlined, FundProjectionScreenOutlined, PercentageOutlined, ReconciliationOutlined, RiseOutlined, StockOutlined, WalletOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { ContratosMenuRoutes } from '../../../routing/sideMenu/ContratosMenuRoutes';
import { useAuth } from '../../../store/AuthContext/AuthContext';
import './sideMenu.sass';
import { useEffect, useState } from 'react';

const { Sider, Content } = AntdLayout;

export const OperationsLayout = () => {

	const location = useLocation();
  	const pathName = location.pathname;
	const { collapsed, setCollapsed } = useAuth();		
	
	const items = [
		{ label: <Link to={'/contratos/inicio'}><b>Panel</b></Link>, key: '/contratos/inicio', icon: <FundProjectionScreenOutlined className='menu2-icon' /> }, 		
		{
		  label: 'Contratos',
		  key: 'operaciones',
		  type: 'group',		  
		  children: [		
			// { label: 
			// 	<Link to={'/contratos/ventas'}>
			// 		<b>Principales</b>
			// 	</Link>, key: '/contratos/ventas', icon: <FileTextOutlined className='menu2-icon' />
			// },	
				
			{ label: 
				<Link to={'/contratos/compraUnica'}>
					<b>CCU - CCP</b>
				</Link>, key: '/contratos/compraUnica', icon: <FileTextOutlined className='menu2-icon' />
			},								
			{ label: 
				<Link to={'/contratos/libertad'}>
					<b>Libertad Total</b>
				</Link>, key: '/contratos/libertad', icon: <FileTextOutlined className='menu2-icon' />
			}
			// { label: 
			// 	<Link to={'/contratos/compraPeriodica'}>
			// 		<b>Compra Periódica</b>
			// 	</Link>, key: '/contratos/compraPeriodica', icon: <FileTextOutlined className='menu2-icon' />
			// },									
			// { label: 
			// 	<Link to={'/contratos/oportunidades'}>
			// 		<b>Oportunidades</b>
			// 	</Link>, 
			// 	key: '/contratos/oportunidades', 
			// 	icon: 
			// 		<StockOutlined className='menu2-icon'/>
			// }				
			],
		},
		{
			label: 'Configuración',
			key: 'tipos',
			type: 'group',		  
			children: [			  
			  { label: <Link to={'/contratos/sgp'}><b>Cartera</b></Link>, key: '/contratos/sgp', icon: <WalletOutlined className='menu2-icon' />},			
			  { label: <Link to={'/contratos/tipos'}><b>Tipo Contrato</b></Link>, key: '/contratos/tipos', icon: <FileDoneOutlined className='menu2-icon' />},	
			  { label: <Link to={'/contratos/negocio'}><b>Negocio</b></Link>, key: '/contratos/negocio', icon: <ControlOutlined className='menu2-icon' />}		

			  ],
		  },
	  ];

	  const [selectedKey, setSelectedKey] = useState('/contratos/inicio');

	  useEffect(() => {
		setSelectedKey(pathName);
	  }, [pathName]);
	  

	return (
		<AntdLayout className='container-menu'>			
			<Sider className='side-menu' collapsed={collapsed} collapsible={true} trigger={null}>	
				<Menu
					style={{ background: '#F9F9F9' }}
					defaultSelectedKeys={['/contratos/inicio']}					
					mode="inline"					
					items={items}
					selectedKeys={[selectedKey]}

				/>
			</Sider>
				<div className='menu-drop'>
					{collapsed?
					<DoubleRightOutlined onClick={()=>setCollapsed(false)}/>								
					:
					<DoubleLeftOutlined onClick={()=>setCollapsed(true)}/>								
					}
				</div>
			<Content className='content'>
				<div className='content-div'>
					<ContratosMenuRoutes />
				</div>
			</Content>			
		</AntdLayout>
	)
};