import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Card, Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { authFetch } from '../../../common/request/authFetch';
import ButtonSG from '../../components/design/Buttons/ButtonSG';

const RecoverPassword = () => {

	const [formError] = useState(false);
	const [form] = Form.useForm();
	const location = useLocation();
	const history = useHistory();
	const pathName = location.pathname.split('/');
	const email = pathName[pathName.length - 1];
	const verifyToken = new URLSearchParams(location.search).get('verificationToken');

	useEffect(() => {
		form.setFieldsValue({ email });
	}, []);

	const onFinishReset = async (values: any) => {

		const data = {
			...values,
			email,
			verificationToken: verifyToken
		};

		await authFetch(`${process.env.REACT_APP_API}/users/recover?verificationToken=${verifyToken}`, { method: 'PUT', data })
			.then((isOk) => {
				if (isOk) {
					message.success(`Contraseñas cambiada correctamente`);
					history.push('/', { from: location });
				} else {
					message.error(`Problema al intentar cambiar la contraseña`);
				}
			}
			);
	}

	return (

		<div className='login-general'>
			<div className='login-general-left'>
				<div className='text-position'>
					<h1>Contraseña</h1>
					<p>
						Introduzca una nueva contraseña válida
					</p>
				</div>
			</div>

			<Card bordered={true} className='login-general-right'>
				<h2>Recuperar contraseña</h2>
				<br />
				<Form
					form={form}
					layout='vertical'
					onFinish={onFinishReset}
					autoComplete='off'
				>
					<Form.Item name='email' label='Usuario'>
						<Input disabled={true} defaultValue={email} prefix={<UserOutlined />} />
					</Form.Item>

					<Form.Item label='Contraseña' name='password'
						rules={[
							{
							  required: true,
							  message: 'La contraseña es un campo obligatorio',
							},
							{
							  min: 8,
							  message: 'Debe tener al menos 8 caracteres',
							},
							{
								pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
								message: 'Debe contener letras y números',
							},
							{
							  pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
							  message: 'Debe contener mayúsculas y minúsculas',
							},
						  ]}>
						<Input.Password placeholder='Introduce la contraseña' prefix={<LockOutlined />} />
					</Form.Item>

					<Form.Item label='Confirme Contraseña' name='password2' validateTrigger='onBlur'
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: 'Debes introducir una contraseña de validación',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error('Las dos contraseñas que has introducido no coinciden'));
								},
							}),
						]}>
						<Input.Password placeholder='Introduce la contraseña de nuevo' prefix={<LockOutlined />} />
					</Form.Item>

					<ButtonSG color='black' htmlType="submit">
						Cambiar
					</ButtonSG>
				</Form>
				{
					formError && <Alert style={{ marginTop: '12px' }} type='error' message='Usuario o contraseña incorrectos' />
				}
			</Card>
		</div>

	);
}

export default RecoverPassword;