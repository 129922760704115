import { Result } from "antd";
import './welcome.sass';

const Welcome = () => {  

  return (
    <div className="welcome-text">
    <Result 
      status = "success"
      title = "¡ Bienvenido !"
      subTitle = "Has completado con éxito el registro y configuración de tu cuenta" />  
    </div>
  )
};

export default Welcome;