import {
    Card, Progress, Tag
} from "antd";
import { Link, useLocation } from "react-router-dom";
import { useContext, useState } from "react";
import { useFetch } from "../../../../../hooks/useFetch/useFetch";
import TableSG from "../../../../components/design/Table/TableSG";
import ButtonSG from "../../../../components/design/Buttons/ButtonSG";
import KpisContract from "./KpisContract";
import { EntityLabel } from "../../../../components/design/EntityLabel/EntityLabel";
import { DrawerContext } from "../../../../../store/DrawerContext/DrawerContext";
import { CaretRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { CardMetal } from "./CardMetal";
import { formatPrice } from "../../../../../common/utils/formatPrice";
import { METAL_NAMES } from "../../../../../common/constants/metalNames";

export const AdjudicateDetail = () => {

    const location = useLocation();
    const pathName = location.pathname.split('/');
    const investmentId = pathName[pathName.length - 1];

    const { setContractDrawer } = useContext(DrawerContext)

    const [updateData, setUpdateData] = useState(false);
    const [metalAdjudication, setMetalAdjudication] = useState<any>({});
    const [investmentKPIs, setInvestmentKPIs] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    
    const {data} = useFetch(`${process.env.REACT_APP_API}/investments/${investmentId}/assignations`, {}, [updateData], (data) => {
                
        setMetalAdjudication(data.metalAdjudication);        
        setInvestmentKPIs(data.mainKPIs);        
        setIsLoading(false);        
    });    

    return (
        <Card
            style={{ border: "none" }}
            bodyStyle={{ padding: "0px 10px 10px 10px" }}
        >
            <div className="table-header">
                {/* <span className='title-summary'>Asignar Lote</span>                        */}
                <div style={{display:'flex', alignItems:'center'}}>
                    <Tag style={{fontSize:'18px', padding:'3px'}}>{data.contractType}</Tag>
                    <Tag color="var(--primary)" style={{borderRadius:'8px'}}>
                        {data.investmentBuy && <> Compra {formatPrice(data?.investmentBuy?.revenue, 2)}€ de {METAL_NAMES[data?.investmentBuy?.metalCode].name}</>}
                        {/* {data.investmentSale && <> Venta {data?.investmentSale?.units} uds. de {METAL_NAMES[data?.investmentSale?.metalCode].name}</>} */}
                    </Tag>

                </div>
                <Link to={`/logistica/adjudicar`}>
                    <><b style={{color:'var(--primary)', fontSize:'17px'}}><DoubleLeftOutlined /> Volver</b></>
                </Link>
            </div>                        
            <div style={{margin:'5px 0px 20px 30px'}}>
                <a  onClick={() => setContractDrawer(investmentKPIs.contractId)}>
                    <EntityLabel prefix='Contrato' id={investmentKPIs.contractId} />
                </a>
            </div>

            <div>{ investmentKPIs && <KpisContract kpis={investmentKPIs}  investment={data.investmentBuy} id={data.id} type={data.type} /> } </div>
            { metalAdjudication && <CardMetal metalAdjudication={metalAdjudication} investmentId={investmentId} contractId={investmentKPIs.contractId} clientId={investmentKPIs.clientId} clientName={investmentKPIs.clientName} updateData={updateData} setUpdateData={setUpdateData}/>}
        </Card>
    );
};
