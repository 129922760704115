interface WeightProps {
    weightInGrams: number
    weightInOunces: number
    units: string
    children?: any
}

const WeightSG = (props: WeightProps) => {

    function formatKilos(weightInGrams: number) {
        
        let kilos = Math.floor(weightInGrams/1000)
        let grams = weightInGrams % 1000

        let ret = ""
        if (kilos !== 0)
            ret += kilos + "kg ";
        if (grams !== 0)
            ret += grams.toFixed(2) + "gr";
        return ret;
    }

    return (
        <div>
            {props.units === 'OZ' ? (
                <div>
                    <div className="select-weight">{props.weightInOunces.toFixed(2)} Oz</div>
                    <div style={{fontSize: '12px'}}>{formatKilos(props.weightInGrams)}</div>
                </div>
            ) :
                (<div>
                    <div>
                    <div className="select-weight">{formatKilos(props.weightInGrams)}</div>
                  {props.weightInOunces && <div style={{fontSize: '12px'}}>{props.weightInOunces.toFixed(2)} Oz</div>}
                    </div>
                </div>)
            }
        </div>
    )
}


export default WeightSG