import { Form, InputNumber } from "antd"

type TInputPriceProps = {
    label: string,
    nameInput: string | [],
    disabled: boolean
}
export const InputPrice = ({ label, nameInput, disabled }: TInputPriceProps) => {

    return (
        <Form.Item label={label} name={nameInput} rules={[{ required: true }]}>
            <InputNumber style={{ width: '100%' }} controls={false} addonAfter="€" min={0} step={0.1} disabled={disabled} />
        </Form.Item>
    )
}