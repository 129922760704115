import { useEffect, useState } from 'react';
import { Alert, Card, Col, message, Row, Table } from "antd";
import { Column } from '@ant-design/plots';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../common/request/authRequest';
import { TSummaryAlerts } from '../../../../types/summaryAlerts';
import { SpinSG } from '../../../components/model/Spin/SpinSG';
import { formatPrice } from '../../../../common/utils/formatPrice';


export const CommercialSummary = () => {
        
    const [usersByMonthData, setUsersByMonthData] = useState([]);    
    const [usersLastWeek, setUsersLastWeek] = useState([]);    
    const [allTime, setAllTime] = useState<any>();
    const [alerts, setAlerts] = useState<TSummaryAlerts[]>();
    const [loading, setLoading] = useState(true);
    
    const columns : any= [       
        {
            title: 'Comercial',
            key: 'commercialName',
            dataIndex: 'commercialName',
            render: (value: any) => {
                return <>
                  <b>{value}</b>
                </>
              }
        },
        {
            title: 'Últimos 12 meses',
            key: 'contracts12MonthsRevenue',            
            align: 'center',
            render: (record: any) => {
                return record.contracts12MonthsNumber > 0? 
                <>                  
                  <div>{formatPrice(record.contracts12MonthsRevenue)} €</div>
                  <small>{record.contracts12MonthsNumber} contratos</small>
                </>
                :
                <>-</>                
              }
        },
        {
            title: 'Últimos 3 meses',
            key: 'contracts3MonthsRevenue',            
            align: 'center',
            render: (record: any) => {
                return record.contracts3MonthsNumber > 0? 
                <>                  
                  <div>{formatPrice(record.contracts3MonthsRevenue)} €</div>
                  <small>{record.contracts3MonthsNumber} contratos</small>
                </>
                :
                <>-</>
              }
        },
        {
            title: 'Último Mes',
            key: 'contracts1MonthsRevenue',            
            align: 'center',
            render: (record: any) => {
                return record.contracts1MonthsNumber > 0? 
                <>                  
                  <div>{formatPrice(record.contracts1MonthsRevenue)} €</div>
                  <small>{record.contracts1MonthsNumber} contratos</small>
                </>
                :
                <>-</>               
              }
        }
    ]
    const { logOut } = useAuth();


    useEffect(() => {
        authRequest(`${process.env.REACT_APP_API}/summary/client`, {})
          .then((response) => {
                setLoading(false);
                setUsersByMonthData(response.monthly.adminMonthlyCharPoints);
                setAllTime(response.allTime);
                setUsersLastWeek(response.lastWeek.notes); 
                setAlerts(response.alerts)           
            })
          .catch((error) => {
            setLoading(false);
            if (error && error.status === 401) {
                message.info('La sesión ha caducado');
                logOut()
              } else {
                throw error.response;
              }
          });
      }, [])

      const config : any = {
        xField: 'x',
        yField: 'value',
        height: 250,
        color: "#58C09C",
        maxColumnWidth: 30,
        isStack: true,
        label: {
          position: 'middle',
          style: {
            fill: '#fffbe6',
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          value: {
            alias: 'Contratos',
          }          
        }
      };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
                <div className="table-header">
                    <span className='title-summary'>Panel Comercial</span>
                </div>
            </Card> 
            
            {loading ? <SpinSG/> : 
            <><Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>TOTALES</div>
                            <div className='title-chart-secondary'>Contratos por Comercial</div>
                            <div>
                              {allTime && allTime.commercialsInfo &&
                                <Table style={{ paddingLeft: '30px'}} scroll={{ y: '40vh' }} pagination={false} columns={columns} dataSource={allTime.commercialsInfo} rowKey={'commercialName'} loading={false} />}
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>ALERTAS</div>
                            <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                                { alerts && alerts.length > 0 ? 
                                    alerts.map((alert: any) => {
                                        return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                    })
                                    :
                                    <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={16}>                        
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                            <div className='title-chart'>HISTÓRICO</div>
                            <div className='title-chart-secondary'>Contratos por mes</div>
                            {usersByMonthData && <Column data={usersByMonthData} {...config} />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }} bodyStyle={{ marginTop: "5px" }}>
                            <div className='title-chart'>ÚLTIMA SEMANA</div>
                            {
                                usersLastWeek && usersLastWeek.length > 0 ?
                                usersLastWeek.map(comment => {
                                    return (<div style={{ paddingLeft: '30px' }}>{comment}</div>);
                                })
                                :
                                <>Sin novedad</>
                            }
                        </Card>
                    </Col>
                </Row>
            </>}
        </div>
    )
}