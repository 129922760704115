import { useState, createContext } from 'react';

export const GlobalVariableContext = createContext<{
    updateUserData: boolean, 
    updateConsultantData:boolean,
    updateWordPressOrderData:boolean,
    updateAdjucateData:boolean,
    setUpdateUserData: React.Dispatch<React.SetStateAction<boolean>>,
    setUpdateConsultantData: React.Dispatch<React.SetStateAction<boolean>>,
    setUpdateWordPressOrderData: React.Dispatch<React.SetStateAction<boolean>>,
    setUpdateAdjudicateData:React.Dispatch<React.SetStateAction<boolean>>

}>({

    updateUserData: false,
    updateConsultantData:false,
    updateWordPressOrderData:false,
    updateAdjucateData:false,
    setUpdateUserData:() => {},
    setUpdateConsultantData:() => {},
    setUpdateWordPressOrderData:() => {},
    setUpdateAdjudicateData:() => {}
})

const GlobalVariableProvider: React.FC = ({ children }) => {

    const [updateUserData, setUpdateUserData] = useState(false);
    const [updateConsultantData, setUpdateConsultantData] = useState(false);
    const [updateWordPressOrderData, setUpdateWordPressOrderData] = useState(false);
    const [updateAdjucateData, setUpdateAdjudicateData] = useState(false);

  
    return (
        <GlobalVariableContext.Provider value={{updateUserData ,setUpdateUserData, updateAdjucateData, setUpdateAdjudicateData, updateConsultantData, setUpdateConsultantData, updateWordPressOrderData, setUpdateWordPressOrderData }}>
            {children}
        </GlobalVariableContext.Provider>
    );
};

export default GlobalVariableProvider;

