import { CheckOutlined, DoubleRightOutlined, FileDoneOutlined, ForwardOutlined, LockOutlined, LockTwoTone, SwapOutlined } from "@ant-design/icons";
import {
    Alert,
    Card,
    Modal,
    Progress,
    Tag,
    Tooltip,
    Switch
} from "antd";
import moment from "moment";
import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { ROLE_ADMIN, ROLE_LOGISTIC } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import TableSG from "../../../components/design/Table/TableSG";
import { EntityLabel } from "../../../components/design/EntityLabel/EntityLabel";
import { INVESTMENT_TYPE } from "../../../../common/constants/investmentType";
import { formatPrice } from "../../../../common/utils/formatPrice";
import { METAL_NAMES } from "../../../../common/constants/metalNames";

export const Marketplace = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const completedParam = searchParams.get('completed')

    const [investmentStatus, setInvestmentStatus] = useState<string>("status=PENDING");
    const [completed, setCompleted] = useState(false)

    const { data: storesData, isLoading } = useFetch(
        `${process.env.REACT_APP_API}/investments/actions/adjudicate?${investmentStatus}`,
        {},
        []
    );

    const { setContractDrawer } = useContext(DrawerContext)

    const columns: any = [
        {
            title: 'Operación',
            dataIndex: 'type',
            align: 'center',
            render: (_: any, record: any) => {
                return <div className="item-view-product">

                    {moment(record.start).isBefore(moment.now()) &&
                        INVESTMENT_TYPE[record.type] && (
                            <>
                                <Tag 
                                color={INVESTMENT_TYPE[record.type].color}
                                style={{ borderRadius: '8px' }}>
                                    {record.type === 'BUY' && <>{INVESTMENT_TYPE[record.type].name} {formatPrice(record.revenue, 2)}€ de {METAL_NAMES[record.investmentBuy.metalCode].name}</>}
                                    {record.type === 'SALE' && <> {INVESTMENT_TYPE[record.type].name} {record.units} uds. de {METAL_NAMES[record.investmentSale.metalCode].name}</>}
                                </Tag>
                                <div><small>{moment(record.start).format('ll')}</small></div>
                            </>
                        )}
                </div>
            }
        },
        {
            title: "Tipo",
            key: "contractType",
            align: 'center',
            render: (_: any, record: any) => {
                return (
                    <>
                        <Tag>
                            {record.contractType}
                        </Tag>
                    </>
                )
            }
        },
        {
            title: 'Completado',
            dataIndex: 'percentageCompleted',
            align: 'center',
            render: (_: any, record: any) => {
                return <div>
                    <Progress strokeColor='var(--primary)' type="circle" percent={(record.percentageCompleted).toFixed(0)} width={40} />
                </div>
            }
        },
        {
            title: "Precio", key: "revenue", align: 'center',
            sorter: (a: any, b: any) => a.revenue - b.revenue,
            render: (record: any) => {
                return <>
                    {record.investmentBuy && <Tag><p>{record.investmentBuy.priceQuotation.toFixed(2)}€ / Onza + {(100 * record.investmentBuy.priceOverCost).toFixed(0)}%</p></Tag>}
                    {record.investmentSale && <Tag><p>{record.investmentSale.priceQuotation.toFixed(2)}€ / Onza + {(100 * record.investmentSale.priceOverCost).toFixed(0)}%</p></Tag>}
                </>
            }
        },
        {
            title: 'Contrato',
            key: 'id',
            render: (id: string, record: any) => {
                return <>
                    <a onClick={() => setContractDrawer(record.contractId)}>
                        <EntityLabel prefix='Contrato' id={record.contractId} />
                    </a>
                    <small>{record.clientName}</small>
                </>
            }
        },
        {
            title: '',
            align: 'center',
            render: (record: any) => {

                const url = record.type === 'BUY' ? `adjudicarcompra` : `adjudicarventa`;

                return (
                    <AuthRoles roles={[ROLE_ADMIN, ROLE_LOGISTIC.WRITE]}>
                        <>
                            {
                                <div style={{ padding: '5px 0px' }}>
                                    <Link to={`/logistica/${url}/${record.id}`}>
                                        <><b style={{ color: 'var(--primary)' }}>Completar <DoubleRightOutlined /></b></>
                                    </Link>
                                </div>}
                        </>
                    </AuthRoles>
                )
            }
        }
    ];

    const [filteredColumns, setFilteredColumns] = useState<any>(columns);

    
    useEffect(()=>{
        if(completedParam === 'true'){
            dataStatusDone(true)
        }
    },[])

    function dataStatusDone(isHistoricSelected: boolean) {
        if (isHistoricSelected) {
            setInvestmentStatus(`status=DONE`)
            const filteredColumnsCopy = [...columns];
            filteredColumnsCopy.pop();
            setFilteredColumns(filteredColumnsCopy)
            setCompleted(true)

        } else {
            setInvestmentStatus(`status=PENDING`)
            setFilteredColumns(columns)
            setCompleted(false)
        }
    }

    return (
        <Card
            style={{ border: "none" }}
            bodyStyle={{ padding: "0px 10px 10px 10px" }}>

            <div className="table-header">
                <span className='title-summary'>Marketplace</span>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <p className='pl-20 desc'>Completado</p>
                    <Switch loading={isLoading} checked={completed} onChange={(e) => { dataStatusDone(e) }} />
                </div>
            </div>

            <TableSG
                columns={filteredColumns}
                dataSource={storesData}
                size="middle"
                loading={isLoading}
                rowKey="id"
            />
        </Card>
    );
};