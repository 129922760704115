import { Card, Input, Modal, Switch, Tag } from "antd";
import { ForwardOutlined, SearchOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";

import { useFetch } from "../../../../hooks/useFetch/useFetch";
import TableSG from "../../../components/design/Table/TableSG";
import { useState, useContext } from 'react'
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { customRequest } from "../../../../common/request/customRequest";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { PriceTag } from "../../../components/design/PriceTag/PriceTag";
import { defaultFormat } from "../../../../common/utils/formatDate";
import { COMPLETED } from "../../../../common/constants/transcationStatus";
import { ROLE_ACCOUNTING, ROLE_ADMIN } from "../../../../common/constants/roles";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { EntityLabel, formattedId } from "../../../components/design/EntityLabel/EntityLabel";
import { DocumentsModal } from "../../common/UploadDocuments/DocumentsModal";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";


const statusNew = 'status=NEW'
const statusCompleted = 'status=COMPLETED'

export const Transactions = () => {

  const IsAuthorized = useIsAuthorized();

  const [statusFilter, setStatusFilter] = useState(statusNew);
  const { setProductDrawer } = useContext(DrawerContext)
  const [updateData, setUpdateData] = useState(false);
  const {setContractDrawer, setArticleDrawer, setWordPressOrderDrawer} = useContext(DrawerContext)
  
  const [documentsModal, setDocumentsModal] = useState(null);

  const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/transactions?${statusFilter}`, {}, [updateData]);


  const transactionStatus = async (id: string, value: string) => {

    await customRequest(`${process.env.REACT_APP_API}/transactions/${id}/?masks=STATUS`, {
      method: 'PATCH',
      data: { status: value }
    }).then(() => setUpdateData(!updateData))
  }

  function dataStatusCompleted(isHistoricSelected: boolean) {
    if (isHistoricSelected) {
      setStatusFilter(statusCompleted)
    } else {
      setStatusFilter(statusNew)
    }
  }

  const columns = [

    {
      title: 'Movimiento',
      dataIndex: 'originOwner',
      key: 'originOwner',
      render: (_: any, record: any) => {
        return <div style={{ display: 'flex', justifyContent: 'left' ,alignItems: 'center' }}>
        
            <span>
              {
              record.sellerContractId ? (
                  <>
                      <a onClick={() => setContractDrawer(record.sellerContractId)}>
                      <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.sellerContractId} />
                      </a>
                      <small>{record.sellerClientName}</small>
                  </>
              ) :
                record.sellerClientId ?
                  <>
                      <a onClick={() => setWordPressOrderDrawer({ id: record.sellerWordPressOrderId, webName: record.sellerWordPressOrderWebName })}>
                      <EntityLabel style={{ display: 'inline' }} prefix='WordPress' id={record.sellerWordPressOrderId} />
                      </a>
                      <small>{record.sellerClientName}</small>
                  </>
                  :
                  record.sellerOwner
              }
          </span>
          <ForwardOutlined style={{ margin: '0 10px' }} />
          <span>
              {
              record.buyerContractId ? (
                  <>
                      <a onClick={() => setContractDrawer(record.buyerContractId)}>
                      <EntityLabel style={{ display: 'inline' }} prefix='Contrato' id={record.buyerContractId} />
                      </a>
                      <small>{record.sellerClientName}</small>
                  </>
              ) :
                record.buyerClientId ?
                  <>
                      <a onClick={() => setWordPressOrderDrawer({ id: record.buyerWordPressOrderId, webName: record.buyerWordPressOrderWebName })}>
                      <EntityLabel style={{ display: 'inline' }} prefix='WordPress' id={record.buyerWordPressOrderId} />
                      </a>
                      <small>{record.buyerClientName}</small>
                  </>
                  :
                  record.buyerOwner
              }
          </span>          
        </div>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Origen"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.sellerOwner.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Producto',
      width: '15%',
      render: (_: any, record: any) => (
        <>
          <div>{record.productTypeName}</div>
          <a key={record.sku} onClick={() => setProductDrawer(record.sku)}>
            <small>{record.sku}</small>
          </a>
        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Nombre o sku"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.productTypeName.toLowerCase().includes(value.toLowerCase()) ||
        record?.sku.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Artículo',
      width: '12%',
      render: (_: any, record: any) => (
        <>
          <div>
            <a  onClick={()=>setArticleDrawer(record.articleId)}><EntityLabel color={false} prefix="Artículo" id={record.articleId}/></a>
            <small>Barcode #{record.articleBarCode}</small>            
          </div>

        </>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Id o Código de barras"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        const searchText = value.toLowerCase();
        const lastFiveDigits = formattedId(record.articleId).toLowerCase();
        const articleBarcode = record.articleBarCode.toLowerCase();
      
        return (
          lastFiveDigits.includes(searchText) ||
          (`Artículo #${lastFiveDigits}`.toLowerCase().includes(searchText)) ||
          articleBarcode.includes(searchText) ||
          (`#${articleBarcode}`.includes(searchText))
        );
      },
    },
    {
      title: 'Facturas',
      align: 'center',
      width:'15%',
      render: (record: any) => {
        return (
          <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
              <div  style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
            {record.documents !== null ? record.documents.map((d: any) => (
              <div key={d.name}><small style={{display:'flex', alignItems:'center', gap:'3px'}}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
            )): <small>Sin documentos</small>}</div>
            <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
            <PlusOutlined />
            </ButtonSG>
          </div>
        );
      },
      sorter: (a:any, b:any) => {
        if (a.documents !== null && b.documents === null) {
          return -1;
        }
        if (a.documents === null && b.documents !== null) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'Estado',
      dataIndex: 'statusDescription',
      key: 'statusDescription',
      render:(statusDescription:string) => {
        return <Tag>{statusDescription}</Tag>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Estado"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record?.statusDescription.toLowerCase().includes(value.toLowerCase()) 
      },
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      width: '15%',
      key: 'date',
      render: (_: any, record: any) => {
        return <div style={{ display: 'flex' }}>
          <p>{defaultFormat(record.date)}</p>
        </div>
      },
      sorter: (a: any, b: any) => {
        const dateA = new Date(a.date.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '20$3-$2-$1'));
        const dateB = new Date(b.date.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '20$3-$2-$1'));
        return dateA.getTime() - dateB.getTime();
      }
    },
    {
      title: 'Precio Venta',
      dataIndex: 'cost',
      key: 'cost',
      render: (cost: number) => (
        <PriceTag price={cost} />

      ),
      sorter: (a: any, b: any) => b.cost - a.cost

    },
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
  
      render: (_: any, record: any) => {
        return <div style={{ display: 'flex' }}>
          {record.status !== COMPLETED &&
          <ButtonSG size='small'
            onClick={
              () => {
                Modal.confirm({
                  title: '¿Confirmar el final de esta transacción?',
                  content: 'La transacción compra-venta será completada',
                  okText: 'Confirmar',
                  cancelText: 'Cancelar',
                  onOk() { transactionStatus(record.id, COMPLETED) }
                });
              }
            }
          disabled={!IsAuthorized([ROLE_ADMIN, ROLE_ACCOUNTING.WRITE])}
          >Tramitado</ButtonSG>
      }
        </div>
      }
      
    },
  ];

  return (

    <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
      <div className="table-header">
        <span className='title-summary'>Facturación</span>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ display: 'flex', gap: '10px' }}>
          <p className='pl-20 desc'>Ver histórico</p>
          <Switch loading={isLoading} onChange={(e) => { dataStatusCompleted(e) }} />
        </div>
      </div>

      <TableSG columns={columns} dataSource={storesData} loading={isLoading} rowKey="id" />

      {
        documentsModal && <DocumentsModal entity='TRANSACTIONS' title='Transacciones' tabs={[{key:'1', label:'Facturas', type:'pdf', tabName:'INVOICES'}]} onFinish={() => {setDocumentsModal(null); setUpdateData(!updateData);}} entityId={documentsModal}/>   
      }
    </Card>
  )
}