import { useEffect, useState } from 'react';
import { Alert, Card, Col, message, Row, Table } from "antd";
import { Column } from '@ant-design/plots';
import { useAuth } from '../../../../store/AuthContext/AuthContext';
import { authRequest } from '../../../../common/request/authRequest';
import { TSummaryAlerts } from '../../../../types/summaryAlerts';
import { SpinSG } from '../../../components/model/Spin/SpinSG';


export const OperationsSummary = () => {
        
    const [allTime, setAllTime] = useState<any>();
    const [alerts, setAlerts] = useState<TSummaryAlerts[]>();
    const [notes, setNotes] = useState([]);
    const [unitsByMonthData, setUnitsByMonthData] = useState([]);    
    const [loading, setLoading] = useState(true);
    
    const columns : any = [       
        {
            title: '',
            key: 'metal',
            align: 'center',
            dataIndex: 'metal'
        },
        {
            title: 'Por vender',
            key: 'toSaleUnits',
            align: 'center',
            dataIndex: 'toSaleUnits',
            render: (value: any) => {
              return <>
                <div>{value? value: '-'}</div>
              </>
            }
        },
        {
            title: 'En Venta',
            key: 'onSaleUnits',
            align: 'center',
            dataIndex: 'onSaleUnits',
            render: (value: any) => {
              return <>
                <div>{value? value: '-'}</div>
              </>
            }
        }
    ]
    const { logOut } = useAuth();

    useEffect(() => {
        authRequest(`${process.env.REACT_APP_API}/summary/operations`, {})
          .then((response) => {
                setLoading(false);                
                setAllTime(response.allTime);                
                setAlerts(response.alerts);           
                setNotes(response.notes); 
                setUnitsByMonthData(response.unitsByMonth.chartPoints);
            })
          .catch((error) => {
            setLoading(false);
            if (error && error.status === 401) {
                message.info('La sesión ha caducado');
                logOut()
              } else {
                throw error.response;
              }
          });
      }, [])

      const config : any = {
        xField: 'x',
        yField: 'value',
        height: 250,
        color: "#58C09C",
        maxColumnWidth: 30,
        isStack: true,
        label: {
          position: 'middle',
          style: {
            fill: '#fffbe6',
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          value: {
            alias: 'Lingotes',
          }          
        }
      };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
                <div className="table-header">
                    <span className='title-summary'>Panel de Operaciones</span>
                </div>
            </Card> 
            
            {loading ? <SpinSG/> : 
            <>
                <Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>TOTALES </div>
                            <div className='title-chart-secondary'>Lingotes asignados por vender</div>
                            {allTime &&
                                <Table size='small' style={{ paddingLeft: '30px', width: '70%' }} showHeader={true} pagination={false} columns={columns} dataSource={allTime} rowKey={'metal'} loading={false} />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>ALERTAS</div>
                            { alerts && alerts.length > 0 ? 
                                alerts.map((alert: any) => {
                                return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;})
                                :
                                <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                            }
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={16}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                            <div className='title-chart'>HISTÓRICO</div>
                            <div className='title-chart-secondary'>Lingotes asignados por mes</div>
                            {unitsByMonthData && <Column data={unitsByMonthData} {...config} />}
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "10px 5px" }}>
                            <div className='title-chart'>ÚLTIMA SEMANA</div>
                            {notes.length > 0 && notes.map(sells => {
                            return (<div>{sells}</div>);
                            })}
                        </Card>
                    </Col>
                </Row>
            </>
            }    
        </div>
    )
}