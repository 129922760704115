import { LineChartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { MarketRatesDrawer } from '../../../pages/common/sideMenu/MarketRatesDrawer';
import './bot.sass';

const Bot = ({ style }: any) => {

  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
 
  return (
    <>
      {
        <div style={{ position: "fixed", right:0, bottom:0, cursor:"pointer",margin:"2em .5em" }} >
          <Button type="link">
            <LineChartOutlined  
              className='botDrawer'
              onClick={() => setSuggestionsVisible(true)} />
          </Button>
        </div>
      }
      {
        suggestionsVisible && (
          <MarketRatesDrawer suggestionsVisible={suggestionsVisible} setSuggestionsVisible={setSuggestionsVisible}/>
        )
      }
    </>
  )
}

export default Bot;