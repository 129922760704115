import { Card, Timeline } from 'antd';
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import DrawerSG from '../../design/Drawer/DrawerSG';
import './logsDrawer.sass'


export default function ArticleLogDrawer(props: {
    visible: boolean,
    onClose: any,
    nameLog?: string,
    id?: string,
}) {

    const { data: storesData } = useFetch(
        `${process.env.REACT_APP_API}/logs/${props.id}/articleLogs`,
        {},
        [],
    );


    function formatDate(props: string) {
        var options: any = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(props).toLocaleDateString([], options);
    }

    const listItems = storesData.map((d: any) =>
        <Timeline.Item key={d.created}>
            <Card size="small" title={formatDate(d.created)} style={{ borderRadius: "10px" }}>
                <p style={{ fontStyle: "italic", padding: "5px 0" }}>{d.msg}</p>
                <div style={{ marginLeft: "5px" }}>
                    <p><strong>Propietario: </strong>{d.owner}</p>
                    <p><strong>Almacén: </strong>{d.store}</p>
                    <p><strong>Coste: </strong>{d.cost} €</p>
                </div>
            </Card>
        </Timeline.Item>
    );


    return (
        <>
            <DrawerSG title={'Logs ' + props.nameLog} visible={props.visible} onClose={props.onClose} placement='right' width={500}>
                <Timeline
                >
                    {listItems}
                </Timeline>

            </DrawerSG>
        </>
    );
}