import { Result } from "antd";

const Error404 = () => {

  return (
    <Result
    status="404"
    title="404"
    subTitle="Lo sentimos, la página a la que intentas acceder no se encuentra. Contacta con su operador"    
  />)
}

export default Error404;