import { useEffect, useState } from 'react';
import { Alert, Card, Col, Input, message, Row } from "antd";
import TableSG from '../../../components/design/Table/TableSG';
import { FilterDropdownProps } from '../../../../types/filterDropDown';
import { SearchOutlined } from '@ant-design/icons';
import { TSummaryAlerts } from '../../../../types/summaryAlerts';
import { Column, DualAxes } from '@ant-design/charts';
import { SpinSG } from '../../../components/model/Spin/SpinSG';
import { useFetch } from '../../../../hooks/useFetch/useFetch';

export const EcommerceSummary = () => {

  const [sellsByMonthData, setSellsByMonthData] = useState([]);
  const [costByMonthData, setCostByMonthData] = useState([]);
  const [revenueByMonthData, setRevenueByMonthData] = useState([]);
  const [sellsLastWeek, setSellsLastWeek] = useState([]);
  const [allTime, setAllTime] = useState<any>();
  const [alerts, setAlerts] = useState<TSummaryAlerts[]>();


  const { data: response, isLoading } = useFetch(`${process.env.REACT_APP_API}/summary/ecommerce`, {}, []);

  useEffect(() => {
    if (response || response.length > 0) {
      setAllTime(response?.ecommerceTotals);
      setSellsLastWeek(response?.weekly?.notes);
      setAlerts(response?.ecommerceAlerts);
      setSellsByMonthData(response?.monthly?.monthlyChartPointsPrice);
      setCostByMonthData(response?.cost);
      setRevenueByMonthData(response?.monthly?.monthlyChartPointsRevenue)
    }
  }, [response])


  const columns: any = [
    {
      title: 'Nombre',
      key: 'webName',
      dataIndex: 'webName',
      render: (value: any) => {
        return <>
          <b>{value}</b>
        </>
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="Dominio"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: any) => {
        return record.webName.toLowerCase().includes(value.toLowerCase());
      },
    },
    {
      title: 'Productos WP',
      key: 'totalProducts',
      dataIndex: 'totalProducts',
      align: 'center',
      render: (value: any) => {
        return <>
          <div>{value}</div>
        </>
      }
    },

  ]



  // const config = {
  //   data: [sellsByMonthData],
  //   xField: 'x',
  //   yField: ['value'],
  //   geometryOptions: [
  //     {
  //       geometry: 'column',
  //       isGroup: true,
  //       seriesField: 'category',
  //     },
      // {
      //   geometry: 'line',
      //   lineStyle: {
      //     lineWidth: 2,
      //   },
      // },
    // ],
    // meta: {
    //   value: { alias: 'Beneficio %' },
    // },
  // };

  const config = {
    data:sellsByMonthData,
    isGroup: true,
    xField: 'x',
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      label: {
        // autoHide: true,
        autoRotate: true,
      },
    },
  };

  const configCost = {
    data:costByMonthData,
    xField: 'x',
    yField: 'value',
    color:'#f4664a',
    xAxis: {
      label: {
        // autoHide: true,
        autoRotate: true,
      },
    },
    
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
        <div className="table-header">
          <span className='title-summary'>Panel e-Commerce</span>
        </div>
      </Card>

      {isLoading ? <SpinSG /> :
        <><Row gutter={[8, 8]} align="top">
          <Col span={12}>
            <Card style={{ border: 'none' }}>
              <div className='title-chart'>TOTALES </div>
              <div style={{ width: '500px' }}>
                {allTime && allTime.ecommerceTotalsInfoList &&
                  <TableSG style={{ paddingTop: '0px' }} showHeader={true} pagination={false} columns={columns} dataSource={allTime.ecommerceTotalsInfoList} rowKey={'webName'} loading={false} />}
              </div>
            </Card>
          </Col>

          <Col span={10}>
            <Card style={{ border: 'none' }}>
                <div className='title-chart'>ALERTAS</div>
                <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                    { alerts && alerts.length > 0 ? 
                        alerts.map((alert: any) => {
                            return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                        })
                        :
                        <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                    }
                </div>
            </Card>
          </Col>
        </Row><Col span={8}>
            <div style={{ paddingLeft: '24px' }}>
              <span className='title-chart'>HISTÓRICO</span>
            </div>
          </Col><Row gutter={[8, 8]}>
            <Col span={12}>
              <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                <div className='title-chart-secondary'>Facturación por mes </div>
                {/* <DualAxes {...config} height={150} /> */}
                <Column {...config} height={200} />
              </Card>

              <Card style={{ border: 'none' }} bodyStyle={{ margin: "5px" }}>
                <div className='title-chart-secondary'>Coste por mes </div>
                <Column {...configCost} height={200} />
              </Card>
            </Col>
            <Col span={10}>
              <Card style={{ border: 'none' }} bodyStyle={{ margin: "10px 5px" }}>
                <div className='title-chart'>ÚLTIMA SEMANA</div>
                {sellsLastWeek.length > 0 && sellsLastWeek.map(sells => {
                  return (<div>{sells}</div>);
                })}
              </Card>
            </Col>
          </Row></>
}

    </div>
  )
}