import { SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { ROLE_ACCOUNTING, ROLE_ADMIN, ROLE_APP_BACKOFFICE, ROLE_CLIENTS, ROLE_ECOMMERCE, ROLE_LOGISTIC, ROLE_OPERATIONS, ROLE_PURCHASES, ROLE_TECHNOLOGY, ROLE_USERS } from '../../../common/constants/roles';
import './mainMenu.sass';
import { useIsAuthorized } from '../../../common/request/AuthorizationUtils';

const MainMenu = () => {
	const IsAuthorized = useIsAuthorized();

	return (
		<Menu
			disabledOverflow={true}
			className='menu-container-header'
			mode='horizontal'>
			{
				[
					<Menu.Item key={'0'} style={{ display: !IsAuthorized([ROLE_CLIENTS.READ, ROLE_CLIENTS.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/client/inicio'}>
							{'Comercial'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'1'} style={{ display: !IsAuthorized([ROLE_OPERATIONS.READ, ROLE_OPERATIONS.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/contratos/inicio'}>
							{'Operaciones'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'2'} style={{ display: !IsAuthorized([ROLE_ECOMMERCE.READ, ROLE_ECOMMERCE.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/ecommerce/inicio'} >
							{'E-Commerce'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'3'} style={{ display: !IsAuthorized([ROLE_LOGISTIC.READ, ROLE_LOGISTIC.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/logistica/inicio'}>
							{'Logística'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'4'} style={{ display: !IsAuthorized([ROLE_PURCHASES.READ, ROLE_PURCHASES.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/compras/inicio'} >
							{'Compras'}
						</Link>
					</Menu.Item>,					
					<Menu.Item key={'5'} style={{ display: !IsAuthorized([ROLE_ACCOUNTING.READ, ROLE_ACCOUNTING.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/contabilidad/inicio'} >
							{'Contabilidad'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'6'} style={{ display: !IsAuthorized([ROLE_APP_BACKOFFICE.READ, ROLE_APP_BACKOFFICE.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/app/inicio'} >
							{'APP'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'7'} style={{ display: !IsAuthorized([ROLE_USERS.READ, ROLE_USERS.WRITE, ROLE_ADMIN]) ? 'none' : undefined }}>						
						<Link to={'/admin/inicio'} >
							{'Usuarios'}
						</Link>
					</Menu.Item>,
					<Menu.Item key={'8'} style={{ display: !IsAuthorized([ROLE_TECHNOLOGY.WRITE]) ? 'none' : undefined }}>						
						<Link to={'/tecnologia/inicio'} >
							{'Tecnología'}
						</Link>						
					</Menu.Item>
				]
			}
		</Menu>
	)
};

export default MainMenu;