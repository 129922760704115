import { useState, createContext } from 'react';

export const DrawerContext = createContext<{
    clientDrawer: any, 
    productDrawer: any,
    contractDrawer: any,
    orderDrawer:any,
    userDrawer:any,
    articleDrawer:any,
    investmentDrawer:any,
    wordPressOrderDrawer: any,
    wordPressClientDrawer: any,
    setClientDrawer: React.Dispatch<React.SetStateAction<any>>,
    setProductDrawer: React.Dispatch<React.SetStateAction<any>>,
    setContractDrawer: React.Dispatch<React.SetStateAction<any>>,
    setOrderDrawer:React.Dispatch<React.SetStateAction<any>>
    setUserDrawer:React.Dispatch<React.SetStateAction<any>>
    setArticleDrawer:React.Dispatch<React.SetStateAction<any>>
    setInvestmentDrawer:React.Dispatch<React.SetStateAction<any>>
    setWordPressOrderDrawer:React.Dispatch<React.SetStateAction<any>>
    setWordPressClientDrawer:React.Dispatch<React.SetStateAction<any>>
}>({

    clientDrawer: null, 
    productDrawer: null,
    contractDrawer:null,
    orderDrawer:null,
    userDrawer:null,
    articleDrawer:null,
    investmentDrawer:null,
    wordPressOrderDrawer:null,
    wordPressClientDrawer:null,
    setClientDrawer: () => {},
    setProductDrawer: () => {},
    setContractDrawer: () => {},
    setOrderDrawer:() => {},
    setUserDrawer:() => {},
    setArticleDrawer:() => {},
    setInvestmentDrawer:()=>{},
    setWordPressOrderDrawer:()=>{},
    setWordPressClientDrawer:()=>{}
})
const DrawerProvider: React.FC = ({ children }) => {

    const [clientDrawer, setClientDrawer] = useState(null);
    const [productDrawer, setProductDrawer] = useState(null);
    const [contractDrawer, setContractDrawer] = useState(null);
    const [orderDrawer, setOrderDrawer] = useState(null);
    const [userDrawer, setUserDrawer] = useState(null);
    const [articleDrawer, setArticleDrawer] = useState(null);
    const [investmentDrawer, setInvestmentDrawer] = useState(null);
    const [wordPressOrderDrawer, setWordPressOrderDrawer] = useState({id:null, webName:null});
    const [wordPressClientDrawer, setWordPressClientDrawer] = useState({id:null, webName:null});

  
    return (
        <DrawerContext.Provider value={{clientDrawer, setClientDrawer, productDrawer, setProductDrawer, contractDrawer, setContractDrawer, orderDrawer, setOrderDrawer, userDrawer, setUserDrawer, articleDrawer, setArticleDrawer, investmentDrawer, setInvestmentDrawer, wordPressOrderDrawer, setWordPressOrderDrawer, wordPressClientDrawer, setWordPressClientDrawer  }}>
            {children}
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;

