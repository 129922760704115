import { Result } from "antd";

const Error403 = () => {

  return (
    <Result
    status="403"
    title="403"
    subTitle="Acceso denegado, estás intentando acceder a un recurso al que no tienes permisos."    
  />)
}

export default Error403;