import { Card } from "antd"
import ButtonSG from "../../components/design/Buttons/ButtonSG"
import { useState } from 'react'
import { CreateProductModal } from "./modal/CreateProductModal"
import { useFetch } from "../../../hooks/useFetch/useFetch"
import { CreateOfferModal } from "./modal/CreateOfferModal"
import { ROLE_ADMIN, ROLE_APP_BACKOFFICE } from "../../../common/constants/roles"
import { useIsAuthorized } from "../../../common/request/AuthorizationUtils"
import TableSG from "../../components/design/Table/TableSG"

export const AppProducts = () => {

    const IsAuthorized = useIsAuthorized();


    const [openModal, setOpenModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [createOffer, setCreateOffer] = useState(null);

    const {data, isLoading} = useFetch(`${process.env.REACT_APP_API}/AppProducts`, {}, [updateData]);

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Pdf',
            dataIndex: 'url',
            key: 'url',
            render: (url: any) => {
                return <a target='blank' href={url}>{url}</a>
            }
        },
        {
            title: 'Ofertas Activas',
            dataIndex: 'titleOffers',
            key: 'titleOffers',
            render: (offers: any) => {
                return offers.map((title:string)=>{
                return <div><small>{title}</small></div>
                })
            }
        },
        {
            title: '',
            render: (record: any) => {
                return <ButtonSG size='small' disabled={!IsAuthorized([ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE])} onClick={()=>{setCreateOffer(record.id)}}>Editar oferta</ButtonSG>
            }
        },
    ];

    return (
        <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
            <div className="table-header">
                <span className='title-summary'>Productos - APP
            </span>

                <ButtonSG onClick={() => { setOpenModal(true) }}
                disabled={!IsAuthorized([ROLE_ADMIN, ROLE_APP_BACKOFFICE.WRITE])}
                >
                    Crear Producto
                </ButtonSG>


            </div>

            <TableSG
                size='middle'
                columns={columns}
                dataSource={data}
                loading={isLoading}
            />

            {openModal && <CreateProductModal onFinish={() => {setOpenModal(false); setUpdateData(!updateData);}} />}
            {createOffer && <CreateOfferModal productId = {createOffer} onFinish={() => {setCreateOffer(null); setUpdateData(!updateData);}} />}

        </Card>

    )
}