import { MailOutlined, PaperClipOutlined, PhoneOutlined, PlusOutlined, SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { Badge, Card, Input } from "antd";
import { useContext, useState } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { ElegibleResponse } from "../../../../interfaces/elegibleResponse";
import TableSG from "../../../components/design/Table/TableSG";
import "./elegible.sass"
import ButtonSG from "../../../components/design/Buttons/ButtonSG";
import { Client } from "../../../../interfaces/client";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { ContractsAddModal } from "./modal/ContractsAddModal";
import { customRequest } from "../../../../common/request/customRequest";
import { ROLE_ADMIN, ROLE_CLIENTS } from "../../../../common/constants/roles";
import AuthRoles from "../../../components/design/AuthRoles/AuthRoles";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import { DocumentsModal } from "../../common/UploadDocuments/DocumentsModal";
import { GlobalVariableContext } from "../../../../store/GlobalVariableContext/GlobalVariableContext";
import { useIsAuthorized } from "../../../../common/request/AuthorizationUtils";
import { UploadClientsModal } from "./modal/UploadClientsModal";


export const Clients = () => {

    const [updateData, setUpdateData] = useState(false);
    const {updateUserData} = useContext(GlobalVariableContext)

    const IsAuthorized = useIsAuthorized();

    const [contractModalClient, setContractModalClient] = useState<Client | null>();
    const [documentsModal, setDocumentsModal] = useState<any>(null);

    const { setClientDrawer } = useContext(DrawerContext);
    const { setUserDrawer } = useContext(DrawerContext);
    const [uploadModal, setUploadModal] = useState(false);
    
    const { data: storesData, isLoading } = useFetch(`${process.env.REACT_APP_API}/client/elegibles`, {}, [updateData, updateUserData]);

    const setProspect = async (clientId: string) => {

        const uri = `${process.env.REACT_APP_API}/client/${clientId}/prospect`;
        await customRequest(uri, { method: 'PATCH' }, 'Cliente movido a prospecto').then(() => setUpdateData(!updateData))

    }

    const columns = [
        {
            title: '',
            width: '3%',
            onFilter: (value: string, record: Client) => record.type.indexOf(value) === 0,
            sorter: (a: any, b: any) => {
                return (a.contactEMail === null ? 1 : b.contactEMail === null ? -1 : a.contactEMail.localeCompare(b.contactEMail));
            },
            render: (record: any) => {
                return (
                    <>
                        {
                            !record.contactEMail ? (
                                <span style={{ fontSize: '20px' }} className="material-symbols-rounded">person</span>
                            )
                                :
                                (
                                    <span style={{ fontSize: '20px' }} className="material-symbols-outlined">corporate_fare</span>

                                )
                        }
                    </>
                )
            }
        },
        {
            title: 'Cliente',
            render: (record: Client) => {
                return <>
                    <a onClick={() => setClientDrawer(record.id)}>{record.name}</a>
                    <p style={{ color: 'DimGray' }}><MailOutlined /> {record.email}</p>
                    <p style={{ color: 'DimGray' }}><PhoneOutlined /> {record.phone}</p>
                </>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Nombre"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: ElegibleResponse) => {
                return record.name.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: 'Contratos',
            sorter: (a: any, b: any) => b.activeContractsNumber - a.activeContractsNumber,
            render: (record: ElegibleResponse) => {
                return <>
                    {record.activeContractsNumber > 0 && <div><Badge color="green" status="processing" text={record.activeContractsNumber} /> activos</div>}
                    {record.inactiveContractsNumber > 0 && <div><Badge color="orange" text={record.inactiveContractsNumber} /> liquidados</div>}
                    {record.activeContractsNumber + record.inactiveContractsNumber == 0 && <div><small style={{color:'#bbbbbb'}}>Sin contratos</small></div>}
                </>
            }
        }
    ,
  {
    title: 'Id CRM',
    key: 'crmId',    
    render: (record: any) => (
      <div style={{color:'grey'}}>
        {record?.crmId}
      </div>
    ),    
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,

      }: FilterDropdownProps) => {
        return (
          <Input
            autoFocus
            placeholder="CRM"
            value={selectedKeys[0]}
            onChange={(e: any) => {
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              confirm({ closeDropdown: false });
            }}
            onPressEnter={() => {
              confirm();
            }}
            onBlur={() => {
              confirm();
            }}
          ></Input>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value: any, record: Client) => {
        return record.crmId?.toLowerCase().includes(value.toLowerCase());
      }
  },
        {
            title: 'eCommerce',
            render: (record: any) => (
              <div>
                {record.websName.length !== 0 ? record.websName.map((web: string) => (
                  <p key={web}>{web}</p>
                )): <small style={{color:'#bbbbbb'}}>Sin registro</small>}
              </div>
            )
        }
         , 
        {
            title: 'Documentos',
            align: 'center',
            width:'15%',
            render: (record: any) => {
              return (
                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap:'10px'}}>
                    <div  style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                  {record.documents !== null ? record.documents.map((d: any) => (
                    <div key={d.name}><small style={{display:'flex', alignItems:'center', gap:'3px'}}><PaperClipOutlined />  <b>x{d.count}</b> {d.name}</small></div>
                  )): <small style={{color:'#bbbbbb'}}>Sin documentos</small>}</div>
                  <ButtonSG size="small" onClick={() => { setDocumentsModal(record.id) }}>
                  <PlusOutlined />
                  </ButtonSG>
                </div>
              );
            },
            sorter: (a:any, b:any) => {
                if (a.documents !== null && b.documents === null) {
                  return -1;
                }
                if (a.documents === null && b.documents !== null) {
                  return 1;
                }
                return 0;
              },
          },
        {
            title: 'Comercial',
            render: (record: ElegibleResponse) => {
                return <>
                    {!!record.commercialId &&
                        <a onClick={() => { setUserDrawer(record.commercialId) }}>
                            <strong>{record.commercialId}</strong>
                        </a>
                    }
                </>
            },
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
            }: FilterDropdownProps) => {
                return (
                    <Input
                        autoFocus
                        placeholder="Comercial"
                        value={selectedKeys[0]}
                        onChange={(e: any) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : []);
                            confirm({ closeDropdown: false });
                        }}
                        onPressEnter={() => {
                            confirm();
                        }}
                        onBlur={() => {
                            confirm();
                        }}
                    ></Input>
                );
            },
            filterIcon: () => {
                return <SearchOutlined />;
            },
            onFilter: (value: any, record: Client) => {
                return record.commercialName.toLowerCase().includes(value.toLowerCase());
            },
        },
        {
            title: 'Acciones',
            render: (record: Client) => {
                return (
                    <>
                        <AuthRoles roles={[ROLE_ADMIN, ROLE_CLIENTS.WRITE]}>                           
                            <div style={{ padding: '5px 0px' }}>
                                <ButtonSG size='small'
                                    disabled={!IsAuthorized([ROLE_ADMIN, ROLE_CLIENTS.WRITE])}
                                    onClick={() => { setContractModalClient(record); }}>
                                    <small>Crear contrato</small>
                                </ButtonSG>
                            </div>
                        </AuthRoles>
                    </>
                )
            }
        }
    ]

    return (

        <>
            <Card style={{ border: 'none', background: 'white' }} bodyStyle={{ padding: "0px 10px 10px 10px" }}>
                <div className="table-header">
                    <span className='title-summary'>Clientes</span>
                </div>
        
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <AuthRoles roles={[ROLE_ADMIN]}>
                        <ButtonSG color="grey" size="small" icon={<UploadOutlined />} onClick={() => setUploadModal(true)} />
                    </AuthRoles>
                </div>

                <TableSG columns={columns} dataSource={storesData} rowKey={'id'} loading={isLoading} />
                {
                    !!contractModalClient && <ContractsAddModal client={contractModalClient} onFinish={() => { setContractModalClient(null); setUpdateData(!updateData); }} />
                }
                {
                documentsModal && <DocumentsModal entity='CLIENTS' title='Documentos de Contratos' tabs={[{key:'1', label:'LOPD', type:'pdf', tabName:'LOPD'}, {key:'2', label:'Contratos', type:'pdf', tabName:'CONTRACTS'}]} onFinish={() => {setDocumentsModal(null); setUpdateData(!updateData);}} entityId={documentsModal}/>     
                }
            </Card>

            {uploadModal && (
                <UploadClientsModal
                    onFinish={() => {
                        setUploadModal(false);
                        setUpdateData(!updateData);
                    }}
                />
            )}
        </>
    )
};