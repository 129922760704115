import { Card, Form, Input, Modal } from 'antd';
import { authFetch } from '../../../../../common/request/authFetch';
import ButtonSG from '../../../../components/design/Buttons/ButtonSG';

export const ProviderAddModal = (props : {
	visible : boolean,
	onFinish : any
	} ) => {

	const [form] = Form.useForm();
	
	const onFormFinish = async (values: any) => {
		
		const data = {
			...values,			
		};

		await authFetch(`${process.env.REACT_APP_API}/providers`, { method: 'POST', data: data });
		props.onFinish();
	};

  return (
		<Modal open={props.visible} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
		footer={[
			<div style={{padding:'10px'}}>
				<ButtonSG key="back" color='dark-white' onClick={props.onFinish}>
				Cancelar 
				</ButtonSG>
				<ButtonSG key="submit" type="primary" color="yellow"  onClick={() => form.submit()}>
				Guardar
				</ButtonSG>
			</div>
		  ]}
		>
			<h2>Nuevo Proveedor</h2>

			<Form layout='vertical' form={form} onFinish={onFormFinish}>
				<Form.Item label='Nombre' name='name' rules={[{ required: true, message: 'Por favor ingrese el nombre'}]}>
					<Input />
				</Form.Item>
				<Form.Item label='NIF' name='nif' rules={[{ required: true, message: 'Por favor ingrese el NIF'}]}>
					<Input />
				</Form.Item>
				<Form.Item label='Dirección' name='address' rules={[{ required: true, message: 'Por favor ingrese la dirección'}]}>
					<Input />
				</Form.Item>		
				<Card title='Contacto' size='small' style={{borderRadius:'10px', background:'transparent', borderColor:'lightgrey'}}>
					<Form.Item label='Nombre' name='contactName' rules={[{ required: true, message: 'Por favor ingrese el nombre de contacto'}]}>
						<Input />
					</Form.Item>
					<Form.Item label='Correo' name='contactEmail' validateTrigger='onFinish' rules={[{ required: true, message: 'Por favor ingrese el E-mail de contacto'}, { type:'email', message:'Introduce un email válido'}]}>
						<Input />
					</Form.Item>				
					<Form.Item label='Teléfono' name='contactPhone' validateTrigger='onFinish' rules={[{ required: true, message:'Por favor ingrese el teléfono de contacto' },{pattern: /^[0-9]{9,15}$/, message: 'Introduce un teléfono válido'}, ]}>
						<Input />
					</Form.Item>		
				</Card>		
			</Form>
		</Modal>
	)
}