import { Card, Radio } from 'antd';
import { Col, Row } from 'antd';
import DrawerSG from '../../../components/design/Drawer/DrawerSG';
import { useState } from 'react';
import '../../../components/model/bot/bot.sass'
import { useFetch } from '../../../../hooks/useFetch/useFetch';
import { Line, Waterfall } from '@ant-design/charts';
import moment from 'moment';


export const MarketRatesDrawer = (props: { suggestionsVisible: boolean, setSuggestionsVisible: any }) => {

	const [metal, setMetal] = useState('AU')
	const [updateData, setUpdateData] = useState(false);
	const [updateDataDay, setUpdateDataDay] = useState(false);

	const { data: metals } = useFetch(`${process.env.REACT_APP_API}/combos/metals`, {});
	const { data: monthlyData, isLoading: isLoading3Days } = useFetch(`${process.env.REACT_APP_API}/marketrates/${metal}/last12months`, {}, [updateData]);
	const { data: dailyData, isLoading: isLoading12months } = useFetch(`${process.env.REACT_APP_API}/marketrates/${metal}/last24hours`, {}, [updateData]);

	function selectMetal(metal: any) {
		setMetal(metal.target.value);
		setUpdateData(!updateData);
		setUpdateDataDay(!updateDataDay);
	}

	const { data, isLoading } = useFetch(
		`${process.env.REACT_APP_API}/opportunities`,
		{},
		[]
	);


	const { data: stockData, isLoading: isLoadingStock } = useFetch(`${process.env.REACT_APP_API}/opportunities/stockValues?metal=${metal}`, {}, [updateData]);
	const config: any = {
		data: stockData,
		xField: 'x',
		yField: 'value',
		appendPadding: [20, 0, 10, 0],

		formatter: (value: any, index: string | number) => {
			if (data[index].Total) {
				return '';
			}
		},
		meta: {
			value: {
				formatter: (v: number) => `${v.toFixed(2)} %`
			}
		},
		background: {
			style: {
				fill: '#fff6f6',
				stroke: '#e6e6e6',
				strokeOpacity: 0.65,
				radius: 2,
			},
		}, color: (v: any) => {
			if (v.x === "Total") {
				return '#9B9B9B';
			} else {
				if (v.value <= 0) {
					return '#f4664a';
				} else {
					return '#30bf78';
				}

			}
		},
		legend: {
			custom: true,
			items: [
				{
					name: 'Incremento',
					value: 'increase',
					marker: {
						symbol: 'square',
						style: {
							r: 5,
							fill: '#30bf78',
						},
					},
				},
				{
					name: 'Descenso',
					value: 'decrease',
					marker: {
						symbol: 'square',
						style: {
							r: 5,
							fill: '#f4664a',
						},
					},
				},
				{
					name: 'Total',
					value: 'total',
					marker: {
						symbol: 'square',
						style: {
							r: 5,
							fill: '#96a6a6',
						},
					},
				},
			],
		},

	}

	const chartMonthlyConfig: any = {
		data: monthlyData,
		min: 'auto',
		autoFit: 'true',
		xField: 'x',
		yField: 'value',
		color: '#00C39A',
		label: {
			formatter: (text: any) => {
				return `${text.value} €`;
			},
		},
		yAxis: {
			min: 50000
		},
		xAxis: {
			label: {
				formatter: (text: any) => {
					const formattedDate = moment(text, 'DD/MM/YY').format('DD MMM YYYY');
					return formattedDate;
				},
				autoHide: true,
				autoRotate: true,
			},
		},
		outerHeight: '30%',
		point: {
			size: 2,
			shape: 'circle',
			style: {
				fill: 'white',
				stroke: 'grey',
				lineWidth: 1
			},
		},
		lineStyle: {
			lineWidth: 4,
			cursor: 'pointer'
		},
		slider: {
			start: 0,
			end: 1,
			trendCfg: {
				isArea: true,
			},
		},
		smooth: false,
	};

	const chartDailyConfig: any = {
		data: dailyData,
		min: 'auto',
		autoFit: 'true',
		xField: 'x',
		yField: 'value',
		color: '#00C39A',
		yAxis: {
			min: 50000
		},
		xAxis: {
			label: {
				formatter: (item: any) => {
					const [dayMonth, time] = item?.split(' - ');
					const date = moment(dayMonth, 'DD/MM');
					const dayOfWeek = date.format('dddd');
					const formattedDate = `${dayOfWeek} - ${time}`;
					return formattedDate;
				},
			},
		},
		outerHeight: '30%',
		label: {
			formatter: (text: any) => {
				return `${text.value} €`;
			},
		},
		point: {
			size: 3,
			shape: 'circle',
			style: {
				fill: 'white',
				stroke: 'grey',
				lineWidth: 1
			},
		},
		lineStyle: {
			lineWidth: 4,
			cursor: 'pointer'
		},
		slider: {
			start: 0,
			end: 1,
			trendCfg: {
				isArea: true,
			},
		},
		smooth: false,
	};

	return (
		<DrawerSG
			title='Cotizaciones en euros por Onza'
			placement="bottom"
			height={'100%'}
			width={'100%'}
			onClose={() => props.setSuggestionsVisible(false)}
			visible={props.suggestionsVisible}>
			<Row justify="space-around">

				<Col  >
					<Radio.Group defaultValue="AU" buttonStyle="outline" onChange={(e) => selectMetal(e)}>
						{
							metals && metals.map((element: any) =>
								<Radio.Button value={element.key}>{element.description}</Radio.Button>
							)}
					</Radio.Group>
				</Col>
			</Row>

			{
				!isLoading12months &&
				<Card style={{ margin: '30px', borderRadius: '25px' }}>
					{
						(<>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<b style={{ fontSize: '16px' }}>Últimos 12 meses </b>
								<small>Día a día</small>
							</div>
							<Line loading={isLoading12months}	{...chartMonthlyConfig} style={{ height: '30vh', padding: '20px' }}></Line>
						</>
						)
					}
				</Card>
			}

			<Row>

				<Col span={12}>

					<Card style={{ margin: '30px', borderRadius: '25px' }}>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<b style={{ fontSize: '16px' }}>Últimos 3 meses </b>
							<small>Semanal por Jueves de cierre</small>
						</div>
						<Waterfall loading={isLoadingStock}  {...config}
						style={{ minHeight: '55%', padding: '20px' }}
						/>
					</Card>
				</Col>

				<Col span={12}>
					{!isLoading3Days &&
						<Card style={{ margin: '30px', borderRadius: '25px' }}>
							{
								(<>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<b style={{ fontSize: '16px' }}>Últimos 3 días </b>
										<small>Hora a hora</small>
									</div>
									<Line loading={isLoading3Days} {...chartDailyConfig} style={{ minHeight: '55%', padding: '20px' }}></Line>
								</>
								)
							}
						</Card>
					}
				</Col>

			</Row>
		</DrawerSG>
	)
}