import { Form, Input, Modal } from "antd"
import ButtonSG from "../../../components/design/Buttons/ButtonSG"
import { CheckOutlined } from "@ant-design/icons"
import { customRequest } from "../../../../common/request/customRequest"

export const CreateProductModal =  (props: {
	onFinish: any,
}) => {

    const [form] = Form.useForm();

    const submitProduct = async(values:any) => {

       
		await customRequest(`${process.env.REACT_APP_API}/AppProducts`, { method: 'POST', data: values }).then(()=> {
            props.onFinish();
        })

    }

    return (
        <Modal open={true} onOk={() => form.submit()} onCancel={props.onFinish} afterClose={form.resetFields}
            footer={null}
        >

            <div>
                <h2 style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '15px' }}>Nuevo Producto</h2>

            </div>

            <Form layout='vertical' form={form} onFinish={submitProduct}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Form.Item name='name'label='Nombre del producto'>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='url' label='URL al PDF'>
                        <Input/>
                    </Form.Item>
                    <ButtonSG size='small' onClick={() => form.submit()}><CheckOutlined /></ButtonSG>

                </div>
            </Form>

        </Modal>
    )
}