import { authRequest } from "../../../common/request/authRequest";


const DownloadLink = ( props: {action?: string, filename: string, entity:string, tabName:string, entityId:string, img?:boolean}) => {
  
	const handleAction = async () => {
		
	authRequest(`${process.env.REACT_APP_API}/documents/${props.entity}/${props.tabName}/${props.entityId}/${props.filename}`, { method: 'GET', responseType: 'blob',  headers: {
		Accept: props.img ? 'image/*' : 'application/pdf'
	  }})
	.then((data) => {
		
		const urlToFile = window.URL.createObjectURL(data);		
		
		if (props.action && props.action == 'open') {
			window.open(urlToFile);		
		} else {
			const link = document.createElement('a');
			link.href = urlToFile;
			link.target = '_blank'
			link.setAttribute('download', props.filename);
			document.body.appendChild(link);		
	
			link.click();

		}			
	  });
	}

	return(
		<>
			<a role='button' onClick={handleAction}>{props.filename}</a>
	  	</>
	  )
}

export default DownloadLink;