import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { Alert, Card, Col, Row, Statistic, message } from "antd";
import { Column } from '@ant-design/plots';
import { CarOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import { DonutGraph } from "./DonutGraph";
import { TSummaryAlerts } from "../../../../types/summaryAlerts";
import { TOrderStatus } from "../../../../types/orderStatus";
import { useEffect, useState } from "react";
import { authRequest } from "../../../../common/request/authRequest";
import { useAuth } from "../../../../store/AuthContext/AuthContext";
import { SpinSG } from "../../../components/model/Spin/SpinSG";

export const PetitionsSummary = () => {

    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/summary/purchases`, {}, []);
    const { allTime, monthly, weekly, ordersByStatusList, petitionsWithAndWithoutOrder, alerts } = storesData
    const [loading, setLoading] = useState(true);


    const { logOut } = useAuth();

    useEffect(() => {
        authRequest(`${process.env.REACT_APP_API}/summary/ecommerce`, {})
            .then((response) => {
                setLoading(false);

                storesData.allTime = response.allTime;
                storesData.monthly = response.monthly;
                storesData.weekly = response.weekly;
                storesData.ordersByStatusList = response.ordersByStatusList;
                storesData.petitionsWithAndWithoutOrder = response.petitionsWithAndWithoutOrder;
                storesData.alerts = response.alerts;
            })
            .catch((error) => {
                setLoading(false);
                if (error && error.status === 401) {
                    message.info('La sesión ha caducado');
                    logOut()
                } else {
                    throw error.response;
                }
            });
    }, [])


    const allTimeData: TOrderStatus = {
        PENDING: 0,
        IN_PROGRESS: 0,
        RECEIVED: 0
    };

    ordersByStatusList && ordersByStatusList.forEach(({ x, value }: { x: string, value: number }) => {
        allTimeData[x as keyof TOrderStatus] += value;
    });

    const config : any = {
        xField: 'x',
        yField: 'value',
        height: 250,
        color: "#58C09C",
        maxColumnWidth: 30,
        isStack: true,
        label: {
          position: 'middle',
          style: {
            fill: '#fffbe6',
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          value: {
            alias: 'Peticiones',
          }          
        }
      };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Card style={{ border: 'none' }} bodyStyle={{ padding: "0px 10px 10px 10px" }} >
                <div className="table-header">
                    <span className='title-summary'>Panel de Compras</span>
                </div>
            </Card>
            {loading ? <SpinSG /> : 

            <><Row gutter={[8, 8]}>
                    <Col span={6}>
                        <Card style={{ border: 'none' }}>                            
                            <div >
                            <div className='title-chart'>TOTALES</div>
                            <div className='title-chart-secondary'>Órdenes de Compra</div>
                                <Card style={{borderRadius: '10px', background: '#fffcff' }}>
                                    {allTimeData &&
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                            <Statistic title={<div style={{ display: 'flex', justifyContent: 'space-between', width:'200px' }}><ShoppingCartOutlined style={{ fontSize: '20px' }} />Pendiente de tramitar</div>} className='kpi' value={allTimeData.PENDING} />
                                            <Statistic title={<div style={{ display: 'flex', justifyContent: 'space-between', width:'200px' }}><CarOutlined style={{ fontSize: '20px' }} />Pendiente Proveedor </div>} className='kpi' value={allTimeData.IN_PROGRESS} />
                                            <Statistic title={<div style={{ display: 'flex', justifyContent: 'space-between', width:'200px' }}> <span className="material-symbols-outlined">barcode_reader </span>Recibidas</div>} className='kpi' value={allTimeData.RECEIVED} />                                                                                         
                                        </div>}
                                </Card>
                            </div>                            
                        </Card>
                    </Col>
                    <Col span={10}>
                        <Card style={{ border: 'none' }} bodyStyle={{ margin: "15px", display: 'flex' }}>                                                                                    
                            <div>
                                <div className='title-chart' style={{visibility:'hidden'}}>.</div>
                                <span style={{ marginLeft: '80px' }} className='title-chart-secondary'>Peticiones Internas</span>
                                <div style={{ width: '550px', marginTop: '-50px' }}>
                                    {petitionsWithAndWithoutOrder && <DonutGraph data={petitionsWithAndWithoutOrder} />}
                                </div>
                            </div>
                        </Card>

                    </Col>
                    <Col span={8}>
                        <Card style={{ border: 'none' }}>
                            <div className='title-chart'>ALERTAS</div>
                            <div style={{maxHeight:'30vh', overflowY: 'scroll'}}>
                                { alerts && alerts.length > 0 ? 
                                    alerts.map((alert: any) => {
                                        return <Alert type={alert.severity} message={alert.description} showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />;
                                    })
                                    :
                                    <Alert type={'success'} message='No hay alertas' showIcon style={{ padding: '1px 10px', borderRadius: '8px', marginBottom: '15px' }} />
                                }
                            </div>
                        </Card>
                    </Col>
                </Row><Col span={8}>
                        <div style={{ paddingLeft: '24px' }}>
                            <span className='title-chart'>HISTÓRICO</span>
                        </div>
                    </Col><Row gutter={[8, 8]}>
                        <Col span={16}>
                            <Card style={{ border: 'none' }}>
                                <div className='title-chart-secondary'>Nuevas peticiones por mes</div>
                                {monthly && <Column data={monthly.adminMonthlyChartActionDot} {...config} />}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card style={{ border: 'none' }}>
                                <div className='title-chart'>ÚLTIMA SEMANA</div>
                                {weekly && weekly.notes.map((comment: string) => {
                                    return (<div>{comment}</div>);
                                })}
                            </Card>
                        </Col>
                    </Row></>
            }
        </div>
    )
}

