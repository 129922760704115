import { Alert, Card, Col, Row } from "antd";
import { useContext } from "react";
import { useFetch } from "../../../../hooks/useFetch/useFetch";
import { DrawerContext } from "../../../../store/DrawerContext/DrawerContext";
import DrawerSG from "../../../components/design/Drawer/DrawerSG";
import { SpinSG } from "../../../components/model/Spin/SpinSG";
import WeightSG from "../../../components/design/Weight/Weight";

const ProductDrawer = () => {

  const { productDrawer, setProductDrawer } = useContext(DrawerContext)

  const { data: productData, isLoading } = useFetch(
    `${process.env.REACT_APP_API}/producttypes/${productDrawer}`,
    {},
    [],
  );

  return (
    <>
      {
        (productDrawer &&
          <DrawerSG
            title={productData.name}            
            onClose={() => setProductDrawer(null)}
            placement='right'
            visible={!!productDrawer}
          >
            {isLoading ? <SpinSG /> :
              (productData ?
                <Card style={{borderRadius:'25px'}} size='small'>
                <div style={{ padding: '10px 30px' }}>
                  <div>
                    <b>SKU</b>
                    <div className="item-view-product">{productData.sku}</div>
                  </div>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <div>
                        <b>Tipo</b>
                        <div className="item-view-product">{productData.type}</div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <b>Metal</b>
                        <div className="item-view-product">{productData.metal}</div>
                      </div>
                    </Col>      
                    
                  </Row>
                  <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <div>
                        <b>Peso</b>
                        <div className="item-view-product">{productData?.weightInGrams ? <WeightSG weightInGrams={productData.weightInGrams} weightInOunces={productData.weightInOnzas} units={productData.unit}/>: "-"}</div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <b>Marca</b>
                        <div className="item-view-product">{productData.brand || "-"}</div>
                      </div>
                    </Col>           
                  </Row>                  
                  <Row gutter={[16, 16]}>
                  <Col span={12}>
                      <div>
                        <b>País</b>
                        <div className="item-view-product">{productData.country || "-"}</div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <b>Año</b>
                        <div className="item-view-product">{productData.year || "-"}</div>
                      </div>
                    </Col>       
                  </Row>
                  </div></Card>
                  :
                  <Card style={{ minHeight: '300px' }}>
                    <Alert message="Este tipo de producto no está definido en Logística" type="warning" showIcon />
                  </Card>)}
                </DrawerSG>
        )
            }
          </>
        )
      }

      export default ProductDrawer;